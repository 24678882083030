import { userRoles } from "../../authentication/components/userRoles";

const privatePages = [
    {name: 'Rovisen Map', path: '/mainMap', allowedRoles: [userRoles.admin]},
    {name: 'Sentinel', path:'/dashboard-sentinel', allowedRoles: [userRoles.admin]},
    {name: 'Risks Map', path: '/risks', allowedRoles: [userRoles.risk]},
    {name: 'Processes', path: '/', allowedRoles: [userRoles.inference]},
];

const privateSettings = [
    {name: 'Perfil', path:'/profile'},
];
const publicPages: {name: string, path: string}[] = [
];

const publicSettings = [
    {name: 'Ingresar', path:'/login'},
    // {name: 'Register', path:'/register'},
];


const roleInitialPaths = {
    "admin": '/mainMap',
    "risk": '/risks',
    "label": '/rovlabel',
    "inference": '/'
};

function filterUserPages(roles: string[], pages: {name: string, path: string, allowedRoles: string[]}[]) {
    if (roles.includes(userRoles.admin)) {
        return pages;
    }
    return pages.filter(page => page.allowedRoles.some(role => roles.includes(role)));
}

export {
    privatePages,
    publicPages,
    publicSettings,
    privateSettings,
    roleInitialPaths,
    filterUserPages
};
