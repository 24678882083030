import { Box, IconButton, List, ListItem, ListItemButton, ListItemText, Menu, MenuItem } from "@mui/material";
import { grey } from "@mui/material/colors";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../app/state/store";
import { selectProjectInfo, selectState, setSelectedBatchIndex, setSelectedTaskIndex } from "../../state/rovLabelSlice";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { useState } from "react";

function TaskTab() {
    const { selectedBatchIndex, selectedTaskIndex } = useSelector((state: RootState) => selectState(state));
    const projectInfo = useSelector((state: RootState) => selectProjectInfo(state));
    const dispatch = useDispatch<AppDispatch>();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Box
            className={'upper box'}
            padding={2}
            flexGrow={1}
            sx={{ display: 'flex', flexDirection: 'column' }}
        >
            <List
                sx={{
                    bgcolor: 'background.paper',
                    flexGrow: 1,
                    overflow: 'auto',
                    height: 0,
                }}
                component="nav"
                subheader={
                    <Box
                        component="div"
                        id="nested-list-subheader"
                        bgcolor={grey[200]}
                        sx={{position: 'sticky', top: 0, zIndex: 1}}
                        marginBottom={2}
                    >
                        <IconButton onClick={handleOpen}>
                            {Boolean(anchorEl) ? <ExpandLess fontSize="small"/> : <ExpandMore fontSize="small"/>}
                        </IconButton>
                        <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >
                            {projectInfo.batches &&
                                projectInfo.batches.map((batch: any, index: number) => {
                                    return (
                                        <MenuItem
                                            key={batch.id}
                                            onClick={(e) => {
                                                dispatch(setSelectedBatchIndex({batchIndex: index}));
                                                dispatch(setSelectedTaskIndex({taskIndex: 0}));
                                                handleClose();
                                            }}
                                        >
                                            Batch {index + 1}
                                        </MenuItem>
                                    );
                                })
                            }
                        </Menu>
                        Batch {projectInfo.batches && projectInfo.batches[selectedBatchIndex] && selectedBatchIndex + 1}
                    </Box>
                }
            >
                <Box flexGrow={1} sx={{ display: 'flex', flexDirection: 'column' }}>
                    {projectInfo.batches &&
                    projectInfo.batches[selectedBatchIndex] &&
                    projectInfo.batches[selectedBatchIndex].tasks &&
                    projectInfo.batches[selectedBatchIndex].tasks.map((task: any, index: number) => (
                        <ListItem key={task.id} disablePadding>
                            <ListItemButton
                                onClick={() => {
                                    dispatch(setSelectedTaskIndex({taskIndex: index}))
                                }}
                                sx={{ backgroundColor: selectedTaskIndex === index ? grey[200]: 'white',
                                    border: 1, borderColor: 'grey.600',
                                    padding: 0}}
                            >
                                <ListItemText primary={`Task ${index + 1}`} />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </Box>
            </List>
        </Box>
    );
}

export default TaskTab;
