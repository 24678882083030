import { Box, Breadcrumbs, Container, Typography, useTheme } from "@mui/material";
import CardSelector, { CardDetails } from "../components/common/CardSelector";
import { useGetPipelineModelsQuery } from "../api/processApi";


const ProcessLibrary = () => {
    const { data, isLoading } = useGetPipelineModelsQuery()

    const analysisProcesses = data
    const theme = useTheme()

    return <Box sx={{ height: 1, width: 1, overflow: 'auto', backgroundColor: theme.palette.background.default }}>
        <Breadcrumbs aria-label="breadcrumb" separator=">" sx={{ paddingX: 5, paddingY: 2 }}>
            <Typography color="textPrimary">Procesos</Typography>
        </Breadcrumbs>
        <Container>
            <CardSelector
                data={analysisProcesses as CardDetails[]}
                isLoading={isLoading}
            />
        </Container>
    </Box>
}

export default ProcessLibrary