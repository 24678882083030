import { Fragment } from 'react'
import EditVertex from './EditVertex'
import EditPolyline from './EditPolyline'
import { useSelector } from 'react-redux';
import { selectPolygonById } from '../../state/rovLabelSlice';
import { RootState } from '../../../app/state/store';
import CenterMarker from './CenterMarker';


function EditingPolygon({ polygonId }: {polygonId: number}) {
    const { vertexs, typeColor } = useSelector((state: RootState) => selectPolygonById(state, polygonId));

    const pathOptions = {
        color: typeColor,
        weight: 2,
    }

    return (
        <>
            <CenterMarker polygonId={polygonId} />
            {vertexs.map((vertex: any, index: number, array: any[]) => {
                return (
                    <Fragment key={`fragment_${vertex.id}`}>
                        <EditVertex
                            polygonId={polygonId}
                            id={vertex.id}
                            position={vertex.coord}
                            draggable={true}
                            pathOptions={pathOptions}
                        />
                        <EditPolyline
                            polygonId={polygonId}
                            vertex1={vertex}
                            vertex2={array[(index + 1) % array.length]}
                            index={index + 1}
                            pathOptions={pathOptions}
                        />
                    </Fragment>
                )
            })};
        </>
    )
}

export default EditingPolygon;
