import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import { useGetPipelineInstancesQuery } from "../../process/api/processApi";
import ExecutionsDataGrid from "../../process/components/common/ExecutionsDataGrid";
import { useGetOrgCreditsQuery } from "../api/authApi";



const History = () => {
    const { data, isFetching } = useGetPipelineInstancesQuery();
    const { data: creditsData, isFetching: creditsIsFetching } = useGetOrgCreditsQuery();

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: 1,
                width: 1,
                boxSizing: 'border-box',
                padding: 3
            }}
        >
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 2 }}>
                <Typography variant="h5" marginRight={3}>Registros</Typography>
            </Box>
            <Stack direction='row' alignItems='center' spacing={1}>
                <Typography>Créditos disponibles:</Typography>
                {creditsIsFetching ? <CircularProgress size={20}/> : <Typography>{creditsData?.credits ? `${creditsData.credits.toFixed(2)}` : '-'}</Typography>}
            </Stack>
            <Box sx={{ flexGrow: 1, overflow: "hidden", width: 1 }}>
                <ExecutionsDataGrid instances={data ? data : []} isLoading={isFetching} searchBar excludeColumns={['runtime','actions']} columnOrder={['id', 'name', 'pipeline_model_id', 'cost']} />
            </Box>
        </Box>
    );
}

export default History;
