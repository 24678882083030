import { latLng, LatLngBounds, latLngBounds, PathOptions } from "leaflet"
import { Dispatch, SetStateAction } from "react"
import { CircleMarker, Polygon, Polyline, Tooltip } from "react-leaflet"
import { GeoJSONGeometry } from "wellknown"

interface GeoJsonLayerOptions {
    data: GeoJSONGeometry,
    setBbox?: Dispatch<SetStateAction<LatLngBounds | undefined>>
    pathOptions?: PathOptions
    pane?: string
    onClick?: (e: any) => void
    tooltip?: any
    onHover?: (e: any) => void
}

const GeoJsonLayer = ({ data, setBbox, pathOptions, pane, onClick, tooltip, onHover }: GeoJsonLayerOptions) => {
    const eventHandlers = {
        click: (e: any) => onClick && onClick(e),
        mouseover: () => onHover && onHover(true),
        mouseout: () => onHover && onHover(false),
    }

    switch (data.type) {
        case "MultiPolygon":
            const multiVertexs = data.coordinates.map(polygon => polygon.map(rings => rings.map(coords => latLng(coords[1], coords[0]))))
            setBbox && setBbox(latLngBounds(multiVertexs.flat(2)))
            return <Polygon positions={multiVertexs} pathOptions={pathOptions} pane={pane} eventHandlers={eventHandlers}>
                {tooltip && <Tooltip permanent direction="top">{tooltip}</Tooltip>}
            </Polygon>

        case "Polygon":
            const vertexs = data.coordinates.map((polygon: any) => polygon.map((coords: any) => [coords[1], coords[0]]))
            setBbox && setBbox(latLngBounds(vertexs.flat(1)))
            return <Polygon positions={vertexs} pathOptions={pathOptions} pane={pane} eventHandlers={eventHandlers}>
                {tooltip && <Tooltip permanent direction="top">{tooltip}</Tooltip>}
            </Polygon>

        case "Point":
            const point = latLng(data.coordinates[1], data.coordinates[0])
            setBbox && setBbox(point.toBounds(1000))
            return <CircleMarker radius={10} center={point} pathOptions={pathOptions} pane={pane} eventHandlers={eventHandlers}>
                {tooltip && <Tooltip permanent direction="top">{tooltip}</Tooltip>}
            </CircleMarker>
        case "MultiPoint":
            const points = data.coordinates.map(point => latLng(point[1], point[0]))
            setBbox && setBbox(latLngBounds(points))
            return <>{points.map((point, index) =>
                <CircleMarker radius={10} center={point} key={index} pathOptions={pathOptions} pane={pane} eventHandlers={eventHandlers} >
                    {tooltip && <Tooltip permanent direction="top">{tooltip}</Tooltip>}
                </CircleMarker>
            )}</>
        case "LineString":
            const lineVertexs = data.coordinates.map(vertex => latLng(vertex[1], vertex[0]))
            setBbox && setBbox(latLngBounds(lineVertexs))
            return <Polyline positions={lineVertexs} pathOptions={pathOptions} pane={pane} eventHandlers={eventHandlers}>
                {tooltip && <Tooltip permanent direction="top">{tooltip}</Tooltip>}
            </Polyline>
        case "MultiLineString":
            const multiLineVertexs = data.coordinates.map(line => line.map((vertex) => latLng(vertex[1], vertex[0])))
            setBbox && setBbox(latLngBounds(multiLineVertexs.flat()))
            return <Polyline positions={multiLineVertexs} pathOptions={pathOptions} pane={pane} eventHandlers={eventHandlers}>
                {tooltip && <Tooltip permanent direction="top">{tooltip}</Tooltip>}
            </Polyline>
        case "GeometryCollection":
            return <>{data.geometries.map((geometry, index) => <GeoJsonLayer data={geometry} key={index} pathOptions={pathOptions} pane={pane} />)}</>
    }
}

export default GeoJsonLayer