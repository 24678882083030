import { LoadingButton } from "@mui/lab";
import {
    Box,
    Grid,
    Icon,
    Link,
    Typography
} from "@mui/material";
import { jwtDecode } from "jwt-decode";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import packageJson from '../../../package.json';
import { roleInitialPaths } from "../../app/routes/routes";
import { AppDispatch } from "../../app/state/store";
import { ErrorAlertDialog } from "../../common/components/UI/general/AlertDialog";
import { loginApi } from "../api/authApi";
import { logIn } from "../state/authSlice";
import CustomJwt from "../utils/customJwt";
import { FormContainer, FormTextField, FormHeader } from "./Form";

function Login() {
    const dispatch = useDispatch<AppDispatch>();
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setLoading(true);
        const data = new FormData(event.currentTarget);
        loginApi(data.get('email') as string, data.get('password') as string)
            .then(response => {
                setLoading(false);
                if (response?.access_token) {
                    const decoded = jwtDecode<CustomJwt>(response.access_token);
                    dispatch(logIn(response.access_token));
                    const defaultPath = roleInitialPaths[decoded?.roles[0] as keyof typeof roleInitialPaths];
                    location.state
                        ? location.state.from.pathname === '/'
                            ? navigate(defaultPath)
                            : navigate(location.state.from)
                        : navigate(defaultPath);
                } else {
                    setAlertOpen(true);
                    response?.detail ? setAlertMessage(response.detail) : setAlertMessage('Error desconocido, por favor intente más tarde');
                }
            });
    };

    return (
        <FormContainer>
            <FormHeader title="Inicio de Sesión" />
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                <FormTextField id="email" label="Email" name="email" autoComplete="email" autoFocus />
                <FormTextField id="password" label="Contraseña" name="password" type="password" autoComplete="current-password" />
                <LoadingButton
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    loading={loading}
                    loadingPosition="end"
                    endIcon={<Icon />}
                >
                    Iniciar Sesión
                </LoadingButton>
                <Grid container>
                    <Grid item xs>
                        <Link href="/auth/password-recovery" variant="body2">
                            ¿Olvidaste tu contraseña?
                        </Link>
                    </Grid>
                    <Grid item>
                        <Link href="/register" variant="body2">
                            {"¿No tienes cuenta? Regístrate aquí"}
                        </Link>
                    </Grid>
                </Grid>
            </Box>
            <Box sx={{ width: 1 }}>
                <Typography marginTop={2} textAlign='right' color='lightgray' variant="body2">Version: {packageJson.version}</Typography>
            </Box>
            <ErrorAlertDialog alertOpen={alertOpen} setAlertOpen={setAlertOpen} message={alertMessage} />
        </FormContainer>
    );
}

export default Login;
