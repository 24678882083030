import { TileLayer, TileLayerProps } from "react-leaflet"

interface RovisenLayerProps extends Omit<TileLayerProps, "url"> {
    name: string
    layerStyle: string
    format?: string
    params?: { [key: string]: string }
}


const RovisenLayer = ({ name, layerStyle, format = 'image/png', params, ...options }: RovisenLayerProps) => {
    const url = `https://geo-api.rovisen.com:8443/geoserver/gwc/service/wmts/rest/${name}/${layerStyle}/EPSG:900913/EPSG:900913:{z}/{y}/{x}?format=${format}`
    return <TileLayer url={params? url + Object.keys(params).reduce((prev, current) => prev + `&${current}=${params[current]}`, "") : url} {...options} />
}

export default RovisenLayer;
