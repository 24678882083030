import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { wktToCoordinates, getCenterFromVertex } from "../utils/rovLabelHelpers";

const modes = {
    CREATE: "create",
    EDIT: "edit",
    NONE: "none",
};

interface RovLabelState {
    projectInfo: any;
    taskInfo: any;
    mode: string;
    selectedItem: string;
    selectedProjectId: number;
    selectedBatchIndex: number;
    selectedTaskIndex: number;
    selectedTypeId: number;
    selectedPolygonId: number;
    nextPolygonId: number;
    polygons: {[key: string]: LabelPolygon};
    imageNumber: number;
    maxImageNumber: number;
};

interface LabelPolygon {
    id: number;
    dbId?: number;
    updated?: boolean;
    typeId: number;
    typeColor: "",
    nextVertexId: number;
    selectedVertexId: number;
    vertexs: any[];
    canDeleteVertex: boolean;
    finished: boolean;
    editing: boolean;
    visible: boolean;
    center: any;
    oldVertexs: any[];
};

const initialState: RovLabelState = {
    projectInfo: {},
    taskInfo: {},
    mode: modes.NONE,
    selectedItem: "",
    selectedProjectId: -1,
    selectedBatchIndex: 0,
    selectedTaskIndex: 0,
    selectedTypeId: -1,
    selectedPolygonId: -1,
    nextPolygonId: 0,
    polygons: {},
    imageNumber: 0,
    maxImageNumber: 1,
};

const rovLabelSlice = createSlice({
    name: 'rovLabel',
    initialState,
    reducers: {
        // project setup actions
        resetRovLabelState: (state) => {
            return initialState
        },
        setProjectInfo: (state, action: PayloadAction<{projectInfo: any}>) => {
            const { projectInfo } = action.payload;
            state.projectInfo = projectInfo;
        },

        // task setup actions
        resetTaskData: (state) => {
            state.taskInfo = {};
            state.selectedPolygonId = -1;
            state.nextPolygonId = 0;
            state.polygons = {};
            state.imageNumber = 0;
            state.maxImageNumber = 1;
        },
        setTaskInfo: (state, action: PayloadAction<{taskInfo: any}>) => {
            const { taskInfo } = action.payload;
            state.taskInfo = taskInfo;
        },
        setMaxImageNumber: (state, action: PayloadAction<{maxImageNumber: number}>) => {
            const { maxImageNumber } = action.payload;
            state.maxImageNumber = maxImageNumber;
        },
        setImageNumber: (state, action: PayloadAction<{imageNumber: number}>) => {
            const { imageNumber } = action.payload;
            state.imageNumber = imageNumber;
        },
        loadPolygon: (state, action: PayloadAction<{label: any}>) => {
            const { label } = action.payload;
            const vertexs = wktToCoordinates(label.geom)
            const polygonInstance: LabelPolygon = {
                id: state.nextPolygonId,
                dbId: label.id,
                updated: false,
                typeId: label.type_id,
                typeColor: state.projectInfo.types.find((labelType: any) => labelType.id === label.type_id).colour,
                nextVertexId: vertexs.length,
                selectedVertexId: -1,
                vertexs: vertexs.map((coord: any, index: number) => {return {id: index, coord: coord}}),
                canDeleteVertex: vertexs.length > 3,
                finished: true,
                editing: false,
                visible: true,
                center: getCenterFromVertex(vertexs),
                oldVertexs: []
            };
            state.polygons[state.nextPolygonId] = polygonInstance;
            state.nextPolygonId++;
        },

        // general actions
        setSelectedType: (state, action: PayloadAction<{typeId: number}>) => {
            const { typeId: selectedTypeId } = action.payload;
            state.selectedTypeId = selectedTypeId;
        },
        setSelectedItem: (state, action: PayloadAction<{item: string}>) => {
            const { item: selectedItem } = action.payload;
            state.selectedItem = selectedItem;
        },
        setSelectedPolygon: (state, action: PayloadAction<{polygonId: number}>) => {
            const { polygonId } = action.payload;
            if (state.mode === modes.NONE || state.mode === modes.EDIT){
                if (state.selectedPolygonId !== -1 && state.selectedPolygonId !== polygonId){
                    state.polygons[state.selectedPolygonId].editing = false;
                    state.polygons[state.selectedPolygonId].selectedVertexId = -1;
                }
                state.selectedItem = 'polygon';
                state.selectedPolygonId = polygonId;
                state.polygons[polygonId].selectedVertexId = -1;
                state.polygons[polygonId].dbId && (state.polygons[polygonId].updated = true);
            }
        },
        deselectPolygon: (state) => {
            if (state.mode === modes.NONE || state.mode === modes.EDIT){
                if (state.selectedPolygonId !== -1){
                    state.polygons[state.selectedPolygonId].editing = false;
                }
                state.selectedPolygonId = -1;
                state.selectedTypeId = -1;
                state.selectedItem = "";
                state.mode = modes.NONE;
            }

        },
        setSelectedProjectId: (state, action: PayloadAction<{projectId: number}>) => {
            const { projectId } = action.payload;
            state.selectedProjectId = projectId;
        },
        setSelectedBatchIndex: (state, action: PayloadAction<{batchIndex: number}>) => {
            const { batchIndex } = action.payload;
            state.selectedBatchIndex = batchIndex;
        },
        setSelectedTaskIndex: (state, action: PayloadAction<{taskIndex: number}>) => {
            const { taskIndex } = action.payload;
            // validate if there are tasks
            const tasks = state.projectInfo.batches &&
                state.projectInfo.batches[state.selectedBatchIndex] &&
                state.projectInfo.batches[state.selectedBatchIndex].tasks;
            // validate if task is within the number of tasks
            if (tasks && taskIndex > -1 &&
                taskIndex < state.projectInfo.batches[state.selectedBatchIndex].tasks.length &&
                state.selectedTaskIndex !== taskIndex){
                    state.polygons = {};
                    state.selectedPolygonId = -1;
                    state.nextPolygonId = 0;
                    state.imageNumber = 0;
                    state.maxImageNumber = 1;
                    state.mode = modes.NONE;
                    state.selectedTypeId = -1;

                    state.selectedTaskIndex = taskIndex;
            }
        },
        nextTask: (state) => {
            // validate if there are tasks
            const tasks = state.projectInfo.batches &&
                state.projectInfo.batches[state.selectedBatchIndex] &&
                state.projectInfo.batches[state.selectedBatchIndex].tasks;
            // validate if the next task is within the number of tasks
            if (tasks && state.selectedTaskIndex < tasks.length -1){
                state.taskInfo = {};
                state.selectedPolygonId = -1;
                state.nextPolygonId = 0;
                state.polygons = {};
                state.imageNumber = 0;
                state.maxImageNumber = 1;

                state.selectedTaskIndex++;
            }
        },
        previousTask: (state) => {
            if (state.selectedTaskIndex > 0){
                state.taskInfo = {};
                state.selectedPolygonId = -1;
                state.nextPolygonId = 0;
                state.polygons = {};
                state.imageNumber = 0;
                state.maxImageNumber = 1;

                state.selectedTaskIndex--;
            }
        },
        setTaskValid: (state, action: PayloadAction<{valid: boolean}>) => {
            const { valid } = action.payload;
            state.taskInfo.is_valid = valid;
        },


        //polygon actions
        addPolygon: (state) => {
            if (state.mode === modes.NONE){
                state.mode = modes.CREATE;
                const polygonInstance: LabelPolygon = {
                    id: state.nextPolygonId,
                    typeId: state.selectedTypeId,
                    typeColor: state.projectInfo.types ?
                        state.projectInfo.types.find((labelType: any) => labelType.id === state.selectedTypeId).colour :
                        "#E74C3C",
                    nextVertexId: 0,
                    selectedVertexId: -1,
                    vertexs: [],
                    canDeleteVertex: false,
                    finished: false,
                    editing: false,
                    visible: true,
                    center: [0,0],
                    oldVertexs: [],
                };
                state.polygons[state.nextPolygonId] = polygonInstance;
                state.nextPolygonId++;

                state.selectedPolygonId = polygonInstance.id;
                state.selectedItem = 'polygon';
            }
        },
        setPolygonEditing: (state, action: PayloadAction<{polygonId: number, editing: boolean}>) => {
            const { polygonId, editing } = action.payload;
            if (state.mode === modes.NONE || state.mode === modes.EDIT){
                if (editing) {
                    state.mode = modes.EDIT;
                    state.polygons[polygonId].editing = editing;
                    state.polygons[polygonId].center = getCenterFromVertex(state.polygons[polygonId].vertexs.map((vertex) => vertex.coord));
                }
                else {
                    state.mode = modes.NONE;
                    state.polygons[polygonId].editing = false;
                }
            }
        },
        deletePolygon: (state, action: PayloadAction<{polygonId: number}>) => {
            const { polygonId } = action.payload;
            delete state.polygons[polygonId];
            if (polygonId === state.selectedPolygonId) {
                state.selectedPolygonId = -1;
                state.selectedTypeId = -1;
                state.mode = modes.NONE;
            }
        },
        setPolygonVisibility: (state, action: PayloadAction<{polygonId: number, visible: boolean}>) => {
            const { polygonId, visible } = action.payload;
            state.polygons[polygonId].visible = visible;
        },
        setPolygonType: (state, action: PayloadAction<{polygonId: number, typeId: number}>) => {
            const { polygonId, typeId } = action.payload;
            state.polygons[polygonId].typeId = typeId;
            state.polygons[polygonId].typeColor = state.projectInfo.types.find((labelType: any) => labelType.id === typeId).colour;
        },

        // create polygon actions
        addPolygonVertex: (state, action: PayloadAction<{polygonId: number, coord: any}>) => {
            const { polygonId, coord } = action.payload;
            const polygon = state.polygons[polygonId];
            const vertexId = polygon.nextVertexId;
            polygon.vertexs.push({id: vertexId, coord: coord});
            polygon.nextVertexId++;
            polygon.canDeleteVertex = polygon.vertexs.length > 3;
            state.polygons[polygonId].center = getCenterFromVertex(state.polygons[polygonId].vertexs.map((vertex) => vertex.coord));
        },
        setPolygonfinished: (state, action: PayloadAction<{polygonId: number}>) => {
            const { polygonId } = action.payload;
            const polygon = state.polygons[polygonId];
            if (polygon.vertexs.length > 2) {
                polygon.finished = true;
                state.selectedPolygonId = -1;
                state.selectedTypeId = -1;
                state.mode = modes.NONE;
            }
        },

        // edit polygon actions
        setSelectedVertex: (state, action: PayloadAction<{polygonId: number, vertexId: number}>) => {
            const { polygonId, vertexId } = action.payload;
            state.selectedItem = 'vertex';
            state.polygons[polygonId].selectedVertexId = vertexId;
        },
        setVertexPos: (state, action: PayloadAction<{polygonId: number, vertexId: number, coord: any}>) => {
            const { polygonId, vertexId, coord } = action.payload;
            state.polygons[polygonId].vertexs.find((vertex: any) => vertex.id === vertexId).coord = coord;
            state.polygons[polygonId].center = getCenterFromVertex(state.polygons[polygonId].vertexs.map((vertex) => vertex.coord));
        },
        insertPolygonVertex: (state, action: PayloadAction<{polygonId: number, coord: any, index: number}>) => {
            const { polygonId, coord, index } = action.payload;
            const polygon = state.polygons[polygonId];
            const vertexId = polygon.nextVertexId;
            polygon.vertexs.splice(index, 0, {id: vertexId, coord: coord});
            polygon.nextVertexId++;
            polygon.canDeleteVertex = polygon.vertexs.length > 3;
            state.polygons[polygonId].center = getCenterFromVertex(state.polygons[polygonId].vertexs.map((vertex) => vertex.coord));
        },
        deletePolygonVertex: (state, action: PayloadAction<{polygonId: number, vertexId: number}>) => {
            const { polygonId, vertexId } = action.payload;
            const polygon = state.polygons[polygonId];
            const index = polygon.vertexs.findIndex((vertex: any) => vertex.id === vertexId);
            polygon.vertexs = polygon.vertexs.slice(0, index).concat(polygon.vertexs.slice(index + 1));
            polygon.selectedVertexId = polygon.vertexs[index % polygon.vertexs.length].id;
            polygon.canDeleteVertex = polygon.vertexs.length > 3;
            state.polygons[polygonId].center = getCenterFromVertex(state.polygons[polygonId].vertexs.map((vertex) => vertex.coord));
        },
        setCenterofPolygon: (state, action: PayloadAction<{polygonId: number, diff: number[]}>) => {
            const { polygonId, diff } = action.payload
            const polygon = state.polygons[polygonId]
            polygon.vertexs = polygon.vertexs.map((vertex, index) => {
                vertex.coord[0] = polygon.oldVertexs[index].coord[0] + diff[0]
                vertex.coord[1] = polygon.oldVertexs[index].coord[1] + diff[1]
                return vertex
            })
        },
        setInitDragging: (state, action: PayloadAction<{polygonId: number}>) => {
            const {polygonId} = action.payload;
            state.polygons[polygonId].oldVertexs = [...state.polygons[polygonId].vertexs]
        }
    },

    selectors: {
        selectState: (state) => state,
        selectProjectInfo: (state) => state.projectInfo,
        selectTaskInfo: (state) => state.taskInfo,
        selectPolygons: (state) => state.polygons,
        selectSelectedPolygonId: (state) => state.selectedPolygonId,
        selectPolygonById: (state, polygonId) => state.polygons[polygonId],
    }
});


export const {
    // project setup actions
    resetRovLabelState,
    setProjectInfo,

    // task setup actions
    resetTaskData,
    setTaskInfo,
    setMaxImageNumber,
    setImageNumber,
    loadPolygon,
    // general actions
    setSelectedType,
    setSelectedItem,
    setSelectedPolygon,
    deselectPolygon,
    setSelectedProjectId,
    setSelectedBatchIndex,
    setSelectedTaskIndex,
    nextTask,
    previousTask,
    setTaskValid,
    //polygon actions
    addPolygon,
    setPolygonEditing,
    deletePolygon,
    setPolygonVisibility,
    setPolygonType,
    // create polygon actions
    addPolygonVertex,
    setPolygonfinished,
    // edit polygon actions
    setSelectedVertex,
    setVertexPos,
    insertPolygonVertex,
    deletePolygonVertex,
    setCenterofPolygon,
    setInitDragging,
} = rovLabelSlice.actions;

export const {
    selectState,
    selectProjectInfo,
    selectTaskInfo,
    selectPolygons,
    selectSelectedPolygonId,
    selectPolygonById,
} = rovLabelSlice.selectors;

export default rovLabelSlice.reducer;
