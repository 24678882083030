import { latLng, LatLng, LatLngBounds, latLngBounds } from "leaflet";
import { parse } from "wellknown";

export const geodesicArea = (latLngs: LatLng[]) => {
    var pointsCount = latLngs.length,
        area = 0.0,
        d2r = Math.PI / 180,
        p1, p2;

    if (pointsCount > 2) {
        for (var i = 0; i < pointsCount; i++) {
            p1 = latLngs[i];
            p2 = latLngs[(i + 1) % pointsCount];
            area += ((p2.lng - p1.lng) * d2r) *
                (2 + Math.sin(p1.lat * d2r) + Math.sin(p2.lat * d2r));
        }
        area = area * 6378137.0 * 6378137.0 / 2.0;
    }

    return Math.abs(area);
}

export const geodesicDistance = (latLngs: LatLng[]) => {
    var distance = 0.0;
    for (var i = 0; i < latLngs.length - 1; i++) {
        distance += latLngs[i].distanceTo(latLngs[i + 1])
    }
    return distance
}

export const centroid = (latLngs: LatLng[]) => {
    if (latLngs.length === 0) {
        return latLng(0, 0)
    }


    if (latLngs.length > 2) {
        const bbox = latLngBounds(latLngs)
        return bbox.getCenter()
    }
    else {
        var lat = 0, lng = 0
        for (var i = 0; i < latLngs.length; i++) {
            lat += latLngs[i].lat
            lng += latLngs[i].lng
        }
        return latLng(lat / latLngs.length, lng / latLngs.length)
    }
}

export const formatDistance = (distance: number): string => {
    if (distance > 1000) return (distance / 1000).toFixed(2) + " Km"
    if (distance > 0) return distance.toFixed(2) + " m"
    if (distance > 0.001) return (distance / 100).toFixed(2) + " cm"
    return (distance / 1000).toFixed(2) + " mm"
}

export const formatArea = (area: number): string => {
    if (area > 1000000) return (area / 1000000).toFixed(2) + " Km2"
    if (area > 10000) return (area / 10000).toFixed(2) + " ha"
    return area.toFixed(2) + " m2"
}

export const latLngBoundsToWKT = (latLngBoundsInput: LatLngBounds): string => {
    const coordinates = latLngBoundsInput.isValid() ? [
        latLngBoundsInput.getNorthEast(),
        latLngBoundsInput.getNorthWest(),
        latLngBoundsInput.getSouthWest(),
        latLngBoundsInput.getSouthEast(),
        latLngBoundsInput.getNorthEast(),
    ] : []

    return leafletPolygonToWKT(coordinates)
}

export const leafletLineToWKT = (line: LatLng[]) => {
    const wktString = line.reduce((wkt, vertex) => wkt + vertex.lng.toString() + " " + vertex.lat.toString() + ", ", "LINESTRING (")
    return wktString.slice(0, wktString.length - 2) + ")"
}

export const leafletPolygonToWKT = (polygon: LatLng[]) => {
    if (polygon.length > 2) {
        const wktPolygon = polygon[0].lat === polygon[polygon.length - 1].lat && polygon[0].lng === polygon[polygon.length - 1].lng ? polygon : [...polygon, polygon[0]]
        const wktString = wktPolygon.reduce((wkt, vertex) => wkt + vertex.lng.toString() + " " + vertex.lat.toString() + ", ", "POLYGON ((")
        return wktString.slice(0, wktString.length - 2) + "))"
    }
    return ""
}

export const leafletMultiPolygonToWKT = (multipolygon: LatLng[][]) => {
    const wktString = multipolygon.reduce((wkt, subPolygon) => {
        const subPolygonWkt = subPolygon.reduce((subwkt, vertex) => subwkt + vertex.lng.toString() + " " + vertex.lat.toString() + ", ", "((")
        return wkt + subPolygonWkt.slice(0, subPolygonWkt.length - 2) + ")), "
    }, "MULTIPOLYGON (")
    return wktString.slice(0, wktString.length - 2) + ")"
}

export const wktToVertexs = (wktString: string) => {
    try {
        const geojson = parse(wktString)
        if (geojson) {
            switch (geojson.type) {
                case "Polygon":
                    return geojson.coordinates[0].map(coord => latLng(coord[1], coord[0])).slice(0, -1)
                case "LineString":
                    return geojson.coordinates.map(vertex => latLng(vertex[1], vertex[0]))
                case "Point":
                    return [latLng(geojson.coordinates[1], geojson.coordinates[0])]
                default:
                    return []
            }
        }
    }
    catch{}
    return []
}

export const wktToBounds = (polygon: string) => {
    return latLngBounds(wktToVertexs(polygon))
}

