import { useMemo, useState } from 'react';
import { CircleMarker, Marker, Pane } from 'react-leaflet'
import { svgIcon } from '../../utils/rovLabelHelpers';
import { useDispatch, useSelector } from 'react-redux';
import { selectPolygonById, setSelectedVertex, setVertexPos } from '../../state/rovLabelSlice';
import { AppDispatch, RootState } from '../../../app/state/store';

function EditVertex({ polygonId, id, position, draggable, pathOptions}: any) {
    const { selectedVertexId } = useSelector((state: RootState) => selectPolygonById(state, polygonId));
    const dispatch = useDispatch<AppDispatch>();

    const [hover, setHover] = useState(false);
    const [dragging, setDragging] = useState(false);

    const markerIcon = useMemo(() => {
        const color = selectedVertexId === id ? pathOptions.color : (hover ? "red" : 'white');
        return svgIcon(color);
    }, [hover, selectedVertexId, id, pathOptions.color]);


    const eventHandlers = {
        mouseover: () => { !dragging && setHover(true) },
        mouseout: () => { !dragging && setHover(false) },
        drag: (e: any) => {
            dispatch(setVertexPos({polygonId: polygonId, vertexId: id, coord: [e.latlng.lat, e.latlng.lng]}));
        },
        dragstart: (e: any) => { setDragging(true) },
        dragend: (e: any) => {
            setDragging(false);
            dispatch(setSelectedVertex({polygonId: polygonId, vertexId: id}));
        },
        click: () => { if (!dragging) {
                dispatch(setSelectedVertex({polygonId: polygonId, vertexId: id}));
            }
        },
    }


    return (
        <>
            <Pane name={`vertex_edition${id}`} style={{ zIndex: 1200 }}>
                <Marker
                    key={id}
                    position={position}
                    draggable={draggable}
                    icon={markerIcon}
                    eventHandlers={eventHandlers}
                />
            </Pane>
            <Pane name={`vertex_edition${id}_circle`} style={{ zIndex: 1100 }}>
                <CircleMarker
                    center={position}
                    radius={10}
                    eventHandlers={eventHandlers}
                    pathOptions={{
                        opacity: 0,
                        fillOpacity: 0
                    }}
                />
            </Pane>
        </>
    )
}

export default EditVertex;
