import { useState, Fragment } from 'react'
import { CircleMarker, Marker, Pane, Polyline, Tooltip, useMapEvents } from 'react-leaflet'
import { LatLngExpression } from 'leaflet'
import { keyBinds, svgIcon } from '../../utils/rovLabelHelpers'
import { useDispatch, useSelector } from 'react-redux'
import { addPolygonVertex, selectPolygonById, setPolygonfinished } from '../../state/rovLabelSlice'
import { AppDispatch, RootState } from '../../../app/state/store'

function CreatePolygon({ polygonId }: any) {
    const {vertexs, finished} = useSelector((state: RootState) => selectPolygonById(state, polygonId));
    const typeColor = useSelector((state: RootState) => selectPolygonById(state, polygonId).typeColor);
    const dispatch = useDispatch<AppDispatch>();

    const [mousePosition, setMousePosition] = useState<LatLngExpression>([0, 0]);
    const [showFinish, setShowFinish] = useState(false);

    const radius = 20;

    const map = useMapEvents({
        click(e) {
            !finished && !showFinish &&
                dispatch(addPolygonVertex({
                    polygonId: polygonId,
                    coord: [e.latlng.lat, e.latlng.lng]
                }));
            if (showFinish) {
                dispatch(setPolygonfinished({polygonId: polygonId}));
            }
        },

        mousemove(e) {
            setMousePosition([e.latlng.lat, e.latlng.lng]);
            if(vertexs.length > 2){
                const point = map.latLngToLayerPoint(vertexs[0].coord);
                const distance = Math.hypot(point.x - e.layerPoint.x, point.y - e.layerPoint.y);
                distance < radius ? setShowFinish(true) : setShowFinish(false);
            }
        }
    })

    return (
        <>
            {vertexs.map((vertex: any, index: number, array: any[]) => {
                return (
                    <Fragment key={`fragment_${vertex.id}`}>
                        <Marker
                            position={vertex.coord}
                            icon={svgIcon()}
                            key={`vertex_${vertex.id}`}
                        />
                        <Polyline
                            positions={[
                                vertex.coord,
                                index < array.length - 1 ?
                                    array[index + 1].coord :
                                    showFinish && array.length > 2 ?
                                        vertexs[0].coord :
                                        mousePosition]}
                            key={`line_${vertex.id}`}
                            interactive={false}
                            pathOptions={{
                                color: typeColor,
                                weight: 2,
                            }}
                        />
                    </Fragment>
                )
            })}
            {showFinish && vertexs.length > 2 ?
                <Pane name={`finish_polygon_${polygonId}`} style={{ zIndex: 1200 }}>
                    <CircleMarker
                        center={vertexs[0].coord}
                        radius={radius}
                        color='yellow'
                    >
                        <Pane name={`finish_polygon_${polygonId}_tooltip`} style={{ zIndex: 1300 }}>
                        <Tooltip>
                            close polygon ({keyBinds.finishPolygon})
                        </Tooltip>
                        </Pane>
                    </CircleMarker>
                </Pane> :
                <Marker position={mousePosition} interactive={false} icon={svgIcon()} />
            }
        </>
    )
}

export default CreatePolygon;
