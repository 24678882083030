import { LatLngExpression } from "leaflet";


function flipCoordinates(coordinates: number[][][]): LatLngExpression[][] {
    return coordinates.map((polygon) => {
        return polygon.map((point) => {
            return [point[1], point[0]];
        });
    });
}

function setColor(color: string) {
    return { color: color, fillColor: color, fillOpacity: 0.2, weight: 2 };
}

const defaultColor = setColor("blue");
const selectedColor = setColor("yellow");
const hoverColor = setColor("lightgreen");
const footprintColor = setColor("red");
const noColor = setColor("transparent");

export { setColor, noColor, defaultColor, selectedColor, hoverColor, footprintColor, flipCoordinates };
