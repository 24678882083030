import "leaflet/dist/leaflet.css";
import L, { CRS, LatLngExpression } from "leaflet";
import { Box, CircularProgress, Divider } from "@mui/material";
import { Pane, ZoomControl } from "react-leaflet";
import RiskResultTab from "../components/UI/resultTab";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../app/state/store";
import SelectorMarker, { SelectedMarker } from "../components/UI/riskMarker";
import { selectIsSelected, toogleSelecting } from "../state/riskSlice";
import AnalysisPolygons from '../components/UI/analysisPolygons';
import BaseMap from '../../common/components/map/BaseMap';
import { MapLibreTileLayer } from "../../common/utils/MapLibreTileLayer";
import BaseMapControls, { ControlGroup } from "../../common/components/map/layout/BaseMapControls";
import LocationUtilities from "../../common/components/map/control/LocationUtilities";
import DrawingUtilities from "../../common/components/map/control/DrawingUtilities";
import RovisenLayer from "../../common/components/map/layers/RovisenLayer";
import ToggleButton from "../../common/components/UI/general/ToggleButton";
import { Bookmark, Clear, Layers, MoreVert, Restore, Room } from "@mui/icons-material";
import packageJson from '../../../package.json';
import BaseMapDrawer, { DrawerTab } from "../../common/components/map/layout/BaseMapDrawer";
import { useGetCapabilitiesQuery } from "../../common/api/redux_api";
import RiskRenderLayers from "../components/UI/RiskRenderLayers";
import RiskRenderLayersTab from "../components/UI/RiskRenderLayersTab";
import { useState } from "react";
import { RecentPlacesTab, SavedPlacesTab } from "../components/UI/RiskPlacesTabs";
import { useGetRecentPlacesQuery } from "../api/risksApi";

const Risks = () => {
    const center: LatLngExpression = [-33.75, -71.05];
    const zoom: number = 6;
    const minZoom: number = 3;
    const crs: L.CRS = CRS.EPSG3857;

    const selecting = useSelector((state: RootState) => state.risksMap.states.selecting)
    const selected = useSelector(selectIsSelected)

    const layers = useSelector((state: RootState) => state.baseMap.layers)

    const [showUtilities, setShowUtilities] = useState(false)

    const dispatch = useDispatch<AppDispatch>()

    const { isLoading } = useGetCapabilitiesQuery('risks')
    const { isLoading: recentPlacesIsLoading } = useGetRecentPlacesQuery()

    return (
        <BaseMap
            center={center}
            crs={crs}
            zoom={zoom}
            zoomControl={false}
            minZoom={minZoom}
            maxZoom={25}
            style={{ cursor: selecting ? "none" : "default" }}
            doubleClickZoom={false}
            baseMap="esri"
            version={packageJson.risk_map_version}
        >
            <Pane name="intersection_layer" style={{ zIndex: 2000 }} />
            <Pane name="polygon_layer" style={{ zIndex: 1500 }} />
            <Pane name="risk_marker" style={{ zIndex: 2500 }} />
            <ZoomControl position="topright" />
            <MapLibreTileLayer url="https://api.maptiler.com/maps/29b43753-8fe0-4881-b56b-91b28b4f694c/style.json?key=dCFtNpk6lDWiGmtbFNYs" attribution="" />
            <BaseMapDrawer>
                <DrawerTab icon={<></>} title="Riesgos">
                    <RiskResultTab />
                </DrawerTab>
                <DrawerTab icon={<Bookmark />} title="Análisis guardados">
                    <SavedPlacesTab />
                </DrawerTab>
                <DrawerTab icon={<Restore />} title="Análisis recientes">
                    {recentPlacesIsLoading
                        ? <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', flexGrow: 1 }}>
                            <CircularProgress />
                        </Box>
                        : <RecentPlacesTab />
                    }
                </DrawerTab>
                <DrawerTab icon={<Layers />} title="Capas">
                    {isLoading
                        ? <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', flexGrow: 1 }}>
                            <CircularProgress />
                        </Box>
                        : <RiskRenderLayersTab />
                    }
                </DrawerTab>
            </BaseMapDrawer>
            <BaseMapControls>
                {showUtilities && <LocationUtilities />}
                {showUtilities && <DrawingUtilities />}
                <ControlGroup>
                    <ToggleButton
                        icon={<MoreVert fontSize="inherit" />}
                        selected={showUtilities}
                        selectedIcon={<Clear fontSize="inherit" />}
                        tooltip="Tools"
                        selectedTooltip="Hide Tools"
                        onClick={() => {
                            setShowUtilities(!showUtilities)
                            selecting && dispatch(toogleSelecting())
                        }}
                    />
                    <ToggleButton
                        icon={<Room fontSize="inherit" />}
                        selected={selecting}
                        tooltip={"Drop Marker"}
                        selectedTooltip="Cancel"
                        onClick={() => dispatch(toogleSelecting())}
                        color="primary"
                    />
                    <Divider variant="middle" orientation="vertical" flexItem />
                </ControlGroup>
            </BaseMapControls>
            {layers.map((layer: any) => layer.selected && <RovisenLayer name={layer.name} layerStyle={layer.style} key={layer.id} bounds={L.latLngBounds(L.latLng(layer.bounds[0]), L.latLng(layer.bounds[1]))} />)}
            {selecting && <SelectorMarker />}
            {selected && <SelectedMarker />}
            <AnalysisPolygons />
            <RiskRenderLayers />
        </BaseMap>
    );
}

export default Risks;
