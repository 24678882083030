import { LocationOnOutlined, LocationSearching, Room } from "@mui/icons-material";
import { Box, Divider, IconButton, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../app/state/store";
import RiskResult from "./risksResult";
import { selectIsSelected, toogleSelecting } from "../../state/riskSlice";


const RiskResultTab = () => {
    const currentPosition = useSelector((state: RootState) => state.risksMap.states.currentPosition)
    const selecting = useSelector((state: RootState) => state.risksMap.states.selecting)
    const selectedId = useSelector((state: RootState) => state.risksMap.places.selectedId)
    const selected = useSelector(selectIsSelected)

    const dispatch = useDispatch<AppDispatch>()

    return selected
        ? <RiskResult placeId={selectedId} />
        : <Box sx={{ display: 'flex', flexDirection: 'column', padding: 1, height: 1 }}>
            <Typography variant="h4">Nothing selected</Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <LocationOnOutlined fontSize="medium" color="disabled" sx={{ padding: 1 }} />
                <Typography><span>&#8212;</span></Typography>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <LocationSearching fontSize="medium" color="disabled" sx={{ padding: 1 }} />
                {selecting
                    ? <Typography>{currentPosition[0].toFixed(8)}, {currentPosition[1].toFixed(8)}</Typography>
                    : <Typography><span>&#8212;</span></Typography>
                }
            </Box>
            <Divider flexItem variant="middle" />
            <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, justifyContent: "center" }}>
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                    <IconButton size="large" color="primary" onClick={() => dispatch(toogleSelecting())}>
                        <Room fontSize="inherit" />
                    </IconButton>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                    <Typography>Select a place using the marker</Typography>
                </Box>
            </Box>
        </Box>
}

export default RiskResultTab
