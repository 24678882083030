import { combineReducers, configureStore } from '@reduxjs/toolkit';
import rovLabelReducer from '../../rovLabel/state/rovLabelSlice';
import rovLabelLoadingReducer from '../../rovLabel/state/rovLabelLoadingSlice';
import authReducer from '../../authentication/state/authSlice';
import { risksReducer } from '../../risksMap/state/riskSlice';
import { gisApi } from '../../common/api/redux_api';
import baseMapReducer from '../../mainMap/state/baseMapSlice';
import analysisReducer from '../../analysis/state/analysisSlice';
import pipelinesReducer from '../../pipeline/state/pipilineState';
import processReducer from '../../process/state/processSlice'
import userConfigReducer from './configSlice';
import { FLUSH, PAUSE, PERSIST, persistReducer, PURGE, REGISTER, REHYDRATE } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { publicApi } from '../api/publicApi';

const rootReducer = combineReducers({
    rovLabel: rovLabelReducer,
    rovLabelLoading: rovLabelLoadingReducer,
    risksMap: risksReducer,
    baseMap: baseMapReducer,
    auth: authReducer,
    analysis: analysisReducer,
    pipelines: pipelinesReducer,
    process: processReducer,
    userConfig: userConfigReducer,
    [gisApi.reducerPath]: gisApi.reducer,
    [publicApi.reducerPath]: publicApi.reducer,
})

const persistConfig = {
    key: 'root',
    storage,
    blacklist: ['auth', gisApi.reducerPath, publicApi.reducerPath],
}

const store = configureStore({
    reducer: persistReducer<ReturnType<typeof rootReducer>>(persistConfig, rootReducer),
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }).concat([gisApi.middleware, publicApi.middleware]),
});

export { store };

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
