import { useEffect, useState } from "react"
import { wktToBounds } from "../../common/utils/tools"
import { GeomDisplayType, RasterDisplayType } from "../types/DisplayDataTypes"

export const useMask = (mask: string, map: any) => {
    useEffect(() => {
        map.current && mask && map.current.fitBounds(wktToBounds(mask))
    }, [map, mask])

    const [showMask, setShowMask] = useState(true)

    return { showMask, setShowMask }
}


export const useMaskMapDisplay = (geom: GeomDisplayType, map: any) => {
    const [mask, setMask] = useState<string>('')
    useEffect(() => {
        if (geom?.payload?.value) {
            setMask(geom.payload.value)
        }
    }, [geom])
    const { showMask, setShowMask } = useMask(mask, map)

    return { mask, showMask, setShowMask }
}

export const useInputMaskCartography = (raster: RasterDisplayType, map: any) => {
    const [mask, setMask] = useState<string>('')
    useEffect(() => {
        if (raster?.bbox) {
            setMask(raster.bbox)
        }
    }, [raster])
    const { showMask, setShowMask } = useMask(mask, map)

    return { mask, showMask, setShowMask }
}
