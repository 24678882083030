import { gisApi } from "../../common/api/redux_api";
import { Geometry, Mosaics, PipelineInstance, PipelineModel, Raster } from '../types/DataTypes';
import { PipelineInstanceDetail, SFStateMachineDefinition } from "../../pipeline/types/StepFunctionTypes";


export const processApi = gisApi.injectEndpoints({
    endpoints: (builder) => ({
        getPipelineModels: builder.query<PipelineModel[], void>({
            query: () => '/gis/pipeline_models',
            keepUnusedDataFor: 3600,
        }),
        getPipelineModel: builder.query<PipelineModel, string>({
            query: (id) => `/gis/pipeline_model/${id}`,
        }),
        getImages: builder.query<Raster[], {origin?: string}>({
            query: (arg) => {
                const params = new URLSearchParams()
                arg.origin && params.append('origin', arg.origin)
                if (params.toString()) {
                    return `/gis/images?origin=${arg.origin}`
                }
                return '/gis/images'
            },
            providesTags: (result) =>
                result
                    ? [
                        ...result.map(({ id }) => ({ type: 'Rasters', id } as const)),
                        { type: 'Rasters', id: 'LIST' }
                    ]
                    : [{ type: 'Rasters', id: 'LIST' }],

        }),
        getGeometries: builder.query<Geometry[], void>({
            query: () => '/gis/geometries',
            providesTags: (result) =>
                result
                    ? [
                        ...result.map(({ id }) => ({ type: 'Geometries', id } as const)),
                        { type: 'Geometries', id: 'LIST' }
                    ]
                    : [{ type: 'Geometries', id: 'LIST' }],
        }),
        createProcess: builder.mutation<any, { name: string, modelId: string, cost: number, input: any }>({
            query: ({ name, modelId, cost, input }) => ({
                url: '/gis/create_process',
                method: 'POST',
                body: {
                    "name": name,
                    "pipeline_model_id": modelId,
                    "cost_estimated": cost,
                    "input": input
                }
            }),
            invalidatesTags: [{ type: 'PipelineInstances', id: 'LIST' }, 'Credits']
        }),
        createChangeProcess: builder.mutation<any, {name: string, area: number, cost: number, date1: Date, date2: Date, geom: string, mask: string, data: any}>({
            query: ({name, area, cost, date1, date2, geom, mask, data}) => ({
                url: '/gis/create_process_change_detector',
                method: 'POST',
                body: {
                    "name": name,
                    "area": area,
                    "cost_estimated": cost,
                    "date_t1": date1.toISOString(),
                    "date_t2": date2.toISOString(),
                    "geom": geom,
                    "mask": mask,
                    "data": data,
                }
            }),
            invalidatesTags: [{ type: 'Processes', id: 'LIST' }, 'Credits']
        }),
        copyProcess: builder.mutation<any, number>({
            query: (id) => ({
                url: '/gis/copy_process',
                method: 'POST',
                body: {
                    "id": id.toString(),
                }
            }),
            invalidatesTags: [{ type: 'Processes', id: 'LIST' }]
        }),
        deleteProcess: builder.mutation<any, number>({
            query: (id) => ({
                url: `/gis/process/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: (result, error, id) => [{ type: 'Processes', id }],
        }),
        editProcess: builder.mutation<any, { id: string, name: string }>({
            query: ({ id, name }) => ({
                url: `/gis/edit_process/${id}`,
                method: 'PUT',
                body: {
                    "name": name,
                }
            }),
            invalidatesTags: (result, error, { id }) => [{ type: 'Processes', id }],
        }),
        getPipelineInstances: builder.query<PipelineInstance[], void>({
            query: () => `/gis/pipeline_instances`,
            providesTags: (result) =>
                result
                    ? [
                        ...result.map(({ id }) => ({ type: 'PipelineInstances', id } as const)),
                        { type: 'PipelineInstances', id: 'LIST' }
                    ]
                    : [{ type: 'PipelineInstances', id: 'LIST' }],
        }),
        getPipelineInstance: builder.query<PipelineInstance, string>({
            query: (id) => `/gis/pipeline_instance/${id}`,
            providesTags: (result, error, id) => [{ type: 'PipelineInstances', id }]
        }),
        getPipelineInstanceDetails: builder.query<PipelineInstanceDetail, string>({
            query: (pipeline_instance_id) => `/gis/pipeline_instance/${pipeline_instance_id}/details`,
            providesTags: (result, error, id) => [{ type: 'PipelineInstanceDetails', id }],
            keepUnusedDataFor: 3600,
        }),
        getPipelineModelDefinition: builder.query<any, string>({
            query: (id) => `/gis/pipeline_model/${id}/definition`,
        }),
        getPipelineModelAWSDefinition: builder.query<SFStateMachineDefinition, string>({
            query: (id) => `/gis/pipeline_model/${id}/aws_definition`,
            providesTags: (result, error, id) => [{ type: 'PipelineModelDefinition', id }],
            keepUnusedDataFor: 3600,
        }),
        getGeometryResult: builder.query<string, string>({
            query: (id) => `/gis/geometry/${id}/download`,
        }),
        getMosaics: builder.query<Mosaics[], void>({
            query: () => '/gis/mosaics',
            providesTags: (result) =>
                result
                    ? [
                        ...result.map(({ id }) => ({ type: 'Mosaics', id } as const)),
                        { type: 'Mosaics', id: 'LIST' }
                    ]
                    : [{ type: 'Mosaics', id: 'LIST' }],
        }),
        addResultToMosaic: builder.query<any, { resultId: string, mosaicId: string }>({
            query: ({ resultId, mosaicId }) => ({
                url: '/gis/mosaics/add-result',
                method: 'POST',
                body: {
                    "result_id": resultId.toString(),
                    "mosaic_id": mosaicId,
                }
            })
        }),
        getImageUploadUrl: builder.query<{upload_url: string, object_key: string}, void>({
            query: () => '/gis/image_upload_url'
        }),
        getRaster: builder.query<Raster, string>({
            query: (id) => `/gis/raster/${id}`,
            providesTags: (result, error, id) => [{ type: 'Rasters', id }],
        }),
        getGeometry: builder.query<Geometry, string>({
            query: (id) => `/gis/geometry/${id}`,
            providesTags: (result, error, id) => [{ type: 'Geometries', id }],
        }),
        getRasterDownloadLink: builder.query<string, string>({
            query: (id) => `/gis/raster/${id}/download`,
        }),
        getGeometryDownloadLink: builder.query<string, string>({
            query: (id) => `/gis/geometry/${id}/download`,
        }),
        recalculateRasterPreview: builder.mutation<any, {id: string, scale?: number}>({
            query: ({id, scale}) => ({
                url: `/gis/calculate_preview/${id}`,
                method: 'POST',
                params: scale ? {scale: scale.toString()} : undefined
            }),
        }),
    }),
})

export const {
    useGetPipelineModelsQuery,
    useLazyGetPipelineModelQuery,
    useGetPipelineModelQuery,
    useGetImagesQuery,
    useCreateProcessMutation,
    useCreateChangeProcessMutation,
    useCopyProcessMutation,
    useDeleteProcessMutation,
    useEditProcessMutation,
    useGetPipelineInstancesQuery,
    useGetPipelineInstanceQuery,
    useGetPipelineInstanceDetailsQuery,
    useGetPipelineModelDefinitionQuery,
    useGetPipelineModelAWSDefinitionQuery,
    useGetGeometryResultQuery,
    useLazyGetMosaicsQuery,
    useLazyAddResultToMosaicQuery,
    useLazyGetImageUploadUrlQuery,
    useLazyGetRasterDownloadLinkQuery,
    useLazyGetGeometryDownloadLinkQuery,
    useGetGeometriesQuery,
    useGetGeometryQuery,
    useGetRasterQuery,
    useRecalculateRasterPreviewMutation,
} = processApi
