import { ReactNode, createContext, useContext, useState } from 'react';
import { CSSObject, ListItem, ListItemButton, ListItemIcon, ListItemText, Theme } from '@mui/material';
import { Add, ArrowLeft, ArrowRight, ExpandLess, ExpandMore } from "@mui/icons-material";
import { Box, Collapse, Divider, List, Toolbar } from "@mui/material";
import { styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import { useNavigate, Outlet } from 'react-router-dom';


const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

interface SideNavBarContextProps {
    open: boolean;
    selectedTab: string;
    setSelectedTab: (tab: string) => void;
}

const SideNavBarContext = createContext<SideNavBarContextProps>({ open: true, selectedTab: "", setSelectedTab: (tab: string) => { } });

export const useSideNavBarContext = () => useContext(SideNavBarContext);

const SideNavBar = ({ initialTab, children }: { initialTab: string, children: any }) => {
    const [open, setOpen] = useState(true);
    const [selectedTab, setSelectedTab] = useState(initialTab);

    return (
        <Box sx={{ display: 'flex', flexDirection: 'row', height: 1, width: 1, overflowX: 'hidden' }}>
            <Drawer variant="permanent" open={open}>
                <Toolbar />
                <Box sx={{ height: 1, display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
                    <List sx={{ flexGrow: 1 }}>
                        <SideNavBarContext.Provider value={{ open, selectedTab, setSelectedTab }}>
                            {children}
                        </SideNavBarContext.Provider>
                    </List>
                    <Divider flexItem />
                    <List sx={{ flexShrink: 1 }}>
                        {['Colapsar'].map((text) => (
                            <ListItem key={text} disablePadding sx={{ display: 'block' }}>
                                <ListItemButton
                                    sx={{
                                        minHeight: 48,
                                        justifyContent: open ? 'initial' : 'center',
                                        px: 2.5,
                                    }}
                                    onClick={() => setOpen(prev => !prev)}
                                >
                                    <ListItemIcon
                                        sx={{
                                            minWidth: 0,
                                            mr: open ? 3 : 'auto',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        {open ? <ArrowLeft /> : <ArrowRight />}
                                    </ListItemIcon>
                                    {open && <ListItemText primary={text} />}
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                </Box>
            </Drawer>
            <Box sx={{ flexGrow: 1, height: 1, overflowX: 'hidden' }}>
                <Outlet />
            </Box>
        </Box>
    );
};

const CollapsableSideNavItem = ({ name, children }: { name: string, children: any }) => {
    const { open, selectedTab } = useSideNavBarContext();
    const [openSubList, setOpenSubList] = useState(false);

    const childrenNames = children.map((child: any) => child.props.name);

    return (
        <>
            <ListItem disablePadding sx={{ display: 'block' }}>
                <ListItemButton
                    sx={{
                        minHeight: 48,
                        justifyContent: open ? 'initial' : 'center',
                        px: 2.5,
                    }}
                    onClick={() => setOpenSubList(!openSubList)}
                >
                    <ListItemIcon
                        sx={{
                            minWidth: 0,
                            mr: open ? 3 : 'auto',
                            justifyContent: 'center',
                        }}
                    >
                        <Add color={childrenNames.includes(selectedTab) ? "primary" : "inherit"} />
                    </ListItemIcon>
                    {open && <ListItemText primary={name} />}
                    {open && (openSubList ? <ExpandLess /> : <ExpandMore />)}
                </ListItemButton>
            </ListItem>
            <Collapse in={openSubList} timeout="auto" unmountOnExit>
                <List component="div" disablePadding sx={{ pl: open ? 4 : 0 }}>
                    {children}
                </List>
            </Collapse>
        </>
    );
}

interface SideNavItemProps {
    name: string;
    Icon: any;
    path: string;
    renderIcon?: ({ Icon, color }: any) => ReactNode;
}

const SideNavItem = ({ name, Icon, path, renderIcon = ({ Icon, color }) => <Icon color={color} /> }: SideNavItemProps) => {
    const { open, setSelectedTab } = useSideNavBarContext();
    const color = window.location.href.includes(path) ? "primary" : "inherit"
    const navigate = useNavigate();

    return (
        <ListItem disablePadding sx={{ display: 'block' }}>
            <ListItemButton
                sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5,
                }}
                onClick={() => {
                    setSelectedTab(name);
                    navigate(path);
                }}
            >
                <ListItemIcon
                    sx={{
                        minWidth: 0,
                        mr: open ? 3 : 'auto',
                        justifyContent: 'center',
                    }}
                >
                    {renderIcon({ Icon, color })}
                </ListItemIcon>
                {open && <ListItemText primary={name} />}
            </ListItemButton>
        </ListItem>
    );
};

export { SideNavBar, SideNavItem, CollapsableSideNavItem };
