import { LoadingButton } from "@mui/lab";
import { Box, Checkbox, FormControlLabel, Tooltip, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { selectState, setTaskValid } from "../../state/rovLabelSlice";
import { AppDispatch, RootState } from "../../../app/state/store";
import { selectRovLabelLoadingState } from "../../state/rovLabelLoadingSlice";
import { keyBinds } from "../../utils/rovLabelHelpers";

function Info(){
    const { projectInfo, taskInfo, selectedTaskIndex, selectedBatchIndex, imageNumber } = useSelector((state: RootState) => selectState(state));
    const { projectLoading, taskLoading } = useSelector((state: RootState) => selectRovLabelLoadingState(state));
    const dispatch = useDispatch<AppDispatch>();

    const tasks = projectInfo.batches &&
        projectInfo.batches[selectedBatchIndex] &&
        projectInfo.batches[selectedBatchIndex].tasks;

    const task_is_valid = taskInfo.id && taskInfo.is_valid;
    const image_date = taskInfo.id && taskInfo.images[imageNumber].url.split('/').pop().split('_')[2];
    const date = image_date && `${image_date.slice(6, 8)}/${image_date.slice(4, 6)}/${image_date.slice(0, 4)}`;

    return (
        <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 2}}>
            <Box>
                <LoadingButton
                    loading={projectLoading || taskLoading}
                    variant="contained"
                    color='primary'
                >
                    Task {selectedTaskIndex + 1}
                </LoadingButton>
                {taskInfo.id && <FormControlLabel
                    control={
                        <Tooltip title={`toggle task validity (${keyBinds.taskValid})`}>
                            <Checkbox
                                checked={task_is_valid}
                                disabled={!taskInfo.id}
                                onChange={() => dispatch(setTaskValid({valid: !task_is_valid}))}
                            />
                        </Tooltip>
                    }
                    label="is valid"
                    sx={{ marginLeft: 1 }}
                />}
            </Box>
            {taskInfo.id &&
                <>
                    <Box>
                        <Typography variant="body2">
                            Image: t{imageNumber + 1}
                            {date && ` (${date})`}
                        </Typography>
                    </Box>
                    <Box>
                        <Typography variant="body2">
                            Task {selectedTaskIndex + 1} of {tasks ? tasks.length : 0}
                        </Typography>
                    </Box>
                </>
            }
        </Box>
    )
}

export default Info;
