import { Delete, East, LocationOnOutlined, Save, ZoomIn } from "@mui/icons-material"
import { Box, Divider, IconButton, TextField, Tooltip, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import { useMap, useMapEvents } from "react-leaflet"


const SelectorTextField = ({ defaultValue, setNumber, onBlur }: { defaultValue: number, setNumber: any, onBlur: any }) => {
    const [error, setError] = useState(false)
    const [value, setValue] = useState(defaultValue.toString())

    useEffect(() => setValue(defaultValue.toString()), [defaultValue])

    return <TextField
        value={value}
        onChange={(e) => {
            const inputNumber = Number(e.target.value)
            if (!isNaN(inputNumber)) {
                setError(false)
                setNumber(inputNumber)
            }
            else {
                setError(true)
            }

            setValue(e.target.value)
        }}
        error={error}
        onBlur={onBlur}
    />
}

const LocationRow = ({ location, index, setLocations }: { location: any, index: number, setLocations: any }) => {
    const map = useMap()
    return <>
        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
            <Box sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <LocationOnOutlined fontSize="medium" color="disabled" sx={{ padding: 1 }} />
                    <Typography>{location.lat}, {location.lng}</Typography>
                </Box>
                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <ZoomIn fontSize="medium" color="disabled" sx={{ padding: 1 }} />
                    <Typography>{location.zoom}</Typography>
                </Box>
            </Box>
            <Tooltip title="Borrar">
                <IconButton sx={{ padding: 2 }} size="medium" onClick={() => setLocations((prev: any[]) => [...prev.slice(0, index), ...prev.slice(index + 1)])}>
                    <Delete fontSize="inherit" />
                </IconButton>
            </Tooltip>
            <Tooltip title="Ir">
                <IconButton sx={{ padding: 2 }} size="medium" onClick={() => map.setView([location.lat, location.lng], location.zoom)}>
                    <East fontSize="inherit" />
                </IconButton>
            </Tooltip>
        </Box>
        <Divider variant="middle" flexItem />
    </>
}

export const SelectLocations = () => {
    const map = useMapEvents({
        move: (e) => {
            setLan(map.getCenter().lat)
            setLng(map.getCenter().lng)
            setZoom(map.getZoom())
        }
    })

    const [lat, setLan] = useState(map.getCenter().lat)
    const [lng, setLng] = useState(map.getCenter().lng)
    const [zoom, setZoom] = useState(map.getZoom())

    const [locations, setLocations] = useState<Array<any>>([])

    const moveMap = () => map.setView([lat, lng], zoom)

    return <Box sx={{ display: 'flex', flexDirection: 'column', overflow: "hidden", padding: 2 }}>
        <Typography variant="h5">Coordenadas actuales</Typography>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: "center" }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 2, }}>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', padding: 1 }}>
                    <Typography>Lat: </Typography>
                    <SelectorTextField defaultValue={lat} setNumber={setLan} onBlur={moveMap} />
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', padding: 1 }}>
                    <Typography>Lng: </Typography>
                    <SelectorTextField defaultValue={lng} setNumber={setLng} onBlur={moveMap} />
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', padding: 1 }}>
                    <Typography>Zoom: </Typography>
                    <SelectorTextField defaultValue={zoom} setNumber={setZoom} onBlur={moveMap} />
                </Box>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row-reverse', flexGrow: 1, }}>
                <Tooltip title="Guardar">
                    <IconButton
                        size="medium"
                        sx={{padding: 2}}
                        onClick={() => {
                            setLocations(prev => [...prev, { lat: lat, lng: lng, zoom: zoom }])
                        }}>
                        <Save fontSize="inherit" />
                    </IconButton>
                </Tooltip>
            </Box>
        </Box>
        <Divider variant="middle" flexItem />
        <Typography variant="h5" sx={{ paddingY: 2 }}>Coordenadas guardadas</Typography>
        <Box sx={{ display: "flex", flexDirection: "column", flexGrow: 1, overflow: "auto" }}>
            {locations.map((location, index) => <LocationRow location={location} setLocations={setLocations} index={index} key={index} />)}
        </Box>
    </Box>
}
