import { Navigate, Route, Routes } from "react-router-dom"
import ProcessDashboard from "./views/ProcessDashboard"
// import Analysis from "../analysis/views/Analysis"
import ImageLibrary from "./views/ImagesLibrary"
import ProcessLibrary from "./views/ProcessLibrary"
import NotFound from "../app/views/NotFound"
import ProcessHome from "./views/ProcessHome"
import ExecutionDetails from "./components/executionDetails/ExecutionDetails"
import ProcessDetails from "./components/processDetails/ProcessDetails"
import ProcessCreation from "./components/newExecution/ProcessCreation"
import ResultsViewer from "./views/ResultsViewer"
// import PipelineHome from "../pipeline/views/PipelineHome"

const ProcessRoutes = () => {
    return (
        <Routes>
            <Route path='/' element={<ProcessHome />}>
                <Route index element={<Navigate to='dashboard' replace />} />
                <Route path='dashboard' element={<ProcessDashboard />} />
                <Route path='processes' element={<ProcessLibrary />} />
                <Route path="processes/:modelId" element={<ProcessDetails />} />
                <Route path="processes/:modelId/new_execution" element={<ProcessCreation />} />
                <Route path="processes/:modelId/instance/:instanceId" element={<ExecutionDetails />} />
                <Route path="processes/:modelId/instance/:instanceId/results" element={<ResultsViewer />} />
                <Route path='imagesLibrary' element={<ImageLibrary />} />
                {/* <Route path='analysis' element={<Analysis />} /> */}
                {/* <Route path='createProcess' element={<PipelineHome />} /> */}
            </Route>
            <Route path="*" element={<NotFound />} />
        </Routes>
    )
}

export default ProcessRoutes;
