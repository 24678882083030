import { Box, Divider, Typography } from "@mui/material"
import { LatLng } from "leaflet"
import { useEffect, useState } from "react"
import ToggleButton from "../../UI/general/ToggleButton"
import { Clear, GpsFixed, GpsOff, LocationOff, PushPin, Room } from "@mui/icons-material"
import { Marker, useMapEvents } from "react-leaflet"
import { svgIcon } from "../../../../rovLabel/utils/rovLabelHelpers"
import { ControlGroup } from "../layout/BaseMapControls"
import DropPin from "./DropPin"

const markerIcon = svgIcon('blue');

const LocationUtilities = () => {
    const [showCurrent, setShowCurrent] = useState(false)
    const [showLocation, setShowLocation] = useState(false)
    const [showDropPin, setShowDropPin] = useState(false)

    const [currentLocation, setCurrentLocation] = useState<LatLng>()
    const [location, setLocation] = useState<LatLng>()

    const map = useMapEvents({
        locationfound(e) {
            setLocation(e.latlng)
            map.flyTo(e.latlng)
        },
        mousemove(e) {
            showCurrent && setCurrentLocation(e.latlng)
        }
    })
    useEffect(() => {
        showLocation && map.locate()
    }, [map, showLocation])

    return (
        <>
            {showCurrent &&
                <ControlGroup>
                    <Box sx={{ margin: 1.7 }}>
                        <Typography>Lat: {currentLocation && currentLocation.lat.toFixed(14)}</Typography>
                    </Box>
                    <Divider orientation='vertical' variant='middle' flexItem />
                    <Box sx={{ margin: 1.7 }}>
                        <Typography>Lng: {currentLocation && currentLocation.lng.toFixed(14)}</Typography>
                    </Box>
                </ControlGroup>
            }
            {showDropPin ? <DropPin /> : null}
            <ControlGroup>
                <ToggleButton tooltip="Drop pin" selected={showDropPin} icon={<PushPin fontSize="inherit" />} selectedIcon={<Clear fontSize="inherit" />} onClick={() => setShowDropPin(prev => !prev)} />
                <ToggleButton tooltip="Location" selected={showCurrent} icon={<GpsFixed fontSize="inherit" />} selectedIcon={<GpsOff fontSize="inherit" />} onClick={() => setShowCurrent(prev => !prev)} />
                <ToggleButton tooltip="My Location" selected={showLocation} icon={<Room fontSize="inherit" />} selectedIcon={<LocationOff fontSize="inherit" />} onClick={() => setShowLocation(prev => !prev)} />
            </ControlGroup>
            {showLocation && location ? <Marker position={location} icon={markerIcon} /> : null}
        </>
    )
}

export default LocationUtilities;
