import proj4 from "proj4";
import { fromUrl } from "geotiff";
import { toProj4 } from "geotiff-geokeys-to-proj4";
import { useMap } from "react-leaflet";
import { useEffect, useRef } from "react";
import L, { Rectangle } from "leaflet";

function getTiffCoordinates(lat: number, lon: number, sourceProjection: string) {
    // Define the source and target projections
    const targetProjection = '+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs';

    // Create a point object representing the source coordinates
    const point = { x: lon, y: lat };

    // Perform the transformation
    const transformedPoint = proj4(sourceProjection, targetProjection, point);

    // Extract the transformed coordinates
    const transformedLat = transformedPoint.y;
    const transformedLon = transformedPoint.x;

    // Return the transformed coordinates
    return { lat: transformedLat, lon: transformedLon };
}

async function getGeoTiffData(url: string) {
    const geotiff = await fromUrl(url);
    const image = await geotiff.getImage();
    const bbox = image.getBoundingBox();
    const geokeys = image.getGeoKeys();
    const proj4String = toProj4(geokeys);

    return { bbox, proj4String };
}

function useDrawBbox(bbox: any) {
    const map = useMap();
    const prevBbox = useRef<Rectangle | null>(null);

    useEffect(() => {
        if (bbox) {
            const bounds = L.latLngBounds(L.latLng(bbox.sw.lat, bbox.sw.lon), L.latLng(bbox.ne.lat, bbox.ne.lon));
            map.fitBounds(bounds);
            const rectangleBBox = L.rectangle(bounds, { color: "#FF9E00", weight: 3, fillOpacity: 0});
            rectangleBBox.addTo(map);

            if (prevBbox.current) {
                map.removeLayer(prevBbox.current);
            }

            prevBbox.current = rectangleBBox;
        }
    }, [map, bbox]);
}

export {
    getTiffCoordinates,
    getGeoTiffData,
    useDrawBbox
};
