import { useSelector } from "react-redux"
import { RootState } from "../../../app/state/store"
import { selectRiskRenderLayerIds, selectRiskRenderLayersById } from "../../state/riskSlice"
import RovisenLayer from "../../../common/components/map/layers/RovisenLayer"
import { memo } from "react"

const RiskRenderLayer = memo(({ id }: { id: string }) => {
    const layer = useSelector((state: RootState) => selectRiskRenderLayersById(state, id))
    return layer.show
        ? <RovisenLayer layerStyle={layer.styles[layer.selectedStyle]} name={layer.fullname} format={layer.formats.tiles[layer.selectedTileFormat]} opacity={layer.opacity} bounds={layer.bounds} />
        : null
})

const RiskRenderLayers = () => {
    const layersIds = useSelector(selectRiskRenderLayerIds)
    return <>
        {layersIds.map(layerId => <RiskRenderLayer id={layerId} key={layerId} />)}
    </>
}

export default RiskRenderLayers
