import { createSlice } from "@reduxjs/toolkit";
import { jwtDecode } from "jwt-decode";
import CustomJwt from "../utils/customJwt";
import { RootState } from "../../app/state/store";
import { useSelector } from "react-redux";
import { Cookies } from "react-cookie";


interface AuthState {
    userId: number | null;
    username: string | null;
    email: string | null;
    displayName: string | null;
    token: string | null;
    roles: string[];
}

const cookies = new Cookies();
const token = cookies.get('rovisen_auth_token');
const decoded = token && jwtDecode<CustomJwt>(token)


const initialState: AuthState = {
    userId: decoded?.user_id || null,
    username: decoded?.username || null,
    email: decoded?.email || null,
    displayName: decoded?.displayName || null,
    token: token || null,
    roles: decoded?.roles || []
};

const authSlice = createSlice({
    name: 'auth',
    initialState: initialState,
    reducers: {
        logIn: (state, action) => {
            const token = action.payload;
            const decoded = jwtDecode<CustomJwt>(token);
            decoded?.exp && cookies.set("rovisen_auth_token", token, {
                path: '/',
                expires: new Date(decoded.exp * 1000),
            })
            if (decoded.username) {
                state.token = token;
                state.userId = decoded.user_id;
                state.username = decoded.username;
                state.email = decoded.email;
                state.displayName = decoded.display_name;
                state.roles = decoded.roles;
            }
        },
        logOut: (state) => {
            cookies.remove('rovisen_auth_token');
            state.userId = null;
            state.username = null;
            state.email = null;
            state.displayName = null;
            state.token = null;
            state.roles = [];
        },
        setUser: (state, action) => {
            state.username = action.payload;
        },
        setToken: (state, action) => {
            state.token = action.payload;
        },
        setRoles: (state, action) => {
            state.roles = action.payload;
        }
    }
});

export const {
    logIn,
    logOut,
    setUser,
    setToken,
    setRoles
} = authSlice.actions;

export default authSlice.reducer;

export const selectToken = (state: RootState) => state.auth.token;
export const selectUser = (state: RootState) => state.auth.username;
export const selectUserRoles = (state:RootState) => state.auth.roles;


export const useToken = () => {
    const token = useSelector((state: RootState) => state.auth.token)
    return token
}

export const useAuth = () => {
    const auth = useSelector((state: RootState) => state.auth)
    return auth
}
