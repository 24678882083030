import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { Box, Typography } from '@mui/material';


const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 24,
    borderRadius: 12,
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
    },
}));

function CustomProgressBar({ progress }: { progress: number }) {
    return (
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <BorderLinearProgress variant="determinate" value={progress} sx={{flexGrow: 1}}/>
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    width: '100%',
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'left',
                }}
            >
                <Typography color={'white'} variant="caption" component="div" sx={{paddingLeft: 2}}>
                    uploading...
                </Typography>
            </Box>
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    width: '100%',
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'right',
                }}
            >
                <Typography color={'white'} variant="caption" component="div" sx={{paddingRight: 2}}>
                    {`${Math.round(progress)}%`}
                </Typography>
            </Box>
        </Box>
    )
}

export default CustomProgressBar;
