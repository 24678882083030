import { PayloadAction, createSlice } from "@reduxjs/toolkit"

export type BaseMapOption = 'esri' | 'osm' | 'rov' | 'google'

interface BaseMapStates {
    baseMap: BaseMapOption,
    layers: any[],
    streets: boolean,
    loading: 'idle' | 'pending' | 'succeeded' | 'failed'
}

const initialState = {
    baseMap: 'osm',
    layers: [],
    streets: false,
    loading: 'idle'
} as BaseMapStates

const baseMapSlice = createSlice({
    name: 'states',
    initialState,
    reducers: {
        setBaseMap(state, action: PayloadAction<BaseMapOption>) {
            state.baseMap = action.payload
        },
        selectLayer(state, action: PayloadAction<{id: number, selected: boolean}>) {
            state.layers[action.payload.id].selected = action.payload.selected
        },
        setStreets(state, action: PayloadAction<boolean>) {
            state.streets = action.payload
        },
        setLayers(state, action: PayloadAction<any>){
            state.layers = action.payload
            state.loading = "succeeded"
        }
    },
})

export default baseMapSlice.reducer;

export const {
    setBaseMap,
    selectLayer,
    setStreets,
    setLayers,
} = baseMapSlice.actions
