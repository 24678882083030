import { useSelector } from "react-redux";
import { RootState } from "../state/store";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { userRoles } from "../../authentication/components/userRoles";

function PermissionRoutes({ allowedRoles }: { allowedRoles: string[]}) {
    const location = useLocation();
    const { roles } = useSelector((state: RootState) => state.auth);

    return (
        roles.includes(userRoles.admin) || roles.find((role: string) => allowedRoles?.includes(role))
            ? <Outlet />
            : <Navigate to="/unauthorized" replace state={{ from: location }} />
    )
}

export default PermissionRoutes;
