import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, styled, tableCellClasses } from '@mui/material';

const keyBinds = {
    'draw poplygon': '1-9',
    'change label class': '1-9',
    'close polygon': 'c',
    'edit polygon': 'e',
    'toggle visible': 'b',
    'deselect polygon': 'Escape',
    'delete selected item': 'Backspace',
    'switch image': 't',
    'toggle task validity': 'v',
    're-center task': 'r',
    'save': 'ctrl + s',
    'save and next task': 'ctrl + Enter',
}


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.grey[300],
        color: theme.palette.common.black,
        fontWeight: 'bold',
        fontSize: 16,
        padding: 5,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        padding: 5,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

function ShortcutTable() {
    return (
        <TableContainer component={Paper}>
            <Table aria-label="customized table">
                <TableHead>
                    <TableRow>
                        <StyledTableCell>Key</StyledTableCell>
                        <StyledTableCell align="right">Description</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {Object.entries(keyBinds).map(([key, value]) => {
                        console.log(key, value);
                        return (
                            <StyledTableRow key={key}>
                                <StyledTableCell component="th" scope="row">
                                    {value}
                                </StyledTableCell>
                                <StyledTableCell align="right">{key}</StyledTableCell>
                            </StyledTableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

function ShortcutDialog({open, setOpen}: {open: boolean, setOpen: React.Dispatch<React.SetStateAction<boolean>>}) {
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle>Rov Label Shortcuts</DialogTitle>
            <ShortcutTable />
        </Dialog>
    );
}

export default ShortcutDialog;
