import ConnectionNode from "../components/ConnectionNode";
import DataNode from "../components/DataNode";
import ModelNode from "../components/ModelNode";

export const graphNodeTypes = {
    operation: ModelNode,
    data: DataNode,
    connection: ConnectionNode,
    flow: ModelNode,
};
