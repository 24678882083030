import { gisApi } from "../../common/api/redux_api";
import { VectorRunData } from "../state/analysisSlice";


export interface GeomOperationResult {
    id: number,
    geom: string,
}
export interface OperationResult {
    operation_id: string
    type: string
    entity: string,
    geoms: GeomOperationResult[]
}

export const analysisApi = gisApi.injectEndpoints({
    endpoints: (builder) => ({
        getVectorRunData: builder.query<Omit<VectorRunData, 'visible'>[], void>({
            query: () => '/gis/vector_runs_data'
        }),
        calculateAnalysis: builder.mutation<OperationResult, { data: any }>({
            query: ({ data }) => ({
                url: '/gis/process_operation',
                method: 'POST',
                body: data,
            }),
        }),
        calculateBuffer: builder.mutation<{ operation_id: string, geom: string }, { data: any }>({
            query: ({ data }) => ({
                url: '/gis/wkt_buffer',
                method: 'POST',
                body: data,
            }),
        }),
        getElementData: builder.query<any, { type: string, entity: string, id: number }>({
            query: (body) => ({
                url: '/gis/get_operation_entity',
                method: 'POST',
                body: body,
            })
        }),
    }),
})

export const {
    useGetVectorRunDataQuery,
    useCalculateAnalysisMutation,
    useLazyGetElementDataQuery,
    useCalculateBufferMutation,
} = analysisApi;