import { Box, createTheme, ThemeProvider } from '@mui/material';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { userRoles } from '../authentication/components/userRoles';
import Login from '../authentication/views/Login';
import Register from '../authentication/views/Register';
import DashBoard from '../dashboard/views/DashBoard';
import MainMap from '../mainMap/views/MainMap';
import ProcessRoutes from '../process/routes';
import Risks from '../risksMap/views/Risks';
import RovLabelRoutes from '../rovLabel/routes';
import './App.css';
import PermissionRoutes from './components/PermissionRoutes';
import PrivateRoutes from './components/PrivateRoutes';
import NavBar from './views/layout/NavBar';
import NotFound from './views/NotFound';
import Unauthorized from './views/Unauthorized';
import AuthRoutes from '../authentication/AuthRoutes';
import ProfileRoutes from '../authentication/ProfileRoutes';
import { esES } from '@mui/material/locale';

const theme = createTheme({
  palette: {
    primary: {
      main: "#473066",
    },
    secondary: {
      main: "#7464a1",
    },
    background: {
      default: "#fcfcfc",
    }
  }
}, esES)

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh', boxSizing: 'border-box', overflow: "hidden", width: '100vw' }}>
        <BrowserRouter>
          <NavBar />
          <Box sx={{ flexGrow: 1, overflow: 'hidden' }}>
            <Routes>
              <Route element={<PrivateRoutes />} >
                <Route element={<PermissionRoutes allowedRoles={[userRoles.admin]} />}>
                  <Route path="/mainMap" element={<MainMap />} />
                  <Route path="/dashboard-sentinel" element={<DashBoard />} />
                </Route>

                <Route element={<PermissionRoutes allowedRoles={[userRoles.label]} />}>
                  <Route path='/rovlabel/*' element={<RovLabelRoutes />} />
                </Route>

                <Route element={<PermissionRoutes allowedRoles={[userRoles.risk]} />}>
                  <Route path="/risks" element={<Risks />} />
                </Route>

                <Route element={<PermissionRoutes allowedRoles={[userRoles.inference]} />}>
                  <Route path='/*' element={<ProcessRoutes />} />
                </Route>

                <Route path="/profile/*" element={<ProfileRoutes />} />
              </Route>
              <Route path="/auth/*" element={<AuthRoutes />} />
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route path="/unauthorized" element={<Unauthorized />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </Box>
        </BrowserRouter>
      </Box>
    </ThemeProvider>
  );
}

export default App;
