import { ControlOptions } from "leaflet"
import { useEffect, useState } from "react"
import L from 'leaflet'
import { createPortal } from "react-dom"

const POSITION_CLASSES = {
  bottomleft: 'leaflet-bottom leaflet-left',
  bottomright: 'leaflet-bottom leaflet-right',
  topleft: 'leaflet-top leaflet-left',
  topright: 'leaflet-top leaflet-right',
}

interface BaseMapProps extends ControlOptions {
  children?: any
}

const RovisenMapControl = (props: BaseMapProps): JSX.Element => {
  const [container, setContainer] = useState<any>(document.createElement('div'))
  const positionClass = props.position ? POSITION_CLASSES[props.position] : POSITION_CLASSES.topright

  useEffect(() => {
    const targetDiv = document.getElementsByClassName(positionClass)
    setContainer(targetDiv[0])
  }, [positionClass])

  const controlContainer = (
    <div className='leaflet-control'>{props.children}</div>
  )

  L.DomEvent.disableClickPropagation(container)
  L.DomEvent.disableScrollPropagation(container)

  return createPortal(controlContainer, container)
}

export default RovisenMapControl
