import { Alert, AlertColor, Collapse, IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

interface AlertDialogProps {
    alertOpen: boolean;
    setAlertOpen: (open: boolean) => void;
    message: string;
}

export interface AlertState {
    alertOpen: boolean;
    message: string;
}

interface CustomAlertDialogProps extends AlertDialogProps {
    severity: AlertColor;
}

export interface CustomAlertState extends AlertState {
    severity: AlertColor;
}

function CustomAlertDialog({ alertOpen, setAlertOpen, message, severity }: CustomAlertDialogProps) {
    return (
        <Collapse in={alertOpen}>
            <Alert action={
                <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                        setAlertOpen(false);
                    }}
                >
                    <CloseIcon fontSize="inherit" />
                </IconButton>
                }
                severity={severity}>{message}</Alert>
        </Collapse>
    );
}

function createAlertDialog(severity: AlertColor) {
    return function AlertDialog(props: AlertDialogProps) {
        return <CustomAlertDialog {...props} severity={severity} />;
    }
}

const ErrorAlertDialog = createAlertDialog('error');
const InfoAlertDialog = createAlertDialog('info');
const SuccessAlertDialog = createAlertDialog('success');
const WarningAlertDialog = createAlertDialog('warning');


export {
    CustomAlertDialog,
    ErrorAlertDialog,
    InfoAlertDialog,
    SuccessAlertDialog,
    WarningAlertDialog
};
