import { Alert, Box, CircularProgress, IconButton, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from "@mui/material";
import { ConfigInput } from "../../types/DataTypes";
import { CheckBox, CheckBoxOutlineBlank, ContentCopy, Download } from "@mui/icons-material";
import { useGetGeometryQuery, useGetRasterQuery, useLazyGetGeometryDownloadLinkQuery, useLazyGetRasterDownloadLinkQuery } from "../../api/processApi";
import { ReactNode, useEffect, useMemo, useState } from "react";
import { formatArea, formatDistance, geodesicArea, geodesicDistance, wktToVertexs } from "../../../common/utils/tools";

const RangeDateData = ({ data, isLoading = false }: { data?: { [key: string]: any }, isLoading?: boolean }) => {
    const initDate = data && new Date(data.payload.value.init)
    const endDate = data && new Date(data.payload.value.end)
    return <>
        <TableCell>{isLoading ? <CircularProgress size={15} /> : (initDate && endDate ? `Entre ${initDate.toLocaleDateString("es-CL")} y ${endDate.toLocaleDateString("es-CL")}` : "-")}</TableCell>
        <TableCell>{ }</TableCell>
    </>
}

const GeometryDataDB = ({ data, isLoading = false }: { data?: { [key: string]: any }, isLoading?: boolean }) => {
    const [getGeomLink, geomLink] = useLazyGetGeometryDownloadLinkQuery()
    const { data: geomData } = useGetGeometryQuery(data?.id)

    useEffect(() => {
        if (geomLink.isSuccess) {
            window.location.href = geomLink.data
        }
    }, [geomLink])

    return <>
        <TableCell>{data ? (isLoading ? <CircularProgress size={15} /> : geomData?.name) : "-"}</TableCell>
        <TableCell>
            {data &&
                (
                    geomLink.isFetching
                        ? <CircularProgress size={15} />
                        : <Tooltip title="Descargar GeoJSON">
                            <IconButton size="small" onClick={() => getGeomLink(data.id)}>
                                <Download fontSize="inherit" />
                            </IconButton>
                        </Tooltip>
                )
            }
        </TableCell>
    </>
}

const GeometryDataValue = ({ data, isLoading = false }: { data?: { [key: string]: any }, isLoading?: boolean }) => {
    const [open, setOpen] = useState(false);

    const area = useMemo(() => {
        var measurement = ""
        if (data && data.payload.value) {
            const vertexs = wktToVertexs(data.payload.value)
            if (vertexs) {
                if (data.geom_type === "Polygon") {
                    const area = geodesicArea(vertexs)
                    measurement = formatArea(area)
                }
                else if (data.geom_type === "LineString") {
                    const distance = geodesicDistance(vertexs)
                    measurement = formatDistance(distance)
                }
            }
            if (measurement) return ` - ${measurement}`
        }
        return ""
    }, [data])

    return <>
        <TableCell>{data ? (isLoading ? <CircularProgress size={15} /> : `${data.geom_type}${area}`) : "-"}</TableCell>
        <TableCell>
            {data && <Tooltip title="Copiar WKT">
                <IconButton size="small" onClick={() => {
                    navigator.clipboard.writeText(data?.payload.value)
                    setOpen(true)
                }}>
                    <ContentCopy fontSize="inherit" />
                </IconButton>
            </Tooltip>}
            <Snackbar
                open={open}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                autoHideDuration={5000}
                onClose={() => setOpen(false)}
            >
                <Alert
                    onClose={() => setOpen(false)}
                    severity="success"
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    WKT copiado al portapapeles
                </Alert>
            </Snackbar>
        </TableCell>
    </>
}

const GeometryData = ({ data, isLoading = false }: { data?: { [key: string]: any }, isLoading?: boolean }) => {
    return data && data.id ? <GeometryDataDB data={data} isLoading={isLoading} /> : <GeometryDataValue data={data} isLoading={isLoading} />
}

const BooleanData = ({ data, isLoading = false }: { data?: boolean, isLoading?: boolean }) => {
    return <>
        <TableCell>
            {isLoading
                ? <CircularProgress size={15} />
                : (data !== undefined
                    ? (data ? <CheckBox color="primary" /> : <CheckBoxOutlineBlank color="primary"/>)
                    : "-"
                )
            }
        </TableCell>
        <TableCell>
        </TableCell>
    </>
}

const ValueData = ({ data, isLoading = false }: { data?: number | string | boolean, isLoading?: boolean }) => {
    const [open, setOpen] = useState(false);
    return <>
        <TableCell>{isLoading ? <CircularProgress size={15} /> : (data !== undefined ? data.toString() : "-")}</TableCell>
        <TableCell>
            {data && <Tooltip title="Copiar valor">
                <IconButton size="small" onClick={() => {
                    navigator.clipboard.writeText(data.toString())
                    setOpen(true)
                }}>
                    <ContentCopy fontSize="inherit" />
                </IconButton>
            </Tooltip>}
            <Snackbar
                open={open}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                autoHideDuration={5000}
                onClose={() => setOpen(false)}
            >
                <Alert
                    onClose={() => setOpen(false)}
                    severity="success"
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    Valor copiado al portapapeles
                </Alert>
            </Snackbar>
        </TableCell>
    </>
}

const DateData = ({ data, isLoading = false }: { data?: { [key: string]: any }, isLoading?: boolean }) => {
    const [open, setOpen] = useState(false)
    return <>
        <TableCell>{isLoading ? <CircularProgress size={15} /> : (data ? data.payload.value : "-")}</TableCell>
        <TableCell>
            {data && <Tooltip title="Copiar fecha">
                <IconButton size="small" onClick={() => {
                    navigator.clipboard.writeText(data.payload.value)
                    setOpen(true)
                }}>
                    <ContentCopy fontSize="inherit" />
                </IconButton>
            </Tooltip>}
            <Snackbar
                open={open}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                autoHideDuration={5000}
                onClose={() => setOpen(false)}
            >
                <Alert
                    onClose={() => setOpen(false)}
                    severity="success"
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    Fecha copiada al portapapeles
                </Alert>
            </Snackbar>
        </TableCell>
    </>
}

const RasterData = ({ data, isLoading = false }: { data?: { [key: string]: any }, isLoading?: boolean }) => {
    const [getRasterLink, rasterLink] = useLazyGetRasterDownloadLinkQuery()
    const { data: rasterData } = useGetRasterQuery(data?.id)

    useEffect(() => {
        if (rasterLink.isSuccess) {
            window.location.href = rasterLink.data
        }
    }, [rasterLink])

    return <>
        <TableCell>{data ? (isLoading ? <CircularProgress size={15} /> : (rasterData?.name)) : "-"}</TableCell>
        <TableCell>
            {data &&
                (
                    rasterLink.isFetching
                        ? <CircularProgress size={15} />
                        : <Tooltip title="Descargar Raster">
                            <IconButton size="small" onClick={() => data && getRasterLink(data.id)}>
                                <Download fontSize="inherit" />
                            </IconButton>
                        </Tooltip>
                )
            }
        </TableCell>
    </>
}

const executionListItem = ({ config, data, isLoading = false }: { config: ConfigInput, data: any, isLoading?: boolean }): ReactNode[] => {
    if (config.subType === undefined || data === undefined) return []
    switch (config.subType) {
        case "date_range":
            return data.map((item: any, index: number) => <RangeDateData key={index} data={item} isLoading={isLoading} />)
        case "geom":
            return data.map((item: any, index: number) => <GeometryData key={index} data={item} isLoading={isLoading} />)
        case "raster":
            return data.map((item: any, index: number) => <RasterData key={index} data={item} isLoading={isLoading} />)
        case "date":
            return data.map((item: any, index: number) => <DateData key={index} data={item} isLoading={isLoading} />)
        case "boolean":
            return data.map((item: any, index: number) => <BooleanData key={index} data={item} isLoading={isLoading} />)
        case "number":
        case "string":
            return data.map((item: any, index: number) => <ValueData key={index} data={item} isLoading={isLoading} />)
        case "sentinel":
        default:
            return []
    }
}

const ExecutionDetailItem = ({ config, data, id, isLoading = false }: { config: ConfigInput, data: any, id: string, isLoading?: boolean }) => {
    const item = useMemo(() => {
        switch (config.type) {
            case "date_range":
                return [<RangeDateData key={id} data={data} isLoading={isLoading} />]
            case "geom":
                return [<GeometryData key={id} data={data} isLoading={isLoading} />]
            case "raster":
                return [<RasterData key={id} data={data} isLoading={isLoading} />]
            case "date":
                return [<DateData key={id} data={data} isLoading={isLoading} />]
            case "boolean":
                return [<BooleanData key={id} data={data} isLoading={isLoading} />]
            case "number":
            case "string":
                return [<ValueData key={id} data={data} isLoading={isLoading} />]
            case "list":
                return executionListItem({ config, data, isLoading })
            default:
                return []
        }
    }, [config, data, isLoading, id])
    return <>
        <TableRow>
            <TableCell rowSpan={config.type !== "list" ? 1 : (data ? data.length : 1)}>{config.name}</TableCell>
            {item.length > 0 ? item[0] : null}
        </TableRow>
        {
            item.length > 1 ? item.slice(1).map((row, index) => <TableRow key={index}>{row}</TableRow>) : null
        }
    </>
}

const ExecutionDetailsData = ({ format, data, isLoading = false }: { format: { [key: string]: any }, data: { [key: string]: any }, isLoading?: boolean }) => {
    return <TableContainer component={Box}>
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Nombre</TableCell>
                    <TableCell>Valor</TableCell>
                    <TableCell>Acciones</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {Object.keys(format).map(key => {
                    const config = format[key] as ConfigInput
                    if (data[key] === undefined) return null
                    return <ExecutionDetailItem id={key} config={config} data={data[key]} key={key} isLoading={isLoading} />
                })}
            </TableBody>
        </Table>
    </TableContainer>
}

export default ExecutionDetailsData