import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { RootState } from '../../app/state/store';
import { Tile } from '../types/DataTypes';
import { jwtDecode } from "jwt-decode";
import CustomJwt from '../../authentication/utils/customJwt';
import { logOut } from '../../authentication/state/authSlice';

const endpoint = `${process.env.REACT_APP_API_URL}`;

const baseQuery = fetchBaseQuery({
    baseUrl: endpoint,
    prepareHeaders: (headers, api) => {
        const token = (api.getState() as RootState).auth.token
        headers.set('accept', 'application/json')
        headers.set('Authorization', `Bearer ${token}`)
    }
})

const baseQueryWithReauth = async (args: any, api: any, extraOptions: any) => {
    let result = await baseQuery(args, api, extraOptions)

    if (result?.error?.status === 401) {
        const token = (api.getState() as RootState).auth.token
        if (token) {
            const decoded = jwtDecode<CustomJwt>(token)
            if (decoded?.exp && (decoded.exp * 1000) < Date.now()) {
                // TODO: Implement refresh token
                // console.log('Token expired, refreshing...')
                // await api.dispatch(refreshToken())
                // result = await baseQuery(args, api, extraOptions)
                await api.dispatch(logOut())
            } else {
            }
        }
    }

    return result
}

export const gisApi = createApi({
    reducerPath: 'gisApi',
    baseQuery: baseQueryWithReauth,
    tagTypes: [
        'PipelineInstances', 'PipelineModels', 'PipelineInstanceDetails', 'PipelineModelDefinition',
        'Processes', 'Runs', 'InferenceModels', 'Tiles', 'Mosaics', 'Geometries', 'Rasters',
        'Credits'
    ],
    endpoints: (builder) => ({
        getAddressByLatLng: builder.query<any, { coords: number[], placeId: string }>({
            query: ({ coords }) => `/utils/geocode?lat=${coords[0]}&lng=${coords[1]}`,
        }),
        getLocationAutocomplete: builder.query<any, { text: string, lat?: number, lng?: number }>({
            query: ({ text, lat, lng }) => {
                var url = `/utils/geocode/autocomplete?text=${text}`
                url += lat && lng ? `&lat=${lat.toFixed(5)}&lng=${lng.toFixed(5)}` : ''
                return {
                    url: url,
                }
            }
        }),
        getCapabilities: builder.query<any[], string>({
            query: (workspace) => `/utils/get_capabilities?workspace=${workspace}`,
        }),
        calculateStatistic: builder.query<any, { bbox: number[], layer_t1: string, layer_t2: string }>({
            query: ({ bbox, layer_t1, layer_t2 }) => ({
                url: `/ine/count_difference`,
                method: 'POST',
                body: {
                    bbox: bbox,
                    layer_t1: layer_t1,
                    layer_t2: layer_t2,
                }
            })
        }),
        findByTiles: builder.query<any, { tile: string, initDate: string, endDate: string, cloud: number }>({
            query: ({ tile, initDate, endDate, cloud }) => ({
                url: '/sentinel/search',
                method: 'POST',
                body: {
                    'tile': tile,
                    'init_date': initDate,
                    'end_date': endDate,
                    'cloud': cloud,
                }
            })
        }),
        findByGeom: builder.query<any, { polygon: string, initDate: string, endDate: string, cloud: number }>({
            query: ({ polygon, initDate, endDate, cloud }) => ({
                url: '/sentinel/search-by-geom',
                method: 'POST',
                body: {
                    'polygon': polygon,
                    'init_date': initDate,
                    'end_date': endDate,
                    'cloud': cloud,
                }
            })
        }),
        getTile: builder.query<Tile, string>({
            query: (tile) => `/sentinel/tile/${tile}`,
            providesTags: (result, error, id) => [{ type: 'Tiles', id }],
        }),
        getTiles: builder.query<Tile[], void>({
            query: () => '/sentinel/tiles',
            providesTags: (result) =>
                result
                    ? [
                        ...result.map(({ id }) => ({ type: 'Tiles', id } as const)),
                        { type: 'Tiles', id: 'LIST' }
                    ]
                    : [{ type: 'Tiles', id: 'LIST' }],
            keepUnusedDataFor: 1000,
        }),
    })
})

export const {
    useGetAddressByLatLngQuery,
    useLazyGetLocationAutocompleteQuery,
    useLazyGetAddressByLatLngQuery,
    useGetCapabilitiesQuery,
    useLazyCalculateStatisticQuery,
    useLazyFindByTilesQuery,
    useLazyFindByGeomQuery,
    useGetTilesQuery,
    useGetTileQuery,
} = gisApi
