import { Stack, TextField } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { AppDispatch, RootState } from "../../../app/state/store"
import { selectProcessNumberById, updateProcessNumber } from "../../state/processSlice"
import { useState } from "react"

const NumberProcessInput = ({ id }: { id: string }) => {
    const processNumber = useSelector((state: RootState) => selectProcessNumberById(state, id))
    const dispatch = useDispatch<AppDispatch>()

    const [localNumber, setLocalNumber] = useState(processNumber.value?.toString())
    const [error, setError] = useState('')

    return <Stack direction='row' spacing={3} useFlexGap flexWrap='wrap'>
        <TextField
            fullWidth
            value={localNumber}
            onChange={(e) => {
                const newValue = e.target.value
                const newNumber = Number(newValue)
                setLocalNumber(newValue)
                setError(prev => {
                    if (isNaN(newNumber))
                        return "No es un número"
                    if (processNumber.minValue !== undefined && newNumber < processNumber.minValue)
                        return `El valor no puede ser menor a ${processNumber.minValue}`
                    if (processNumber.maxValue !== undefined && newNumber > processNumber.maxValue)
                        return `El valor no puede ser mayor a ${processNumber.maxValue}`

                    dispatch(updateProcessNumber({ id: id, changes: { value: newNumber } }))
                    return ''
                })
            }}
            error={error !== ''}
            label={processNumber.name}
            helperText={error}
        />
    </Stack>
}

export default NumberProcessInput