import { StatusType } from "../../process/types/DataTypes"

export interface ModelHandleProps {
    type: string
    name: string
    id: string
}

export interface ModelNodeProps {
    name: string
    type: "export" | "data" | "tool" | "util" | "model",
    inputs: ModelHandleProps[]
    outputs: ModelHandleProps[]
    status?: StatusType
}

export interface DataNodeProps {
    name: string
    type: "image" | "layer",
    inputs: ModelHandleProps[]
    outputs: ModelHandleProps[]
}

export const OperationNodes = {
    change: {
        name: "Detección de Cambios",
        type: 'model',
        inputs: [
            {
                type: 'Raster',
                name: 'raster 1',
                id: 'input_image1',
            },
            {
                type: 'Raster',
                name: 'raster 2',
                id: 'input_image2',
            },
            {
                type: 'Polygon',
                name: 'máscara',
                id: 'input_mask',
            }
        ],
        outputs: [
            {
                type: 'Raster',
                name: 'raster',
                id: 'output_raster',
            },
        ]
    },
    burnedArea: {
        name: "Detección de Áreas Quemadas",
        type: 'model',
        inputs: [
            {
                type: 'Raster',
                name: 'raster',
                id: 'input_raster'
            },
            {
                type: 'Polygon',
                name: 'máscara',
                id: 'input_mask'
            }
        ],
        outputs: [
            {
                type: 'Raster',
                name: 'raster',
                id: 'output_raster'
            },
        ]
    },
    buildingDetection: {
        name: "Detección de Edificaciones",
        type: 'model',
        inputs: [
            {
                type: 'Raster',
                name: 'raster',
                id: 'input_raster',
            }
        ],
        outputs: [
            {
                type: 'Raster',
                name: 'raster',
                id: 'output_raster'
            },
            {
                type: 'List<Polygon>',
                name: 'edificaciones',
                id: 'output_buildings'
            }
        ]
    },
    streetDetection: {
        name: "Detección de Calles",
        type: 'model',
        inputs: [
            {
                type: 'Raster',
                name: 'raster',
                id: 'input_raster',
            }
        ],
        outputs: [
            {
                type: 'Raster',
                name: 'raster',
                id: 'output_raster'
            },
            {
                type: 'List<LineString>',
                name: 'calles',
                id: 'output_streets'
            }
        ]
    },
    blockDetection: {
        name: "Detección de Cuadras",
        type: 'model',
        inputs: [
            {
                type: 'Raster',
                name: 'raster',
                id: 'input_raster',
            }
        ],
        outputs: [
            {
                type: 'Raster',
                name: 'raster',
                id: 'output_raster'
            },
            {
                type: 'List<Polygon>',
                name: 'cuadras',
                id: 'output_blocks'
            }
        ]
    },
    semanticSegmentation: {
        name: "Segmentación Semántica",
        type: 'model',
        inputs: [
            {
                type: 'Raster',
                name: 'raster',
                id: 'input_raster',
            },
            {
                type: 'Polygon',
                name: 'máscara',
                id: 'input_mask',
            }
        ],
        outputs: [
            {
                type: 'Raster',
                name: 'raster',
                id: 'output_raster'
            },
        ]
    },
    densityEstimation: {
        name: "Estimación de Densidad",
        type: 'model',
        inputs: [
            {
                type: 'Raster',
                name: 'raster',
                id: 'input_raster',
            },
            {
                type: 'Polygon',
                name: 'máscara',
                id: 'input_mask',
            }
        ],
        outputs: [
            {
                type: 'Raster',
                name: 'raster',
                id: 'output_raster'
            },
        ]
    },
    wildfirePrediction: {
        name: "Predicción de Incendios",
        type: 'model',
        inputs: [
            {
                type: 'Date',
                name: 'fecha',
                id: 'input_date',
            },
            {
                type: 'Polygon',
                name: 'máscara',
                id: 'input_mask',
            }
        ],
        outputs: [
            {
                type: 'Raster',
                name: 'raster',
                id: 'output_raster',
            }
        ]
    },
    findSentinel: {
        name: "Buscador Sentinel",
        type: 'tool',
        inputs: [
            {
                type: 'Date',
                name: 'fecha',
                id: 'input_date',
            },
            {
                type: 'Polygon',
                name: 'máscara',
                id: 'input_mask',
            }
        ],
        outputs: [
            {
                type: 'Raster',
                name: 'raster',
                id: 'output_raster',
            },
            {
                type: 'Polygon',
                name: 'máscara',
                id: 'output_mask',
            }
        ]
    },
    findPairs: {
        name: "Buscador de Pares",
        type: 'tool',
        inputs: [
            {
                type: 'DateRange',
                name: 'rango de fechas',
                id: 'input_date_range',
            },
            {
                type: 'Polygon',
                name: 'máscara',
                id: 'input_mask',
            }
        ],
        outputs: [
            {
                type: 'Raster',
                name: 'raster 1',
                id: 'output_image1',
            },
            {
                type: 'Raster',
                name: 'raster 2',
                id: 'output_image2',
            },
            {
                type: 'Polygon',
                name: 'máscara',
                id: 'output_mask',
            }
        ]
    },
    polygonizer: {
        name: "Poligonizador",
        type: 'tool',
        inputs: [
            {
                type: 'Raster',
                name: 'raster',
                id: 'raster'
            },
            {
                type: 'Number',
                name: 'threshold',
                id: 'min_t'
            },
            {
                type: 'Number',
                name: 'area min',
                id: 'min_a'
            },
            {
                type: 'Number',
                name: 'area max',
                id: 'max_a'
            },
        ],
        outputs: [
            {
                type: 'List<Polygon>',
                name: 'polígonos',
                id: 'output_polygons'
            },
        ]
    },
    intersect: {
        name: 'Intersección',
        type: 'tool',
        inputs: [
            {
                type: 'Polygon',
                name: 'polígono',
                id: 'input_polygon',
            },
            {
                type: 'List<Polygon>',
                name: 'polígonos',
                id: 'input_list',
            }
        ],
        outputs: [
            {
                type: 'List<Polygon>',
                name: 'intersecciones',
                id: 'output-el'
            }
        ]
    },
    diff: {
        name: 'Diferencia',
        type: 'tool',
        inputs: [
            {
                type: 'List<Polygon>',
                name: 'polígonos 1',
                id: 'input1',
            },
            {
                type: 'List<Polygon>',
                name: 'polígonos 2',
                id: 'input2',
            },
        ],
        outputs: [
            {
                type: 'List<Polygon>',
                name: 'diferencias',
                id: 'output-diff'
            }
        ]
    },
    contains: {
        name: 'Contiene',
        type: 'tool',
        inputs: [
            {
                type: 'Polygon',
                name: 'máscara',
                id: 'input_mask',
            },
            {
                type: 'List<Polygon>',
                name: 'polígonos',
                id: 'input_list',
            }
        ],
        outputs: [
            {
                type: 'List<Polygon>',
                name: 'contenidos',
                id: 'output_el'
            }
        ]
    },
    area: {
        name: 'Área',
        type: 'tool',
        inputs: [
            {
                type: 'List<Polygon>',
                name: 'polígonos',
                id: 'inputList',
            },
        ],
        outputs: [
            {
                type: 'Number',
                name: 'área',
                id: 'output_area'
            }
        ]
    },
    foreach: {
        name: 'For each <T>',
        type: 'util',
        inputs: [
            {
                type: 'List<T>',
                name: 'list',
                id: 'input-list'
            },
        ],
        outputs: [
            {
                type: '<T>',
                name: 'element',
                id: 'outputs'
            },
        ],
    },
    raster_intersect: {
        name: 'informacion de intersecciones',
        type: 'tool',
        inputs: [
            {
                type: 'Raster',
                name: 'raster',
                id: 'input_raster',
            },
            {
                type: 'List<Polygon>',
                name: 'polígonos',
                id: 'input_polygons',
            }
        ],
        outputs: [
            {
                type: 'List<Polygon>',
                name: 'polígonos',
                id: 'output_polygons'
            }
        ]
    },
}

export const FlowNodes = {
    start: {
        name: "Inicio",
        type: 'flow',
        inputs: [],
        outputs: [
            {
                type: 'any',
                name: 'entradas',
                id: 'inputs',
            }
        ]
    },
    end: {
        name: "Fin",
        type: 'flow',
        inputs: [
            {
                type: 'any',
                name: 'salidas',
                id: 'outputs',
            }
        ],
        outputs: []
    },
}

export const DataNodes = {
    libraryImage: {
        name: "Mis Imágenes",
        type: 'image',
        inputs: [],
        outputs: [
            {
                type: 'Raster',
                name: 'raster',
                id: 'raster',
            },
            {
                type: 'Polygon',
                name: 'bbox',
                id: 'bbox',
            },
            {
                type: 'Polygon',
                name: 'footprint',
                id: 'footprint',
            },
        ]
    },
    layer: {
        name: "Capa",
        type: 'layer',
        inputs: [],
        outputs: [
            {
                type: 'List<Polygon>',
                name: 'elements',
                id: 'el',
            },
            {
                type: 'Polygon',
                name: 'bbox',
                id: 'bbox',
            },
        ]
    },
}

export const ConnectionNodes = {
    inputImage: {
        name: "Raster de Entrada",
        type: 'image',
        inputs: [],
        outputs: [
            {
                type: 'Raster',
                name: 'raster',
                id: 'raster',
            },
            {
                type: 'Polygon',
                name: 'bbox',
                id: 'bbox',
            },
            {
                type: 'Polygon',
                name: 'footprint',
                id: 'footprint',
            },
        ],
    },
    outputRaster: {
        name: "Raster de Salida",
        type: 'image',
        inputs: [
            {
                type: 'Raster',
                name: 'raster',
                id: 'raster',
            }
        ],
        outputs: [],
    },
    outputValue: {
        name: "Valor de Salida",
        type: 'output',
        inputs: [
            {
                type: 'Number',
                name: 'number',
                id: 'number',
            }
        ],
        outputs: [],
    }
}