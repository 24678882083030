import { Box, Stack, Typography } from "@mui/material";
import { ConfigInput } from "../../types/DataTypes";
import InfoPopover from "../common/InfoPopover";
import { useMemo } from "react";
import RangeDateProcessInput from "./RangeDateProcessInput";
import GeometryProcessInput from "./GeometryProcessInput";
import DateProcessInput from "./DateProcessInput";
import NumberProcessInput from "./NumberProcessInput";
import StringProcessInput from "./StringProcessInput";
import RasterProcessInput from "./RasterProcessInput";
import BooleanProcessInput from "./BooleanProcessInput";

interface ConfigProcessInputComponentProps {
    config: ConfigInput,
    id: string
}

const ConfigProcessInputComponent = ({ config, id }: ConfigProcessInputComponentProps) => {
    const component = useMemo(() => {
        switch (config.type) {
            case "date_range":
                return <RangeDateProcessInput id={id} key={id} />
            case "geom":
                return <GeometryProcessInput id={id} key={id} />
            case "date":
                return <DateProcessInput id={id} key={id} />
            case "number":
                return <NumberProcessInput id={id} key={id} />
            case "string":
                return <StringProcessInput id={id} key={id} />
            case "raster":
                return <RasterProcessInput id={id} key={id} />
            case "boolean":
                return <BooleanProcessInput id={id} key={id} />
            default:
                return null
        }
    }, [config, id])

    return <Stack direction='row' spacing={3} useFlexGap flexWrap='wrap' alignItems='center'>
        <Box sx={{ display: 'flex', flexDirection: 'row', width: 250, alignItems: 'center' }}>
            <Typography flexGrow={1} variant={config.required ? "h6" : "body1"}>{`${config.name}${config.required ? '*' : ''}`}</Typography>
            {config.description &&
                <InfoPopover>
                    {config.description}
                </InfoPopover>
            }
        </Box>
        <Box sx={{ flexGrow: 1, paddingY: config.required ? 3 : 1, overflow: 'hidden' }}>
            {component}
        </Box>
    </Stack>
}

export default ConfigProcessInputComponent