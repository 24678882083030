import { getStepFunctionStateInfo, getStepFunctionStateNames } from "../../process/utils/stepFunctionUtils";
import { DataNodes, FlowNodes, OperationNodes } from "../types/NodesTypes";
import { PipelineDefinition, PipelineNode } from "../types/PipelineGraphTypes";
import { SFExecutionEvent, SFStateDisplay, SFStateMachineDefinition } from "../types/StepFunctionTypes";

function getNodeType(node: PipelineNode) {
    switch (node.type) {
        case ('data'):
            return DataNodes[node.value as keyof typeof DataNodes]
        case ('operation'):
            return OperationNodes[node.value as keyof typeof OperationNodes]
        case ('flow'):
            return FlowNodes[node.value as keyof typeof FlowNodes]
        default:
            return null;
    }
}

export function getPipelineModelGraph(definition: PipelineDefinition) {
    const nodes = definition.nodes.map((node) => {
        const data = getNodeType(node);
        return {
            id: node.id,
            data: data,
            type: node.type,
            width: 250,
            height: 200,
        }
    });

    const edges = definition.edges.map((edge) => {
        return {
            source: edge.source,
            target: edge.target,
            sourceHandle: edge.source_output,
            targetHandle: edge.target_input,
        }
    });

    return { nodes, edges };
}

export function getPipelineGraph(definition: PipelineDefinition, stateMachineDef?: SFStateMachineDefinition, history?: SFExecutionEvent[]) {
    let states_info: { [key: string]: SFStateDisplay } = {};
    if (stateMachineDef && history) {
        const state_names = getStepFunctionStateNames(stateMachineDef)
        states_info = getStepFunctionStateInfo(state_names, history);
    }

    const nodes = definition.nodes.map((node) => {
        const data = getNodeType(node);
        return {
            id: node.id,
            data: {
                ...data,
                status: states_info[node.id]?.status
            },
            type: node.type,
            width: 250,
            height: 200,
        }
    });

    const edges = definition.edges.map((edge) => {
        return {
            source: edge.source,
            target: edge.target,
            sourceHandle: edge.source_output,
            targetHandle: edge.target_input,
        }
    });

    return { nodes, edges };
}
