import { useSelector } from "react-redux"
import { selectProcessRasterById, selectProcessRastersIds } from "../../state/processSlice"
import { RootState } from "../../../app/state/store"
import { ImageOverlay, useMap } from "react-leaflet"
import { wktToBounds } from "../../../common/utils/tools"
import { useEffect, useMemo } from "react"

const RasterProcessDraw = ({ id }: { id: string }) => {
    const raster = useSelector((state: RootState) => selectProcessRasterById(state, id))
    const bounds = useMemo(() => raster.key !== '' && wktToBounds(raster.bbox), [raster])
    const map = useMap()
    useEffect(() => {
        bounds && map.fitBounds(bounds)
    }, [bounds, map])

    return bounds && raster.preview ? <ImageOverlay url={raster.preview} bounds={bounds} key={raster.rasterId} /> : null
}

const RastersProcessDraw = () => {
    const rasters = useSelector(selectProcessRastersIds)
    return <>
        {rasters.map((raster) => <RasterProcessDraw key={raster} id={raster} />)}
    </>
}

export default RastersProcessDraw