import { useMapEvent } from "react-leaflet"

const ResizeMap = ({ setPosition }: { setPosition: any }) => {
    const map = useMapEvent('moveend', () => {
        setPosition({ center: map.getCenter(), zoom: map.getZoom() })
    })

    return null
}

export default ResizeMap