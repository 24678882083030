import { Polygon } from 'react-leaflet'
import EditingPolygon from './EditingPolygon'
import { useDispatch, useSelector } from 'react-redux'
import { setSelectedPolygon, setPolygonEditing, selectPolygonById, setSelectedType } from '../../state/rovLabelSlice'
import { AppDispatch, RootState } from '../../../app/state/store';
import { useState } from 'react';

function EditPolygon({ polygonId }: { polygonId: number}) {
    const polygon = useSelector((state: RootState) => selectPolygonById(state, polygonId));
    const { vertexs, editing } = polygon;
    const dispatch = useDispatch<AppDispatch>();

    const [hover, setHover] = useState(false);

    const pathOptions = {
        color: hover ? 'yellow' : polygon.typeColor,
        weight: 2,
        fillColor: polygon.typeColor,
    }


    return (
        <>
            {editing && <EditingPolygon polygonId={polygonId} />}
            <Polygon
                positions={vertexs.map((vertex: any) => vertex.coord)}
                pathOptions={pathOptions}
                eventHandlers={{
                    click: (e) => {
                        dispatch(setSelectedPolygon({polygonId: polygonId}));
                        dispatch(setSelectedType({typeId: polygon.typeId}));
                        dispatch(setPolygonEditing({polygonId: polygonId, editing: !editing}));
                    },
                    mouseover: () => setHover(true),
                    mouseout: () => setHover(false)
                }}
            />
        </>
    )
}

export default EditPolygon;
