import { Typography } from "@mui/material"
import dayjs from "dayjs"
import localizedFormat from "dayjs/plugin/localizedFormat"
import 'dayjs/locale/es-mx'

dayjs.locale('es-mx')
dayjs.extend(localizedFormat)

const DateDisplay = ({ date, format = 'LLLL' }: { date: string, format?: string }) => {

    const dateString = dayjs(date).format(format    )

    return <Typography>{dateString}</Typography>
}

export default DateDisplay