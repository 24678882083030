import { Box, Container, Divider, Stack } from "@mui/material"
import { useCallback, useEffect, useRef, useState } from "react"
import { VirtuosoHandle } from "react-virtuoso"
import { ImageOverlay, Polygon } from "react-leaflet"
import { useGetPipelineInstanceQuery, useGetRasterQuery } from "../../api/processApi"
import { useInputMaskCartography } from "../../hooks/useMask"
import DraggableLayout from "../../../common/components/layout/DraggableLayout"
import { DetectionOptionsDisplay, RasterDisplay } from "./DataDisplay"
import { wktToBounds } from "../../../common/utils/tools"
import { PipelineModel } from "../../types/DataTypes"
import { usePolygons } from "../../hooks/useGeoJsonResponse"
import { BreadcrumbsRender, ExecutionTitleRender, ResultsMap } from "../../views/ResultsViewer"
import { GeometryList } from "./GeometryLists"


const BlocksViewer = ({ pipelineInstanceId, model }: { pipelineInstanceId: string, model: PipelineModel }) => {
    const { data: pipelineInstance } = useGetPipelineInstanceQuery(pipelineInstanceId)
    const { data: raster } = useGetRasterQuery(pipelineInstance?.input_data?.raster?.id, {skip: !pipelineInstance?.input_data?.raster?.id})

    const { geometries: geometries1, downloadGeoJson: downloadGeoJson1 } = usePolygons(pipelineInstance?.output_data?.blocks_first?.id)
    const { geometries: geometries2, downloadGeoJson: downloadGeoJson2 } = usePolygons(pipelineInstance?.output_data?.blocks_second?.id)
    const { geometries: geometries3, downloadGeoJson: downloadGeoJson3 } = usePolygons(pipelineInstance?.output_data?.blocks_third?.id)

    const [detections, setDetections] = useState('0.50')
    const [geometries, setGeometries] = useState(geometries3)
    const [downloadGeoJson, setDownloadGeoJson] = useState<() => void>(() => downloadGeoJson3)

    useEffect(() => {
        if (detections === '0.10') {
            setGeometries(geometries1)
            setDownloadGeoJson(() => downloadGeoJson1)
        }
        if (detections === '0.25') {
            setGeometries(geometries2)
            setDownloadGeoJson(() => downloadGeoJson2)
        }
        if (detections === '0.50') {
            setGeometries(geometries3)
            setDownloadGeoJson(() => downloadGeoJson3)
        }
    }, [detections, geometries1, geometries2, geometries3, downloadGeoJson1, downloadGeoJson2, downloadGeoJson3])


    const map = useRef<L.Map>(null)
    const updateMapSize = useCallback(() => {
        map.current && map.current.invalidateSize(true)
    }, [map])

    useInputMaskCartography(pipelineInstance?.input_data?.raster, map)
    const [showRaster, setShowRaster] = useState(true)
    const [showDetections, setShowDetections] = useState(true)

    const virtuoso = useRef<VirtuosoHandle>(null)
    const scrollToIndex = useCallback((index: number) => {
        virtuoso.current && virtuoso.current.scrollToIndex({
            index: index,
            align: 'start',
            behavior: 'smooth',
        })
    }, [virtuoso])


    return <DraggableLayout
        topLeft={
            <Box sx={{ height: 1, width: 1, display: 'flex', flexDirection: "column" }}>
                <Stack direction={'column'} sx={{ paddingX: 5, paddingY: 2 }} spacing={3}>
                    <BreadcrumbsRender model={model} instanceId={pipelineInstanceId} />
                </Stack>
                <Container>
                    <Stack paddingY={2} divider={<Divider variant="middle" flexItem />} spacing={1}>
                        <ExecutionTitleRender pipelineInstance={pipelineInstance} />
                        <Stack direction="row" spacing={1} alignItems="center">
                            <RasterDisplay name={raster?.name} showRaster={showRaster} setShowRaster={setShowRaster} />
                        </Stack>
                        <Stack direction="row" spacing={1} alignItems="center">
                            <DetectionOptionsDisplay detections={detections} setDetections={setDetections} show={showDetections} setShow={setShowDetections} onClick={downloadGeoJson} />
                        </Stack>
                    </Stack>
                </Container>
                <Divider flexItem />
                <GeometryList geometries={geometries} map={map} virtuoso={virtuoso} />
            </Box>
        }
        downRight={
            <ResultsMap map={map} >
                {showDetections && geometries && geometries.map((geometry, index) => <Polygon positions={geometry.vertexs} key={index} eventHandlers={{ click: () => scrollToIndex(index) }} />)}
                {raster && showRaster && raster.preview && <ImageOverlay url={raster.preview} bounds={wktToBounds(raster.preview_bbox)} />}
            </ResultsMap>
        }
        onDragEnd={updateMapSize}
        onChangeOrientation={updateMapSize}
    />
}

export default BlocksViewer
