import { Box, Checkbox, Collapse, Divider, Slider, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { selectRiskRenderLayerIds, selectRiskRenderLayersById, updateRiskMapRenderLayer } from "../../state/riskSlice"
import { AppDispatch, RootState } from "../../../app/state/store"
import { useState } from "react"
import { Tune } from "@mui/icons-material"

const RiskRenderLayersRow = ({ id }: { id: string }) => {
    const layer = useSelector((state: RootState) => selectRiskRenderLayersById(state, id))
    const dispatch = useDispatch<AppDispatch>()

    const [showConfig, setShowConfig] = useState(false)

    return (
        <>
            <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, padding: 1 }}>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <Typography sx={{ flexGrow: 1 }} variant="h6">{layer.title}</Typography>
                    <Checkbox
                        icon={<Tune />}
                        checkedIcon={<Tune />}
                        checked={showConfig}
                        onChange={(e, value) => setShowConfig(value)}
                    />
                    <Checkbox
                        checked={layer.show}
                        onChange={(e, value) => dispatch(updateRiskMapRenderLayer({ id: id, changes: { show: value } }))}
                    />
                </Box>
                <Collapse in={showConfig}>
                    <Typography variant="subtitle1">{layer.description}</Typography>
                    <Box sx={{ padding: 2 }}>
                        <Typography>Opacidad</Typography>
                        <Slider
                            valueLabelDisplay="auto"
                            step={0.1}
                            value={layer.opacity}
                            marks
                            min={0}
                            max={1}
                            onChange={(e, value) => dispatch(updateRiskMapRenderLayer({ id: id, changes: { opacity: Number(value) } }))}
                        />
                        <Typography sx={{ marginTop: 2 }}>Formato</Typography>
                        <ToggleButtonGroup
                            color="primary"
                            value={layer.selectedTileFormat}
                            exclusive
                            onChange={(e, value) => dispatch(updateRiskMapRenderLayer({ id: id, changes: { selectedTileFormat: Number(value) } }))}
                            aria-label="Platform"
                        >
                            {layer.formats.tiles.map((format, index) => <ToggleButton value={index} key={index}>{format}</ToggleButton>)}
                        </ToggleButtonGroup>
                        <Typography sx={{ marginTop: 2 }}>Estilo</Typography>
                        <ToggleButtonGroup
                            color="primary"
                            value={layer.selectedStyle}
                            exclusive
                            onChange={(e, value) => dispatch(updateRiskMapRenderLayer({ id: id, changes: { selectedStyle: Number(value) } }))}
                            sx={{flexWrap: 'wrap'}}
                        >
                            {layer.styles.map((style, index) => <ToggleButton value={index} key={index}>{style}</ToggleButton>)}
                        </ToggleButtonGroup>
                    </Box>
                </Collapse>
            </Box>
            <Divider variant="middle" />
        </>
    )
}

const RiskRenderLayersTab = () => {
    const renderLayersIds = useSelector(selectRiskRenderLayerIds)

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', overflow: 'auto' }}>
            {renderLayersIds.map(id => <RiskRenderLayersRow id={id} key={id} />)}
        </Box>
    )
}

export default RiskRenderLayersTab
