import { Box, Container, Divider, Stack } from "@mui/material"
import { useCallback, useRef, useState } from "react"
import { useGetPipelineInstanceQuery } from "../../api/processApi"
import { useMaskMapDisplay } from "../../hooks/useMask"
import DraggableLayout from "../../../common/components/layout/DraggableLayout"
import { GeomDisplay, RasterMapDisplay, RasterOpacityDisplay } from "./DataDisplay"
import { PipelineModel } from "../../types/DataTypes"
import { BreadcrumbsRender, ExecutionTitleRender, ResultsMap } from "../../views/ResultsViewer"
import WktLayer from "../../../common/utils/WktLayer"


export function WildfirePredictionViewer({ pipelineInstanceId, model }: { pipelineInstanceId: string, model: PipelineModel }) {
    const { data: pipelineInstance } = useGetPipelineInstanceQuery(pipelineInstanceId)

    const map = useRef<L.Map>(null)
    const updateMapSize = useCallback(() => {
        map.current && map.current.invalidateSize(true)
    }, [map])

    const { mask, showMask, setShowMask } = useMaskMapDisplay(pipelineInstance?.input_data?.mask, map)

    const [opacity, setOpacity] = useState(0.5);
    const [showRasters, setShowRasters] = useState(true);

    return <DraggableLayout
        topLeft={
            <Box sx={{ height: 1, width: 1, display: 'flex', flexDirection: "column" }}>
                <Stack direction={'column'} sx={{ paddingX: 5, paddingY: 2 }} spacing={3}>
                    <BreadcrumbsRender model={model} instanceId={pipelineInstanceId} />
                </Stack>
                <Container>
                    <Stack paddingY={2} divider={<Divider variant="middle" flexItem />} spacing={1}>
                        <ExecutionTitleRender pipelineInstance={pipelineInstance} />
                        <Stack direction="row" spacing={1} alignItems="center">
                            <GeomDisplay showMask={showMask} setShowMask={setShowMask} />
                        </Stack>
                        <Stack direction="row" spacing={1} alignItems="center">
                            <RasterOpacityDisplay title={'Segmentaciones'} showRaster={showRasters} setShowRaster={setShowRasters} opacity={opacity} setOpacity={setOpacity} />
                        </Stack>
                    </Stack>
                </Container>
            </Box>
        }
        downRight={
            <ResultsMap map={map} >
                {showMask && mask && <WktLayer wktData={mask} pathOptions={{ color: 'red', fillOpacity: 0 }} />}
                {showRasters && pipelineInstance?.output_data?.wildfires?.id
                    && <RasterMapDisplay rasterId={pipelineInstance.output_data.wildfires.id} opacity={opacity}/>}
            </ResultsMap>
        }
        onDragEnd={updateMapSize}
        onChangeOrientation={updateMapSize}
    />
}
