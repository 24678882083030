import { Box } from '@mui/material';
import RovisenMapControl from '../control/RovisenMapControl';

export const ControlGroup = ({ children }: { children: any }) => {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 3, backgroundColor: 'white', borderRadius: 2, boxShadow: 3 }}>
            {children}
        </Box>
    )
}

const BaseMapControls = ({ children }: { children: any }) => {

    return (
        <RovisenMapControl position='bottomright'>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 5, marginRight: 5 }}>
                {children}
            </Box>
        </RovisenMapControl>
    );
}


export default BaseMapControls;
