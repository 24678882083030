import { Box, Typography } from "@mui/material";
import Stack from '@mui/material/Stack';
import { CloudSlider, DateSelect, TileSelect, getDate } from "./filterTab/filterTabFields";
import { useContext, useState } from "react";
import { LoadingButton } from "@mui/lab";
import { ErrorAlertDialog } from "../../common/components/UI/general/AlertDialog";
import { searchApi } from "../api/gisApi";
import { FilterContext, ResultsRowType, TileType } from "../views/DashBoard";
import daysjs from "dayjs";



function FilterTab() {
    const defaultValues = {
        init_date: getDate(true),
        end_date: getDate(),
        cloud: 30,
    };

    const [formValues, setFormValues] = useState(defaultValues);
    const [loading, setLoading] = useState(false);
    const [errorAlertOpen, setErrorAlertOpen] = useState(false);

    const { selectedTiles, setResults } = useContext(FilterContext);


    const handleFormChange = (e: any) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
    };

    const handleFormSubmit = (e: any) => {
        setLoading(true);
        const tiles = selectedTiles;
        const init_date = formValues.init_date.format("DD/MM/YYYY");
        const end_date = formValues.end_date.format("DD/MM/YYYY");
        const cloud = formValues.cloud;
        const results: ResultsRowType[][] = [];
        Promise.all(tiles.map((tile: TileType) =>
            searchApi.post(tile.name, init_date, end_date, cloud).then((data) => {
                data ? data.length > 0 && data[0].uuid && results.push(data) : setErrorAlertOpen(true);
            }
        ))).then(() => {
            setLoading(false);
            setResults(results);
        });
    };

    const datePickerProps = (label: string, name: string, value: daysjs.Dayjs) => {
        return {
            label: label,
            name: name,
            value: value,
            handleFormChange: handleFormChange,
        };
    }

    return (
        <Box>
            <Typography variant="h5" sx={{ margin: 2}}>Sentinel Images</Typography>
            <Stack spacing={2} sx={{margin: 2}}>
                <TileSelect/>
                <DateSelect {...datePickerProps("init date", "init_date", formValues.init_date)}/>
                <DateSelect {...datePickerProps("end date", "end_date", formValues.end_date)}/>
                <CloudSlider value={formValues.cloud} handleFormChange={handleFormChange}/>
                <LoadingButton loading={loading} onClick={(e) => handleFormSubmit(e)} variant="contained">Search</LoadingButton>
                <ErrorAlertDialog alertOpen={errorAlertOpen} setAlertOpen={setErrorAlertOpen} message={"Failed to get response"}/>
            </Stack>
        </Box>
    );
}


export default FilterTab;
