
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'


const endpoint = `${process.env.REACT_APP_API_URL}`;

export const publicApi = createApi({
    reducerPath: 'publicApi',
    baseQuery: fetchBaseQuery({
        baseUrl: endpoint,
    }),
    endpoints: (builder) => ({
        verifyUser: builder.query<{message: string, display_name: string}, { token: string }>({
            query: ({ token }) => `/auth/verify?token=${token}`,
        }),
        forgotPassword: builder.mutation<any, { email: string }>({
            query: ({ email }) => ({
                url: '/auth/forgot-password',
                method: 'POST',
                body: {
                    email: email,
                }
            })
        }),
        resetPassword: builder.mutation<any, { password: string, token: string }>({
            query: ({ password, token }) => ({
                url: '/auth/reset-password',
                method: 'POST',
                body: {
                    password: password,
                    token: token,
                }
            })
        }),
    }),
})

export const {
    useVerifyUserQuery,
    useForgotPasswordMutation,
    useResetPasswordMutation
} = publicApi;
