import { Circle, Marker, useMapEvents } from "react-leaflet"
import { useDispatch, useSelector } from "react-redux"
import { AppDispatch, RootState } from "../../../app/state/store"
import { addPlace, selectRiskLayersNames, selectSelectedPlace, setCurrentPosition } from "../../state/riskSlice"
import { riskMarkerIcon } from "../../utils/risksMapHelpers"
import { memo, useState } from "react"
import { useLazyGetAddressByLatLngQuery } from "../../../common/api/redux_api"
import { nanoid } from "@reduxjs/toolkit"
import { useLazyCalculateRiskQuery, useSaveRecentPlaceMutation } from "../../api/risksApi"

const SelectorMarker = () => {
    const currentPosition = useSelector((state: RootState) => state.risksMap.states.currentPosition)
    const layersNames = useSelector(selectRiskLayersNames)

    const [getAdressTrigger] = useLazyGetAddressByLatLngQuery()
    const [calcRiskTrigger] = useLazyCalculateRiskQuery()
    const [saveRecentTrigger] = useSaveRecentPlaceMutation()

    async function calculateRisk(coords: number[], placeId: string) {
        try {
            const [addressResult, riskResult] = await Promise.all([
                getAdressTrigger({ coords: coords, placeId: placeId }).unwrap(),
                calcRiskTrigger({ coords: coords, radius: 600, layers: layersNames, placeId: placeId }).unwrap()
            ])
            var layerScores: {[key:string]: number} = {}
            Object.keys(riskResult).forEach((layer) => {
                const score = riskResult[layer].reduce((prev: number, current: any) => prev < current.score ? current.score : prev, 0)
                layerScores[layer] = score
            })
            saveRecentTrigger({
                coords: coords,
                name: addressResult.features[0].properties.address_line1,
                address: addressResult.features[0].properties.address_line2,
                data: layerScores,
                placeId: placeId
            })
        }
        catch (error) {
            console.error(error)
        }
    }

    const dispatch = useDispatch<AppDispatch>()
    useMapEvents({
        mousemove(e) {
            dispatch(setCurrentPosition([e.latlng.lat, e.latlng.lng]))
        },
        click(e) {
            const coords = [e.latlng.lat, e.latlng.lng]
            const id = nanoid()
            dispatch(addPlace({ id: id, db_id: -1, name: "", address: "", coords: coords, saved: false, created: Date.now(), visited: Date.now(), layers: [] }))
            calculateRisk(coords, id)
        }
    })

    return <Marker position={currentPosition} icon={riskMarkerIcon} interactive={false} pane="risk_marker" />
}

export default SelectorMarker

export const SelectedMarker = memo(() => {
    const [showCircle, setShowCircle] = useState(false)

    const map = useMapEvents({
        moveend(e) {
            setShowCircle(true)
        }
    })
    const place = useSelector(selectSelectedPlace)


    map.flyTo({ lat: place.coords[0], lng: place.coords[1] }, 15)


    return (
        <>
            <Marker position={place.coords} icon={riskMarkerIcon} interactive={false} pane="risk_marker" />
            {showCircle && place.layers.length === 0 && <Circle center={place.coords} radius={500} pathOptions={{ fillOpacity: 0 }} />}
        </>
    )
})
