import { gisApi } from "../../common/api/redux_api";

export const mainMapApi = gisApi.injectEndpoints({
    endpoints: (builder) => ({
        uploadImageInfo: builder.mutation<any, any>({
            query: ({ name, object_key, bbox }) => ({
                url: `/gis/image_upload`,
                method: 'POST',
                body: {
                    'name': name,
                    'object_key': object_key,
                    'bbox': bbox
                }
            }),
            invalidatesTags: [{ type: 'Rasters', id: 'LIST' }]
        })
    }),
})

export const {
    useUploadImageInfoMutation
} = mainMapApi;
