import { DragIndicatorOutlined, Flip } from "@mui/icons-material"
import { Box, Icon, IconButton, Tooltip } from "@mui/material"
import { useEffect, useRef, useState } from "react"


interface DraggableLayoutProps {
    topLeft: any
    downRight: any
    disableChangeOrientation?: boolean
    onDrag?: any
    onDragEnd?: any
    onChangeOrientation?: any
    defaultOrientation?: "vertical" | "horizontal"
    defaultSize?: number
    separatorWidth?: number
}

const DraggableLayout = ({ topLeft, downRight, disableChangeOrientation = false, onDrag, onDragEnd, onChangeOrientation, defaultOrientation = "horizontal", defaultSize = 0.3, separatorWidth = 35 }: DraggableLayoutProps) => {
    const [drag, setDrag] = useState(false)
    const [percentageSize, setPercentageSize] = useState(Math.min(1, Math.max(0, defaultSize > 1 ? defaultSize/100 : defaultSize)))
    const [orientation, setOrientation] = useState<"vertical" | "horizontal">(defaultOrientation)
    const [mouseStart, setMouseStart] = useState<{ initPos: number, initSize: number }>({ initPos: 0, initSize: 0 })

    const windowsResize = (value: number) => {
        const currentWindowsSize = mouseStart.initSize + value - mouseStart.initPos
        mainBox.current && setPercentageSize(currentWindowsSize / (orientation === "horizontal" ? mainBox.current.offsetWidth : mainBox.current.offsetHeight))
        onDrag && onDrag()
    }

    const onMouseMove = (e: React.MouseEvent<HTMLDivElement | MouseEvent>) => {
        e.preventDefault()
        if (drag) {
            const value = orientation === "vertical" ? e.clientY : e.clientX
            windowsResize(value)
        }
    }

    const onTouchMove = (e: React.TouchEvent<HTMLDivElement | TouchEvent>) => {
        e.preventDefault()
        if (drag) {
            const value = orientation === "vertical" ? e.touches[0].clientY : e.touches[0].clientX
            windowsResize(value)
        }
    }

    const onDragLayoutEnd = () => {
        setDrag(false)
        onDragEnd && onDragEnd()
    }

    const mainBox = useRef<HTMLElement>(null)
    const leftTopBox = useRef<HTMLElement>(null)

    useEffect(() => {
        onChangeOrientation && onChangeOrientation()
    }, [orientation, onChangeOrientation])

    return <Box
        sx={{
            display: 'flex',
            flexDirection: orientation === "vertical" ? 'column' : 'row',
            height: 1, width: 1,
            overflow: 'hidden'
        }}
        onMouseMove={onMouseMove}
        onTouchMove={onTouchMove}
        onMouseUp={onDragLayoutEnd}
        onTouchEnd={onDragLayoutEnd}
        ref={mainBox}
    >
        <Box
            sx={{
                width: orientation === "vertical" ? 1 : percentageSize,
                height: orientation === "horizontal" ? 1 : percentageSize,
                overflow: 'hidden'
            }}
            ref={leftTopBox}
        >
            {topLeft}
        </Box>
        <Box
            onMouseDown={(e) => {
                setDrag(true)
                leftTopBox.current && setMouseStart({
                    initPos: orientation === "horizontal" ? e.clientX : e.clientY,
                    initSize: orientation === "horizontal" ? leftTopBox.current.offsetWidth : leftTopBox.current.offsetHeight
                })
            }}
            onTouchStart={(e) => {
                setDrag(true)
                leftTopBox.current && setMouseStart({
                    initPos: orientation === "horizontal" ? e.touches[0].clientX : e.touches[0].clientY,
                    initSize: orientation === "horizontal" ? leftTopBox.current.offsetWidth : leftTopBox.current.offsetHeight
                })
            }}
            sx={{
                display: 'flex',
                flexDirection: orientation === "vertical" ? 'row' : 'column',
                width: orientation === "vertical" ? 1 : `${separatorWidth}px`,
                height: orientation === "vertical" ? `${separatorWidth}px` : 1,
                backgroundColor: '#eeeeee',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: orientation === "vertical" ? "row-resize" : "col-resize"
            }}
        >
            {!disableChangeOrientation &&
                <Tooltip title="Cambiar orientación">
                    <IconButton
                        sx={{ flexShrink: 0 }}
                        size="medium"
                        onClick={() => setOrientation(orientation === 'vertical' ? 'horizontal' : 'vertical')}
                    >
                        <Flip fontSize="inherit" />
                    </IconButton>
                </Tooltip>
            }
            <Box sx={{
                flexGrow: 1,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
                <Icon
                    color="disabled"
                    sx={{
                        transform: orientation === "vertical" ? 'rotate(90deg)' : ""
                    }}
                >
                    <DragIndicatorOutlined fontSize="medium" />
                </Icon>
            </Box>
        </Box>
        <Box
            sx={{
                width: orientation === "vertical" ? 1 : `calc(${(1 - percentageSize) * 100}% - ${separatorWidth}px)`,
                height: orientation === "horizontal" ? 1 : `calc(${(1 - percentageSize) * 100}% - ${separatorWidth}px)`,
                overflow: 'hidden'
            }}
        >
            {downRight}
        </Box>
    </Box>
}

export default DraggableLayout