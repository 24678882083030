import { Avatar, Box, IconButton, ListItem, Paper, Tooltip, Typography } from "@mui/material";
import { useState } from "react";

import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { grey } from "@mui/material/colors";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDispatch, useSelector } from "react-redux";
import { deletePolygon, deselectPolygon, selectProjectInfo, selectState, setPolygonEditing, setPolygonVisibility, setSelectedPolygon, setSelectedType } from "../../state/rovLabelSlice";
import { AppDispatch } from "../../../app/state/store";
import { keyBinds } from "../../utils/rovLabelHelpers";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

function PolygonSelectMenu({polygon}: any) {
    const projectInfo = useSelector((state: any) => selectProjectInfo(state));
    const dispatch = useDispatch<AppDispatch>();

    const polygonType = projectInfo.types ?
        projectInfo.types.find((type: any) => type.id === polygon.typeId) :
        {name: 'unknown'};
    return (
        <Box sx={{ width: '100%', bgcolor: 'background.paper', marginBottom: 2}}>
            <Box component="div" id="nested-list-subheader" bgcolor={grey[200]}>
                Polygon
            </Box>
            <Paper elevation={6} sx={{marginTop: 1, border: 'solid', borderColor: polygon.typeColor}}>
                <Box sx={{flex: 'row', flexWrap: 'wrap'}}>
                    <Avatar sx={{ marginRight:1, width: 20, height: 20, fontSize: 10, bgcolor: polygon.typeColor}}>
                        {polygon.id}
                    </Avatar>
                    <Typography variant="body2" color="text.secondary">
                        polygon
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        class: {polygonType.name}
                    </Typography>
                    <Tooltip title={`deselect polygon (${keyBinds.deselectPolygon})`}>
                        <IconButton onClick={() => dispatch(deselectPolygon())}>
                            <CheckBoxIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={`edit polygon (${keyBinds.editPolygon})`}>
                        <IconButton onClick={() => dispatch(setPolygonEditing({polygonId: polygon.id, editing: !polygon.editing}))}>
                            <EditIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={`delete polygon (${keyBinds.deleteItem})`}>
                        <IconButton onClick={() => dispatch(deletePolygon({polygonId: polygon.id}))}>
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={`toggle visibility (${keyBinds.polygonVisible})`}>
                        <IconButton onClick={() => dispatch(setPolygonVisibility({polygonId: polygon.id, visible: !polygon.visible}))}>
                            {polygon.visible ? <Visibility fontSize="small"/> : <VisibilityOff fontSize="small"/>}
                        </IconButton>
                    </Tooltip>
                </Box>
            </Paper>
        </Box>
    );
}

function PolygonListItem({polygon, selectedPolygonId}: any) {
    const projectInfo = useSelector((state: any) => selectProjectInfo(state));
    const dispatch = useDispatch<AppDispatch>();

    const [open, setOpen] = useState(false);
    const polygonType = projectInfo.types ?
        projectInfo.types.find((type: any) => type.id === polygon.typeId) :
        {name: 'unknown'};

    function handleOpen() {
        setOpen(!open);
    }

    return (
        <Paper elevation={6} sx={{
            marginTop: 1,
            border: 'solid',
            borderColor: polygon.typeColor,
            backgroundColor: selectedPolygonId === polygon.id ? '#D7DBDD': 'white',
        }}>
            <ListItem sx={{flex: 'row', flexWrap: 'wrap'}}>
                <IconButton onClick={() =>
                {
                    if (polygon.id === selectedPolygonId){
                        dispatch(deselectPolygon());
                    } else {
                        dispatch(setSelectedPolygon({ polygonId: polygon.id }));
                        dispatch(setSelectedType({typeId: polygon.typeId}));
                        dispatch(setPolygonEditing({polygonId: polygon.id, editing: true}));
                    }
                }
                }>
                    {polygon.id === selectedPolygonId ?
                        <CheckBoxIcon fontSize="small"/> :
                        <CheckBoxOutlineBlankIcon fontSize="small"/>}
                </IconButton>
                <Avatar sx={{ marginRight:1, width: 20, height: 20, fontSize: 10, bgcolor: polygon.typeColor}}>
                    {polygon.id}
                </Avatar>
                <ListItemText primary="polygon"/>
                <IconButton
                    onClick={() => dispatch(deletePolygon({polygonId: polygon.id}))}
                >
                    <DeleteIcon fontSize="small"/>
                </IconButton>
                <IconButton onClick={handleOpen}>
                    {open ? <ExpandLess fontSize="small"/> : <ExpandMore fontSize="small"/>}
                </IconButton>
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <ListItem sx={{ pl: 4, flex: "row"}}>
                    <Box flexGrow={1}>
                        <Typography variant="body2" color="text.secondary">
                            class: {polygonType.name}
                        </Typography>
                    </Box>
                </ListItem>
            </Collapse>
        </Paper>
    );
}

function PolygonsTab() {
    const { polygons, selectedPolygonId } = useSelector((state: any) => selectState(state));

    return (
        <Box
            className={'upper box'}
            padding={2}
            flexGrow={1}
            sx={{ display: 'flex', flexDirection: 'column' }}
        >
            {Object.keys(polygons).length !== 0 && selectedPolygonId !== -1 &&
                <Box>
                    <PolygonSelectMenu polygon={polygons[selectedPolygonId]}/>
                </Box>
            }
            <List
                sx={{
                    bgcolor: 'background.paper',
                    flexGrow: 1,
                    overflow: 'auto',
                    height: 0,
                }}
                component="nav"
                subheader={
                    <Box
                        component="div"
                        id="nested-list-subheader"
                        bgcolor={grey[200]}
                        sx={{position: 'sticky', top: 0, zIndex: 1}}
                    >
                        Polygons
                    </Box>
                }
            >
                <Box flexGrow={1}>
                    {Object.entries(polygons).map(([id, polygon]: any) => (
                        <PolygonListItem key={polygon.id} polygon={polygon} selectedPolygonId={selectedPolygonId} />
                    ))}
                </Box>
            </List>
        </Box>
    );
}

export default PolygonsTab;
