import { combineSlices, createEntityAdapter, createSelector, createSlice, nanoid, PayloadAction } from "@reduxjs/toolkit";
import { analysisApi } from "../api/analysisApi";
import { RootState } from "../../app/state/store";
import { gisApi } from "../../common/api/redux_api";
import { randomColor } from "../utils/color";
import { parse } from "wellknown";

interface Operations {
    id: string
    type: string
    geomSelectorIds: string[]
    executed: boolean
    color: string
    visible: boolean
}

const operationsAdapter = createEntityAdapter<Operations>();

const operationsSlice = createSlice({
    name: "operations",
    initialState: operationsAdapter.getInitialState(),
    reducers: {
        addOperation: operationsAdapter.addOne,
        removeOperation: operationsAdapter.removeOne,
        updateOperation: operationsAdapter.updateOne,
    },
});

export const {
    addOperation,
    removeOperation,
    updateOperation,
} = operationsSlice.actions;

export const {
    selectById: selectOperationById,
    selectEntities: selectOperationEntities,
    selectIds: selectOperationIds,
    selectAll: selectAllOperations,
} = operationsAdapter.getSelectors((state: any) => state.analysis.operations);


export interface VectorRunData {
    id: string
    type: string
    status: string
    created_at: string
    data: any
    user_id: string
    organization_id: string
    process_name: string
    run_id: string
    visible: boolean
}

const vectorRunsDataAdapter = createEntityAdapter<VectorRunData>();

const vectorRunsDataSlice = createSlice({
    name: "vectorRunsData",
    initialState: vectorRunsDataAdapter.getInitialState(),
    reducers: {
        addVectorRunData: vectorRunsDataAdapter.addOne,
        removeVectorRunData: vectorRunsDataAdapter.removeOne,
        updateVectorRunData: vectorRunsDataAdapter.updateOne,
    },
    extraReducers: (builder) => {
        builder.addMatcher(analysisApi.endpoints.getVectorRunData.matchFulfilled, (state, action) => {
            vectorRunsDataAdapter.setAll(state, action.payload.map(vectorResult => ({ ...vectorResult, visible: false })))
        });
    }
});

export const {
    addVectorRunData,
    removeVectorRunData,
    updateVectorRunData,
} = vectorRunsDataSlice.actions;

export const {
    selectById: selectVectorRunDataById,
    selectEntities: selectVectorRunDataEntities,
    selectIds: selectVectorRunsDataIds,
    selectAll: selectAllVectorRunsData,
} = vectorRunsDataAdapter.getSelectors((state: any) => state.analysis.vectorRunsData);

export interface DrawnGeom {
    id: string
    geom: string
    color: string
    selected: boolean
    editing: boolean
    type: string
    hover: boolean
    editable: boolean
    originType: string
    originEntity: string
    originId: number
    operationId: string
    data?: any
    visible: boolean
    info: boolean
}

const drawnGeomsAdapter = createEntityAdapter<DrawnGeom>();

const drawnGeomsSlice = createSlice({
    name: "drawnGeoms",
    initialState: drawnGeomsAdapter.getInitialState({
        selectedId: '',
        selectorActive: false,
    }),
    reducers: {
        addDrawnGeom: (state, action: PayloadAction<Pick<DrawnGeom, 'geom' | 'type'> & Partial<Omit<DrawnGeom, 'type' | 'geom'>>>) => {
            const {
                id = nanoid(),
                geom,
                color = randomColor(),
                selected = false,
                editing = false,
                type,
                hover = false,
                editable = true,
                originType = "drawn",
                originEntity = "",
                originId = -1,
                operationId = "",
                visible = true,
                info = false
            } = action.payload
            drawnGeomsAdapter.addOne(state, { id, geom, color, selected, editing, type, hover, editable, originType, originEntity, originId, operationId, visible, info })
        },
        removeDrawnGeom: drawnGeomsAdapter.removeOne,
        removeDrawnGeoms: drawnGeomsAdapter.removeMany,
        updateDrawnGeom: drawnGeomsAdapter.updateOne,
        upsertDrawnGeom: drawnGeomsAdapter.upsertOne,
        selectDrawnGeom: (state, action) => {
            if (state.selectorActive) {
                state.selectorActive = false
                return
            }
            if (state.ids.includes(state.selectedId)) {
                state.entities[state.selectedId].selected = false
                state.entities[state.selectedId].editing = false
                state.entities[state.selectedId].info = false
            }
            state.selectedId = action.payload;
            if (state.ids.includes(action.payload)) {
                state.entities[action.payload].selected = true
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(operationsSlice.actions.updateOperation, (state, action) => {
                if (action.payload.changes.color) {
                    for (let id of state.ids) {
                        if (state.entities[id].operationId === action.payload.id) {
                            state.entities[id].color = action.payload.changes.color
                        }
                    }
                }
                if (action.payload.changes.visible !== undefined) {
                    for (let id of state.ids) {
                        if (state.entities[id].operationId === action.payload.id) {
                            state.entities[id].visible = action.payload.changes.visible
                        }
                    }
                }
            })
            .addCase(geomSelectorsSlice.actions.setActiveSelectorId, (state, action) => {
                state.selectorActive = action.payload !== ""
                if (state.ids.includes(state.selectedId)) {
                    state.entities[state.selectedId].selected = false
                    state.entities[state.selectedId].editing = false
                    state.entities[state.selectedId].info = false
                }
                state.selectedId = ""
            })
            .addCase(operationsSlice.actions.removeOperation, (state, action) => {
                for (let id of state.ids) {
                    if (state.entities[id].operationId === action.payload) {
                        state.entities[id].editable = true
                    }
                }
            })
            .addMatcher(analysisApi.endpoints.calculateAnalysis.matchFulfilled, (state, action) => {
                drawnGeomsAdapter.addMany(state, action.payload.geoms.map(geom => {
                    const geomJson = parse(geom.geom)
                    const geomType = geomJson ? geomJson.type : ""

                    return {
                        id: nanoid(),
                        geom: geom.geom,
                        color: "#ff0000",
                        selected: false,
                        editing: false,
                        type: geomType,
                        hover: false,
                        editable: false,
                        originType: action.payload.type,
                        originEntity: action.payload.entity,
                        originId: geom.id,
                        operationId: action.payload.operation_id,
                        visible: true,
                        info: false,
                    }
                }))
            })
            .addMatcher(analysisApi.endpoints.calculateBuffer.matchFulfilled, (state, action) => {
                const geomJson = parse(action.payload.geom)
                const geomType = geomJson ? geomJson.type : ""
                drawnGeomsAdapter.addOne(state, {
                    id: nanoid(),
                    geom: action.payload.geom,
                    color: "#ff0000",
                    selected: false,
                    editing: false,
                    type: geomType,
                    hover: false,
                    editable: false,
                    originType: "wkt",
                    originEntity: "",
                    originId: 0,
                    operationId: action.payload.operation_id,
                    visible: true,
                    info: false,
                })
            })
    }
});

export const {
    addDrawnGeom,
    removeDrawnGeom,
    removeDrawnGeoms,
    updateDrawnGeom,
    upsertDrawnGeom,
    selectDrawnGeom,
} = drawnGeomsSlice.actions;

export const {
    selectById: selectDrawnGeomById,
    selectEntities: selectDrawnGeomEntities,
    selectIds: selectDrawnGeomIds,
    selectAll: selectAllDrawnGeoms,
} = drawnGeomsAdapter.getSelectors((state: any) => state.analysis.drawnGeoms);

export const selectSelectedDrawnGeom = (state: RootState) => selectDrawnGeomById(state, state.analysis.drawnGeoms.selectedId);

interface GeomSelector {
    id: string
    type: "layer" | "vector" | "wkt" | ''
    elementId: string
    allowedTypes: Array<"layer" | "vector" | "wkt">
}

const geomSelectorsAdapter = createEntityAdapter<GeomSelector>();

const geomSelectorsSlice = createSlice({
    name: "geomSelectors",
    initialState: geomSelectorsAdapter.getInitialState({
        activeSelectorId: '',
    }),
    reducers: {
        addGeomSelector: (state, action: PayloadAction<Pick<GeomSelector, 'id'> & Partial<Omit<GeomSelector, 'id'>>>) => {
            const { id, type = '', elementId = '', allowedTypes = ["layer", "vector", "wkt"] } = action.payload
            geomSelectorsAdapter.addOne(state, { id, type, elementId, allowedTypes })
        },
        removeGeomSelector: geomSelectorsAdapter.removeOne,
        updateGeomSelector: geomSelectorsAdapter.updateOne,
        setActiveSelectorId: (state, action) => {
            state.activeSelectorId = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(drawnGeomsSlice.actions.removeDrawnGeom, (state, action) => {
                const ids = state.ids.filter(id => state.entities[id].elementId === action.payload)
                for (let id of ids) {
                    state.entities[id].elementId = ""
                }
            })
            .addCase(drawnGeomsSlice.actions.selectDrawnGeom, (state, action) => {
                if (state.activeSelectorId !== '') {
                    state.entities[state.activeSelectorId].elementId = action.payload
                    state.activeSelectorId = ''
                }
            })

    }
});

export const {
    addGeomSelector,
    removeGeomSelector,
    updateGeomSelector,
    setActiveSelectorId,
} = geomSelectorsSlice.actions;

export const {
    selectById: selectGeomSelectorById,
    selectEntities: selectGeomSelectorEntities,
    selectIds: selectGeomSelectorIds,
    selectAll: selectAllGeomSelectors,
} = geomSelectorsAdapter.getSelectors((state: any) => state.analysis.geomSelectors);

export const selectGeomSelectorIdsByOperationId = (state: any, operationId: string) => {
    const operation = selectOperationById(state, operationId);
    return operation?.geomSelectorIds;
}

export const selectActiveSelectorId = (state: RootState) => state.analysis.geomSelectors.activeSelectorId;

export interface AnalysisRenderLayers {
    id: string
    fullname: string
    title: string
    description: string
    bounds: any[]
    styles: any[]
    formats: { tiles: string[], info: string[] }
    matrix: string[]
    workspace: string
    type: string
    show: boolean
    opacity: number
    color: string
    shape: string
    size: number
}

const analysisRenderLayersAdapter = createEntityAdapter<AnalysisRenderLayers>()

const analysisRenderLayersSlice = createSlice({
    name: "analysisRenderLayers",
    initialState: analysisRenderLayersAdapter.getInitialState({}),
    reducers: {
        updateAnalysisRenderLayer: analysisRenderLayersAdapter.updateOne
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(gisApi.endpoints.getCapabilities.matchFulfilled, (state, { payload }) => {
                const analysisLayers = payload.filter(
                    (layer: any) => layer.workspace === "rov_geodata"
                )
                if (analysisLayers.length > 0) {
                    analysisRenderLayersAdapter.setAll(state, analysisLayers.map((layer: any) => ({
                        ...layer,
                        id: layer.fullname,
                        color: "#ff0000",
                        shape: 'circle',
                        size: layer.styles[0] === "parpoint" ? '6' : 1,
                        show: false,
                        opacity: 1,
                    })))
                }
            })
    }
})

export const {
    updateAnalysisRenderLayer
} = analysisRenderLayersSlice.actions

export const {
    selectAll: selectAllAnalysisRenderLayers,
    selectById: selectAnalysisRenderLayersById,
    selectEntities: selectAnalysisRenderLayerEntities,
    selectIds: selectAnalysisRenderLayerIds,
} = analysisRenderLayersAdapter.getSelectors((state: RootState) => state.analysis.analysisRenderLayers)

const analysisReducer = combineSlices(operationsSlice, vectorRunsDataSlice, geomSelectorsSlice, drawnGeomsSlice, analysisRenderLayersSlice);

export default analysisReducer;

const getData = ({ type, id, layers, geoms }: { type: string, id: string, layers: any, geoms: any }) => {
    if (id === "") return undefined
    switch (type) {
        case "layer":
            return layers[id] ? layers[id].type : ""
        case "wkt":
            return geoms[id].geom
        case "vector":
            return id
    }
}

export const selectOperationQuery = createSelector(
    [selectDrawnGeomEntities, selectAnalysisRenderLayerEntities, selectGeomSelectorEntities, selectOperationById],
    (geoms, layers, selectors, operation) => {
        if (operation.type !== "buffer") {
            const selector1 = selectors[operation.geomSelectorIds[0]]
            const selector2 = selectors[operation.geomSelectorIds[1]]

            return {
                "operation_id": operation.id,
                "operation": operation.type,
                "type1": selector1.type,
                "type2": selector2.type,
                "entity1": getData({ type: selector1.type, id: selector1.elementId, layers: layers, geoms: geoms }),
                "entity2": getData({ type: selector2.type, id: selector2.elementId, layers: layers, geoms: geoms })
            }
        }
        else {
            const selector1 = selectors[operation.geomSelectorIds[0]]
            return {
                "operation_id": operation.id,
                "wkt": selector1.elementId !== "" ? geoms[selector1.elementId].geom : "",
            }
        }
    }
)

export const selectOperationReady = createSelector(
    [selectGeomSelectorEntities, selectOperationById],
    (selectors, operation) => {
        if (operation.type !== "buffer") {
            const selector1 = selectors[operation.geomSelectorIds[0]]
            const selector2 = selectors[operation.geomSelectorIds[1]]
            return selector1.elementId !== '' && selector2.elementId !== ''
        }
        else {
            const selector1 = selectors[operation.geomSelectorIds[0]]
            return selector1.elementId !== ''
        }
    }
)

export const selectOperationGeoms = createSelector(
    [selectAllDrawnGeoms, (state, operationId) => operationId],
    (allGeoms, operationId) => allGeoms.filter(geom => geom.operationId === operationId)
)