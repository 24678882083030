import { LatLng, latLngBounds } from "leaflet";
import { useState } from "react";
import { Marker, Pane, Rectangle, useMapEvents } from "react-leaflet";
import { geodesicArea } from "../../../utils/tools";
import { svgIcon } from "../../../../rovLabel/utils/rovLabelHelpers";
import { LeafletEventHandlerFnMap } from "leaflet";
import { useDebouncyEffect } from "use-debouncy";

const markerIcon = svgIcon('red');

const Vertex = ({ position, setPosition, savePolygon }: { position: LatLng, setPosition: React.Dispatch<React.SetStateAction<LatLng | undefined>>, savePolygon?: any }) => {
    const eventHandlers: LeafletEventHandlerFnMap = {
        drag: (e: any) => {
            setPosition(e.latlng)
        },
        dragend: (e: any) => {
            savePolygon && savePolygon()
        },
    }
    return <Marker position={position} icon={markerIcon} draggable eventHandlers={eventHandlers} interactive pane="vertexs" />
}

const RectangleTool = ({ setMeasure, setPolygon }: { setMeasure?: React.Dispatch<React.SetStateAction<number>>, setPolygon?: any }) => {

    const [firstVertex, setFirstVertex] = useState<LatLng>()
    const [lastVertex, setLastVertex] = useState<LatLng>()
    const [completed, setCompleted] = useState(false);
    const [mousePosition, setMousePosition] = useState<LatLng>()

    const savePolygon = () => {
        if (firstVertex && lastVertex && setPolygon) {
            const bounds = latLngBounds([firstVertex, lastVertex])
            const vertexs = [bounds.getNorthEast(), bounds.getNorthWest(), bounds.getSouthWest(), bounds.getSouthEast()]
            setPolygon(vertexs)
        }
    }

    useMapEvents({
        mousemove(e) {
            !completed && setMousePosition(e.latlng)
        },
        click(e) {
            if (!firstVertex) setFirstVertex(e.latlng)
            else if (!lastVertex) {
                setLastVertex(e.latlng)
                setCompleted(true)
                const bounds = latLngBounds([firstVertex, e.latlng])
                const vertexs = [bounds.getNorthEast(), bounds.getNorthWest(), bounds.getSouthWest(), bounds.getSouthEast()]
                setPolygon(vertexs)
            }
        },
    })

    useDebouncyEffect(() => {
        const bounds = firstVertex && mousePosition && latLngBounds([firstVertex, lastVertex ? lastVertex : mousePosition])
        const vertexs = bounds && [bounds.getNorthEast(), bounds.getNorthWest(), bounds.getSouthWest(), bounds.getSouthEast()]
        vertexs && setMeasure && setMeasure(geodesicArea(vertexs))
    }, 50, [setMeasure, firstVertex, lastVertex, mousePosition])

    const finalBounds = firstVertex && (mousePosition || lastVertex) && (lastVertex ? latLngBounds(firstVertex, lastVertex) : (mousePosition ? latLngBounds(firstVertex, mousePosition) : undefined))

    return (
        <>
            <Pane name="polygon" style={{ zIndex: 500 }} />
            <Pane name="vertexs" style={{ zIndex: 2250 }} />
            <Pane name="vertexControl" style={{ zIndex: 2000 }} />
            {!firstVertex && mousePosition && <Marker position={mousePosition} icon={markerIcon} />}
            {firstVertex && !completed && <Marker position={firstVertex} icon={markerIcon} />}
            {mousePosition && !completed && <Marker position={mousePosition} icon={markerIcon} />}
            {finalBounds && <Rectangle bounds={finalBounds} pathOptions={{ color: completed ? 'blue' : 'red' }} />}
            {completed && firstVertex && <Vertex position={firstVertex} setPosition={setFirstVertex} savePolygon={savePolygon} />}
            {completed && lastVertex && <Vertex position={lastVertex} setPosition={setLastVertex} savePolygon={savePolygon} />}
        </>
    )
}

export default RectangleTool;