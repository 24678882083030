import { useCallback, useEffect, useState } from "react";
import { ImageOverlay, useMap } from "react-leaflet";
import { imageApi, labelsApi } from "../../api/rovLabelApi";
import { base64ToBlob, getBounds, keyBinds } from "../../utils/rovLabelHelpers";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../app/state/store";
import { resetTaskData, selectProjectInfo, selectState, selectTaskInfo, setMaxImageNumber, setTaskInfo } from "../../state/rovLabelSlice";
import { setTaskLoading, setAlertOpen, setAlertMessage, setAlertSeverity, setSaveButtonDisabled } from "../../state/rovLabelLoadingSlice";

function ImageSwitcher({imageNumber}: {imageNumber: number}) {
    const { selectedBatchIndex, selectedTaskIndex } = useSelector((state: RootState) => selectState(state));
    const projectInfo = useSelector((state: RootState) => selectProjectInfo(state));
    const taskInfo = useSelector((state: RootState) => selectTaskInfo(state));
    const dispatch = useDispatch<AppDispatch>();
    const [taskImageUrls, setTaskImageUrls] = useState<string[]>([]);

    const map = useMap();

    const taskId = projectInfo.batches &&
        projectInfo.batches[selectedBatchIndex] &&
        projectInfo.batches[selectedBatchIndex].tasks &&
        projectInfo.batches[selectedBatchIndex].tasks[selectedTaskIndex] &&
        projectInfo.batches[selectedBatchIndex].tasks[selectedTaskIndex].id;

    useEffect(() => {
        async function getImageUrl(image: any) {
            if (image.in_storage) {
                return image.url;
            }
            else {
                const response = await imageApi.post_str(image.url, taskInfo.bbox);
                if (!response) return;
                const base64String = base64ToBlob(response.body);
                return URL.createObjectURL(base64String);
            }
        }
        async function getAllImageUrls(images: any[]) {
            const urls: string[] = [];
            for (const image of images) {
                dispatch(setTaskLoading(true));
                const url = await getImageUrl(image);
                if (url) {
                    urls.push(url);
                }
                else {
                    dispatch(setAlertOpen(true));
                    dispatch(setAlertMessage('failed to get image url'));
                    dispatch(setAlertSeverity('error'));
                }
            }
            return urls;
        }
        setTaskImageUrls([]);
        taskInfo.images &&
            getAllImageUrls(taskInfo.images).then((response) => {
                setTaskImageUrls(response);
                dispatch(setTaskLoading(false));
            });
    }, [taskInfo.images, dispatch, taskInfo.bbox]);

    const taskBBox = taskInfo.bbox && getBounds(taskInfo.bbox);

    useEffect(() => {
        const getTaskData = async (taskId: number) => {
            dispatch(setSaveButtonDisabled(false));
            dispatch(setTaskLoading(true));
            await labelsApi.get(taskId).then((response) => {
                if (response) {
                    dispatch(resetTaskData());
                    dispatch(setTaskInfo({taskInfo: response}));
                    dispatch(setMaxImageNumber({maxImageNumber: response.images.length}));
                    const bounds = response.bbox && getBounds(response.bbox);
                    map.fitBounds(bounds);
                }
                else {
                    dispatch(setAlertOpen(true));
                    dispatch(setAlertMessage('failed to get task data'));
                    dispatch(setAlertSeverity('error'));
                }
                dispatch(setTaskLoading(false));
            });
        }
        taskId && getTaskData(taskId);
    }, [dispatch, taskId, map]);

    const keyDownHandler = useCallback((e: KeyboardEvent) => {
        e.preventDefault();
        // recenter
        if (e.key === keyBinds.recenter) {
            const bounds = taskInfo.bbox && getBounds(taskInfo.bbox);
            map.fitBounds(bounds);
        }
    }, [taskInfo.bbox, map]);

    useEffect(() => {
        document.addEventListener('keydown', keyDownHandler);

        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        }
    }, [keyDownHandler]);

    return (
        <>
            { taskImageUrls && taskImageUrls[imageNumber] && taskBBox &&
                <ImageOverlay
                    url={taskImageUrls[imageNumber]}
                    bounds={taskBBox}
                />
            }
        </>
    );
}

export default ImageSwitcher;
