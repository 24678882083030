import { useDispatch, useSelector } from "react-redux"
import { selectPlace, selectPlaceById, selectPlaceIsSelected, selectPlacesIds, selectSavedPlaces } from "../../state/riskSlice"
import { AppDispatch, RootState } from "../../../app/state/store"
import { Box, CircularProgress, Divider, IconButton, Typography } from "@mui/material"
import { memo } from "react"
import { Bookmark, CrisisAlert } from "@mui/icons-material"
import { useSaveAnalysisMutation } from "../../api/risksApi"

const LoadingIconButton = ({ isLoading, children, onClick }: { isLoading: boolean, children: JSX.Element, onClick: () => void }) => {
    return (
    <IconButton
        size="large"
        onClick={onClick}
    >
        {isLoading
            ? <CircularProgress size={24} />
            : children
        }
    </IconButton>
    )
}

const PlacesRow = memo(({ id }: { id: string }) => {
    const place = useSelector((state: RootState) => selectPlaceById(state, id))
    const selected = useSelector((state: RootState) => selectPlaceIsSelected(state, id))
    const dispatch = useDispatch<AppDispatch>()

    const [saveAnalysis, saveResults] = useSaveAnalysisMutation()

    return (
        <Box sx={{ padding: 1 }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <Box sx={{ flexGrow: 1 }}>
                    <Typography variant="h6">{place.name}</Typography>
                    <Typography variant="subtitle1">{place.address}</Typography>
                </Box>
                <LoadingIconButton
                    isLoading={saveResults.isLoading}
                    onClick={() => saveAnalysis({ analysisId: place.db_id, saved: !place.saved })}
                >
                    <Bookmark fontSize="inherit" color={place.saved ? "primary" : "action"} />
                </LoadingIconButton>

                <IconButton size="large" onClick={() => dispatch(selectPlace(id))}>
                    <CrisisAlert fontSize="inherit" color={selected ? 'primary' : 'action'} />
                </IconButton>
            </Box>
            <Divider variant="middle" />
        </Box>
    )
})

export const SavedPlacesTab = () => {
    const savedPlacesIds = useSelector(selectSavedPlaces)

    return (
        <Box>
            {savedPlacesIds.map((placeId, index) => <PlacesRow id={placeId} key={index} />)}
        </Box>
    )
}

export const RecentPlacesTab = () => {
    const recentPlaces = useSelector(selectPlacesIds)

    return (
        <Box>
            {recentPlaces.map((placeId, index) => <PlacesRow id={placeId} key={index} />)}
        </Box>
    )
}
