import { List, ListItem, ListItemProps, ListProps } from "@mui/material";
import { forwardRef } from "react";

export const MUIComponents = {
    List: forwardRef<HTMLDivElement>(({ style, children }: ListProps<"div">, listRef) => {
        return (
            <List style={{ padding: 0, ...style, margin: 0 }} component="div" ref={listRef}>
                {children}
            </List>
        );
    }),

    Item: ({ children, ...props }: ListItemProps<"div">) => {
        return (
            <ListItem component="div" {...props} style={{ margin: 0 }} disablePadding>
                {children}
            </ListItem>
        );
    },
};