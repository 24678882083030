import { useEffect, useMemo, useState } from "react"
import { useGetGeometryResultQuery } from "../api/processApi"
import { FeatureCollection, Polygon, LineString } from "geojson"
import { LatLng, latLngBounds } from "leaflet"
import { geodesicArea, geodesicDistance } from "../../common/utils/tools"

function useGeoJsonResponse(geometryId: string, rtkOptions?: {skip?: boolean}) {
    const { data: geojsonURL, isLoading } = useGetGeometryResultQuery(geometryId, {skip: rtkOptions?.skip})
    const [geojson, setGeojson] = useState<any | undefined>(undefined)

    useEffect(() => {
        if (!isLoading && geojsonURL) {
            fetch(geojsonURL).then(response => response.json())
            .then(json => setGeojson(json))
        }
    }, [isLoading, geojsonURL])

    const downloadGeoJson = () => {
        if (geojson) {
            const blob = new Blob([JSON.stringify(geojson)], {type: 'application/json'})
            const url = window.URL.createObjectURL(blob)
            const a = document.createElement('a')
            a.href = url
            a.download = 'result.geojson'
            a.click()
            window.URL.revokeObjectURL(url)
        }
    }

    return { geojson, downloadGeoJson }
}

export default useGeoJsonResponse;

// TODO: failed experiment
// export const useGeometry = (id: number) => {
//     const {geojson, downloadGeoJson} = useGeoJsonResponse(id, {skip: !id})
//     const {data: geometry } = useGetGeometryQuery(id, {skip: !id})

//     const geometries = useMemo(() => {
//         if (geometry){
//             if (geometry.type === 'Polygon'){
//                 if (geojson) {
//                     const polygonGeojson = geojson as FeatureCollection<Polygon>
//                     return polygonGeojson.features.map((feature) => {
//                         const vertexs = feature.geometry.coordinates[0].map((coord: number[]) => new LatLng(coord[1], coord[0]))
//                         return {
//                             ...feature,
//                             vertexs: vertexs,
//                             area: geodesicArea(vertexs),
//                             bbox: latLngBounds(vertexs)
//                         }
//                     }).filter(item => item.area > 0 && item.vertexs.length > 0).sort((a, b) => b.area - a.area)
//                 }
//             }
//             if (geometry.type === 'LineString'){
//                 if (geojson) {
//                     const lineGeojson = geojson as FeatureCollection<LineString>
//                     return lineGeojson.features.map((feature) => {
//                         const vertexs = feature.geometry.coordinates.map((coord: number[]) => new LatLng(coord[1], coord[0]))
//                         return {
//                             ...feature,
//                             vertexs: vertexs,
//                             distance: geodesicDistance(vertexs),
//                             bbox: latLngBounds(vertexs)
//                         }
//                     }).filter(item => item.vertexs.length > 0).sort((a, b) => b.distance - a.distance)
//                 }
//             }
//         }
//         return undefined
//     }, [geojson])

//     return {geometries, downloadGeoJson}
// }

export const usePolygons= (id: string) => {
    const {geojson, downloadGeoJson} = useGeoJsonResponse(id, {skip: !id})

    const geometries = useMemo(() => {
        if (geojson) {
            const polygonGeojson = geojson as FeatureCollection<Polygon>
            const polygons: any[] = []
            polygonGeojson.features.forEach((feature) => {
                if (feature.geometry.type !== 'Polygon') {
                    return
                }
                const vertexs = feature.geometry.coordinates[0].map((coord: number[]) => new LatLng(coord[1], coord[0]))
                polygons.push({
                    ...feature,
                    vertexs: vertexs,
                    area: geodesicArea(vertexs),
                    bbox: latLngBounds(vertexs)
                })
            })
            return polygons.filter(item => item.area > 0 && item.vertexs.length > 0).sort((a, b) => b.area - a.area)
        }
    }, [geojson])

    return {geometries, downloadGeoJson}
}

export const useLines = (id: string) => {
    const {geojson, downloadGeoJson} = useGeoJsonResponse(id, {skip: !id})

    const geometries = useMemo(() => {
        if (geojson) {
            const lineGeojson = geojson as FeatureCollection<LineString>
            const lines: any[] = []
            lineGeojson.features.forEach((feature) => {
                if (feature.geometry.type !== 'LineString') {
                    return
                }
                const vertexs = feature.geometry.coordinates.map((coord: number[]) => new LatLng(coord[1], coord[0]))
                lines.push({
                    ...feature,
                    vertexs: vertexs,
                    distance: geodesicDistance(vertexs),
                    bbox: latLngBounds(vertexs)
                })
            })
            return lines.filter(item => item.vertexs.length > 0).sort((a, b) => b.distance - a.distance)
        }
    }, [geojson])

    return {geometries, downloadGeoJson}
}
