import { useEffect, useState } from "react";
import { Polygon, Tooltip } from "react-leaflet";
import { defaultColor, flipCoordinates, hoverColor, noColor, selectedColor } from "../utils/polygonConfigs";
import { TileType } from "../views/DashBoard";
import "../styles/polygon.css";

interface SelectPolygonProps {
    tile: TileType;
    selectedTiles: TileType[];
    setSelectedTiles: (tiles: TileType[]) => void;
    colorTiles: boolean;
}

function SelectPolygon({tile, selectedTiles, setSelectedTiles, colorTiles}: SelectPolygonProps) {
    const [selected, setSelected] = useState(false);
    const [hover, setHover] = useState(false);

    useEffect(() => {
        selectedTiles.includes(tile) ? setSelected(true) : setSelected(false);
    }, [selectedTiles, tile]);

    const polygonEventHanlder = {
        click: (e: any) => {
            selected ? setSelectedTiles(selectedTiles.filter((checkTile: any) => checkTile.name !== tile.name)) :
                setSelectedTiles([...selectedTiles, tile]);
        },
        mouseover: (e: any) => {
            setHover(true);
        },
        mouseout: (e: any) => {
            setHover(false);
        }

    }

    return (
        <Polygon
            className="polygon"
            eventHandlers={polygonEventHanlder}
            pathOptions={hover ? hoverColor : selected ? selectedColor : colorTiles ? defaultColor : noColor}
            positions={flipCoordinates(JSON.parse(tile.geom).coordinates)}
        >
            <Tooltip sticky>{tile.name}</Tooltip>
        </Polygon>
    )
}

export default SelectPolygon;
