import InfoIcon from "@mui/icons-material/Info";
import { Box, Container, Popover, Typography } from "@mui/material";
import { useState } from "react";

interface InfoPopoverProps {
    children: any
    color?: "error" | "inherit" | "primary" | "secondary" | "success" | "action" | "disabled" | "info" | "warning"
}

const InfoPopover = ({ children, color = "disabled" }: InfoPopoverProps) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handlePopoverOpen = (event: any) => {
        setAnchorEl(event.currentTarget)
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    }

    const open = Boolean(anchorEl);
    return <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', boxSizing: 'border-box' }}>
        <InfoIcon
            fontSize="small"
            color={color}
            aria-owns={open ? 'mouse-over-popover' : undefined}
            aria-haspopup="true"
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
            shapeRendering="geometricPrecision"
        />
        <Popover
            id="mouse-over-popover"
            sx={{
                pointerEvents: 'none',
            }}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'center',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'center',
                horizontal: -10,
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
        >
            <Container maxWidth="sm" sx={{ paddingY: 1 }}>
                <Typography variant="subtitle2">
                    {children}
                </Typography>
            </Container>
        </Popover>
    </Box>
}

export default InfoPopover