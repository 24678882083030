import dayjs from "dayjs"

export const safeDayjs = (date?: string) => {
    if (date === undefined)
        return undefined
    if (date === "now")
        return dayjs()
    const dayjsDate = dayjs(date)
    if (dayjsDate.isValid())
        return dayjsDate
    return undefined
}