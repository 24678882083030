import { useDispatch, useSelector } from "react-redux"
import { AppDispatch, RootState } from "../../../app/state/store"
import { ProcessRaster, selectProcessRasterById, updateProcessRaster } from "../../state/processSlice"
import { Alert, Box, Button, List, ListItem, ListItemText, Snackbar, Typography } from "@mui/material"
import InfoPopover from "../common/InfoPopover"
import { useGetImagesQuery } from "../../api/processApi"
import { DataGrid, GridRenderCellParams, GridRowParams, GridRowSelectionModel, GridSlots } from "@mui/x-data-grid"
import { Raster } from "../../types/DataTypes"
import { useProcessCreationContext } from "./ProcessCreation"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useNavigate } from "react-router-dom"

const InfoRow = ({ params, config }: { params: GridRenderCellParams<Raster>, config: ProcessRaster }) => {
    const infoMessages = useMemo(() => {
        const messages = []
        if (params.row.channels === null || params.row.resolution === null || params.row.dtype === null) messages.push('La imagen aún está siendo procesada para obtener sus datos.')
        if (config.maxChannels && params.row.channels && params.row.channels > config.maxChannels) messages.push(`La imagen tiene ${params.row.channels} canales, el máximo permitido es ${config.maxChannels}.`)
        if (config.minChannels && params.row.channels && params.row.channels < config.minChannels) messages.push(`La imagen tiene ${params.row.channels} canales, el mínimo permitido es ${config.minChannels}.`)
        if (config.minRes && params.row.resolution && params.row.resolution < config.minRes) messages.push(`La imagen tiene una resolución de ${params.row.resolution} metros por pixel, el mínimo permitido es ${config.minRes}.`)
        if (config.maxRes && params.row.resolution && params.row.resolution > config.maxRes) messages.push(`La imagen tiene una resolución de ${params.row.resolution} metros por pixel, el máximo permitido es ${config.maxRes}.`)
        if (config.dtypes && params.row.dtype && !config.dtypes.includes(params.row.dtype)) messages.push(`La imagen tiene un tipo de dato ${params.row.dtype}, los tipos permitidos son ${config.dtypes}.`)
        return messages
    }, [config, params])
    return <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 1, width: 1 }}>
        <InfoPopover color={infoMessages.length !== 0 ? 'warning' : 'success'}>
            <List dense={true}>
                {infoMessages.length !== 0 ? infoMessages.map((message, index) => <ListItem key={index}>
                    <ListItemText primary={message} />
                </ListItem>)
                    : <Typography variant="body2">La imagen puede ser seleccionada.</Typography>
                }
            </List>
        </InfoPopover>
    </Box>
}

const SendToImageLibrary = () => {
    const navigate = useNavigate()
    return <Box sx={{ display: 'flex', flexDirection: 'column', height: 1, justifyContent: 'center', alignItems: 'center' }}>
        <Typography sx={{ padding: 1 }}>No existen imágenes en su biblioteca.</Typography>
        <Button
            variant="contained"
            onClick={() => navigate("/imagesLibrary")}
        >
            Subir Imagen
        </Button>
    </Box>
}

const RasterProcessInput = ({ id }: { id: string }) => {
    const processRaster = useSelector((state: RootState) => selectProcessRasterById(state, id))
    const { data: images, isSuccess } = useGetImagesQuery({ origin: 'library' })
    const dispatch = useDispatch<AppDispatch>()
    const { setPolygons } = useProcessCreationContext()
    const [openAlert, setOpenAlert] = useState(false)

    const handleRowOver = useCallback((e: any) => {
        const rowId = e.currentTarget.dataset.id
        const row = images?.find((el: Raster) => el.id === rowId)
        row && setPolygons((prevPolygons) => ({ ...prevPolygons, [`${id}_hover`]: { wkt: row.preview_bbox, color: '#0000ff', opacity: 0.2 } }))
    }, [setPolygons, id, images])

    const handleRowOut = useCallback(() => {
        setPolygons((prevPolygons) => {
            const { [`${id}_hover`]: _, ...rest } = prevPolygons
            return rest
        })
    }, [setPolygons, id])

    const isRasterSelectable = useCallback((params: GridRowParams<Raster>) => {
        if (params.row.channels === undefined || (processRaster.maxChannels && params.row.channels > processRaster.maxChannels)) return false
        if (processRaster.minChannels && params.row.channels < processRaster.minChannels) return false
        if (params.row.resolution === undefined || (processRaster.minRes && params.row.resolution < processRaster.minRes)) return false
        if (processRaster.maxRes && params.row.resolution > processRaster.maxRes) return false
        if (params.row.dtype === undefined || (processRaster.dtypes && !processRaster.dtypes.includes(params.row.dtype))) return false
        return true
    }, [processRaster])

    useEffect(() => {
        if (isSuccess && processRaster.rasterId) {
            if (processRaster.preview === undefined) {
                const selectedImage = images.find((row: any) => row.id === processRaster.rasterId)
                selectedImage && dispatch(updateProcessRaster({ id: id, changes: { preview: selectedImage.preview } }))
                selectedImage && setPolygons((prevPolygons) => ({
                    ...prevPolygons,
                    [`${id}_selected`]: { wkt: selectedImage.preview_bbox, color: '#FF0000', opacity: 0 }
                }))
            }
        }
    }, [images, isSuccess, processRaster, dispatch, id, setPolygons])

    const onRowSelection = useCallback((rowSelectionModel: GridRowSelectionModel) => {
        if (isSuccess) {
            const selectedImages = images.filter((row: any) => rowSelectionModel.includes(row.id))
            if (selectedImages.length === 0) {
                dispatch(updateProcessRaster({ id: id, changes: { bbox: '', dtype: '', resolution: 0, channels: 0, key: '', bucket: '', preview: '', rasterId: '' } }))
                // Delete the element '${id}_selected' from the polygons object
                setPolygons((prevPolygons) => {
                    const { [`${id}_selected`]: _, ...rest } = prevPolygons
                    return rest
                })
                return
            }
            setOpenAlert(true)
            const selectedImage: Raster = selectedImages[0]
            dispatch(updateProcessRaster({ id: id, changes: { bbox: selectedImage.preview_bbox, dtype: selectedImage.dtype, resolution: selectedImage.resolution, channels: selectedImage.channels, key: selectedImage.object_key, bucket: selectedImage.bucket, preview: selectedImage.preview, rasterId: selectedImage.id } }))
            // Add the element '${id}_selected' to the polygons object
            setPolygons((prevPolygons) => ({
                ...prevPolygons,
                [`${id}_selected`]: { wkt: selectedImage.preview_bbox, color: '#FF0000', opacity: 0 }
            }))
        }
    }, [dispatch, id, images, setPolygons, isSuccess])


    return <Box sx={{ width: 1, height: 300, overflow: 'hidden' }}>
        {isSuccess &&
            <DataGrid
                rows={images}
                columns={[
                    { field: 'id', headerName: 'ID', width: 70 },
                    { field: 'name', headerName: 'Nombre', width: 200 },
                    { field: 'resolution', headerName: 'Resolución', width: 100 },
                    { field: 'channels', headerName: 'Canales', width: 80 },
                    { field: 'dtype', headerName: 'Tipo de dato', width: 100 },
                    { field: 'info', headerName: 'Información', renderCell: (params) => <InfoRow params={params} config={processRaster} /> }
                ]}
                checkboxSelection
                disableMultipleRowSelection
                onRowSelectionModelChange={onRowSelection}
                isRowSelectable={isRasterSelectable}
                rowSelectionModel={processRaster.rasterId ? [processRaster.rasterId] : []}
                slotProps={{
                    row: {
                        onMouseEnter: handleRowOver,
                        onMouseLeave: handleRowOut,
                    }
                }}
                slots={{
                    noRowsOverlay: SendToImageLibrary as GridSlots["noRowsOverlay"],
                }}
            />
        }
        <Snackbar
            open={openAlert}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            autoHideDuration={5000}
            onClose={() => setOpenAlert(false)}
        >
            <Alert
                onClose={() => setOpenAlert(false)}
                severity="info"
                variant="filled"
                sx={{ width: '100%' }}
            >
                En el mapa se muestra una previsualizacion de la imagen seleccionada, pero el modelo utiliza la resolución original.
            </Alert>
        </Snackbar>
    </Box>

}

export default RasterProcessInput