import { gisApi } from "../../common/api/redux_api";
import { Credits, User } from "../types/UserTypes";


async function registerApi (username: string, displayName: string, email: string, password: string) {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_API_URL}/auth/register`,
            {
                method: 'POST',
                headers: {
                    'accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    "username": username,
                    "display_name": displayName,
                    "email": email,
                    "password": password
                })
            }
        );
        const data = await response.json();
        return data;
    } catch (error) {
        console.error(error);
    }
}

async function loginApi(email: string, password: string) {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_API_URL}/auth/token`,
            {
                method: 'POST',
                headers: {
                    'accept': 'application/json',
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: JSON.stringify(`grant_type=&username=${email}&password=${password}&scope=&client_id=&client_secret=`)

            }
        );
        const data = await response.json();
        return data;
    } catch (error) {
        console.error(error);
    }
}


export { registerApi, loginApi };


export const usersApi = gisApi.injectEndpoints({
    endpoints: (builder) => ({
        getUsers: builder.query<User[], void>({
            query: () => '/auth/users',
            keepUnusedDataFor: 3600,
        }),
        getUserById: builder.query<User, number>({
            query: (id) => `/auth/user/${id}`,
            keepUnusedDataFor: 3600,
        }),
        getCurrentUserInfo: builder.query<User, void>({
            query: () => '/auth/user',
            keepUnusedDataFor: 3600,
        }),
        getOrgCredits: builder.query<Credits, void>({
            query: () => '/auth/org/credits',
            keepUnusedDataFor: 3600,
            providesTags: ['Credits'],
        }),
    }),
})

export const {
    useGetUsersQuery,
    useGetCurrentUserInfoQuery,
    useGetOrgCreditsQuery,
} = usersApi
