import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import { Fragment, useContext, useEffect, useState } from 'react';
import ResultsTable from './results/ResultsTable';
import { ResultsContext } from '../views/DashBoard';

function Results() {
    const [openResults, setOpenResults] = useState(false);

    const { results } = useContext(ResultsContext);

    const toggleDrawer = (state: boolean) => {
        setOpenResults(state);
    };

    useEffect(() => {
        results.length > 0 && setOpenResults(true);
    }, [results]);

    const list = () => (
        <Box
            sx={{ 'auto' : 250 }}
            role="presentation"
        >
            <ResultsTable/>
        </Box>
    );

    return (
        <Box>
            <Fragment>
                <Button onClick={(e) => toggleDrawer(true)}>show results</Button>
                <Drawer
                    variant='persistent'
                    anchor={'bottom'}
                    open={openResults}
                >
                    <div>
                        <Button onClick={(e) => toggleDrawer(false)}>close results</Button>
                    </div>
                    {list()}
                </Drawer>
            </Fragment>
        </Box>
    );
}


export default Results;
