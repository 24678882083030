import History from '@mui/icons-material/History';
import { SideNavBar, SideNavItem } from '../../app/views/layout/SideNavBar';
import PersonIcon from '@mui/icons-material/Person';


const ProfileHome = () => {
    const basePath = "/profile";

    return (
        <SideNavBar initialTab='Perfil'>
            <SideNavItem name="Perfil" Icon={PersonIcon} path={basePath} />
            <SideNavItem name="Historial" Icon={History} path={basePath + "/history"} />
        </SideNavBar>
    );
};

export default ProfileHome;
