import L, { latLng, LatLng, LeafletEventHandlerFnMap, LeafletMouseEvent } from "leaflet";
import { svgIcon } from "../../../../rovLabel/utils/rovLabelHelpers";
import { CircleMarker, Marker, Pane, Polygon, Polyline, Tooltip, useMap, useMapEvents } from "react-leaflet";
import { ReactNode, useRef, useState } from "react";
import { geodesicArea } from "../../../utils/tools";
import { useDebouncyEffect } from "use-debouncy";

const markerIcon = svgIcon('red');
const newMarkerIcon = svgIcon('blue');

interface VertexProps {
    position: LatLng
    onChange: (newPosition: LatLng) => void
    onClick?: () => void
}

const EditableVertex = ({ position, onChange, onClick }: VertexProps) => {
    const [hover, setHover] = useState(false)
    const eventHandlers: LeafletEventHandlerFnMap = {
        drag: (e: any) => {
            onChange(e.latlng)
        },
        click: () => (onClick && onClick()),
        mouseover: () => setHover(true),
        mouseout: () => setHover(false)
    }
    return <>
        <Marker position={position} icon={markerIcon} draggable eventHandlers={eventHandlers} interactive pane="vertexs" />
        <CircleMarker center={position} radius={10} eventHandlers={eventHandlers} interactive pathOptions={{ stroke: false, fillOpacity: 0 }} pane="vertexControl" />
        {hover && <CircleMarker center={position} radius={10} pathOptions={{ color: 'red', fill: false }} pane="vertexs" />}
    </>
}

const Edge = ({ vertexId, pos1, pos2, setVertexs }: { vertexId: number, pos1: LatLng, pos2: LatLng, setVertexs: React.Dispatch<React.SetStateAction<LatLng[]>> }) => {
    const edge = useRef<L.Polyline>(null)
    const [newVertex, setNewVertex] = useState<LatLng>()
    const map = useMap()
    const eventHandlers = {
        click: (e: LeafletMouseEvent) => {
            newVertex && setVertexs((prev) => [...prev.slice(0, vertexId + 1), newVertex, ...prev.slice(vertexId + 1)])
        },
        mousemove: (e: LeafletMouseEvent) => {
            edge.current && setNewVertex(map.layerPointToLatLng(edge.current.closestLayerPoint(e.layerPoint)))
        },
        mouseout: () => {
            setNewVertex(undefined)
        }
    }
    return <>
        <Polyline positions={[pos1, pos2]} eventHandlers={eventHandlers} pathOptions={{ opacity: 0, weight: 20 }} pane="edgeControl" />
        <Polyline positions={[pos1, pos2]} pathOptions={{ dashArray: '5 5', color: 'yellow' }} pane="edges" ref={edge} />
        {newVertex && <Marker position={newVertex} icon={newMarkerIcon} interactive={false} pane="newVertex" />}
    </>
}

const PolygonTool = ({ setMeasure, setPolygon, initVertexs, barContent }: { setMeasure?: React.Dispatch<React.SetStateAction<number>>, setPolygon?: any, initVertexs?: LatLng[], barContent?: ReactNode }) => {
    const [completed, setCompleted] = useState(initVertexs !== undefined ? true : false);
    const [vertexs, setVertexs] = useState<LatLng[]>(initVertexs !== undefined ? initVertexs : [])
    const [vertexsBkp, setVertexsBkp] = useState<LatLng[]>()
    const [closing, setClosing] = useState(false)
    const [center, setCenter] = useState<LatLng | undefined>(initVertexs ? L.PolyUtil.polygonCenter(initVertexs, L.CRS.EPSG4326) : undefined)
    const closingDistance = 10
    const vertexEditable = completed && vertexs.length > 3

    const [mousePosition, setMousePosition] = useState<LatLng>()
    const map = useMapEvents({
        mousemove(e) {
            !completed && setMousePosition(e.latlng)
            !completed && vertexs.length > 2 && mousePosition && setClosing(map.latLngToLayerPoint(mousePosition).distanceTo(map.latLngToLayerPoint(vertexs[0])) < closingDistance)
        },
        click(e) {
            if (!completed) {
                if (!closing) {
                    setVertexs(prev => [...prev, e.latlng])
                }
                else {
                    setCompleted(true)
                    setClosing(false)
                    setPolygon && setPolygon(vertexs)
                    setCenter(L.PolyUtil.polygonCenter(vertexs, L.CRS.EPSG4326))
                }
            }
        },
    })

    const centerEvents: LeafletEventHandlerFnMap = {
        dragstart: (e) => {
            setVertexsBkp(vertexs)
        },
        drag: (e: any) => {
            const diff = latLng(e.latlng.lat - e.oldLatLng.lat, e.latlng.lng - e.oldLatLng.lng)
            vertexsBkp && setVertexs(vertexsBkp.map(vertex => latLng(vertex.lat + diff.lat, vertex.lng + diff.lng)))
        },
        dragend: () => setVertexsBkp(undefined)
    }

    useDebouncyEffect(() => {
        completed && setMeasure && setMeasure((geodesicArea(vertexs)))
        completed && setPolygon && setPolygon(vertexs)
    }, 50, [vertexs, completed, setMeasure])

    const polygonRef = useRef<L.Polygon>(null)

    return (
        <>
            <Pane name="polygon" style={{ zIndex: 500 }} />
            <Pane name="vertexs" style={{ zIndex: 2250 }} />
            <Pane name="vertexControl" style={{ zIndex: 2000 }} />
            <Pane name="newVertex" style={{ zIndex: 1250 }} />
            <Pane name="edges" style={{ zIndex: 1000 }} />
            <Pane name="edgeControl" style={{ zIndex: 1500 }} />
            {!completed && !closing && mousePosition && <Marker position={mousePosition} icon={markerIcon} />}
            {completed && vertexs.map((vertex, index) => (
                <EditableVertex
                    key={index}
                    position={vertex}
                    onChange={(pos) => {
                        setVertexs(prev => {
                            const newVertexs = [...prev.slice(0, index), pos, ...prev.slice(index + 1)]
                            setCenter(L.PolyUtil.polygonCenter(newVertexs, L.CRS.EPSG4326))

                            return newVertexs
                        })
                    }}
                    onClick={() => {
                        vertexEditable && setVertexs(prev => {
                            const newVertexs = [...prev.slice(0, index), ...prev.slice(index + 1)]
                            setCenter(L.PolyUtil.polygonCenter(newVertexs, L.CRS.EPSG4326))
                            return newVertexs
                        })
                    }}
                />
            ))}
            {!completed && vertexs.map((vertex, index) => <Marker key={index} position={vertex} icon={markerIcon} />)}
            {vertexs.length > 0 && !completed && mousePosition &&
                <Polyline positions={[vertexs[vertexs.length - 1], closing ? vertexs[0] : mousePosition]} pathOptions={{ dashArray: '5 5', color: 'yellow' }} />
            }
            {closing &&
                <CircleMarker center={vertexs[0]} radius={closingDistance} pathOptions={{ fill: false, color: 'red' }} />
            }
            {completed
                ? <Polygon positions={vertexs} pathOptions={{ stroke: false, color: 'yellow' }} interactive={false} pane="polygon" ref={polygonRef} />
                : <Polyline positions={vertexs} pathOptions={{ dashArray: '5 5', color: 'yellow' }} pane="polygon" />
            }
            {completed &&
                vertexs.map((vertex, index, array) => <Edge key={index} vertexId={index} pos1={vertex} pos2={array[(index + 1) % vertexs.length]} setVertexs={setVertexs} />)
            }
            {center && <Marker position={center} icon={markerIcon} draggable eventHandlers={centerEvents} pane="vertexs" />}
            {barContent && polygonRef.current && <CircleMarker center={latLng(polygonRef.current.getBounds().getNorth(), polygonRef.current.getBounds().getCenter().lng)} radius={10} interactive={false} pathOptions={{ opacity: 0, fillOpacity: 0 }}>
                <Tooltip permanent direction="top" interactive>
                    {barContent}
                </Tooltip>
            </CircleMarker>}
        </>
    )
}

export default PolygonTool;
