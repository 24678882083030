import { useSelector } from "react-redux"
import { selectRiskResultById, selectSelectedPlace } from "../../state/riskSlice"
import { memo } from "react"
import { RootState } from "../../../app/state/store"
import WktLayer from "../../../common/utils/WktLayer"

const LayersPolygons = memo(({ layerId }: { layerId: string }) => {

    const riskResult = useSelector((state: RootState) => selectRiskResultById(state, layerId))

    return <>
        {riskResult.intersections.map((intersection, index) => <WktLayer wktData={intersection} key={index} pathOptions={{ color: 'red', fillOpacity: riskResult.showIntersection ? 0.3 : 0, opacity: riskResult.showIntersection ? 1 : 0 }} pane="intersection_layer"/>)}
        {riskResult.geoms.map((geom, index) => <WktLayer wktData={geom} key={index} pathOptions={{ color: 'cyan', fillOpacity: riskResult.showGeom ? 0.3 : 0, opacity: riskResult.showGeom ? 1 : 0 }} pane="polygon_layer"/>)}
    </>
})

const AnalysisPolygons = () => {
    const selectedPlace = useSelector(selectSelectedPlace)

    return <>{selectedPlace && selectedPlace.layers.map((id, index) => <LayersPolygons layerId={selectedPlace.id + "###" + id} key={index} />)}</>
}

export default AnalysisPolygons
