
import { RefObject, useRef, useState } from "react"
import { Avatar, Box, CircularProgress, Container, IconButton, ListItem, ListItemAvatar, ListItemText, Tooltip, Typography } from "@mui/material"
import { CenterFocusStrong, Pentagon } from "@mui/icons-material"
import { Virtuoso, VirtuosoHandle } from "react-virtuoso"
import { MUIComponents } from "../../../common/utils/MuiComponents"
import { formatArea, formatDistance } from "../../../common/utils/tools"


export const GeometryList = ({ geometries, map, virtuoso }: {geometries: any | undefined, map: RefObject<L.Map>, virtuoso: RefObject<VirtuosoHandle>}) => {
    const panel = useRef<HTMLElement>(null)

    const [isScrolling, setIsScrolling] = useState(false)

    return <Box sx={{ flexGrow: 1, overflow: 'auto' }} ref={panel}>
        {geometries && panel.current
            ? <Container>
                <Typography padding={1} variant="h5">Listado de detecciones</Typography>
                <Virtuoso
                    useWindowScroll
                    customScrollParent={panel.current}
                    ref={virtuoso}
                    data={geometries}
                    isScrolling={setIsScrolling}
                    context={{ isScrolling }}
                    components={MUIComponents}
                    itemContent={(index, geometry, { isScrolling }) => {
                        const center = geometry.bbox?.getCenter()
                        if (!isScrolling) {
                            return (
                                <ListItem
                                    divider
                                    secondaryAction={
                                        <Tooltip title="Ir a la detección">
                                            <IconButton onClick={() => map.current?.flyTo(center, 17)}>
                                                <CenterFocusStrong />
                                            </IconButton>
                                        </Tooltip>
                                    }
                                >
                                    <ListItemAvatar>
                                        <Avatar>
                                            <Pentagon />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={
                                            geometry.area
                                                ? `Area: ${formatArea(geometry.area)}`
                                                : geometry.distance
                                                    ? `Distancia: ${formatDistance(geometry.distance)}`
                                                    : undefined
                                        }
                                        secondary={geometry.id ? `id: ${geometry.id}` : undefined}
                                    />
                                </ListItem>
                            )
                        }
                        else {
                            return (
                                <ListItem
                                    secondaryAction={
                                        <IconButton>
                                            <CenterFocusStrong />
                                        </IconButton>
                                    }
                                    divider
                                >
                                    <ListItemAvatar>
                                        <Avatar>
                                            <Pentagon />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={
                                            geometry.area
                                                ? `Area: ${formatArea(geometry.area)}`
                                                : geometry.distance
                                                    ? `Distancia: ${formatDistance(geometry.distance)}`
                                                    : undefined
                                        }
                                        secondary={geometry.id ? `id: ${geometry.id}` : undefined}
                                    />
                                </ListItem>
                            )
                        }
                    }}
                />
            </Container>
            : <Box sx={{ height: 1, width: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <CircularProgress />
            </Box>
        }
    </Box>
}
