import { CenterFocusWeak, ExpandLess, ExpandMore, LegendToggle, Tune, Visibility, VisibilityOff } from "@mui/icons-material"
import { Box, Checkbox, CircularProgress, Collapse, Divider, IconButton, Slider, ToggleButton, ToggleButtonGroup, Tooltip, Typography } from "@mui/material"
import { useGetCapabilitiesQuery } from "../../../common/api/redux_api"
import { useEffect, useState } from "react"
import RovisenLayer from "../../../common/components/map/layers/RovisenLayer"
import { useMap } from "react-leaflet"
import RovisenMapControl from "../../../common/components/map/control/RovisenMapControl"

const sections: any = {
    ine: { title: "Cartografía", subTitle: "Mapa de cambios y cartografía" },
    wildfires: { title: "Incendios", subTitle: "Predicción, detección e históricos de incendios" },
    risks: { title: "Riesgos", subTitle: "Distintos tipos de riesgos naturales" },
    basemap_data: { title: "Chile", subTitle: "Datos misceláneos sobre Chile" },
    ne: { title: "Mundo", subTitle: "Datos misceláneos sobre el mundo" },
    test: { title: "Mis capas", subTitle: "" },
}

const LegendControl = ({ workspaces }: { workspaces: any }) => {
    const legends = Object.values<any[]>(workspaces).filter(workspace => workspace.filter(layer => layer.showLegend).length > 0)
    return Object.keys(legends).length > 0 ?
        <RovisenMapControl position="topright">
            {Object.entries(legends).map(([title, workspace]) => {
                return <Box key={title} sx={{ backgroundColor: 'white', padding: 2, borderRadius: 2, maxHeight: "30vh", overflow: "auto", maxWidth: "40vw" }}>
                    <Typography variant="h4">{sections[title]}</Typography>
                    <Box>
                        {workspace.map((layer) => {
                            return layer.showLegend
                                ? <Box key={layer.fullname}>
                                    <Typography variant="h5">{layer.title}</Typography>
                                    <img src={`https://geo-api.rovisen.com:8443/geoserver/ows?service=WMS&request=GetLegendGraphic&format=image/png&width=20&height=20&layer=${layer.fullname}`} alt={`${layer.fullname}_legend`} />
                                </Box>
                                : null
                        })}
                    </Box>
                </Box>
            })}
        </RovisenMapControl>
        : null
}

const LayerRow = ({ layer, setData }: { layer: any, setData: any }) => {

    const [showConfig, setShowConfig] = useState(false)
    const map = useMap()

    return (
        <>
            <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, padding: 1 }}>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <Typography sx={{ flexGrow: 1 }} variant="h6">{layer.title}</Typography>
                    <Tooltip title="Configuraciones" placement="top">
                        <Checkbox
                            icon={<Tune />}
                            checkedIcon={<Tune />}
                            checked={showConfig}
                            onChange={(e, value) => setShowConfig(value)}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                    </Tooltip>
                    <Tooltip title={layer.show ? "Ocultar" : "Mostrar"} placement="top">
                        <Checkbox
                            checked={layer.show}
                            icon={<VisibilityOff />}
                            checkedIcon={<Visibility />}
                            onChange={(e, value) => setData((prev: any) => ({
                                ...prev,
                                [layer.workspace]: prev[layer.workspace].map((prevLayer: any) => {
                                    if (layer.fullname === prevLayer.fullname) {
                                        return { ...layer, show: value }
                                    }
                                    return prevLayer
                                })
                            }))}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                    </Tooltip>
                    <Tooltip title="Leyenda" placement="top">
                        <Checkbox
                            checked={layer.showLegend}
                            icon={<LegendToggle />}
                            checkedIcon={<LegendToggle />}
                            onChange={(e, value) => setData((prev: any) => ({
                                ...prev,
                                [layer.workspace]: prev[layer.workspace].map((prevLayer: any) => {
                                    if (layer.fullname === prevLayer.fullname) {
                                        return { ...layer, showLegend: value }
                                    }
                                    return prevLayer
                                })
                            }))}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                    </Tooltip>
                    <Tooltip title="Centrar" placement="top">
                        <IconButton size="medium" onClick={() => map.flyToBounds(layer.bounds)}>
                            <CenterFocusWeak fontSize="inherit" />
                        </IconButton>
                    </Tooltip>
                </Box>
                <Collapse in={showConfig}>
                    <Typography variant="subtitle1">{layer.description}</Typography>
                    <Box sx={{ padding: 2 }}>
                        <Typography>Opacidad</Typography>
                        <Slider
                            valueLabelDisplay="auto"
                            step={0.1}
                            value={layer.opacity}
                            marks
                            min={0}
                            max={1}
                            onChange={(e, value) => setData((prev: any) => ({
                                ...prev,
                                [layer.workspace]: prev[layer.workspace].map((prevLayer: any) => {
                                    if (layer.fullname === prevLayer.fullname) {
                                        return { ...layer, opacity: value }
                                    }
                                    return prevLayer
                                })
                            }))}
                        />
                        <Typography sx={{ marginTop: 2 }}>Formato</Typography>
                        <ToggleButtonGroup
                            color="primary"
                            value={layer.selectedTileFormat}
                            exclusive
                            onChange={(e, value) => setData((prev: any) => ({
                                ...prev,
                                [layer.workspace]: prev[layer.workspace].map((prevLayer: any) => {
                                    if (layer.fullname === prevLayer.fullname) {
                                        return { ...layer, selectedTileFormat: value }
                                    }
                                    return prevLayer
                                })
                            }))}
                        >
                            {layer.formats.tiles.map((format: string, index: number) => <ToggleButton value={index} key={index}>{format}</ToggleButton>)}
                        </ToggleButtonGroup>
                        <Typography sx={{ marginTop: 2 }}>Estilo</Typography>
                        <ToggleButtonGroup
                            color="primary"
                            sx={{ flexWrap: 'wrap' }}
                            value={layer.selectedStyle}
                            exclusive
                            onChange={(e, value) => setData((prev: any) => ({
                                ...prev,
                                [layer.workspace]: prev[layer.workspace].map((prevLayer: any) => {
                                    if (layer.fullname === prevLayer.fullname) {
                                        return { ...layer, selectedStyle: value }
                                    }
                                    return prevLayer
                                })
                            }))}
                        >
                            {layer.styles.map((style: string, index: number) => <ToggleButton value={index} key={index}>{style}</ToggleButton>)}
                        </ToggleButtonGroup>
                    </Box>
                </Collapse>
            </Box>
            <Divider variant="middle" />
        </>
    )
}

const LayersSection = ({ name, data, setData }: { name: string, data: any, setData: any }) => {
    const [showSection, setShowSection] = useState(false)

    return (
        <Box>
            <Box sx={{ display: "flex", flexDirection: "row", padding: 2, alignItems: "center" }}>
                <Box sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
                    <Typography variant="h4">{sections[name].title}</Typography>
                    <Typography variant="subtitle1">{sections[name].subTitle}</Typography>
                </Box>
                <IconButton size="medium" onClick={() => setShowSection(prev => !prev)}>
                    {showSection ? <ExpandLess fontSize="inherit" /> : <ExpandMore fontSize="inherit" />}
                </IconButton>
            </Box>
            <Collapse in={showSection}>
                {data[name].map((layer: any, index: number) => <LayerRow layer={layer} key={index} setData={setData} />)}
            </Collapse>
        </Box>
    )
}

const LayersTab = () => {
    //const { data: renderLayers, isLoading } = useGetCapabilitiesQuery({ workspace: Object.keys(sections), token: "" })
    const { data: renderLayers, isLoading } = useGetCapabilitiesQuery("all")

    const [workspacesData, setWorkspaces] = useState<any>({})

    useEffect(() => {
        renderLayers && setWorkspaces(renderLayers.reduce((prev, curr) => {
            if (curr.workspace in sections) {
                return {
                    ...prev,
                    [curr.workspace]: curr.workspace in prev ? [
                        ...prev[curr.workspace],
                        { ...curr, show: false, opacity: 1, selectedTileFormat: 0, selectedStyle: 0, showLegend: false }
                    ] : [{ ...curr, show: false, opacity: 1, selectedTileFormat: 0, selectedStyle: 0, showLegend: false }]

                }
            }
            else {
                return prev
            }
        }, {}))
    }, [renderLayers, setWorkspaces])

    return isLoading
        ? <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, justifyContent: 'center', alignItems: 'center' }}>
            <CircularProgress />
        </Box>
        : <Box sx={{ display: 'flex', flexDirection: 'column', overflow: 'auto' }}>
            <LegendControl workspaces={workspacesData} />
            {Object.keys(workspacesData).map((workspace, index) => workspace !== "rovisen" && workspace !== "basemap_tiles" && <LayersSection name={workspace} data={workspacesData} setData={setWorkspaces} key={index} />)}
            {Object.keys(workspacesData).map(workspace => workspacesData[workspace].filter((layer: any) => layer.show).map((layer: any) => (
                <RovisenLayer
                    layerStyle={layer.styles[layer.selectedStyle]}
                    name={layer.fullname}
                    opacity={layer.opacity}
                    key={layer.fullname}
                    bounds={layer.bounds}
                    pane="overlayPane"
                    maxZoom={25}
                />)
            ))}
        </Box>
}

export default LayersTab
