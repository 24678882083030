import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import DownloadIcon from '@mui/icons-material/Download';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { LoadingButton } from "@mui/lab";
import { Radio, Tooltip, styled } from '@mui/material';
import {downloadApi, requestApi} from "../../api/gisApi";
// @ts-ignore
import { wktToGeoJSON } from '@terraformer/wkt';
import { useContext, useState } from 'react';
import { FootprintType, ResultsContext, ResultsRowType } from '../../views/DashBoard';
import { useSelector } from 'react-redux';
import { RootState } from '../../../app/state/store';
import { ErrorAlertDialog } from '../../../common/components/UI/general/AlertDialog';

interface Column {
    id: 'title' | 'date' | 'tile' | 'cloud' | 'uuid';
    label: string;
    align?: 'right';
    format?: (value: number) => string;
}

const columns: Column[] = [
    { id: 'title', label: 'Title' },
    { id: 'date', label: 'Date'},
    {
        id: 'tile',
        label: 'Tile',
        align: 'right',
    },
    {
        id: 'cloud',
        label: 'Cloud %',
        align: 'right',
        format: (value: number) => value.toFixed(2),
    },
    {
        id: 'uuid',
        label: 'UUID',
        align: 'right',
    },
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.grey[300],
        color: theme.palette.common.black,
        fontSize: 12,
        padding: 5,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 12,
        padding: 5,
    },
}));

function ResultsRow({
        row,
        handleRowHover,
        handleRowHoverLeave,
        setErrorAlertOpen,
        setErrorMessage
    }: any) {
    const [status, setStatus] = useState(row.status);
    const [loading, setLoading] = useState(false);
    const token = useSelector((state: RootState) => state.auth.token);

    const handleDownload = async (uuid: string) => {
        setLoading(true);
        downloadApi.get(uuid, token).then((signedUrl) => {
            if (!signedUrl) {
                setErrorAlertOpen(true);
                setErrorMessage('Error downloading image');
            }
            else if (signedUrl.detail) {
                setErrorAlertOpen(true);
                setErrorMessage(signedUrl.detail);
            }
            else {
                window.location.href = signedUrl;
            }
            setLoading(false);
        })
    };

    const  handleRequest = (row: any) => {
        setLoading(true);
        requestApi.post(row.uuid, row.tile, row.date, row.cloud, row.footprintRaw, token).then((result) => {
            if (!result) {
                setErrorAlertOpen(true);
                setErrorMessage('Error requesting image');
            }
            else if (result.detail) {
                setErrorAlertOpen(true);
                setErrorMessage(result.detail);
            }
            else {
                setStatus('requested');
            }
            setLoading(false);
        })
    };

    return (

        <TableRow hover role="checkbox" tabIndex={-1} key={row.uuid} onMouseEnter={() => handleRowHover(row.footprint)} onMouseLeave={() => handleRowHoverLeave(row.footprint)}>
            <StyledTableCell>
                <LoadingButton loading={loading} disabled={status === 'requested'} onClick={() => {
                    status === 'requested' ? console.log('requested') :
                    status === 'on db' ? handleDownload(row.uuid) :
                    status === 'online' && handleRequest(row);
                }}>
                    {status === 'requested' ?
                        <DownloadIcon /> :
                    status === 'on db' ?
                        <Tooltip title='download'>
                            <DownloadIcon />
                        </Tooltip> :
                    status === 'online' &&
                        <Tooltip title='request'>
                            <CloudUploadIcon />
                        </Tooltip>}

                </LoadingButton>
            </StyledTableCell>
            {columns.map((column) => {
                const value: any = row[column.id as keyof typeof row];
                return (
                    <StyledTableCell key={column.id} align={column.align}>
                        {column.format && typeof value === 'number'
                        ? column.format(value)
                        : value}
                    </StyledTableCell>
                );
            })}
            <StyledTableCell key='status' align='right'>
                <Tooltip title={status}>
                    <Radio size='small' checked={true} color={status === 'requested' ? 'info' : status === 'on db' ? 'success' : status === 'online' ? 'warning' : 'error'} />
                </Tooltip>
            </StyledTableCell>
        </TableRow>
    )
}


function ResultsTable() {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [errorAlertOpen, setErrorAlertOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const { results, setFootprint } = useContext(ResultsContext);

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleRowHover = (footprint: FootprintType) => {
        setFootprint(footprint);
    };

    const handleRowHoverLeave = (footprint: FootprintType) => {
        setFootprint({type: "Polygon", coordinates: []});
    }

    const rows = results.map((tile:ResultsRowType[]) => (tile.map((info:ResultsRowType) => (
        {
            title: info.title,
            date: info.date,
            tile: info.tile,
            cloud: info.cloud,
            uuid: info.uuid,
            footprint: wktToGeoJSON(info.footprint.slice(info.footprint.indexOf(';')+1, -1)),
            footprintRaw: info.footprint,
            status: info.requested ? 'requested' : info.on_db ? 'on db' : info.online ? 'online' : 'unavailable',
        }
    )))).flat();


    return (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <ErrorAlertDialog alertOpen={errorAlertOpen} setAlertOpen={setErrorAlertOpen} message={errorMessage}/>
            <TableContainer sx={{ maxHeight: '25vh' }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell/>
                            {columns.map((column) => (
                                <StyledTableCell
                                    key={column.id}
                                    align={column.align}
                                >
                                    {column.label}
                                </StyledTableCell>
                            ))}
                            <StyledTableCell
                                key='status'
                                align='right'
                            >
                                Status
                            </StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row) => {
                            return (
                                <ResultsRow
                                    key={row.uuid}
                                    row={row}
                                    handleRowHover={handleRowHover}
                                    handleRowHoverLeave={handleRowHoverLeave}
                                    setErrorAlertOpen={setErrorAlertOpen}
                                    setErrorMessage={setErrorMessage}
                                />
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
    );
}

export default ResultsTable;
