import { useLocation } from "react-router-dom";
import { useResetPasswordMutation } from "../../app/api/publicApi";
import { AlertColor, Box, Grid, Link } from "@mui/material";
import { FormContainer, FormHeader, FormTextField } from "./Form";
import { LoadingButton } from "@mui/lab";
import { CustomAlertDialog } from "../../common/components/UI/general/AlertDialog";
import { useCallback, useMemo, useState } from "react";
import PasswordHelper, { passwordCheck } from "../components/PasswordHelper";

const PasswordReset = () => {
    const query = new URLSearchParams(useLocation().search);
    const token = query.get('token');
    const [resetPasswordTrigger, result] = useResetPasswordMutation();
    const { isLoading } = result;

    const [password, setPassword] = useState("")

    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [severity, setSeverity] = useState<AlertColor>('error');

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const password = data.get('password') as string;
        const confirm_password = data.get('confirm_password') as string;

        if (password !== confirm_password) {
            setAlertMessage("Las contraseñas no coinciden");
            setSeverity('error');
            setAlertOpen(true);
            return
        }

        if (passwordChecker(password)) {
            setAlertMessage("La contraseña no cumple con los requisitos");
            setSeverity('error');
            setAlertOpen(true);
            return
        }

        if (!token) {
            setAlertMessage("Error al enviar el correo");
            setSeverity('error');
            setAlertOpen(true);
            return
        }

        resetPasswordTrigger({ password, token})
            .unwrap()
            .then((response) => {
                setAlertMessage(response?.message);
                setSeverity('success');
                setAlertOpen(true);
            })
            .catch((error) => {
                setAlertMessage(error?.data?.detail || 'Error al enviar el correo');
                setSeverity('error');
                setAlertOpen(true);
            });
    };

    const passwordChecker = useCallback((password: string) => passwordCheck(password), [])

    const passwordHelper = useMemo(() => PasswordHelper({password}), [password])

    return <Box sx={{ height: 1, width: 1, overflow: 'auto' }}>
            <FormContainer>
                <FormHeader title="Restablecer Contraseña" />
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                    <FormTextField id="password" label="Nueva contraseña" name="password" type="password" autoComplete="new-password" checkValidity validityFunction={passwordChecker} setValue={(value) => setPassword(value)} />
                    <FormTextField id="confirm_password" label="Confirmar contraseña" name="confirm_password" type="password" autoComplete="new-password" checkValidity validityFunction={(value) => password !== value} helperText="La contraseña no coincide" />
                    {passwordHelper}
                    <LoadingButton
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        loading={isLoading}
                    >
                        restablecer contraseña
                    </LoadingButton>
                    <Grid container>
                        <Grid item xs />
                        <Grid item>
                            <Link href="/login" variant="body2">
                                {"¿Ya tienes cuenta? Ingresa aquí"}
                            </Link>
                        </Grid>
                    </Grid>
                </Box>
                <CustomAlertDialog alertOpen={alertOpen} setAlertOpen={setAlertOpen} message={alertMessage} severity={severity} />
            </FormContainer>
        </Box>
}

export default PasswordReset;
