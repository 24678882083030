import { useEffect } from 'react';
import L from 'leaflet';
import 'leaflet-compass';
import { useMap } from 'react-leaflet';

const CompassControl = () => {
    const map = useMap();

    useEffect(() => {
        const compass = new L.Control.Compass({ autoActive: false, showDigit: false, position: 'topright', callErr: null });
        map.addControl(compass);

        return () => {
            map.removeControl(compass);
        };
    }, [map]);

    return null;
};

export default CompassControl;
