import { combineSlices, createEntityAdapter, createSelector, createSlice } from "@reduxjs/toolkit"
import { RootState } from "../../app/state/store"
import { processApi } from "../api/processApi"
import { randomColor } from "../../analysis/utils/color"
import { ConfigBooleanInput, ConfigDateInput, ConfigDateRangeInput, ConfigGeometryInput, ConfigNumberInput, ConfigRasterInput, ConfigStringInput } from "../types/DataTypes"
import { safeDayjs } from "../utils/DateTools"


export interface ErrorInput {
    error?: string
    cause?: string
}

export interface ProcessGeometry extends ConfigGeometryInput, ErrorInput {
    id: string
    geom: string
    color: string
    visible: boolean
    editing: boolean
    editingTool: string
}

const geometriesAdapter = createEntityAdapter<ProcessGeometry>()

const geometriesSlice = createSlice({
    name: 'geometries',
    initialState: geometriesAdapter.getInitialState({
        editingGeometry: '',
    }),
    reducers: {
        updateProcessGeometry: geometriesAdapter.updateOne,
        editProcessGeometry: (state, action) => {
            if (state.ids.includes(state.editingGeometry)) {
                state.entities[state.editingGeometry].editing = false
            }
            state.editingGeometry = action.payload
            if (state.ids.includes(action.payload)) {
                state.entities[action.payload].editing = true
            }
        },
        setAllProcessGeometries: geometriesAdapter.setAll,
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(processApi.endpoints.getPipelineModel.matchFulfilled, (state, { payload }) => {
                geometriesAdapter.setAll(state, Object.keys(payload.input_format).filter(key => payload.input_format[key].type === "geom").map(key => {
                    const config: ConfigGeometryInput = payload.input_format[key]
                    return {
                        id: key,
                        geom: "",
                        color: randomColor(),
                        visible: true,
                        editing: false,
                        editingTool: "",
                        ...config,
                    }
                }))
            })
    }
})

export const {
    updateProcessGeometry,
    editProcessGeometry,
    setAllProcessGeometries
} = geometriesSlice.actions

export const {
    selectById: selectProcessGeometryById,
    selectEntities: selectProcessGeometriesEntities,
    selectIds: selectProcessGeoemtriesIds,
} = geometriesAdapter.getSelectors((state: RootState) => state.process.geometries)

interface ProcessDateRange extends ConfigDateRangeInput, ErrorInput {
    id: string
    init?: string
    end?: string
}

const dateRangesAdapter = createEntityAdapter<ProcessDateRange>()

const dateRangeSlice = createSlice({
    name: "dateRange",
    initialState: dateRangesAdapter.getInitialState(),
    reducers: {
        updateProcessDateRange: dateRangesAdapter.updateOne,
        setAllProcessDateRanges: dateRangesAdapter.setAll,
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(processApi.endpoints.getPipelineModel.matchFulfilled, (state, { payload }) => {
                dateRangesAdapter.setAll(state, Object.keys(payload.input_format).filter(key => payload.input_format[key].type === "date_range").map(key => {
                    const config: ConfigDateRangeInput = payload.input_format[key]
                    return {
                        id: key,
                        init: safeDayjs(config.initDate.default)?.toISOString(),
                        end: safeDayjs(config.endDate.default)?.toISOString(),
                        ...config,
                    }
                }))
            })
    }
})

export const {
    updateProcessDateRange,
    setAllProcessDateRanges
} = dateRangeSlice.actions

export const {
    selectById: selectDateRangeById,
    selectEntities: selectDateRangeEntities,
    selectIds: selectDateRangeIds,
} = dateRangesAdapter.getSelectors((state: RootState) => state.process.dateRange)

interface ProcessNumber extends ConfigNumberInput, ErrorInput {
    id: string,
    value?: number,
}

const numberAdapter = createEntityAdapter<ProcessNumber>()

const numbersSlice = createSlice({
    name: 'numbers',
    initialState: numberAdapter.getInitialState(),
    reducers: {
        updateProcessNumber: numberAdapter.updateOne,
        setAllProcessNumbers: numberAdapter.setAll,
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(processApi.endpoints.getPipelineModel.matchFulfilled, (state, { payload }) => {
                numberAdapter.setAll(state, Object.keys(payload.input_format).filter(key => payload.input_format[key].type === "number").map(key => {
                    const config: ConfigNumberInput = payload.input_format[key]
                    return {
                        id: key,
                        value: config.default,
                        ...config,
                    }
                }))
            })
    }
})

export const {
    updateProcessNumber,
    setAllProcessNumbers,
} = numbersSlice.actions

export const {
    selectById: selectProcessNumberById,
    selectEntities: selectProcessNumbersEntities,
    selectIds: selectProcessNumbersIds,
} = numberAdapter.getSelectors((state: RootState) => state.process.numbers)

interface ProcessBoolean extends ConfigBooleanInput, ErrorInput {
    id: string,
    value?: boolean,
}

const booleanAdapter = createEntityAdapter<ProcessBoolean>()

const booleansSlice = createSlice({
    name: 'booleans',
    initialState: booleanAdapter.getInitialState(),
    reducers: {
        updateProcessBoolean: booleanAdapter.updateOne,
        setAllProcessBooleans: booleanAdapter.setAll,
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(processApi.endpoints.getPipelineModel.matchFulfilled, (state, { payload }) => {
                booleanAdapter.setAll(state, Object.keys(payload.input_format).filter(key => payload.input_format[key].type === "boolean").map(key => {
                    const config: ConfigBooleanInput = payload.input_format[key]
                    return {
                        id: key,
                        value: config.default,
                        ...config,
                    }
                }))
            })
    }
})

export const {
    updateProcessBoolean,
    setAllProcessBooleans,
} = booleansSlice.actions

export const {
    selectById: selectProcessBooleanById,
    selectEntities: selectProcessBooleansEntities,
    selectIds: selectProcessBooleansIds,
} = booleanAdapter.getSelectors((state: RootState) => state.process.booleans)

interface ProcessString extends ConfigStringInput, ErrorInput {
    id: string,
    value?: string,
}

const stringAdapter = createEntityAdapter<ProcessString>()

const stringsSlice = createSlice({
    name: 'strings',
    initialState: stringAdapter.getInitialState(),
    reducers: {
        updateProcessString: stringAdapter.updateOne,
        setAllProcessStrings: stringAdapter.setAll,
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(processApi.endpoints.getPipelineModel.matchFulfilled, (state, { payload }) => {
                stringAdapter.setAll(state, Object.keys(payload.input_format).filter(key => payload.input_format[key].type === "string").map(key => {
                    const config: ConfigStringInput = payload.input_format[key]
                    return {
                        id: key,
                        value: config.default,
                        ...config,
                    }
                }))
            })
    }
})

export const {
    updateProcessString,
    setAllProcessStrings,
} = stringsSlice.actions

export const {
    selectById: selectProcessStringById,
    selectEntities: selectProcessStringsEntities,
    selectIds: selectProcessStringsIds,
} = stringAdapter.getSelectors((state: RootState) => state.process.strings)

interface ProcessDate extends ConfigDateInput, ErrorInput {
    id: string
    date?: string
}

const dateAdapter = createEntityAdapter<ProcessDate>()

const datesSlice = createSlice({
    name: 'dates',
    initialState: dateAdapter.getInitialState(),
    reducers: {
        updateProcessDate: dateAdapter.updateOne,
        setAllProcessDates: dateAdapter.setAll,
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(processApi.endpoints.getPipelineModel.matchFulfilled, (state, { payload }) => {
                dateAdapter.setAll(state, Object.keys(payload.input_format).filter(key => payload.input_format[key].type === "date").map(key => {
                    const config: ConfigDateInput = payload.input_format[key]
                    return {
                        id: key,
                        date: safeDayjs(config.default)?.toISOString(),
                        ...config,
                    }
                }))
            })
    }
})

export const {
    updateProcessDate,
    setAllProcessDates,
} = datesSlice.actions

export const {
    selectById: selectProcessDateById,
    selectEntities: selectProcessDatesEntities,
    selectIds: selectProcessDatesIds,
} = dateAdapter.getSelectors((state: RootState) => state.process.dates)

export interface ProcessRaster extends ConfigRasterInput, ErrorInput {
    id: string
    bbox: string
    preview?: string
    channels?: number
    resolution?: number
    dtype?: string
    key: string
    bucket: string
    rasterId: string
}

const rasterAdapter = createEntityAdapter<ProcessRaster>()

const rastersSlice = createSlice({
    name: 'rasters',
    initialState: rasterAdapter.getInitialState(),
    reducers: {
        updateProcessRaster: rasterAdapter.updateOne,
        setAllProcessRasters: rasterAdapter.setAll,
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(processApi.endpoints.getPipelineModel.matchFulfilled, (state, { payload }) => {
                rasterAdapter.setAll(state, Object.keys(payload.input_format).filter(key => payload.input_format[key].type === "raster").map(key => {
                    const config: ConfigRasterInput = payload.input_format[key]
                    return {
                        id: key,
                        bbox: '',
                        key: '',
                        bucket: '',
                        rasterId: '',
                        ...config,
                    }
                }))
            })
    }
})

export const {
    updateProcessRaster,
    setAllProcessRasters,
} = rastersSlice.actions

export const {
    selectById: selectProcessRasterById,
    selectEntities: selectProcessRastersEntities,
    selectIds: selectProcessRastersIds,
} = rasterAdapter.getSelectors((state: RootState) => state.process.rasters)

const processStatusSlice = createSlice({
    name: 'processStatus',
    initialState: {
        currentModelId: '',
    },
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(processApi.endpoints.getPipelineModel.matchFulfilled, (state, { payload }) => {
                state.currentModelId = payload.id
            })
        }
})


const processReducer = combineSlices(processStatusSlice, geometriesSlice, dateRangeSlice, numbersSlice, datesSlice, rastersSlice, stringsSlice, booleansSlice)
export default processReducer

const selectGeometryValues = createSelector(
    [selectProcessGeometriesEntities, selectProcessGeoemtriesIds],
    (entities, ids) => ids.reduce((result: any, key) => {
        result.values[key] = {
            data_type: "geom",
            geom_format: "wkt",
            geom_type: entities[key].geomType,
            epsg: 4326,
            payload: {
                payload_type: "value",
                value: entities[key].geom,
            }
        }
        if (!result.valid)
            return result
        if (!entities[key].required)
            return result
        result.valid = entities[key].geom !== ''
        return result
    }, { values: {}, valid: true })
)

const selectDateRangesValues = createSelector(
    [selectDateRangeEntities, selectDateRangeIds],
    (entities, ids) => ids.reduce((result: any, key) => {
        result.values[key] = {
            data_type: "date_range",
            payload: {
                payload_type: "value",
                value: {
                    init: entities[key].init,
                    end: entities[key].end
                }
            }
        }
        if (!result.valid)
            return result
        if (!entities[key].required)
            return result
        result.valid = entities[key].init !== undefined && entities[key].end !== undefined
        return result
    }, { values: {}, valid: true })
)

const selectDatesValues = createSelector(
    [selectProcessDatesEntities, selectProcessDatesIds],
    (entities, ids) => ids.reduce((result: any, key) => {
        result.values[key] = {
            data_type: "date",
            payload: {
                payload_type: "value",
                value: entities[key].date
            }
        }
        if (!result.valid)
            return result
        if (!entities[key].required)
            return result
        result.valid = entities[key].date !== undefined
        return result
    }, { values: {}, valid: true })
)

const selectNumberValues = createSelector(
    [selectProcessNumbersEntities, selectProcessNumbersIds],
    (entities, ids) => ids.reduce((result: any, key) => {
        result.values[key] = entities[key].value
        if (!result.valid)
            return result
        if (!entities[key].required)
            return result
        result.valid = entities[key].value !== undefined
        return result
    }, { values: {}, valid: true })
)

const selectBooleanValues = createSelector(
    [selectProcessBooleansEntities, selectProcessBooleansIds],
    (entities, ids) => ids.reduce((result: any, key) => {
        result.values[key] = entities[key].value
        if (!result.valid)
            return result
        if (!entities[key].required)
            return result
        result.valid = entities[key].value !== undefined
        return result
    }, { values: {}, valid: true })
)

const selectStringValues = createSelector(
    [selectProcessStringsEntities, selectProcessStringsIds],
    (entities, ids) => ids.reduce((result: any, key) => {
        result.values[key] = entities[key].value
        if (!result.valid)
            return result
        if (!entities[key].required)
            return result
        result.valid = entities[key].value !== undefined
        return result
    }, { values: {}, valid: true })
)

const selectRasterValues = createSelector(
    [selectProcessRastersEntities, selectProcessRastersIds],
    (entities, ids) => ids.reduce((result: any, key) => {
        result.values[key] = {
            data_type: "raster",
            catalog: "rovisen",
            n_bands: entities[key].channels,
            d_type: entities[key].dtype,
            resolution: entities[key].resolution,
            id: entities[key].rasterId,
            bbox: entities[key].bbox,
            payload: {
                payload_type: "file",
                location: {
                    source: "S3",
                    bucket: entities[key].bucket,
                    obj_key: entities[key].key,
                    region: "us-east-2"
                }
            }
        }
        if (!result.valid)
            return result
        if (!entities[key].required)
            return result
        result.valid = entities[key].key !== ''
        return result
    }, { values: {}, valid: true })
)

export const selectInputValues = createSelector(
    [selectDateRangesValues, selectGeometryValues, selectDatesValues, selectNumberValues, selectBooleanValues, selectRasterValues, selectStringValues],
    (dateRanges, geometries, dates, numbers, booleans, rasters, strings) => ({
        values: {
            ...dateRanges.values,
            ...geometries.values,
            ...dates.values,
            ...numbers.values,
            ...booleans.values,
            ...rasters.values,
            ...strings.values,
        },
        valid: dateRanges.valid && geometries.valid && dates.valid && numbers.valid && booleans.valid && rasters.valid && strings.valid
    })
)


