import { useState } from 'react';
import { Marker, Pane, Polyline, useMap } from 'react-leaflet'
import { PointExpression } from 'leaflet'
import { svgIcon } from '../../utils/rovLabelHelpers';
import { useDispatch } from 'react-redux';
import { insertPolygonVertex } from '../../state/rovLabelSlice';
import { AppDispatch } from '../../../app/state/store';


function EditPolyline({ polygonId, vertex1, vertex2, index, pathOptions }: any) {
    const dispatch = useDispatch<AppDispatch>();

    const [showMiddle, setShowMiddle] = useState(false);
    const [closestPoint, setClosestPoint] = useState<PointExpression>([0,0]);

    const map = useMap()


    return (
        <>
            <Polyline
                positions={[vertex1.coord, vertex2.coord]}
                pathOptions={pathOptions}

            />
            <Pane name={`control_lines_${vertex1.id}`} style={{ zIndex: 1000 }}>
                <Polyline
                    positions={[vertex1.coord, vertex2.coord]}
                    pathOptions={{ opacity: 0, weight: 20 }}
                    eventHandlers={{
                        mouseover: (e) => {
                            setShowMiddle(true)
                        },
                        mouseout: (e) => {
                            setShowMiddle(false)
                        },
                        mousemove: (e) => {
                            setClosestPoint(e.sourceTarget.closestLayerPoint(e.layerPoint))
                        },
                        click: (e) => {
                            const latlang = map.layerPointToLatLng(closestPoint);
                            dispatch(insertPolygonVertex({
                                polygonId: polygonId,
                                coord: [latlang.lat, latlang.lng],
                                index: index
                            }));
                        }
                    }}
                />
            </Pane>
            {showMiddle && <Marker position={map.layerPointToLatLng(closestPoint)} icon={svgIcon()} />}
        </>
    )
}

export default EditPolyline;
