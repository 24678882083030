import { AlertColor, Box, Button, IconButton, Tooltip } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../app/state/store";
import { addPolygon, selectState, setPolygonType, setSelectedTaskIndex, setSelectedType } from "../../state/rovLabelSlice";
import { labelsApi } from "../../api/rovLabelApi";
import { LoadingButton } from "@mui/lab";
import { selectRovLabelLoadingState, setAlertMessage, setAlertOpen, setAlertSeverity, setSaveButtonDisabled, setSaveButtonLoading } from "../../state/rovLabelLoadingSlice";
import { useCallback, useEffect, useState } from "react";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import ShortcutDialog from "./ShortcutDialog";

const customStyle = (color: string) => {
    return {
        color: color,
        borderColor: color,
        '&:hover': {
            color: color,
            borderColor: color,
        },
        '&.MuiButton-contained': {
            backgroundColor: color,
            color: 'white',
        }
    }
}


function RovLabelControls(){
    const {
        polygons,
        selectedTypeId,
        projectInfo,
        selectedTaskIndex,
        selectedBatchIndex,
        selectedPolygonId,
        taskInfo
    } = useSelector((state: RootState) => selectState(state));
    const task_is_valid = taskInfo.id && taskInfo.is_valid;
    const { saveButtonLoading, saveButtonDisabled } = useSelector((state: RootState) => selectRovLabelLoadingState(state));
    const token = useSelector((state: RootState) => state.auth.token);

    const selectedPolygon = polygons[selectedPolygonId];
    const dispatch = useDispatch<AppDispatch>();

    const taskId = projectInfo.batches &&
        projectInfo.batches[selectedBatchIndex] &&
        projectInfo.batches[selectedBatchIndex].tasks &&
        projectInfo.batches[selectedBatchIndex].tasks[selectedTaskIndex] &&
        projectInfo.batches[selectedBatchIndex].tasks[selectedTaskIndex].id;

    const setAlertSettings = useCallback((message: string, severity: AlertColor) => {
        dispatch(setAlertOpen(true));
        dispatch(setAlertMessage(message));
        dispatch(setAlertSeverity(severity));
    }, [dispatch]);

    const handleSave = useCallback((next=false) => {
        if (taskId){
            dispatch(setSaveButtonLoading(true));
            labelsApi.post(taskId, task_is_valid, polygons, token).then((response) => {
                if (!response) {
                    setAlertSettings('failed to save labels', 'error');
                }
                else if (response.detail) {
                    setAlertSettings(response.detail, 'error');
                }
                else {
                    setAlertSettings('saved labels successfully', 'success');
                    next ? dispatch(setSelectedTaskIndex({taskIndex: selectedTaskIndex + 1})) : dispatch(setSaveButtonDisabled(true));
                }
                dispatch(setSaveButtonLoading(false));
            });
        }
        else {
            setAlertSettings('no task found to save labels to', 'error');
        }
    }, [dispatch, polygons, taskId, token, task_is_valid, setAlertSettings, selectedTaskIndex]);

    const keyDownHandler = useCallback((e: KeyboardEvent) => {
        e.preventDefault();
        e.key === 's' && e.ctrlKey && handleSave();
        e.key === 'Enter' && e.ctrlKey && handleSave(true);
    }, [handleSave]);

    useEffect(() => {
        document.addEventListener('keydown', keyDownHandler);

        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        }
    }, [keyDownHandler]);

    const [open, setOpen] = useState(false);

    return (
            <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 2}}>
                <Box>
                    {projectInfo.types && projectInfo.types.map((labelType: any, index: number) => {
                        return (
                            <Tooltip key={labelType.id} title={`select ${labelType.name} (${index + 1})`}>
                                <Button
                                    onClick={() => {
                                        dispatch(setSelectedType({typeId: labelType.id}));
                                        selectedPolygon ?
                                            dispatch(setPolygonType({ polygonId: selectedPolygonId, typeId: labelType.id })) :
                                            dispatch(addPolygon());
                                    }}
                                    variant={ selectedTypeId === labelType.id ? "contained" : "outlined"}
                                    sx={customStyle(labelType.colour)}
                                >
                                    {labelType.name}
                                </Button>
                            </Tooltip>
                        );
                    })}
                </Box>
                <Box>
                    <Tooltip title="show shortcuts">
                        <IconButton onClick={() => setOpen(true) }>
                            <HelpOutlineIcon />
                        </IconButton>
                    </Tooltip>
                    <ShortcutDialog open={open} setOpen={setOpen} />
                    <Tooltip title="save (ctrl + s)">
                        <LoadingButton
                            loading={saveButtonLoading}
                            disabled={saveButtonDisabled}
                            onClick={() => handleSave()}
                            variant="contained"
                            color="primary"
                        >
                            save
                        </LoadingButton>
                    </Tooltip>
                    <Tooltip title="save and next task (ctrl + Enter)">
                        <LoadingButton
                            loading={saveButtonLoading}
                            disabled={saveButtonDisabled}
                            onClick={() => handleSave(true)}
                            variant="contained"
                            color="primary"
                        >
                            save & next
                        </LoadingButton>
                    </Tooltip>
                </Box>
            </Box>
    );
}

export default RovLabelControls;
