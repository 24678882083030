import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, styled, tableCellClasses } from "@mui/material";
import { useEffect, useState } from "react";
import { projectApi } from "../api/rovLabelApi";
import { useNavigate } from "react-router-dom";

/* import { useEffect, useState } from "react";
import { projectApi } from "../api/rovLabelApi";
import BaseMapDrawer, { DrawerTab } from "../../common/components/map/layout/BaseMapDrawer";
import { SelectLocations } from "../../mainMap/components/control/SelectLocation";
import LayersTab from "../../mainMap/components/control/LayersTab";
import { Layers } from "@mui/icons-material"; */


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.grey[300],
        color: theme.palette.common.black,
        fontWeight: 'bold',
        fontSize: 16,
        padding: 5,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        padding: 5,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    cursor: 'pointer',
}));

function ProjectsTable(){
    const [projects, setProjects] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        projectApi.getProjects().then((response) => {
            setProjects(response);
        });
    }, []);

    useEffect(() => {
        console.log(projects);
    }, [projects]);

    return (
        <TableContainer component={Paper}>
            <Table aria-label="customized table">
                <TableHead>
                    <TableRow>
                        <StyledTableCell>Name</StyledTableCell>
                        <StyledTableCell align="right">Batches</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {projects && projects.map((project: any) => {
                        return (
                            <StyledTableRow
                                key={project.id}
                                hover
                                onClick={() => navigate(`/rovlabel/project/${project.id}`)}
                            >
                                <StyledTableCell component="th" scope="row">
                                    {project.name}
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                    {project.batches}
                                </StyledTableCell>
                            </StyledTableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    )
}




function ProjectSelect() {
    return (
        <>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: "center", marginTop: 2}}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: {
                        xs: "100%",
                        md: 1/2,
                    },
                }}>
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', backgroundColor: '#1976D2', borderRadius: '10px 10px 0px 0px' }}>
                        <Typography variant="h5" sx={{ flexGrow: 1, textAlign: "center", color: 'white' }}>Select Project</Typography>
                    </Box>
                    <Box
                        sx={{ display: "flex", flexDirection: 'column', overflow: "hidden", alignItems: "center", flexGrow: 1 }}
                    >
                        <ProjectsTable />
                    </Box>
                </Box>
            </Box>
        </>
    );
}

export default ProjectSelect;
