import { ReactNode, useCallback, useEffect, useRef, useState } from "react"
import { Pane, useMapEvents } from "react-leaflet"
import L from "leaflet"
import "../../../styles/compare.css"

const options = {
    thumbSize: 42,
    padding: 0,
}

const Compare = ({ leftElements, rightElements, zIndex = 300 }: { leftElements: ReactNode, rightElements: ReactNode, zIndex?: number }) => {
    const [value, setValue] = useState(0.5)
    const [rangeDrag, setRangeDrag] = useState(false)

    const leftPane = useRef<HTMLElement>(null)
    const rightPane = useRef<HTMLElement>(null)

    const map = useMapEvents({
        mousemove: (e) => {
            rangeDrag && setValue(e.containerPoint.x / map.getSize().x)
        },
        mouseup: () => {
            if (rangeDrag) {
                setRangeDrag(false)
                map.dragging.enable()
            }
        },
        move: () => {
            recalculatePanes(value)
        }
    })

    const getPosition = useCallback((rangeValue: number) => {
        const offset =
            (0.5 - rangeValue) *
            (2 * options.padding + options.thumbSize);
        return map.getSize().x * rangeValue + offset;
    }, [map])


    const calculateLeftClip = useCallback((value: number): string => {
        const nw = map.containerPointToLayerPoint([0, 0]);
        const se = map.containerPointToLayerPoint(map.getSize())
        const clipX = nw.x + getPosition(value);

        return `rect(${[nw.y, clipX, se.y, nw.x].join("px,")}px)`
    }, [map, getPosition])

    const calculateRightClip = useCallback((value: number): string => {
        const nw = map.containerPointToLayerPoint([0, 0]);
        const se = map.containerPointToLayerPoint(map.getSize());
        const clipX = nw.x + getPosition(value);

        return `rect(${[nw.y, se.x, se.y, clipX].join("px,")}px)`
    }, [map, getPosition])

    const recalculatePanes = useCallback((value: number) => {
        if (leftPane.current) leftPane.current.style.clip = calculateLeftClip(value)
        if (rightPane.current) rightPane.current.style.clip = calculateRightClip(value)
    }, [leftPane, rightPane, calculateLeftClip, calculateRightClip])

    useEffect(() => {
        const container = L.DomUtil.create(
            "div",
            "leaflet-sbs",
            // @ts-ignore
            map._controlContainer,
        )

        const divider = L.DomUtil.create(
            "div",
            "leaflet-sbs-divider",
            container,
        )
        const range = L.DomUtil.create(
            "input",
            "leaflet-sbs-range",
            container,
        )

        range.type = "range";
        // @ts-ignore
        range.min = 0;
        // @ts-ignore
        range.max = 1;
        range.step = "any";
        // @ts-ignore
        range.value = value
        // eslint-disable-next-line no-multi-assign
        range.style.paddingLeft = range.style.paddingRight = `0px`;

        const dividerX = getPosition(Number(range.value))
        divider.style.left = `${dividerX}px`;

        //L.DomEvent.disableClickPropagation(range)
        L.DomEvent.on(range, "mousedown", (e) => {
            map.dragging.disable()
            setRangeDrag(true)
        })

        L.DomEvent.on(range, "mouseup", (e) => {
            map.dragging.enable()
            setRangeDrag(false)
        })

        return () => {
            L.DomEvent.off(range, "mousedown", (e) => {
                setRangeDrag(true)
            })
            L.DomEvent.off(range, "mouseup", (e) => {
                setRangeDrag(false)
            })

            L.DomUtil.remove(container)
        }

    }, [map, value, setRangeDrag, setValue, getPosition, recalculatePanes])

    useEffect(() => {
        recalculatePanes(value)
    }, [value, recalculatePanes])

    return <>
        <Pane name="compare-tool-left-pane" ref={leftPane} style={{ clip: calculateLeftClip(value), zIndex: zIndex }}>
            {leftElements}
        </Pane>
        <Pane name="compare-tool-right-pane" ref={rightPane} style={{ clip: calculateRightClip(value), zIndex: zIndex }}>
            {rightElements}
        </Pane>
    </>
}

export default Compare