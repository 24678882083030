
import ErrorIcon from "@mui/icons-material/Error"
import InfoIcon from "@mui/icons-material/Info"
import CheckIcon from "@mui/icons-material/Check"
import WarningIcon from "@mui/icons-material/Warning"
import { AlertColor, alpha, Box, Paper, Stack, useTheme } from "@mui/material"
import { useMemo } from "react"

interface CalloutBoxProps {
    children: React.ReactNode
    severity: AlertColor
}

const CalloutBox = ({ severity, children }: CalloutBoxProps) => {
    const theme = useTheme()

    const icon = useMemo(() => {
        switch (severity) {
            case 'error':
                return <ErrorIcon color={severity}/>
            case 'info':
                return <InfoIcon color={severity}/>
            case 'success':
                return <CheckIcon color={severity}/>
            case 'warning':
                return <WarningIcon color={severity}/>
            default:
                return null
        }
    }, [severity])

    return <Paper
        sx={{
            padding: 2,
            backgroundColor: alpha(theme.palette[severity].light, 0.1),
            border: `1px solid ${alpha(theme.palette[severity].main, 0.2)}`
        }}
    >
        <Stack direction="row" spacing={2}>
            {icon}
            <Box sx={{ flexGrow: 1 }}>
                {children}
            </Box>
        </Stack>
    </Paper>
}

export default CalloutBox