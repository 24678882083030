import { Link, useNavigate, useParams } from "react-router-dom"
import { useGetPipelineInstancesQuery, useGetPipelineModelQuery } from "../../api/processApi"
import { PipelineModel } from "../../types/DataTypes"
import { Box, Breadcrumbs, Button, CircularProgress, Divider, Paper, Skeleton, Stack, Typography, useTheme } from "@mui/material"
import DetailItem from "../common/DetailItem"
import NotFound from "../../../app/views/NotFound"
import ExecutionsDataGrid from "../common/ExecutionsDataGrid"
import { PipelineModelGraph } from "../../../pipeline/views/GraphView"
import { useMemo } from "react"
import CalloutBox from "../../../common/components/UI/general/CalloutBox"
import DateDisplay from "../common/DateDisplay"

const ProcessDetail = ({ model }: { model: PipelineModel }) => {
    const theme = useTheme()
    const navigate = useNavigate()

    const { data: instances, isFetching } = useGetPipelineInstancesQuery()
    const modelInstances = instances ? instances.filter(instance => instance.pipeline_model_id === model.id) : []

    const exampleUrl = useMemo(() => {
        if (model.config) {
            return model.config.exampleImage !== undefined ? model.config.exampleImage : model.img_url
        }
        return model.img_url
    }, [model])

    return <Box
        sx={{
            width: 1,
            minHeight: 1,
            paddingX: 5,
            paddingY: 2,
            boxSizing: "border-box",
            overflow: 'auto',
            backgroundColor: theme.palette.background.default,
        }}>
        <Stack spacing={3}>
            <Breadcrumbs aria-label="breadcrumb" separator=">">
                <Link to="/processes">Procesos</Link>
                <Typography color="textPrimary">{model.subtitle ? `${model.title}:${model.subtitle}` : model.title}</Typography>
            </Breadcrumbs>
            <Stack direction="row" alignItems="center" spacing={2}>
                <Stack spacing={0} flexGrow={1}>
                    <Typography variant="h4">{model.title}</Typography>
                    {model.subtitle && <Typography variant="subtitle1" color='gray'>{model.subtitle}</Typography>}
                </Stack>
                <Button variant="outlined" disabled>Editar</Button>
                <Button variant="outlined" onClick={() => navigate(`new_execution`)}>Nueva ejecución</Button>
            </Stack>
            <Paper>
                <Stack
                    direction={{ md: 'column', lg: 'row' }}
                    sx={{ width: 1, padding: 2, boxSizing: "border-box" }}
                    spacing={{ md: 0, lg: 2 }}
                    divider={<Divider variant="middle" orientation="vertical" flexItem />}
                >
                    <Box sx={{ width: 1 }}>
                        <DetailItem label="Nombre">
                            <Typography>{model.subtitle ? `${model.title}:${model.subtitle}` : model.title}</Typography>
                        </DetailItem>
                        <DetailItem label="Descripción">
                            <Typography>{model.description}</Typography>
                        </DetailItem>
                    </Box>
                    <Box sx={{ width: 1 }}>
                        <DetailItem label="ID">
                            <Typography>{model.id}</Typography>
                        </DetailItem>
                        <DetailItem label="Fecha de creación">
                            <DateDisplay date={model.created_at} />
                        </DetailItem>
                    </Box>
                </Stack>
            </Paper>
            {model.config.warning &&
                <Box sx={{ width: 1 }}>
                    <CalloutBox severity="warning">
                        <Typography>{model.config.warning}</Typography>
                    </CalloutBox>
                </Box>
            }
            <Stack
                direction={{ lg: 'column', xl: 'row' }}
                sx={{ width: 1, boxSizing: "border-box" }}
                spacing={{ xs: 3, lg: 3, xl: 2 }}
            >
                <Box sx={{ width: 1 }}>
                    <Stack direction={'row'} spacing={1} alignItems={'center'}>
                        <Typography variant="h5">Ejemplo de resultado</Typography>
                    </Stack>
                    <Paper sx={{ display: "flex", flexDirection: "column", height: 500, borderRadius: 2, overflow: "hidden", width: 1 }}>
                        <Box sx={{
                            height: 1, width: 1,
                            backgroundImage: `url(${exampleUrl})`,
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            backgroundRepeat: "no-repeat",
                        }}
                        />
                    </Paper>
                </Box>
                <Box sx={{ width: 1 }}>
                    <Typography variant="h5">Etapas del proceso</Typography>
                    <Paper sx={{ height: 500, width: 1 }}>
                        <PipelineModelGraph modelId={model.id} />
                    </Paper>
                </Box>
            </Stack>
            <Box width={1}>
                <Typography variant="h5">Ejecuciones</Typography>
                <Paper sx={{ height: 600, overflow: 'hidden' }}>
                    <ExecutionsDataGrid
                        instances={modelInstances}
                        isLoading={isFetching}
                        excludeColumns={["pipeline_model_id"]}
                        slots={{
                            noRowsOverlay: () => (
                                <Box sx={{ display: 'flex', flexDirection: 'column', height: 1, justifyContent: 'center', alignItems: 'center' }}>
                                    <Typography sx={{ padding: 1 }}>Aún no han realizado ejecuciones de este proceso.</Typography>
                                    <Button
                                        variant="contained"
                                        onClick={() => navigate(`new_execution`)}
                                    >
                                        Nueva ejecución
                                    </Button>
                                </Box>
                            )
                        }} />
                </Paper>
            </Box>
        </Stack>
    </Box>
}

const ProcessDetailsGetter = ({ modelId }: { modelId: string }) => {
    const { data, isLoading, isSuccess } = useGetPipelineModelQuery(modelId)
    return isLoading
        ? <Box
            sx={{
                width: 1,
                minHeight: 1,
                paddingX: 5,
                paddingY: 2,
                boxSizing: "border-box",
                overflow: 'auto',
                backgroundColor: "#fcfcfc",
            }}>
            <Stack spacing={3} width={1}>
                <Breadcrumbs aria-label="breadcrumb" separator=">">
                    <Link to="/processes">Procesos</Link>
                    <CircularProgress size={15} />
                </Breadcrumbs>
                <Stack direction="row" alignItems="center" spacing={2}>
                    <Typography variant="h4" width={400}><Skeleton /></Typography>
                    <Box flexGrow={1}></Box>
                    <Button variant="outlined">Editar</Button>
                    <Button variant="outlined">Nueva ejecución</Button>
                </Stack>
                <Skeleton variant="rectangular" height={200} />
                <Stack
                    direction={{ lg: 'column', xl: 'row' }}
                    sx={{ width: 1, boxSizing: "border-box" }}
                    spacing={{ lg: 3, xl: 2 }}
                    width={1}
                >
                    <Skeleton variant="rectangular" height={500} width='100%' />
                    <Skeleton variant="rectangular" height={500} width='100%' />
                </Stack>
                <Skeleton variant="rectangular" height={600} width='100%' />
            </Stack>
        </Box>
        : isSuccess
            ? <ProcessDetail model={data} />
            : <NotFound />
}

const ProcessDetails = () => {
    const { modelId } = useParams()
    return modelId ? <ProcessDetailsGetter modelId={modelId} /> : <NotFound />

}

export default ProcessDetails
