import { useSelector } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { selectToken } from "../../authentication/state/authSlice";

function PrivateRoutes() {
    const token = useSelector(selectToken);
    const location = useLocation();

    return (
        token
            ? <Outlet />
            : <Navigate to="/login" replace state={{ from: location }} />
    )
}

export default PrivateRoutes;
