import { PayloadAction, combineSlices, createEntityAdapter, createSelector, createSlice, nanoid } from "@reduxjs/toolkit";
import { RootState } from "../../app/state/store";
import { gisApi } from "../../common/api/redux_api";
import { Parser } from "expr-eval";
import { risksApi } from "../api/risksApi";
import { GeoJSONPoint, parse } from "wellknown";

interface Place {
    id: string
    db_id: number
    name: string
    address: string
    coords: any
    saved: boolean
    created: number,
    visited: number,
    layers: string[],
}

const placesAdapter = createEntityAdapter<Place>()

const placesSlice = createSlice({
    name: 'places',
    initialState: placesAdapter.getInitialState({
        selectedId: "",
    }),
    reducers: {
        addPlace(state, action: PayloadAction<Place>) {
            placesAdapter.addOne(state, action.payload)
            state.selectedId = action.payload.id
        },
        removePlace: placesAdapter.removeOne,
        updatePlace: placesAdapter.updateOne,
        selectPlace(state, action: PayloadAction<string>) {
            state.selectedId = action.payload
            state.entities[action.payload].visited = Date.now()
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase('states/toogleSelecting', (state) => {
                state.selectedId = ""
            })
            .addMatcher(gisApi.endpoints.getAddressByLatLng.matchFulfilled, (state, { payload, meta: { arg: { originalArgs: { placeId } } } }) => {
                state.entities[placeId].name = payload.features[0].properties.address_line1
                state.entities[placeId].address = payload.features[0].properties.address_line2
            })
            .addMatcher(risksApi.endpoints.calculateRisk.matchFulfilled, (state, { payload, meta: { arg: { originalArgs: { placeId } } } }) => {
                state.entities[placeId].layers = Object.keys(payload)
                //state.entities[placeId].layers = layers.map(layer => layer)
            })
            .addMatcher(risksApi.endpoints.getRecentPlaces.matchFulfilled, (state, { payload }) => {
                placesAdapter.addMany(state, payload.map((place: any) => {
                    const geojson = parse(place.location) as GeoJSONPoint
                    const layers = Object.keys(place.data)
                    return {
                        id: place.id,
                        db_id: place.id,
                        name: place.name,
                        address: place.address,
                        coords: [geojson.coordinates[1], geojson.coordinates[0]],
                        saved: place.saved,
                        created: place.created,
                        visited: place.visited,
                        layers: [...layers],
                    }
                }))
            })
            .addMatcher(risksApi.endpoints.saveRecentPlace.matchFulfilled, (state, { payload, meta: { arg: { originalArgs: { placeId } } } }) => {
                state.entities[placeId].db_id = payload.id
            })
            .addMatcher(risksApi.endpoints.saveAnalysis.matchFulfilled, (state, { payload, meta: { arg: { originalArgs: { analysisId } } } }) => {
                state.entities[analysisId].saved = payload.saved
            })
    },
})

export const { addPlace, removePlace, updatePlace, selectPlace } = placesSlice.actions

export const {
    selectById: selectPlaceById,
    selectEntities: selectPlaceEntities,
    selectIds: selectPlacesIds,
    selectAll: selectAllPlaces,
} = placesAdapter.getSelectors<RootState>(state => state.risksMap.places)

export const selectIsSelected = createSelector(
    [(state: RootState) => state.risksMap.places.selectedId],
    (selectedId) => selectedId !== ""
)

export const selectSelectedPlace = createSelector(
    [selectPlaceEntities, (state: RootState) => state.risksMap.places.selectedId],
    (entities, selectedId) => entities[selectedId]
)

export const selectSavedPlaces = createSelector(
    [selectPlaceEntities, selectPlacesIds],
    (entities, ids) => ids.filter((id) => entities[id].saved)
)

export const selectRecentPlaces = createSelector(
    [selectSavedPlaces, selectPlacesIds],
    (savedIds, ids) => ids.filter((id) => !savedIds.includes(id))
)

export const selectPlaceIsSelected = createSelector(
    [(state: RootState) => state.risksMap.places.selectedId, (state, id) => id],
    (selectedId, id) => selectedId === id
)

export const selectOrderedPlaces = createSelector(
    [selectPlaceEntities, selectPlacesIds],
    (entities, ids) => ids.slice().sort((a, b) => entities[a].visited - entities[b].visited)
)

interface AnalysisResult {
    id: string
    name: string,
    placeId: string
    area: number
    distance: number
    percentage: number
    riskWeight: number
    score: number
    intersections: string[]
    geoms: string[]
    showIntersection: boolean
    showGeom: boolean
}

const analysisEntityAdapter = createEntityAdapter<AnalysisResult>({})

const analysisResultSlice = createSlice({
    name: "analysis_result",
    initialState: analysisEntityAdapter.getInitialState({}),
    reducers: {
        addRiskResult: analysisEntityAdapter.addOne,
        updateRiskResult: analysisEntityAdapter.updateOne,
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(risksApi.endpoints.calculateRisk.matchFulfilled, (state, { payload, meta: { arg: { originalArgs: { layers, placeId } } } }) => {
                analysisEntityAdapter.addMany(state, layers.map(layer => {
                    const area = payload[layer].reduce((prev: number, current: any) => prev + current.area_intersected, 0)
                    const percentage = payload[layer].reduce((prev: number, current: any) => prev + current.percentage_covered, 0)
                    const distance = payload[layer].reduce((prev: number, current: any) => prev > current.distance ? current.distance : prev, 600)
                    const riskWeight = payload[layer].reduce((prev: number, current: any) => prev < current.risk_weight ? current.risk_weight : prev, 0)
                    const score = payload[layer].reduce((prev: number, current: any) => prev < current.score ? current.score : prev, 0)

                    return {
                        id: placeId + "###" + layer,
                        name: layer,
                        placeId: placeId,
                        area: area,
                        distance: distance,
                        percentage: percentage,
                        riskWeight: riskWeight,
                        score: score,
                        intersections: payload[layer].map((layer: any) => layer.intersection),
                        geoms: payload[layer].map((layer: any) => layer.geom),
                        showIntersection: true,
                        showGeom: false,
                    }
                }))
            })
            .addMatcher(risksApi.endpoints.getRecentPlaces.matchFulfilled, (state, { payload }) => {
                const results = payload.map((place: any) => {
                    const data: [string, number][] = Object.entries(place.data)
                    return data.map(([layerId, score]) => ({
                            id: place.id + "###" + layerId,
                            name: layerId,
                            placeId: place.id,
                            area: 0,
                            distance: 0,
                            percentage: 0,
                            riskWeight: 0,
                            score: score,
                            intersections: [],
                            geoms: [],
                            showIntersection: true,
                            showGeom: false,
                    }))
                }).flat()
                analysisEntityAdapter.addMany(state, results)
            })
    }
})

export const {
    addRiskResult,
    updateRiskResult,
} = analysisResultSlice.actions

export const {
    selectById: selectRiskResultById,
    selectEntities: selectRiskResultEntities,
} = analysisEntityAdapter.getSelectors((state: RootState) => state.risksMap.analysis_result)

interface RisksState {
    selecting: boolean
    currentPosition: any
}

const initialState = {
    selecting: false,
    currentPosition: [0, 0],
} as RisksState

const risksSlice = createSlice({
    name: 'states',
    initialState,
    reducers: {
        setCurrentPosition(state, action: PayloadAction<any>) {
            state.currentPosition = action.payload
        },
        toogleSelecting(state) {
            state.selecting = !state.selecting
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(placesSlice.actions.addPlace, (state, action) => {
                state.selecting = false
            })
    }
})

export const {
    setCurrentPosition,
    toogleSelecting
} = risksSlice.actions

interface RiskLayer {
    id: string
    name: string
    shortName: string
    alpha: number
    beta: number
    gamma: number
    theta: number
    equation: string
}

const riskLayersAdapter = createEntityAdapter<RiskLayer>()

const riskLayersInitialState = riskLayersAdapter.getInitialState({})

// TODO: dont hardcode this later, equation value will returned by the backend
const prepopulatedRiskLayers = riskLayersAdapter.setAll(riskLayersInitialState, [
    { id: 'tsunami:hazard', name: 'Riesgo de Tsunami', shortName: 'Tsunami', alpha: 0, beta: 0, gamma: 0, theta: 0, equation: 's' },
    { id: 'volcanic:hazard', name: 'Riesgo volcánico', shortName: 'Volcán', alpha: 0, beta: 0, gamma: 0, theta: 0, equation: 's' },
    // { id: 'volcanic:hazard_area', name: 'Área riesgo volcánico', shortName: 'Area Volcán', alpha: 0, beta: 0, gamma: 0, theta: 0, equation: '(area^2)/(pi*500^2 * (dist + 1)^2)' },
    // { id: 'volcanic:pyroclast_fall', name: 'Riesgo caída de piroclastos', shortName: 'Piroclastos', alpha: -1, beta: 0, gamma: 0, theta: 0, equation: '(area^2)/(pi*500^2 * (dist + 1)^2)' },
    { id: 'volcanic:hazard_pyroclast', name: 'Riesgo de caída de piroclastos', shortName: 'Piroclastos', alpha: 0, beta: 0, gamma: 0, theta: 0, equation: 's' },
    { id: 'flood:hazard', name: 'Riesgo de inundación', shortName: 'Inundación', alpha: 0, beta: 0, gamma: 0, theta: 0, equation: 's' },
    // { id: 'rm:pri', name: 'Riesgo de remoción de masas RPI', shortName: 'Remoción RPI', alpha: 0, beta: 0, gamma: 0, theta: 0, equation: '(area^2)/(pi*500^2 * (dist + 1)^2)' },
    // { id: 'rm:prc', name: 'Riesgo de remoción de masas RPC', shortName: 'Remoción RPC', alpha: 0, beta: 0, gamma: 0, theta: 0, equation: '(area^2)/(pi*500^2 * (dist + 1)^2)' },
    { id: 'rm:hazard', name: 'Riesgo de remoción de masas', shortName: 'Remoción', alpha: 0, beta: 0, gamma: 0, theta: 0, equation: 's' },
    { id: 'wildfire:hazard', name: 'Riesgo de incendio', shortName: 'Incendios', alpha: 0, beta: 0, gamma: 0, theta: 0, equation: 's' },
]);

const riskLayersSlice = createSlice({
    name: 'riskLayers',
    initialState: prepopulatedRiskLayers,
    reducers: {
        updateRiskLayer: riskLayersAdapter.updateOne
    }
})

export const {
    updateRiskLayer
} = riskLayersSlice.actions

export const {
    selectById: selectRiskLayerById,
    selectAll: selectAllRiskLayers,
    selectEntities: selectRiskLayersEntities,
    selectIds: selectRiskLayersIds,
} = riskLayersAdapter.getSelectors<RootState>(state => state.risksMap.riskLayers)

export const selectRiskLayersNames = createSelector(
    [selectAllRiskLayers],
    (layers) => layers.map((layer) => layer.id)
)

export const selectRiskLayersTitles = createSelector(
    [selectAllRiskLayers, (state, ids) => ids],
    (riskLayers, ids) => riskLayers.filter(layer => ids.includes(layer.id)).map(layer => layer.shortName)
)

const calculateRiskLayerValue = (riskResult: AnalysisResult, riskLayer: RiskLayer) => {
    try {
        return Parser.evaluate(riskLayer.equation, {
            dist: riskResult.distance,
            area: riskResult.area,
            p: riskResult.percentage,
            rw: riskResult.riskWeight,
            a: riskLayer.alpha,
            b: riskLayer.beta,
            g: riskLayer.gamma,
            th: riskLayer.theta,
            pi: Math.PI,
            s: riskResult.score,
        })
    } catch {
        return -1
    }
}

export const selectRiskLayerValue = createSelector(
    [selectRiskResultEntities, selectRiskLayersEntities, (state, resultId) => resultId],
    (riskResults, riskLayers, resultId) => {
        const completeId = resultId.split("###")
        const layerId = completeId[completeId.length - 1]
        return calculateRiskLayerValue(riskResults[resultId], riskLayers[layerId])
    }
)

export const selectRiskLayerValues = createSelector(
    [selectPlaceById, selectRiskResultEntities, selectRiskLayersEntities],
    (place, riskResults, riskLayers) => (
        place.layers.map(name => (
            calculateRiskLayerValue(riskResults[place.id + "###" + name], riskLayers[name])
        ))
    )
)



interface RiskMapRenderLayers {
    id: string
    fullname: string
    title: string
    description: string
    bounds: any[]
    styles: any[]
    formats: { tiles: string[], info: string[] }
    matrix: string[]
    workspace: string
    type: string,
    selectedStyle: number
    selectedTileFormat: number
    show: boolean
    opacity: number
}

const riskMapRenderLayersAdapter = createEntityAdapter<RiskMapRenderLayers>()

const riskMapRenderLayersSlice = createSlice({
    name: "riskMapRenderLayers",
    initialState: riskMapRenderLayersAdapter.getInitialState({}),
    reducers: {
        updateRiskMapRenderLayer: riskMapRenderLayersAdapter.updateOne
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(gisApi.endpoints.getCapabilities.matchFulfilled, (state, { payload }) => {
                riskMapRenderLayersAdapter.setAll(state, payload.filter(
                    (layer: any) => layer.workspace === "risks" 
                ).map((layer: any) => ({
                    ...layer,
                    id: nanoid(),
                    selectedStyle: 0,
                    selectedTileFormat: 0,
                    show: false,
                    opacity: 1,
                })))
            })
    }
})

export const {
    updateRiskMapRenderLayer
} = riskMapRenderLayersSlice.actions

export const {
    selectAll: selectAllRiskRenderLayers,
    selectById: selectRiskRenderLayersById,
    selectEntities: selectRiskRenderLayerEntities,
    selectIds: selectRiskRenderLayerIds,
} = riskMapRenderLayersAdapter.getSelectors((state: RootState) => state.risksMap.riskMapRenderLayers)


export const risksReducer = combineSlices(placesSlice, risksSlice, riskLayersSlice, riskMapRenderLayersSlice, analysisResultSlice)
