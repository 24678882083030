import { Box, Typography } from "@mui/material"
import { ReactNode } from "react"

const DetailItem = ({ label, children }: { label: string, children: ReactNode }) => <>
    <Typography color={'gray'}>{label}</Typography>
    <Box sx={{ marginLeft: 1 }}>
        {children}
    </Box>
</>

export default DetailItem