import {
    AlertColor,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    Link,
} from "@mui/material";
import { registerApi } from "../api/authApi";
import { FormContainer, FormTextField, FormHeader } from "./Form";
import { CustomAlertDialog } from "../../common/components/UI/general/AlertDialog";
import { useCallback, useMemo, useState } from "react";
import { LoadingButton } from "@mui/lab";
import PasswordHelper, { passwordCheck } from "../components/PasswordHelper";

function Register() {
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [severity, setSeverity] = useState<AlertColor>('error');
    const [loading, setLoading] = useState(false)
    const [modal, setModal] = useState(false)

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!event.currentTarget.checkValidity()) {
            event.currentTarget.reportValidity()
            setModal(true)
        }
        else {
            const data = new FormData(event.currentTarget);
            const username = data.get('username') as string;
            const displayName = data.get('displayName') as string;
            const email = data.get('email') as string;
            const password = data.get('password') as string;
            const confirm_password = data.get('confirm_password') as string;

            if (password !== confirm_password) {
                setModal(true)
                return
            }

            setLoading(true)

            registerApi(username, displayName, email, password)
                .then(response => {
                    setLoading(false)
                    if (response?.message) {
                        setAlertOpen(true);
                        setAlertMessage(response.message);
                        setSeverity('success');
                    } else {
                        setAlertOpen(true);
                        setAlertMessage(response.detail);
                        setSeverity('error');
                    }
                });
        }
    };

    const [password, setPassword] = useState("")

    const usernameChecker = useCallback((username: string) => {
        const usernameRegex = /^[a-zA-Z0-9_\-.]+$/
        return !usernameRegex.test(username)
    }, [])

    const nameChecker = useCallback((name: string) => {
        const nameRegex = /^[a-zA-Z\- ]+$/
        return !nameRegex.test(name)
    }, [])

    const passwordChecker = useCallback((password: string) => passwordCheck(password), [])

    const passwordHelper = useMemo(() => PasswordHelper({password}), [password])

    return (
        <Box sx={{ height: 1, width: 1, overflow: 'auto' }}>
            <FormContainer>
                <FormHeader title="Creación de Cuenta" />
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                    <FormTextField id="username" label="Nombre de usuario" name="username" autoComplete="username" autoFocus checkValidity helperText="El nombre de usario no puede estar vacío, ni contener espacios o símbolos." validityFunction={usernameChecker} />
                    <FormTextField id="displayName" label="Nombre y apellido" name="displayName" autoComplete="displayName" checkValidity helperText="El nombre no puede estar vacío o contener símbolos." validityFunction={nameChecker} />
                    <FormTextField id="email" label="Email" name="email" type="email" autoComplete="email" checkValidity helperText="Debe ingresar un mail válido" />
                    <FormTextField id="password" label="Contraseña" name="password" type="password" autoComplete="new-password" checkValidity validityFunction={passwordChecker} setValue={(value) => setPassword(value)} />
                    {passwordHelper}
                    <FormTextField id="confirm_password" label="Confirmar contraseña" name="confirm_password" type="password" autoComplete="new-password" checkValidity validityFunction={(value) => password !== value} helperText="La contraseña no coincide" />
                    <LoadingButton
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        loading={loading}
                    >
                        Crear Cuenta
                    </LoadingButton>
                    <Grid container>
                        <Grid item xs />
                        <Grid item>
                            <Link href="/login" variant="body2">
                                {"¿Ya tienes cuenta? Ingresa aquí"}
                            </Link>
                        </Grid>
                    </Grid>
                </Box>
                <CustomAlertDialog alertOpen={alertOpen} setAlertOpen={setAlertOpen} message={alertMessage} severity={severity} />
                <Box sx={{ height: '5vh' }} />
                <Dialog
                    open={modal}
                    onClose={() => setModal(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Errores en el formulario"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Revise los errores en el formulario.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setModal(false)}>Entendido</Button>
                    </DialogActions>
                </Dialog>
            </FormContainer>
        </Box>
    );
}

export default Register;
