import L, { LatLngBounds, LatLngExpression } from 'leaflet';
// @ts-ignore
import { wktToGeoJSON } from '@terraformer/wkt';

const svgIcon = (color='red', size=100, radious=20) => {
    return L.divIcon({
        html: `
        <svg viewBox="0 0 ${size} ${size}" xmlns="http://www.w3.org/2000/svg">
            <circle cx="${size/2}" cy="${size/2}" r="${radious}" fill="${color}" stroke="black" stroke-width="6" />
        </svg>`,
        className: "",
        iconSize: [20, 20],
        iconAnchor: [10, 10],
    });
}

function flipWktCoordinates(coordinates: number[][][]): LatLngExpression[] {
    return coordinates[0].map((point) => {
            return [point[1], point[0]];
        });
}

const flipCoordinates = (vertexs: any[]): number[][] => {
    const newVertexs = vertexs.map((vertex) => {
        return [vertex.coord[1], vertex.coord[0]];
    });
    newVertexs.push(newVertexs[0]);
    return newVertexs;
}

function wktToCoordinates(wkt: string): LatLngExpression[] {
    const geoJson = wktToGeoJSON(wkt);
    const flippedCoordinates = flipWktCoordinates(geoJson.coordinates);
    // delete repeated point that closes the polygon
    flippedCoordinates.pop();
    return flippedCoordinates;

}

const getBounds = (bboxWkt: string) => {
    const coordinates = wktToCoordinates(bboxWkt);
    const bbox = new LatLngBounds(coordinates[0], coordinates[2]);
    return bbox;
}

function base64ToBlob(base64String: string, contentType = 'image/png') {
    const byteCharacters = atob(base64String);
    const byteArrays = [];

    for (let i = 0; i < byteCharacters.length; i++) {
        byteArrays.push(byteCharacters.charCodeAt(i));
    }

    const byteArray = new Uint8Array(byteArrays);
    return new Blob([byteArray], { type: contentType });
}

const getCenterFromVertex = (vertexs: any): LatLngExpression => {
    var center : LatLngExpression = [0,0]
    for(const vertex of vertexs){

        center[0] += vertex[0]
        center[1] += vertex[1]
    }

    center[0] /= vertexs.length
    center[1] /= vertexs.length

    return center
}

const keyBinds = {
    deselectPolygon: 'Escape',
    deleteItem: 'Backspace',
    editPolygon: 'e',
    polygonVisible: 'b',
    finishPolygon: 'c',
    changePolygonType: ['1', '2', '3', '4', '5', '6', '7', '8', '9'],
    imageSwitcher: 't',
    taskValid: 'v',
    recenter: 'r',
}


export {
    svgIcon,
    flipCoordinates,
    getBounds,
    wktToCoordinates,
    base64ToBlob,
    keyBinds,
    getCenterFromVertex
};
