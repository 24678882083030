import { useState } from "react"
import { ControlGroup } from "../layout/BaseMapControls"
import { Box, Snackbar, Typography } from "@mui/material"
import MeasureTool from "../tools/MeasureTool"
import ToggleButton from "../../UI/general/ToggleButton"
import { Clear, Pentagon, Straighten } from "@mui/icons-material"
import PolygonTool from "../tools/PolygonTool"
import { formatDistance } from "../../../utils/tools"
import { FormatedArea } from "../../../utils/Measurements"

const DrawingUtilities = () => {
    const [drawing, setDrawing] = useState(false)

    const [measuring, setMeasuring] = useState(false)
    const [measure, setMeasure] = useState(0)
    const [showSnackbar, setShowSnackbar] = useState(false)

    const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setShowSnackbar(false);
    };


    return (
        <>
            {measuring &&
                <ControlGroup>
                    <Box sx={{ margin: 1.7 }}>
                        <Typography>Distance: {formatDistance(measure)}</Typography>
                    </Box>
                </ControlGroup>
            }
            {drawing &&
                <ControlGroup>
                    <Box sx={{ margin: 1.7 }}>
                        <FormatedArea area={measure} />
                    </Box>
                </ControlGroup>
            }
            <ControlGroup>
                <ToggleButton
                    tooltip="Draw Polygon"
                    icon={<Pentagon fontSize="inherit" />}
                    selectedIcon={<Clear fontSize='inherit' />}
                    selected={drawing}
                    onClick={() => {
                        setDrawing(prev => {
                            setMeasuring(false)
                            setShowSnackbar(!prev)
                            return !prev
                        })
                    }}
                />
                <ToggleButton
                    tooltip="Measure"
                    icon={<Straighten fontSize="inherit" />}
                    selectedIcon={<Clear fontSize='inherit' />}
                    selected={measuring}
                    onClick={() => {
                        setMeasuring(prev => {
                            setDrawing(false)
                            setShowSnackbar(!prev)
                            return !prev
                        })
                        setMeasure(0)
                    }}
                />
            </ControlGroup>
            {measuring && <MeasureTool setMeasure={setMeasure} />}
            {drawing && <PolygonTool setMeasure={setMeasure} />}
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={showSnackbar}
                autoHideDuration={5000}
                onClose={handleClose}
                message="Complete the task by pressing double click"
            />
        </>
    )
}

export default DrawingUtilities;
