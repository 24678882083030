import { Box, Paper, Stack, Typography } from "@mui/material"
import { NodeProps } from "reactflow"
import { ModelNodeProps } from "../types/NodesTypes"
import ModelHandle from "./ModelHandle"

const ConnectionNode = ({ data, selected, id }: NodeProps<ModelNodeProps>) => {
    if(data === undefined) return null
    return (
        <Paper
            sx={{
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: '#fff07c',
                overflow: 'hidden',
                width: 200,
                borderRadius: 10,
            }}
            elevation={selected ? 5 : 1}
        >
            <Box sx={{ padding: 1 }}>
                <Typography textAlign='center' variant="h6">{data.name}</Typography>
            </Box>
            <Stack spacing={2} direction="row" padding={2}>
                <Stack spacing={1} flexGrow={1}>
                    {data.inputs.map(input => <Stack spacing={1} direction={'row'} alignItems={'center'} key={input.id}>
                        <ModelHandle type="target" props={input} />
                        <Typography>{input.name}</Typography>
                    </Stack>)}
                </Stack>
                <Stack spacing={1} flexGrow={1}>
                    {data.outputs.map(output => <Stack spacing={1} direction='row-reverse' alignItems='center' key={output.id}>
                        <ModelHandle type="source" props={output} />
                        <Typography>{output.name}</Typography>
                    </Stack>)}
                </Stack>
            </Stack>
        </Paper>
    )
}

export default ConnectionNode