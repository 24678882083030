import dagre from 'dagre';

const nodeWidth = 170;
const nodeHeight = 50;

export const layoutElements = (nodes: any, edges: any, horizontal = true, defaultNodeType = 'simple') => {
    const dagreGraph = new dagre.graphlib.Graph();
    dagreGraph.setDefaultEdgeLabel(() => ({}));

    const direction = horizontal ? 'LR' : 'TB';

    dagreGraph.setGraph({ rankdir: direction });

    nodes.forEach((node: any) => {
        dagreGraph.setNode(
            node.id,
            (node.width && node.height) ? {width: node.width, height: node.height} : { width: nodeWidth, height: nodeHeight }
        );
        if (!node.type) {
            node.type = defaultNodeType;
        }
    });

    edges.forEach((edge: any) => {
        dagreGraph.setEdge(edge.source, edge.target);
        if (edge.isParallelSuccess) {
            edge.type = 'parallelSuccess';
        }
    });

    dagre.layout(dagreGraph);

    nodes.forEach((node: any) => {
        const nodeWithPosition = dagreGraph.node(node.id);
        node.position = {
            x: nodeWithPosition.x - nodeWidth / 2,
            y: nodeWithPosition.y - nodeHeight / 2,
        };
    });

    return { nodes, edges };
};
