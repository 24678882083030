// @ts-ignore
import { geojsonToWKT } from "@terraformer/wkt"
import { getRequest, getRequestBlob, postRequest } from "../../app/api/apiBase"
import { flipCoordinates } from "../utils/rovLabelHelpers";

const projectApi = {
    get(projectId: number) {
        return getRequest(
            `/rov_label/project/${projectId}`
        );
    },
    getProjects() {
        return getRequest(
            `/rov_label/projects`
        );
    }
}

const labelsApi = {
    // get task labels
    get(taskId: number) {
        return getRequest(
            `/rov_label/task/${taskId}`
        );
    },
    // save task labels
    post(taskId: number, task_is_valid: boolean, polygons: { [key: string]: any }, token: string | null) {
        const newLabelsData = [];
        const updatedLabelsData = [];
        for (const polygon of Object.values(polygons)) {
            const geoJsonPolygon = {
                "type": "Polygon",
                "coordinates": [
                    flipCoordinates(polygon.vertexs)
                ]
            };
            if (polygon.dbId) {
                polygon.updated && updatedLabelsData.push({
                    'id': polygon.dbId,
                    'type_id': polygon.typeId,
                    'geom': geojsonToWKT(geoJsonPolygon),
                });
            }
            else {
                newLabelsData.push({
                    'type_id': polygon.typeId,
                    'geom': geojsonToWKT(geoJsonPolygon),
                });
            }
        }

        const data = {
            'user_id': 2,
            'task_is_valid': task_is_valid,
            'new_labels': newLabelsData,
            'updated_labels': updatedLabelsData
        };
        return postRequest(
            `/rov_label/task/${taskId}/save_label`,
            data,
            token
        );
    }
}

const imageApi = {
    post(path: string, geom: string) {
        return postRequest(
            `/rov_label/image_png`,
            {
                'path': path,
                'geom': geom
            }
        );
    },
    post_str(path: string, geom: string) {
        return postRequest(
            `/rov_label/image_str`,
            {
                'path': path,
                'geom': geom
            }
        );
    },
    getTestImage() {
        return getRequestBlob(
            `/rov_label/demo-image`
        );
    }
}

export { labelsApi, projectApi, imageApi }
