import EditPolygon from "./EditPolygon"
import CreatePolygon from "./CreatePolygon"
import { useSelector } from "react-redux"
import { selectPolygonById } from "../../state/rovLabelSlice"
import { RootState } from "../../../app/state/store";

function DrawPolygon({id}: {id: number}) {
    const { finished, visible } = useSelector((state: RootState) => selectPolygonById(state, id));

    return (
        <>
            {finished
                ? visible && <EditPolygon polygonId={id} />
                : <CreatePolygon polygonId={id} />
            }
        </>
    )
}

export default DrawPolygon;
