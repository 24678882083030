import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/state/store";

interface Pipeline {
    id: string,
    name: string,
    flow: string | undefined,
    image: string,
}

const pipelineAdapter = createEntityAdapter<Pipeline>()

const pipelineSlice = createSlice({
    name: 'pipelines',
    initialState: pipelineAdapter.getInitialState<{ selectedPipeline: undefined | string }>({
        selectedPipeline: undefined,
    }),
    reducers: {
        addPipeline: pipelineAdapter.addOne,
        removePipeline: pipelineAdapter.removeOne,
        updatePipeline: pipelineAdapter.updateOne,
        selectPipeline: (state, action) => {
            state.selectedPipeline = action.payload
        },
        unselectPipeline: (state) => {
            state.selectedPipeline = undefined
        }
    }
})

export const {
    addPipeline,
    removePipeline,
    updatePipeline,
    selectPipeline,
    unselectPipeline,
} = pipelineSlice.actions

export const {
    selectAll: selectAllPipelines,
    selectById: selectPipelineById,
    selectIds: selectPipelineIds,
    selectEntities: selectPipelinesEntities,
} = pipelineAdapter.getSelectors((state: RootState) => state.pipelines)

export default pipelineSlice.reducer