import { Typography } from "@mui/material"
import dayjs from "dayjs"
import relativeTime from "dayjs/plugin/relativeTime"
import 'dayjs/locale/es-mx'

dayjs.locale('es-mx')
dayjs.extend(relativeTime)

const TimeDisplay = ({ time }: { time: number }) => {

    const timeString = dayjs().second(time).fromNow(true)

    return <Typography>{timeString}</Typography>
}

export default TimeDisplay