import L from "leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet-compass/dist/leaflet-compass.src.css";
import {
    AttributionControl,
    MapContainer,
    MapContainerProps,
    ScaleControl,
    TileLayer,
} from "react-leaflet";
import packageJson from '../../../../package.json';
import '../../styles/leaflet_elements.css';
import CompassControl from "./control/CompassControl";
import SentinelLayer from "./layers/SentinelLayer";

interface BaseMapProps extends MapContainerProps {
    baseMap?: 'esri' | 'osm' | 'rov' | 'google'
    version?: string
    innerRef?: any
}

const BaseMap = ({
    center = L.latLng(-33.75, -71.05),
    zoom = 8,
    minZoom = 3,
    maxZoom = 22,
    crs = L.CRS.EPSG3857,
    baseMap = 'osm',
    children = null,
    version = packageJson.version,
    innerRef,
    ...options
}: BaseMapProps) => {

    return (
        <MapContainer
            center={center}
            crs={crs}
            zoom={zoom}
            minZoom={minZoom}
            maxZoom={maxZoom}
            {...options}
            attributionControl={false}
            ref={innerRef}
        >
            <AttributionControl position="bottomleft" prefix={`<a href="https://www.maptiler.com/copyright/" target="_blank">&copy; MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap contributors</a> - v${version}`} />
            <ScaleControl position='bottomright' imperial={false} />
            <CompassControl />
            {baseMap === 'esri' &&
                <TileLayer
                    url={'http://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}'}
                    maxZoom={maxZoom}
                    maxNativeZoom={18}
                    pane="tilePane"
                />}

            {baseMap === 'osm' &&
                <TileLayer
                    url={'https://a.tile.openstreetmap.org/{z}/{x}/{y}.png'}
                    maxZoom={maxZoom}
                    pane="tilePane"
                />
            }
            {baseMap === "google" &&
                <TileLayer
                    url="http://mt0.google.com/vt/lyrs=s&hl=en&x={x}&y={y}&z={z}"
                    maxZoom={maxZoom}
                    maxNativeZoom={20}
                    pane="tilePane"
                />
            }
            {baseMap === "rov" &&
                <SentinelLayer
                    maxZoom={maxZoom}
                    maxNativeZoom={18}
                />
            }
            {children}
        </MapContainer>
    );
}

export default BaseMap;
