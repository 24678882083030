import { Box, Typography } from "@mui/material";
import useTimeoutRedirect from "../components/useTimeoutRedirect";
import { SearchOffOutlined } from "@mui/icons-material";
import { useEffect, useState } from "react";

function NotFound() {
    useTimeoutRedirect()
    const [timer, setTimer] = useState(3)

    useEffect(() => {
        const interval = setInterval(() => {
            setTimer(timer => timer - 1)
        }, 1000)
        return () => clearInterval(interval)
    }, [])

    return <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: 1, width: 1 }}>
        <SearchOffOutlined sx={{ fontSize: 200, color: 'lightgray' }} />
        <Typography variant="h2">Página no encontrada</Typography>
        <Typography variant="h5">Redirigiendo en {timer} segundos...</Typography>
    </Box>
}

export default NotFound;
