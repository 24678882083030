import { Checkbox, FormControl, FormGroup, FormHelperText } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { AppDispatch, RootState } from "../../../app/state/store"
import { selectProcessBooleanById, updateProcessBoolean } from "../../state/processSlice"

const BooleanProcessInput = ({ id }: { id: string }) => {
    const processBoolean = useSelector((state: RootState) => selectProcessBooleanById(state, id))
    const dispatch = useDispatch<AppDispatch>()

    return <FormControl
        required={processBoolean.required}
        error={processBoolean.error !== ''}
        component="fieldset"
        sx={{ m: 3 }}
        variant="standard"
    >
        <FormGroup>
            <Checkbox
                checked={processBoolean.value}
                onChange={() => dispatch(updateProcessBoolean({ id: id, changes: { value: !processBoolean.value } }))}
            />
        </FormGroup>
        <FormHelperText>
            {processBoolean.error}
        </FormHelperText>
    </FormControl>
}

export default BooleanProcessInput