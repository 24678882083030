import AceEditor from 'react-ace'
import { Alert, AlertProps, Box, Button, Snackbar, Typography } from "@mui/material"
import { useCallback, useEffect, useMemo, useState } from "react";
import { ArrowDownward, ArrowUpward, CopyAll, Save } from '@mui/icons-material';
import { EolStyle, Formatter, FracturedJsonOptions } from 'fracturedjsonjs';
import { LatLngBounds } from 'leaflet';
import { useMap } from 'react-leaflet';
import { parse, stringify } from "wellknown";

import GeoJsonLayer from '../../../common/utils/GeoJsonLayer';
import WktLayer from '../../../common/utils/WktLayer';

import "ace-builds/src-noconflict/mode-text"
import "ace-builds/src-noconflict/mode-json"
import "ace-builds/src-noconflict/theme-monokai"


const WktGeoJsonTab = () => {
    const [wktString, setWktString] = useState('')
    const [geojsonString, setGeojsonString] = useState('')

    const [geoJson, setGeoJson] = useState<any>()

    const [drawWktString, setDrawWktString] = useState<string>()
    const [drawGeoJson, setDrawGeoJson] = useState<any>()

    const options = useMemo(() => {
        var o = new FracturedJsonOptions()
        o.MaxTotalLineLength = 100
        o.AlwaysExpandDepth = 1
        o.MaxInlineComplexity = 1
        o.MaxInlineLength = 50
        o.JsonEolStyle = EolStyle.Crlf;
        return o
    }, [])

    const formatter = useMemo(() => {
        var f = new Formatter()
        f.Options = options
        return f
    }, [options])

    const convertToGeoJson = useCallback(() => {
        const parsed = parse(wktString)
        if (parsed) {
            const geoJSONText = formatter.Serialize(parsed)
            geoJSONText && setGeojsonString(geoJSONText)
            setSbMessage("Conversion to GeoJSON finished")
            setSbSeverity('success')
            setSbOpen(true)
            setGeoJson(parsed)
        }
        else {
            setSbMessage("Could not convert to GeoJSON")
            setSbSeverity('error')
            setSbOpen(true)
            setGeoJson(undefined)
        }
    }, [wktString, setGeojsonString, setGeoJson, formatter])

    const convertToWkt = useCallback(() => {
        try {
            const parsed = stringify(geoJson)
            if (parsed !== '') {
                setWktString(parsed)
                setSbMessage("Conversion to WKT finished")
                setSbSeverity('success')
                setSbOpen(true)
            }
            else {
                setSbMessage("Could not converto to WKT")
                setSbSeverity('error')
                setSbOpen(true)
            }
        } catch {
            setSbMessage("Could not converto to WKT")
            setSbSeverity('error')
            setSbOpen(true)
        }
    }, [geoJson, setWktString])

    const drawWktGeom = useCallback(() => {
        setDrawWktString(wktString)
        setDrawGeoJson(undefined)
    }, [wktString, setDrawGeoJson, setDrawWktString])

    const drawGeoJsonGeom = useCallback(() => {
        setDrawWktString(undefined)
        setDrawGeoJson(geoJson)
    }, [geoJson, setDrawGeoJson, setDrawWktString])

    const [bbox] = useState<LatLngBounds>()

    const donwloadFile = useCallback(({ data, filename }: { data: string, filename: string }) => {
        const element = document.createElement("a");
        const file = new Blob([data], { type: filename.includes(".geojson") ? 'application/json' : 'text/plain' });
        element.href = URL.createObjectURL(file);
        element.download = filename;
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
    }, [])

    const map = useMap()

    useEffect(() => {
        bbox && map.flyToBounds(bbox)
    }, [map, bbox])

    // Snackbar behavior
    const [sbOpen, setSbOpen] = useState(false);
    const [sbMessage, setSbMessage] = useState('')
    const [sbSeverity, setSbSeverity] = useState<AlertProps["severity"]>()

    const handleClose = (event: any) => {
        setSbOpen(false);
    };

    return <Box sx={{ display: 'flex', flexDirection: 'column', overflow: 'hidden', paddingLeft: 2, height: 1 }}>
        <Typography variant='h4' padding={1}>WKT</Typography>
        <Box sx={{ flexGrow: 1 }}>
            <AceEditor
                mode="text"
                theme='monokai'
                fontSize="16px"
                value={wktString}
                onChange={(e) => setWktString(e)}
                setOptions={{
                        showLineNumbers: true,
                    tabSize: 2,
                    useWorker: false,
                    wrap: true,
                }}
                width='100%'
                height='100%'
            />
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', padding: 3 }}>
            <Button variant='contained' onClick={convertToGeoJson} endIcon={<ArrowDownward />}>Convert</Button>
            <Button variant='contained' endIcon={<Save />} onClick={() => donwloadFile({ data: wktString, filename: "wktFile.txt" })}>Save to file</Button>
            <Button
                variant='contained'
                endIcon={<CopyAll />}
                onClick={() => {
                    navigator.clipboard.writeText(wktString)
                    setSbMessage("Wkt data copied")
                    setSbSeverity('success')
                    setSbOpen(true)
                }}
            >
                Copy
            </Button>
            <Button variant='contained' onClick={drawWktGeom}>Draw</Button>
        </Box>
        <Typography variant='h4' padding={1}>GeoJSON</Typography>
        <Box sx={{ flexGrow: 1 }}>
            <AceEditor
                mode="json"
                theme='monokai'
                fontSize="16px"
                value={geojsonString}
                onChange={(e) => {
                    setGeojsonString(e)
                    try {
                        setGeoJson(JSON.parse(e))
                    } catch {
                        setGeoJson(undefined)
                    }
                }}
                setOptions={{
                    showLineNumbers: true,
                    tabSize: 2,
                    useWorker: false,
                    wrap: true,
                }}
                width='100%'
                height='100%'
            />
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', padding: 3 }}>
            <Button variant='contained' onClick={convertToWkt} endIcon={<ArrowUpward />}>Convert</Button>
            <Button variant='contained' endIcon={<Save />} onClick={() => donwloadFile({ data: geojsonString, filename: "geoJSONFile.geojson" })}>Save to file</Button>
            <Button variant='contained' endIcon={<CopyAll />} onClick={() => {
                navigator.clipboard.writeText(geojsonString)
                setSbMessage("GeoJSON data copied")
                setSbSeverity('success')
                setSbOpen(true)
            }}>
                Copy </Button>
            <Button variant='contained' onClick={drawGeoJsonGeom}>Draw</Button>
        </Box>
        <Snackbar open={sbOpen} autoHideDuration={3000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} sx={{ top: { xs: 80 }, right: { xs: 50 } }}>
            <Alert
                onClose={handleClose}
                severity={sbSeverity}
                variant="filled"
                sx={{ width: '100%' }}
            >
                {sbMessage}
            </Alert>
        </Snackbar>
        {drawWktString && <WktLayer wktData={drawWktString} pathOptions={{ color: 'blue' }} />}
        {drawGeoJson && <GeoJsonLayer data={drawGeoJson} pathOptions={{ color: 'red' }} />}
    </Box>
}

export default WktGeoJsonTab;
