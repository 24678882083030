import { Box, Typography } from "@mui/material"
import { ChangeEvent, useCallback, useState } from "react"
import InputFileUpload from "../../../common/components/UI/general/InputFileUpload"
import numberToBytes from "../../../common/utils/numberToBytes"
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { LoadingButton } from "@mui/lab"
import { getRequest } from "../../../app/api/apiBase";
import { getGeoTiffData, getTiffCoordinates, useDrawBbox } from "../../../common/utils/geoTiffUtils";
import { useUploadImageInfoMutation } from "../../api/mainMapApi";
import { useSelector } from "react-redux";
import { RootState } from "../../../app/state/store";
// @ts-ignore
import { geojsonToWKT } from "@terraformer/wkt"
import axios from "axios";
import CustomProgressBar from "../../../common/components/UI/general/ProgressBar";

const UploadTab = () => {
    const token = useSelector((state: RootState) => state.auth.token);
    const [file, setFile] = useState<File | null>(null)
    const [validFile, setValidFile] = useState(false)
    const [bbox, setBbox] = useState<any>(null);
    const [loading, setLoading] = useState(false)
    const [progress, setProgress] = useState(0);

    useDrawBbox(bbox)

    const [uploadImageInfoTrigger, InfoResult] = useUploadImageInfoMutation();

    const fileUpload = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        const tempFile = e.target.files && e.target.files[0]
        if (tempFile && tempFile.type === "image/tiff") {
            setValidFile(true)
            setFile(tempFile)
            const url = URL.createObjectURL(tempFile)
            getGeoTiffData(url).then(({ bbox, proj4String }) => {
                const coordinate1 = getTiffCoordinates(bbox[1], bbox[0], proj4String.proj4)
                const coordinate2 = getTiffCoordinates(bbox[3], bbox[2], proj4String.proj4)
                setBbox({ sw: coordinate1, ne: coordinate2 })
            })
        }
        else {
            setValidFile(false)
            setFile(null)
        }
    }, [])

    // const { isLoading, bbox } = useGeotiffLayer({ url, options: {} })

    const handleUpload = async () => {
        if (file) {
            setProgress(0)
            const secure_url_response = await getRequest("/gis/image_upload_url", token)
            setLoading(true)
            const upload_response = await axios.put(secure_url_response.upload_url, file, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                onUploadProgress: (progressEvent) => {
                    if (progressEvent.total) {
                        const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                        setProgress(progress)
                    }
                }
            })
            if (upload_response.status === 200) {
                const wktBBox = geojsonToWKT({
                    "type": "Polygon",
                    "coordinates": [[
                        [bbox.sw.lon, bbox.sw.lat],
                        [bbox.ne.lon, bbox.sw.lat],
                        [bbox.ne.lon, bbox.ne.lat],
                        [bbox.sw.lon, bbox.ne.lat],
                        [bbox.sw.lon, bbox.sw.lat]
                    ]]
                })
                uploadImageInfoTrigger({ name: file.name, url: secure_url_response.object_key, bbox: wktBBox })
                setLoading(false)
            }
        }
    }



    return (<>
        <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, padding: 1 }}>
            {!validFile && <Typography variant="h6" textAlign={"center"}>Please Select a GEOTIFF File</Typography>}
            {file && <>
                <Typography sx={{ fontWeight: "bold" }} variant="h6">File Name: </Typography>
                <Typography variant="h6">{file.name}</Typography>
                <Typography sx={{ fontWeight: "bold" }} variant="h6">File Size: </Typography>
                <Typography variant="h6">{numberToBytes(file.size)}</Typography>
                <Typography sx={{ fontWeight: "bold" }} variant="h6">File Type: </Typography>
                <Typography variant="h6">{file.type}</Typography>
                <Typography sx={{ fontWeight: "bold" }} variant="h6">bounding box: </Typography>
                {bbox && <>
                    <Typography variant="h6">{`sw:`}</Typography>
                    <Typography variant="h6">{`\u00A0\u00A0lat: ${bbox.sw.lat}`}</Typography>
                    <Typography variant="h6">{`\u00A0\u00A0lon: ${bbox.sw.lon}`}</Typography>
                </>}
                {bbox && <>
                    <Typography variant="h6">{`ne:`}</Typography>
                    <Typography variant="h6">{`\u00A0\u00A0lat: ${bbox.ne.lat}`}</Typography>
                    <Typography variant="h6">{`\u00A0\u00A0lon: ${bbox.ne.lon}`}</Typography>
                </>}
                {(loading || InfoResult.isLoading) &&
                    <CustomProgressBar progress={progress} />
                }
                <Box sx={{ display: 'flex', flexDirection: 'column', overflow: 'hidden', alignItems: 'center' }}>
                    <LoadingButton
                        variant="contained"
                        loading={loading || InfoResult.isLoading}
                        loadingPosition="center"
                        startIcon={<CloudUploadIcon />}
                        onClick={handleUpload}
                        sx={{ marginTop: 2, marginBottom: 2 }}
                    >
                        Upload File
                    </LoadingButton>
                </Box>
            </>}
            <Box sx={{ display: 'flex', flexDirection: 'column', overflow: 'hidden', alignItems: 'center' }}>
                <InputFileUpload onChange={fileUpload} />
            </Box>
        </Box>
    </>)
}

export default UploadTab
