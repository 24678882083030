import { useCallback, useMemo, useRef, useState } from "react"
import { svgIcon } from "../../../../rovLabel/utils/rovLabelHelpers"
import { Marker, useMapEvents } from "react-leaflet"
import { LatLng, LeafletMouseEvent, Marker as LeafletMarker, latLng } from "leaflet"
import { ControlGroup } from "../layout/BaseMapControls"
import { Box, Divider, InputAdornment, TextField } from "@mui/material"
import ToggleButton from "../../UI/general/ToggleButton"
import { CenterFocusWeak } from "@mui/icons-material"

const icon = svgIcon('red', 50)

const DropPin = () => {
    const markerRef = useRef<LeafletMarker>(null)
    const [markerDrop, setMarkerDrop] = useState(false)
    const [markerPos, setMarkerPos] = useState<LatLng>()
    const [isLatNumber, setIsLatNumber] = useState(true)
    const [isLngNumber, setIsLngNumber] = useState(true)
    const [lat, setLat] = useState("")
    const [lng, setLng] = useState("")

    const eventHandlers = useMemo(
        () => ({
            dragend() {
                const marker = markerRef.current
                if (marker != null) {
                    const markerLatLng = marker.getLatLng()
                    //setMarkerPos(markerLatLng)
                    setLat(markerLatLng.lat.toFixed(14))
                    setLng(markerLatLng.lng.toFixed(14))
                }
            },
            drag() {
                const marker = markerRef.current
                if (marker != null) {
                    const markerLatLng = marker.getLatLng()
                    setMarkerPos(markerLatLng)
                    setLat(markerLatLng.lat.toFixed(14))
                    setLng(markerLatLng.lng.toFixed(14))
                }
            }
        }),
        [],
    )

    const map = useMapEvents({
        mousemove: (e: LeafletMouseEvent) => {
            if (!markerDrop) {
                setMarkerPos(e.latlng)
                setLat(e.latlng.lat.toFixed(14))
                setLng(e.latlng.lng.toFixed(14))
            }
        },
        click: (e: LeafletMouseEvent) => {
            setMarkerDrop(true)
        }
    })

    const centerMap = useCallback(() => {
        markerPos && map.flyTo(markerPos, 13)
    }, [markerPos, map])





    return <>
        <ControlGroup>
            <ToggleButton icon={<CenterFocusWeak fontSize="inherit" />} selected={false} tooltip="Centrar" onClick={centerMap} />
            <Box sx={{ margin: 0.8 }}>
                <TextField
                    size="small"
                    value={lat}
                    InputProps={{
                        startAdornment: <InputAdornment position="start">Lat:</InputAdornment>,
                    }}
                    onChange={(e) => {
                        const value = e.target.value
                        setLat(value)
                        const numericValue = Number(value)
                        if (numericValue) {
                            if (Number(lng)) {
                                setMarkerPos(latLng(numericValue, Number(lng)))
                            }
                            setIsLatNumber(true)
                        }
                        else {
                            setIsLatNumber(false)
                        }
                    }}
                    error={!isLatNumber}
                />
            </Box>
            <Divider orientation='vertical' variant='middle' flexItem />
            <Box sx={{ margin: 0.8 }}>
                <TextField
                    size="small"
                    value={lng}
                    InputProps={{
                        startAdornment: <InputAdornment position="start">Lng:</InputAdornment>,
                    }}
                    onChange={(e) => {
                        const value = e.target.value
                        setLng(value)
                        const numericValue = Number(value)
                        if (numericValue) {
                            if (Number(lat)) {
                                setMarkerPos(latLng(Number(lat), numericValue))
                            }
                            setIsLngNumber(true)
                        }
                        else {
                            setIsLngNumber(false)
                        }
                    }}
                    error={!isLngNumber}
                />
            </Box>
        </ControlGroup>
        {markerPos && <Marker position={markerPos} ref={markerRef} icon={icon} draggable={markerDrop} eventHandlers={eventHandlers} />}
    </>
}

export default DropPin