import ReactFlow, { Controls } from 'reactflow';
import { useMemo } from 'react';
import 'reactflow/dist/style.css';
import CenteredCircularProgress from '../../common/components/UI/general/CenteredCircularProgress';
import { useGetPipelineInstanceDetailsQuery, useGetPipelineModelDefinitionQuery } from '../../process/api/processApi';
import '../css/createProcessFlow.css';
import { layoutElements } from '../utils/layoutElements';
import { getPipelineGraph, getPipelineModelGraph } from '../utils/pipelineGraphUtils';
import { graphNodeTypes } from '../types/graphNodeTypes';


const PipelineModelGraphRender = ({ nodes, edges }: { nodes: any, edges: any }) => {
    const layedOutElements = layoutElements(nodes, edges, true);

    return <ReactFlow
        nodes={layedOutElements.nodes}
        edges={layedOutElements.edges}
        fitView
        nodeTypes={graphNodeTypes}
        className='create-process-flow'
        preventScrolling={false}
    >
        <Controls showInteractive={false} />
    </ReactFlow>
}

export const PipelineModelGraph = ({ modelId }: { modelId: string }) => {
    const { data, isLoading } = useGetPipelineModelDefinitionQuery(modelId)

    const graph = useMemo(() => {
        return data ? getPipelineModelGraph(data) : { nodes: [], edges: [] };
    }, [data]);

    return <>
        {isLoading
            ? <CenteredCircularProgress />
            : <PipelineModelGraphRender
                nodes={graph.nodes}
                edges={graph.edges}
            />
        }
    </>
}

export const PipelineInstanceGraph = ({ modelId, instanceId }: { modelId: string, instanceId: string }) => {
    const { data: modelDefinition, isLoading: modelDefinitionIsLoading } = useGetPipelineModelDefinitionQuery(modelId)
    const { data: instanceDetails, isLoading: instanceDetailsIsLoading } = useGetPipelineInstanceDetailsQuery(instanceId, { refetchOnMountOrArgChange: true})

    const graph = useMemo(() => {
        if (modelDefinition && instanceDetails){
            try {
                return getPipelineGraph(modelDefinition, instanceDetails.state_machine_definition, instanceDetails.execution_history)
            } catch (error) {
                console.error(error)
                return { nodes: [], edges: [] };
            }
        } else {
            return { nodes: [], edges: [] };
        }
    }, [modelDefinition, instanceDetails]);

    return <>
        {(modelDefinitionIsLoading && instanceDetailsIsLoading)
            ? <CenteredCircularProgress />
            : <PipelineModelGraphRender
                nodes={graph.nodes}
                edges={graph.edges}
            />
        }
    </>
}
