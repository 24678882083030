import { Navigate, Route, Routes } from 'react-router-dom';
import ProjectSelect from './views/ProjectSelect';
import RovLabel from './views/RovLabel';
import NotFound from '../app/views/NotFound';

const RovLabelRoutes = () => {
    return (
        <Routes>
            <Route index path="/" element={<Navigate to="projects" />}/>
            <Route path="/project/:projectId" element={<RovLabel />} />
            <Route path="/projects" element={<ProjectSelect />} />
            <Route path="*" element={<NotFound />} />
        </Routes>
    )
}

export default RovLabelRoutes;
