
const chileTiles = [
    {
      "id": 1,
      "name": "12JXQ",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-109.99105763799997,-27.118845825999983],[-108.88368977899995,-27.106525458999954],[-108.86456580199996,-28.097094727999945],[-109.98193386099996,-28.109942609999962],[-109.99105763799997,-27.118845825999983]]]}"
    },
    {
      "id": 2,
      "name": "12JXR",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-109.99897038499995,-26.21590286199995],[-108.90027566099997,-26.20405519999997],[-108.88202578499994,-27.194762701999935],[-109.99026377699994,-27.207129683999938],[-109.99897038499995,-26.21590286199995]]]}"
    },
    {
      "id": 3,
      "name": "13JDL",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-106.00142997699999,-26.215900073999933],[-104.90229360399996,-26.21935442199998],[-104.90144361399996,-27.21073255799996],[-106.01014006699995,-27.20712677399996],[-106.00142997699999,-26.215900073999933]]]}"
    },
    {
      "id": 27,
      "name": "17HNC",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-81.00021516799995,-33.43903388299998],[-79.81904972799998,-33.43341099299994],[-79.80530367999995,-34.423486416999935],[-81.00021767399994,-34.42932184699998],[-81.00021516799995,-33.43903388299998]]]}"
    },
    {
      "id": 28,
      "name": "17HPC",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-79.92424041299995,-33.43436809899998],[-78.74364557399997,-33.41850594199997],[-78.71740207299996,-34.40801810599993],[-79.91171814999996,-34.42447969899996],[-79.92424041299995,-33.43436809899998]]]}"
    },
    {
      "id": 29,
      "name": "17HQC",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-78.84940614899995,-33.42038541199997],[-77.66992355099995,-33.394314588999976],[-77.63123966899997,-34.382912991999945],[-78.82439033099996,-34.40996858999995],[-78.84940614899995,-33.42038541199997]]]}"
    },
    {
      "id": 30,
      "name": "17JNL",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-81.00020021799997,-26.219387620999953],[-79.90108344799995,-26.21518801299993],[-79.89152585699998,-27.20638349799998],[-81.00020195999997,-27.21076721199995],[-81.00020021799997,-26.219387620999953]]]}"
    },
    {
      "id": 31,
      "name": "17JNM",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-81.00019870799997,-25.316192143999956],[-79.90936706999997,-25.31215761699997],[-79.90025208599997,-26.30348054899997],[-81.00020036999996,-26.307696430999954],[-81.00019870799997,-25.316192143999956]]]}"
    },
    {
      "id": 32,
      "name": "17JPL",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-79.99897038499995,-26.21590286199995],[-78.90027566099997,-26.20405519999997],[-78.88202578499994,-27.194762701999935],[-79.99026377699994,-27.207129683999938],[-79.99897038499995,-26.21590286199995]]]}"
    },
    {
      "id": 70,
      "name": "18FVG",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-76.46845322999997,-52.34080855299993],[-74.85671097799997,-52.34984685999996],[-74.85342435199993,-53.33690938199993],[-76.50212008199998,-53.32754389599995],[-76.46845322999997,-52.34080855299993]]]}"
    },
    {
      "id": 71,
      "name": "18FVH",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-76.43945214999997,-51.44234525199994],[-74.85954204199999,-51.45109807799997],[-74.85642418599997,-52.43831167099995],[-76.47139104099995,-52.42924463999998],[-76.43945214999997,-51.44234525199994]]]}"
    },
    {
      "id": 72,
      "name": "18FVJ",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-76.41189775399994,-50.54319290299998],[-74.86223181699995,-50.551671031999945],[-74.85927151599998,-51.53903698499994],[-76.44222341599993,-51.53025672499996],[-76.41189775399994,-50.54319290299998]]]}"
    },
    {
      "id": 73,
      "name": "18FVK",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-76.38571231599997,-49.643890554999984],[-74.86478789899996,-49.652104193999946],[-74.86197485099996,-50.63962355899997],[-76.41453017499998,-50.63111903299995],[-76.38571231599997,-49.643890554999984]]]}"
    },
    {
      "id": 74,
      "name": "18FVL",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-76.36082319799993,-48.74497768799995],[-74.86721738999995,-48.75293650899994],[-74.86454211499995,-49.740610094999965],[-76.38823024599998,-49.732370867999975],[-76.36082319799993,-48.74497768799995]]]}"
    },
    {
      "id": 75,
      "name": "18FVM",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-76.33712083599994,-47.845375127999944],[-74.86953099199997,-47.85308786799993],[-74.86698498499999,-48.840916515999936],[-76.36320410799993,-48.832933164999986],[-76.33712083599994,-47.845375127999944]]]}"
    },
    {
      "id": 76,
      "name": "18FWE",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-75.00030620099994,-54.14810410399997],[-73.31966413199996,-54.136377427999946],[-73.27849655399996,-55.12271216499994],[-75.00031370699998,-55.13487191199994],[-75.00030620099994,-54.14810410399997]]]}"
    },
    {
      "id": 77,
      "name": "18FWF",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-75.00029974499995,-53.24908712399997],[-73.35507400999995,-53.237737451999976],[-73.31609739099997,-54.224238699999944],[-75.00030685099995,-54.236003110999945],[-75.00029974499995,-53.24908712399997]]]}"
    },
    {
      "id": 78,
      "name": "18FWG",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-75.00029362599997,-52.349933731999954],[-73.38863524199996,-52.338945638999974],[-73.35169531099996,-53.32561356299993],[-75.00030035999998,-53.33699939999997],[-75.00029362599997,-52.349933731999954]]]}"
    },
    {
      "id": 79,
      "name": "18FWH",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-75.00028782399994,-51.45118220499995],[-73.42045597899994,-51.440541164999956],[-73.38541180799996,-52.427375805999986],[-75.00029421299996,-52.438398818999985],[-75.00028782399994,-51.45118220499995]]]}"
    },
    {
      "id": 80,
      "name": "18FWJ",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-75.00028231199997,-50.551752517999944],[-73.45068952999998,-50.54144542199998],[-73.41741526699997,-51.52844698399997],[-75.00028837799994,-51.53912137699996],[-75.00028231199997,-50.551752517999944]]]}"
    },
    {
      "id": 81,
      "name": "18FWK",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-75.00027707399994,-49.65218313799994],[-73.47942115099994,-49.64219757799998],[-73.44780114799994,-50.62936611199996],[-75.00028283899996,-50.639705299999946],[-75.00027707399994,-49.65218313799994]]]}"
    },
    {
      "id": 82,
      "name": "18FWL",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-75.00027209599995,-48.75301300399997],[-73.50673052399998,-48.74333722399996],[-73.47665838099994,-49.73067261799997],[-75.00027757799995,-49.740689284999974],[-75.00027209599995,-48.75301300399997]]]}"
    },
    {
      "id": 83,
      "name": "18FWM",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-75.00026735499995,-47.85316199699997],[-73.53273784499999,-47.84378537699996],[-73.50411808599995,-48.831287646999954],[-75.00027257199997,-48.84099324699997],[-75.00026735499995,-47.85316199699997]]]}"
    },
    {
      "id": 84,
      "name": "18FXE",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-73.46929874399996,-54.138373317999935],[-71.79073991499996,-54.10530483499997],[-71.71223803899994,-55.09049329399994],[-73.43179335999997,-55.12478174499995],[-73.46929874399996,-54.138373317999935]]]}"
    },
    {
      "id": 85,
      "name": "18FXF",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-73.50155840699995,-53.23966918999997],[-71.85826952499997,-53.20766283699999],[-71.78393819799999,-54.193066219999935],[-73.46604930499996,-54.226241010999956],[-73.50155840699995,-53.23966918999997]]]}"
    },
    {
      "id": 86,
      "name": "18FXG",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-73.53213370699996,-52.34081584799998],[-71.92227946699995,-52.309828263999975],[-71.85182578699994,-53.29544321099996],[-73.49848029999998,-53.327551454999934],[-73.53213370699996,-52.34081584799998]]]}"
    },
    {
      "id": 87,
      "name": "18FXH",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-73.56112320599993,-51.44235231599998],[-71.98297499099994,-51.412342653999985],[-71.91613130299999,-52.39816598099998],[-73.52919706899996,-52.42925195799995],[-73.56112320599993,-51.44235231599998]]]}"
    },
    {
      "id": 88,
      "name": "18FXJ",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-73.58866659699999,-50.54319974499998],[-72.04064764399999,-50.51413114099995],[-71.97717486199997,-51.500160164999954],[-73.55835304699997,-51.53026381099994],[-73.58866659699999,-50.54319974499998]]]}"
    },
    {
      "id": 89,
      "name": "18FXK",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-73.61484157599995,-49.64389718299998],[-72.09545929699993,-49.61573473599998],[-72.03513765999998,-50.601966853999954],[-73.58603522799996,-50.63112589599996],[-73.61484157599995,-49.64389718299998]]]}"
    },
    {
      "id": 90,
      "name": "18FXL",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-73.63972075399994,-48.74498411099995],[-72.14756129099999,-48.717694750999954],[-72.09018855899996,-49.70412740199998],[-73.61232465199998,-49.732377516999975],[-73.63972075399994,-48.74498411099995]]]}"
    },
    {
      "id": 91,
      "name": "18FXM",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-73.66341364799996,-47.84538135299994],[-72.19718236199998,-47.81893520099993],[-72.14257701899999,-48.80556619999993],[-73.63734079399995,-48.832939608999936],[-73.66341364799996,-47.84538135299994]]]}"
    },
    {
      "id": 92,
      "name": "18FYF",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-72.00539666699996,-53.21145426199996],[-70.36587500499996,-53.15888437999996],[-70.25650219799996,-54.14251056799998],[-71.93453280799997,-54.19699596099997],[-72.00539666699996,-53.21145426199996]]]}"
    },
    {
      "id": 93,
      "name": "18FYG",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-72.06641988199993,-52.313499082999954],[-70.46007535199993,-52.26259965699995],[-70.35639281199997,-53.24650976399994],[-71.99925355899995,-53.29924669799993],[-72.06641988199993,-52.313499082999954]]]}"
    },
    {
      "id": 94,
      "name": "18FYH",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-72.12428271099998,-51.415897695999945],[-70.54941030099997,-51.36660201099994],[-70.45102681299994,-52.35078767799996],[-72.06055862199997,-52.40184844899994],[-72.12428271099998,-51.415897695999945]]]}"
    },
    {
      "id": 95,
      "name": "18FYM",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-72.32848826199995,-47.822068298999966],[-70.86478429599998,-47.77861862699996],[-70.78437645899999,-48.763837946999956],[-72.27643323399997,-48.80880909899997],[-72.32848826199995,-47.822068298999966]]]}"
    },
    {
      "id": 96,
      "name": "18GVN",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-76.31454753599996,-46.945622431999936],[-74.87173434499994,-46.95309740499994],[-74.86930974099994,-47.94108171499994],[-76.33938753099994,-47.93334528999998],[-76.31454753599996,-46.945622431999936]]]}"
    },
    {
      "id": 97,
      "name": "18GVP",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-76.29304891499999,-46.04625960899995],[-74.87383276299994,-46.05350473499993],[-74.87152227999997,-47.04164505999995],[-76.31672015699996,-47.034147048999955],[-76.29304891499999,-46.04625960899995]]]}"
    },
    {
      "id": 98,
      "name": "18GVQ",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-76.27253764199997,-45.14620721899996],[-74.87583477599998,-45.15322965599995],[-74.87363185499999,-46.14152638199994],[-76.29510726299998,-46.13425909699998],[-76.27253764199997,-45.14620721899996]]]}"
    },
    {
      "id": 99,
      "name": "18GVR",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-76.25297027999994,-44.246005382999954],[-74.87774462799996,-44.252811974999986],[-74.87564316899994,-45.24126524199994],[-76.27450072899995,-45.23422133899993],[-76.25297027999994,-44.246005382999954]]]}"
    },
    {
      "id": 100,
      "name": "18GWA",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-75.00023345299996,-39.74972731499997],[-73.71872966299998,-39.742655714999955],[-73.69994847799995,-40.73163987899994],[-75.00023687599997,-40.73896176899996],[-75.00023345299996,-39.74972731499997]]]}"
    },
    {
      "id": 101,
      "name": "18GWN",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-75.00026283999995,-46.953169248999984],[-73.55750640399998,-46.94408168099994],[-73.53025071899998,-47.93175065699995],[-75.00026780799999,-47.941156071999956],[-75.00026283999995,-46.953169248999984]]]}"
    },
    {
      "id": 102,
      "name": "18GWP",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-75.00025853999995,-46.05357436899993],[-73.58109585599993,-46.04476622599998],[-73.55512249199995,-47.03260155099997],[-75.00026327399996,-47.041717124999934],[-75.00025853999995,-46.05357436899993]]]}"
    },
    {
      "id": 103,
      "name": "18GWQ",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-75.00025443699997,-45.15329714899997],[-73.60360201099996,-45.144759730999965],[-73.57883732099998,-46.132761147999986],[-75.00025895099998,-46.14159622899996],[-75.00025443699997,-45.15329714899997]]]}"
    },
    {
      "id": 104,
      "name": "18GWR",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-75.00025052399997,-44.25287739399994],[-73.62507252199998,-44.24460237999995],[-73.60144799599993,-45.23276942799998],[-75.00025482999996,-45.241332941999985],[-75.00025052399997,-44.25287739399994]]]}"
    },
    {
      "id": 105,
      "name": "18GWS",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-75.00024679099994,-43.35285539199998],[-73.64555266999997,-43.344834815999945],[-73.62300453199998,-44.333166827999946],[-75.00025090099996,-44.341467302999945],[-75.00024679099994,-43.35285539199998]]]}"
    },
    {
      "id": 106,
      "name": "18GWT",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-75.00024322399997,-42.452150840999934],[-73.66511990399994,-42.44437751299995],[-73.64359052699996,-43.43287390699993],[-75.00024714799997,-43.440919045999976],[-75.00024322399997,-42.452150840999934]]]}"
    },
    {
      "id": 107,
      "name": "18GWU",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-75.00023981699997,-41.551304226999946],[-73.68381053299998,-41.54377123499995],[-73.66324603799995,-42.53243121199995],[-75.00024356599994,-42.54022840499994],[-75.00023981699997,-41.551304226999946]]]}"
    },
    {
      "id": 108,
      "name": "18GWV",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-75.00023656399998,-40.65085651499993],[-73.70165933799996,-40.64355721399994],[-73.68200932199994,-41.63237975599998],[-75.00024014599995,-41.639936097999964],[-75.00023656399998,-40.65085651499993]]]}"
    },
    {
      "id": 109,
      "name": "18GXA",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-73.83285116499997,-39.74385939599995],[-72.55213413399997,-39.72391172199997],[-72.51628509099999,-40.71223272699996],[-73.81574180899997,-40.732886159999964],[-73.83285116499997,-39.74385939599995]]]}"
    },
    {
      "id": 110,
      "name": "18GXN",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-73.68597793099997,-46.94562846499997],[-72.24444275499997,-46.919997078999984],[-72.19243687799997,-47.90682421699995],[-73.66114785799994,-47.93335153299995],[-73.68597793099997,-46.94562846499997]]]}"
    },
    {
      "id": 111,
      "name": "18GXP",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-73.70746796399999,-46.04626545599996],[-72.28945585199995,-46.02142174199997],[-72.23989393999994,-47.00844276799995],[-73.68380617799994,-47.03415310099996],[-73.70746796399999,-46.04626545599996]]]}"
    },
    {
      "id": 112,
      "name": "18GXQ",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-73.72797104299997,-45.14621288699993],[-72.33240408399996,-45.122132361999945],[-72.28514603899998,-46.109345308999934],[-73.70541043799994,-46.134264961999975],[-73.72797104299997,-45.14621288699993]]]}"
    },
    {
      "id": 113,
      "name": "18GXR",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-73.74753058899995,-44.24601087699995],[-72.37337803599996,-44.222670118999986],[-72.32829350699996,-45.210072931999946],[-73.72600874099999,-45.23422702399995],[-73.74753058899995,-44.24601087699995]]]}"
    },
    {
      "id": 114,
      "name": "18GXS",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-73.76618786199998,-43.34620000899997],[-72.41246381599996,-43.32357659599995],[-72.36943143399998,-44.311167119999936],[-73.74564666099997,-44.33457965799994],[-73.76618786199998,-43.34620000899997]]]}"
    },
    {
      "id": 115,
      "name": "18GXT",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-73.78401342699993,-42.44570062599996],[-72.44980895899994,-42.42377431299997],[-72.40871904599999,-43.41155061499995],[-73.76440036599996,-43.434243280999965],[-73.78401342699993,-42.44570062599996]]]}"
    },
    {
      "id": 116,
      "name": "18GXU",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-73.80104036799997,-41.54505344399996],[-72.48548252599994,-41.52380477299994],[-72.44623251499996,-42.51176478799994],[-73.78230635499995,-42.53375838599993],[-73.80104036799997,-41.54505344399996]]]}"
    },
    {
      "id": 117,
      "name": "18GXV",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-73.81730037699998,-40.644799648999935],[-72.51955067999995,-40.624209908999944],[-72.48204461199998,-41.61235143199997],[-73.79939948899994,-41.63366593999996],[-73.81730037699998,-40.644799648999935]]]}"
    },
    {
      "id": 118,
      "name": "18GYA",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-72.66685174799994,-39.72627516699998],[-71.38766764199994,-39.69349357699997],[-71.33484245399995,-40.680739193999955],[-72.63267885499994,-40.71467976899993],[-72.66685174799994,-39.72627516699998]]]}"
    },
    {
      "id": 119,
      "name": "18GYN",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-72.37354102399996,-46.92303369299998],[-70.93438361999995,-46.880921083999965],[-70.85779609099995,-47.866384068999935],[-72.32396443099998,-47.90996692699997],[-72.37354102399996,-46.92303369299998]]]}"
    },
    {
      "id": 120,
      "name": "18GYP",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-72.41645115999995,-46.024365074999935],[-71.00067943999994,-45.983545205999974],[-70.92768439799994,-46.96924655899994],[-72.36920470699994,-47.01148872999994],[-72.41645115999995,-46.024365074999935]]]}"
    },
    {
      "id": 121,
      "name": "18GYQ",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-72.45739262799998,-45.12498531099993],[-71.06393950599994,-45.08541812799996],[-70.99433164399994,-46.07135312799994],[-72.41234270899997,-46.11229763499995],[-72.45739262799998,-45.12498531099993]]]}"
    },
    {
      "id": 122,
      "name": "18GYR",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-72.49645182099994,-44.22543545499997],[-71.12429642299998,-44.187082627999985],[-71.05788465599994,-45.17324665199993],[-72.45347412899997,-45.21293459399993],[-72.49645182099994,-44.22543545499997]]]}"
    },
    {
      "id": 123,
      "name": "18GYS",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-72.53371085899994,-43.326256970999964],[-71.18187629599998,-43.289081797999984],[-71.11848265399993,-44.275470294999934],[-72.49268965799996,-44.31394095699994],[-72.53371085899994,-43.326256970999964]]]}"
    },
    {
      "id": 124,
      "name": "18GYT",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-72.56931041599995,-42.426372122999965],[-71.23689582199995,-42.39034146599994],[-71.17635944299997,-43.37695032499994],[-72.53014111599998,-43.41423919299996],[-72.56931041599995,-42.426372122999965]]]}"
    },
    {
      "id": 125,
      "name": "18GYU",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-72.60331635299997,-41.52632231999996],[-71.28945616999994,-41.49140432099995],[-71.23162658499996,-42.478229434999946],[-72.56590114999995,-42.51437056699996],[-72.60331635299997,-41.52632231999996]]]}"
    },
    {
      "id": 126,
      "name": "18GYV",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-72.63579176599995,-40.626649406999945],[-71.33965430799998,-40.59281342199995],[-71.28439070399997,-41.57985067399994],[-72.60003915799996,-41.61487677699995],[-72.63579176599995,-40.626649406999945]]]}"
    },
    {
      "id": 127,
      "name": "18HWC",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-75.00022763499999,-37.94758957199997],[-73.75064570799998,-37.94095622999998],[-73.73347616099994,-38.93025991099995],[-75.00023076499997,-38.93713117599998],[-75.00022763499999,-37.94758957199997]]]}"
    },
    {
      "id": 128,
      "name": "18HXB",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-73.84771819899998,-38.84277401899993],[-72.58328606899994,-38.823452129999964],[-72.54901240699996,-39.81195042999997],[-73.83136131399993,-39.831960152999955],[-73.84771819899998,-38.84277401899993]]]}"
    },
    {
      "id": 129,
      "name": "18HXC",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-73.86192606799995,-37.94208531999993],[-72.61305784199999,-37.923373494999964],[-72.58028284699998,-38.91204673799996],[-73.84628495599998,-38.931429495999964],[-73.86192606799995,-37.94208531999993]]]}"
    },
    {
      "id": 130,
      "name": "18HXD",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-73.87552242899994,-37.040713289999985],[-72.64154916799998,-37.02259739799996],[-72.61020324299994,-38.01144337699998],[-73.86056379899998,-38.03021423699994],[-73.87552242899994,-37.040713289999985]]]}"
    },
    {
      "id": 131,
      "name": "18HXE",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-73.88852705399995,-36.13919988899994],[-72.66880134899998,-36.12166623099995],[-72.63881886099995,-37.11068255999993],[-73.87421951599998,-37.128856105999944],[-73.88852705399995,-36.13919988899994]]]}"
    },
    {
      "id": 132,
      "name": "18HXF",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-73.90095918799994,-35.23808753499998],[-72.69485459299995,-35.221122830999946],[-72.66617363699999,-36.210306945999946],[-73.88727313799995,-36.22789729799996],[-73.90095918799994,-35.23808753499998]]]}"
    },
    {
      "id": 133,
      "name": "18HYB",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-72.69654701499996,-38.82574144899996],[-71.43357413099994,-38.79398758899998],[-71.38306750399994,-39.781437737999966],[-72.66387598699998,-39.81432122499996],[-72.69654701499996,-38.82574144899996]]]}"
    },
    {
      "id": 134,
      "name": "18HYC",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-72.72492653899997,-37.925590546999956],[-71.47744908499993,-37.89483865899996],[-71.42914837799998,-38.882489437999936],[-72.69368422899998,-38.914343265999946],[-72.72492653899997,-37.925590546999956]]]}"
    },
    {
      "id": 135,
      "name": "18HYD",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-72.75208538999993,-37.02474385599993],[-71.51943912499996,-36.99497078799993],[-71.47324213599995,-37.98281857099994],[-72.72220543699996,-38.01366742199997],[-72.75208538999993,-37.02474385599993]]]}"
    },
    {
      "id": 136,
      "name": "18HYE",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-72.77806295199997,-36.12374371699997],[-71.55960482799998,-36.09492702299997],[-71.51541515599996,-37.08296808099993],[-72.74948277699997,-37.112835848999964],[-72.77806295199997,-36.12374371699997]]]}"
    },
    {
      "id": 137,
      "name": "18HYF",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-72.80289756699995,-35.22313291699993],[-71.59800518599997,-35.195250828999974],[-71.55573188799997,-36.183481325999935],[-72.77555814599998,-36.21239114799994],[-72.80289756699995,-35.22313291699993]]]}"
    },
    {
      "id": 138,
      "name": "18HYG",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-72.82666836599998,-34.32183252799996],[-71.63476229499997,-34.29486555699998],[-71.59432278799994,-35.28328187899996],[-72.80051610399994,-35.311254505999955],[-72.82666836599998,-34.32183252799996]]]}"
    },
    {
      "id": 139,
      "name": "18HYH",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-72.84940614899995,-33.42038541199997],[-71.66992355099995,-33.394314588999976],[-71.63123966899997,-34.382912991999945],[-72.82439033099996,-34.40996858999995],[-72.84940614899995,-33.42038541199997]]]}"
    },
    {
      "id": 140,
      "name": "18HYJ",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-72.87114115299994,-32.519334871999945],[-71.70353549599997,-32.49414172699994],[-71.66653325399994,-33.482918336999944],[-72.84721378199998,-33.50907651199998],[-72.87114115299994,-32.519334871999945]]]}"
    },
    {
      "id": 192,
      "name": "19EET",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-69.00032022499994,-55.945195300999956],[-67.24274870099998,-55.93266362199995],[-67.19667039999996,-56.918665922999935],[-69.00032862799998,-56.93167192299995],[-69.00032022499994,-55.945195300999956]]]}"
    },
    {
      "id": 194,
      "name": "19EFT",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-67.39922534899995,-55.93479648799996],[-65.64407822499999,-55.89946041099995],[-65.55623172299994,-56.88420754799995],[-67.35724548999997,-56.92087949499995],[-67.39922534899995,-55.93479648799996]]]}"
    },
    {
      "id": 195,
      "name": "19FCA",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-71.99520147799996,-53.21143921699996],[-70.35164149399998,-53.241424353999946],[-70.38367464199996,-54.22806030299995],[-72.06607943699998,-54.19698036699998],[-71.99520147799996,-53.21143921699996]]]}"
    },
    {
      "id": 196,
      "name": "19FCB",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-71.93416611699996,-52.31348451699995],[-70.32405933799998,-52.34251510299998],[-70.35441827999995,-53.329312209999955],[-72.00134580899999,-53.29923160499993],[-71.93416611699996,-52.31348451699995]]]}"
    },
    {
      "id": 197,
      "name": "19FCC",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-71.87629176799999,-51.415883588999975],[-70.29790787299999,-51.44399790699998],[-70.32670848999999,-52.43095661199993],[-71.94002854399997,-52.40183383599998],[-71.87629176799999,-51.415883588999975]]]}"
    },
    {
      "id": 198,
      "name": "19FCU",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-72.12755410799997,-55.00624517999995],[-70.41146072599997,-55.038263890999986],[-70.44723509399995,-56.02457946399994],[-72.20669380099997,-55.99136550299994],[-72.12755410799997,-55.00624517999995]]]}"
    },
    {
      "id": 199,
      "name": "19FCV",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-72.05959368299995,-54.10920644799995],[-70.38074327899994,-54.14018677499996],[-70.41457748999994,-55.12666216399998],[-72.13444936199994,-55.09453873899997],[-72.05959368299995,-54.10920644799995]]]}"
    },
    {
      "id": 200,
      "name": "19FDA",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-70.49904074599993,-53.23966165499996],[-68.85372496699995,-53.24899739299997],[-68.85025701399996,-54.235910099999955],[-70.53456403199993,-54.226233201999946],[-70.49904074599993,-53.23966165499996]]]}"
    },
    {
      "id": 201,
      "name": "19FDB",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-70.46845322999997,-52.34080855299993],[-68.85671097799997,-52.34984685999996],[-68.85342435199993,-53.33690938199993],[-70.50212008199998,-53.32754389599995],[-70.46845322999997,-52.34080855299993]]]}"
    },
    {
      "id": 202,
      "name": "19FDC",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-70.43945214999997,-51.44234525199994],[-68.85954204199999,-51.45109807799997],[-68.85642418599997,-52.43831167099995],[-70.47139104099995,-52.42924463999998],[-70.43945214999997,-51.44234525199994]]]}"
    },
    {
      "id": 203,
      "name": "19FDU",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-70.56537734299997,-55.036381560999985],[-68.84724877799994,-55.04635102599997],[-68.84337559399995,-56.032968808999954],[-70.60504899099993,-56.02262681099995],[-70.56537734299997,-55.036381560999985]]]}"
    },
    {
      "id": 204,
      "name": "19FDV",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-70.53131329499996,-54.138365532999956],[-68.85057437099994,-54.148011390999955],[-68.84691133999996,-55.134775773999934],[-70.56883365999994,-55.124773672999936],[-70.53131329499996,-54.138365532999956]]]}"
    },
    {
      "id": 205,
      "name": "19FEA",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-69.00029974499995,-53.24908712399997],[-67.35507400999995,-53.237737451999976],[-67.31609739099997,-54.224238699999944],[-69.00030685099995,-54.236003110999945],[-69.00029974499995,-53.24908712399997]]]}"
    },
    {
      "id": 206,
      "name": "19FEB",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-69.00029362599997,-52.349933731999954],[-67.38863524199996,-52.338945638999974],[-67.35169531099996,-53.32561356299993],[-69.00030035999998,-53.33699939999997],[-69.00029362599997,-52.349933731999954]]]}"
    },
    {
      "id": 207,
      "name": "19FEC",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-69.00028782399994,-51.45118220499995],[-67.42045597899994,-51.440541164999956],[-67.38541180799996,-52.427375805999986],[-69.00029421299996,-52.438398818999985],[-69.00028782399994,-51.45118220499995]]]}"
    },
    {
      "id": 208,
      "name": "19FEU",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-69.00031301499996,-55.04644684999994],[-67.28228883399998,-55.03432677699993],[-67.23876115299998,-56.02049526999997],[-69.00032095199998,-56.03306821499996],[-69.00031301499996,-55.04644684999994]]]}"
    },
    {
      "id": 209,
      "name": "19FEV",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-69.00030620099994,-54.14810410399997],[-67.31966413199996,-54.136377427999946],[-67.27849655399996,-55.12271216499994],[-69.00031370699998,-55.13487191199994],[-69.00030620099994,-54.14810410399997]]]}"
    },
    {
      "id": 210,
      "name": "19FFU",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-67.43524829699999,-55.03638960699993],[-65.71946911299995,-55.00221291099996],[-65.63647566199995,-55.98718285199993],[-67.39559248799998,-56.02263515799996],[-67.43524829699999,-55.03638960699993]]]}"
    },
    {
      "id": 211,
      "name": "19FFV",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-67.46929874399996,-54.138373317999935],[-65.79073991499996,-54.10530483499997],[-65.71223803899994,-55.09049329399994],[-67.43179335999997,-55.12478174499995],[-67.46929874399996,-54.138373317999935]]]}"
    },
    {
      "id": 212,
      "name": "19GBP",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-72.59409469499997,-41.49510705299997],[-71.28003291399995,-41.52869548499996],[-71.31563109299998,-42.51682691099995],[-72.65011931099997,-42.482061803999954],[-72.59409469499997,-41.49510705299997]]]}"
    },
    {
      "id": 213,
      "name": "19GBQ",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-72.54546370199995,-40.596401467999954],[-71.24913477999996,-40.62894899299994],[-71.28315094799996,-41.617257292999966],[-72.59900203999996,-41.58356486399998],[-72.54546370199995,-40.596401467999954]]]}"
    },
    {
      "id": 214,
      "name": "19GBR",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-72.49894961899997,-39.69696986099996],[-71.21958345999997,-39.728503056999955],[-71.25209649599998,-40.71698646799996],[-72.55012531899996,-40.68433832699998],[-72.49894961899997,-39.69696986099996]]]}"
    },
    {
      "id": 215,
      "name": "19GCK",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-71.54311549299996,-45.12497399099993],[-70.14739654699997,-45.147533188999944],[-70.16774796099998,-46.13563129399995],[-71.58817438999995,-46.11228591999998],[-71.54311549299996,-45.12497399099993]]]}"
    },
    {
      "id": 216,
      "name": "19GCL",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-71.50404851499997,-44.22542448199994],[-70.12975237399996,-44.247290601999964],[-70.14916669299998,-45.23555136199997],[-71.54703477299995,-45.21292323899996],[-71.50404851499997,-44.22542448199994]]]}"
    },
    {
      "id": 217,
      "name": "19HBA",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-72.29518354399994,-35.19820772199995],[-71.09014717199994,-35.22502769799996],[-71.11615817499995,-36.21435579799993],[-72.33613566699995,-36.186547175999976],[-72.29518354399994,-35.19820772199995]]]}"
    },
    {
      "id": 218,
      "name": "19HBB",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-72.25957539199999,-34.29772542999996],[-71.06753146699998,-34.32366509899998],[-71.09241291599994,-35.31315544199998],[-72.29875084699995,-35.28624837099994],[-72.25957539199999,-34.29772542999996]]]}"
    },
    {
      "id": 219,
      "name": "19HBC",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-72.22551336499998,-33.39707944999998],[-71.04589864299999,-33.422157064999965],[-71.06969880199995,-34.411807185999976],[-72.26298789999998,-34.385782261999964],[-72.22551336499998,-33.39707944999998]]]}"
    },
    {
      "id": 220,
      "name": "19HBD",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-72.19295235399994,-32.496813531999976],[-71.02521992499999,-32.52104686399997],[-71.04798446699994,-33.51085410299993],[-72.22879766599993,-33.48569245999994],[-72.19295235399994,-32.496813531999976]]]}"
    },
    {
      "id": 221,
      "name": "19HBE",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-72.16179328199996,-31.595850284999983],[-71.00543227899993,-31.619255161999945],[-71.02720267799998,-32.609216946999936],[-72.19607447999994,-32.58490174699995],[-72.16179328199996,-31.595850284999983]]]}"
    },
    {
      "id": 222,
      "name": "19HBS",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-72.45447687799998,-38.79735493199996],[-71.19133068499997,-38.82789945899998],[-71.22241467599997,-39.81655604399998],[-72.50340610099994,-39.78492482299998],[-72.45447687799998,-38.79735493199996]]]}"
    },
    {
      "id": 223,
      "name": "19HBT",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-72.41197247899999,-37.89809978699998],[-71.16432984099998,-37.92768043299998],[-71.19405440099996,-38.91650807399998],[-72.45876439399996,-38.88586737799994],[-72.41197247899999,-37.89809978699998]]]}"
    },
    {
      "id": 224,
      "name": "19HBU",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-72.37129434499997,-36.998128151999936],[-71.13849046599995,-37.026767193999945],[-71.16691874799994,-38.01576389999997],[-72.41604800099998,-37.98608997799994],[-72.37129434499997,-36.998128151999936]]]}"
    },
    {
      "id": 225,
      "name": "19HBV",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-72.33238375999997,-36.09798299999994],[-71.11377507299994,-36.12570203599995],[-71.14096662999998,-37.11486562399995],[-72.37519258199995,-37.08613548499994],[-72.33238375999997,-36.09798299999994]]]}"
    },
    {
      "id": 226,
      "name": "19HCA",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-71.19754165299997,-35.223124939999934],[-69.99134995099996,-35.23901758399995],[-70.00369553099995,-36.22886165399996],[-71.22488652899995,-36.21238287799997],[-71.19754165299997,-35.223124939999934]]]}"
    },
    {
      "id": 227,
      "name": "19HCB",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-71.17376611199995,-34.32182481399997],[-69.98061613599998,-34.337195581999936],[-69.99242532599999,-35.327190781999946],[-71.19992359099996,-35.31124650399994],[-71.17376611199995,-34.32182481399997]]]}"
    },
    {
      "id": 228,
      "name": "19HCC",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-71.15102379199993,-33.420377954999935],[-69.97034903499997,-33.43523769199993],[-69.98164478099994,-34.42538215999997],[-71.17604460099994,-34.40996085099994],[-71.15102379199993,-33.420377954999935]]]}"
    },
    {
      "id": 229,
      "name": "19HCD",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-71.12928445099993,-32.519327664999935],[-69.96053496099995,-32.53368692599997],[-69.97133897399993,-33.52397857699998],[-71.15321659599994,-33.50906902899993],[-71.12928445099993,-32.519327664999935]]]}"
    },
    {
      "id": 230,
      "name": "19HCE",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-71.10848183099995,-31.617594750999956],[-69.95114396999998,-31.63146293999995],[-69.96147596299994,-32.62189972999994],[-71.13136890299995,-32.60749193899994],[-71.10848183099995,-31.617594750999956]]]}"
    },
    {
      "id": 231,
      "name": "19HCS",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-71.30391342199994,-38.825732364999965],[-70.03937641399995,-38.84383332899995],[-70.05413133999997,-39.83305718399998],[-71.33659096499997,-39.814311816999975],[-71.30391342199994,-38.825732364999965]]]}"
    },
    {
      "id": 232,
      "name": "19HCT",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-71.27552823599996,-37.925581749999935],[-70.02656005899996,-37.94311117399997],[-70.04066928699996,-38.93249214399998],[-71.30677677799997,-38.91433415399996],[-71.27552823599996,-37.925581749999935]]]}"
    },
    {
      "id": 233,
      "name": "19HCU",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-71.24836396799998,-37.02473533899996],[-70.01429534399995,-37.04170646499995],[-70.02778890699994,-38.03124332899995],[-71.27824988099997,-38.01365859699996],[-71.24836396799998,-37.02473533899996]]]}"
    },
    {
      "id": 234,
      "name": "19HCV",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-71.22238122299996,-36.12373547399994],[-70.00256442799997,-36.14016113599996],[-70.01547064599998,-37.12985244299995],[-71.25096709999997,-37.11282730399995],[-71.22238122299996,-36.12373547399994]]]}"
    },
    {
      "id": 235,
      "name": "19HDB",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-70.08757629999997,-34.33629221799998],[-68.89388665499996,-34.341076373999954],[-68.89260852099994,-35.33121640799993],[-70.10067297299997,-35.32625370499994],[-70.08757629999997,-34.33629221799998]]]}"
    },
    {
      "id": 236,
      "name": "19HDC",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-70.07618982499997,-33.434364365999954],[-68.89499787799997,-33.43898943199997],[-68.89377532299994,-34.42927571599995],[-70.08871709299996,-34.424475824999945],[-70.07618982499997,-33.434364365999954]]]}"
    },
    {
      "id": 237,
      "name": "19HDD",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-70.06530575299996,-32.53284301899998],[-68.89606006299994,-32.53731228299995],[-68.89489073599998,-33.52774289699994],[-70.07728769299996,-33.52310232399998],[-70.06530575299996,-32.53284301899998]]]}"
    },
    {
      "id": 238,
      "name": "19JBF",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-72.13200001499996,-30.694733439999936],[-70.98651265399997,-30.717325329999937],[-71.00732827699994,-31.707438889999935],[-72.16477890799996,-31.68395370099995],[-72.13200001499996,-30.694733439999936]]]}"
    },
    {
      "id": 239,
      "name": "19JBG",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-72.10353674999999,-29.794007146999945],[-70.96843822299996,-29.815801161999957],[-70.98833643199998,-30.80606353199994],[-72.13487201399994,-30.78339232299993],[-72.10353674999999,-29.794007146999945]]]}"
    },
    {
      "id": 240,
      "name": "19JBH",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-72.07632020599993,-28.89259359999994],[-70.95115601399993,-28.913603113999955],[-70.97017070999993,-29.90401140499995],[-72.10626507799998,-29.882139949999953],[-72.07632020599993,-28.89259359999994]]]}"
    },
    {
      "id": 241,
      "name": "19JBJ",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-72.05032184499998,-27.991036962999942],[-70.93464783099995,-28.011275094999974],[-70.95281127599998,-29.001826218999952],[-72.07892699799999,-28.980740585999968],[-72.05032184499998,-27.991036962999942]]]}"
    },
    {
      "id": 242,
      "name": "19JBK",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-72.02551312499997,-27.08988180299997],[-70.91889547899996,-27.109361416999946],[-70.93623836799998,-28.10005210099996],[-72.05282677799994,-28.07973864199994],[-72.02551312499997,-27.08988180299997]]]}"
    },
    {
      "id": 243,
      "name": "19JBL",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-72.00182391499999,-26.18804997199993],[-70.90385436499997,-26.206782356999952],[-70.92040449599995,-27.19760938899998],[-72.02788973799994,-27.178056090999974],[-72.00182391499999,-26.18804997199993]]]}"
    },
    {
      "id": 244,
      "name": "19JCF",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-71.08859170599999,-30.715722606999975],[-69.94216509699999,-30.729108908999933],[-69.95204378099999,-31.71968857199994],[-71.11047508299998,-31.70577277899997],[-71.08859170599999,-30.715722606999975]]]}"
    },
    {
      "id": 245,
      "name": "19JCG",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-71.06959008499996,-29.814255052999954],[-69.93358748399999,-29.827168443999938],[-69.94303061799997,-30.817888496999956],[-71.09050903899998,-30.80445518099998],[-71.06959008499996,-29.814255052999954]]]}"
    },
    {
      "id": 246,
      "name": "19JCH",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-71.05142128499995,-28.912112669999942],[-69.92538597199996,-28.924561091999976],[-69.93440966699995,-29.915419090999933],[-71.07141144799994,-29.902459801999953],[-71.05142128499995,-28.912112669999942]]]}"
    },
    {
      "id": 247,
      "name": "19JCJ",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-71.03406618299994,-28.009839382999928],[-69.91755190699996,-28.02183062599994],[-69.92617149399996,-29.01282391099994],[-71.05316146499996,-29.00033037399993],[-71.03406618299994,-28.009839382999928]]]}"
    },
    {
      "id": 248,
      "name": "19JCK",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-71.01750565499998,-27.107979523999973],[-69.91007663599999,-27.11952122599996],[-69.91830670099995,-28.110646930999962],[-71.03573832099994,-28.098611044999927],[-71.01750565499998,-27.107979523999973]]]}"
    },
    {
      "id": 249,
      "name": "19JCL",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-71.00169282499996,-26.20545348099995],[-69.90293898299996,-26.21655234499997],[-69.91079273499997,-27.207807638999952],[-71.01909209399997,-27.19622226799993],[-71.00169282499996,-26.20545348099995]]]}"
    },
    {
      "id": 250,
      "name": "19JCM",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-70.98661256299994,-25.30280569499996],[-69.89613210099998,-25.31346832099996],[-69.90362213699996,-26.304850167999973],[-71.00320630099998,-26.293708298999945],[-70.98661256299994,-25.30280569499996]]]}"
    },
    {
      "id": 251,
      "name": "19JCN",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-70.97224957699996,-24.40058099299995],[-69.88964907299999,-24.410813874999974],[-69.89678740099998,-25.402319045999946],[-70.98806435199998,-25.391613785999937],[-70.97224957699996,-24.40058099299995]]]}"
    },
    {
      "id": 252,
      "name": "19JDG",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-70.03542020099997,-29.82640952299994],[-68.89897657999995,-29.83042869299993],[-68.89795455799998,-30.821280033999983],[-70.04589292899993,-30.81709901599993],[-70.03542020099997,-29.82640952299994]]]}"
    },
    {
      "id": 253,
      "name": "19JDH",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-70.02632445999996,-28.923829500999943],[-68.89986421799995,-28.927703932999975],[-68.89888759599995,-29.918690930999958],[-70.03633202799995,-29.91465747199993],[-70.02632445999996,-28.923829500999943]]]}"
    },
    {
      "id": 254,
      "name": "19JDJ",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-70.01763622099998,-28.021125904999963],[-68.90071208399996,-28.024858026999937],[-68.89977920199993,-29.015978142999984],[-70.02719562999994,-29.01208966699994],[-70.01763622099998,-28.021125904999963]]]}"
    },
    {
      "id": 255,
      "name": "19JDK",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-70.00934588799998,-27.118842926999946],[-68.90152111499998,-27.12243511799994],[-68.90063039499995,-28.113685604999944],[-70.01847331299996,-28.10993958599994],[-70.00934588799998,-27.118842926999946]]]}"
    },
    {
      "id": 256,
      "name": "19JDL",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-70.00142997699999,-26.215900073999933],[-68.90229360399996,-26.21935442199998],[-68.90144361399996,-27.21073255799996],[-70.01014006699995,-27.20712677399996],[-70.00142997699999,-26.215900073999933]]]}"
    },
    {
      "id": 257,
      "name": "19JDM",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-69.99388089699994,-25.31284168899998],[-68.90303029099994,-25.31616025099993],[-68.90221966799999,-26.30766310299998],[-70.00218761999997,-26.304195368999956],[-69.99388089699994,-25.31284168899998]]]}"
    },
    {
      "id": 258,
      "name": "19JDN",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-69.98669097699997,-24.410212500999933],[-68.90373192599998,-24.41339729899994],[-68.90295936999996,-25.405021740999928],[-69.99460764899999,-25.40168990899997],[-69.98669097699997,-24.410212500999933]]]}"
    },
    {
      "id": 259,
      "name": "19JEK",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-69.00020180099995,-27.122469640999952],[-67.89239730899993,-27.11810245299995],[-67.88238178599994,-28.109167403999948],[-69.00020362599997,-28.113721606999945],[-69.00020180099995,-27.122469640999952]]]}"
    },
    {
      "id": 260,
      "name": "19JEL",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-69.00020021799997,-26.219387620999953],[-67.90108344799995,-26.21518801299993],[-67.89152585699998,-27.20638349799998],[-69.00020195999997,-27.21076721199995],[-69.00020021799997,-26.219387620999953]]]}"
    },
    {
      "id": 261,
      "name": "19JEM",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-69.00019870799997,-25.316192143999956],[-67.90936706999997,-25.31215761699997],[-67.90025208599997,-26.30348054899997],[-69.00020036999996,-26.307696430999954],[-69.00019870799997,-25.316192143999956]]]}"
    },
    {
      "id": 262,
      "name": "19JEN",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-69.00019727099993,-24.41342790699997],[-67.91725659299993,-24.40955600199993],[-67.90856960399998,-25.401003101999947],[-69.00019885399996,-25.405053761999966],[-69.00019727099993,-24.41342790699997]]]}"
    },
    {
      "id": 263,
      "name": "19KCA",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-70.88041617799996,-17.175687066999956],[-69.84820026699998,-17.182666994999977],[-69.85286754399993,-18.17503208499994],[-70.89075732199996,-18.167622476999952],[-70.88041617799996,-17.175687066999956]]]}"
    },
    {
      "id": 264,
      "name": "19KCP",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-70.95856442699994,-23.49769959899993],[-69.88347208399995,-23.50750837499993],[-69.89026954399998,-24.49913367199997],[-70.97362422299994,-24.488859008999952],[-70.95856442699994,-23.49769959899993]]]}"
    },
    {
      "id": 265,
      "name": "19KCQ",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-70.94554514399994,-22.594706447999954],[-69.87759571699996,-22.60409668999995],[-69.88406266499999,-23.59583872299993],[-70.95987287099996,-23.58598872799996],[-70.94554514399994,-22.594706447999954]]]}"
    },
    {
      "id": 266,
      "name": "19KCR",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-70.93317947999998,-21.692146830999945],[-69.87201443399994,-21.701124044999972],[-69.87816075499995,-22.692979244999947],[-70.94679701099994,-22.683548065999958],[-70.93317947999998,-21.692146830999945]]]}"
    },
    {
      "id": 267,
      "name": "19KCS",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-70.92143436699996,-20.78894083499995],[-69.86671329899997,-20.79750973199998],[-69.87254787299997,-21.78947455699995],[-70.93436135199994,-21.780457155999954],[-70.92143436699996,-20.78894083499995]]]}"
    },
    {
      "id": 268,
      "name": "19KCT",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-70.91030036099994,-19.88563385499998],[-69.86168803899994,-19.893799110999964],[-69.86721935699995,-20.885869843999956],[-70.92255558699998,-20.877261231999967],[-70.91030036099994,-19.88563385499998]]]}"
    },
    {
      "id": 269,
      "name": "19KCU",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-70.89976765999995,-18.982771612999954],[-69.85693422299994,-18.990537866999944],[-69.86217039699994,-19.98271062899994],[-70.91136908099998,-19.974505861999944],[-70.89976765999995,-18.982771612999954]]]}"
    },
    {
      "id": 270,
      "name": "19KCV",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-70.88980873299994,-18.079274075999933],[-69.85243941399995,-18.086645225999973],[-69.85738767999999,-19.07891615899996],[-70.90077235999996,-19.07111105299998],[-70.88980873299994,-18.079274075999933]]]}"
    },
    {
      "id": 271,
      "name": "19KDA",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-69.94072256499999,-17.182256800999937],[-68.90821772499999,-17.184429121999926],[-68.90771261299994,-18.17690269299993],[-69.94589877599998,-18.174596638999958],[-69.94072256499999,-17.182256800999937]]]}"
    },
    {
      "id": 272,
      "name": "19KDP",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-69.97984045899994,-23.506931926999982],[-68.90440043699994,-23.50998471799994],[-68.90366477399994,-24.50172764499996],[-69.98737910299997,-24.498529841999982],[-69.97984045899994,-23.506931926999982]]]}"
    },
    {
      "id": 273,
      "name": "19KDQ",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-69.97332333899993,-22.603544839999927],[-68.90503641099997,-22.606467359999954],[-68.90433652099995,-23.59832547299993],[-69.98049543699995,-23.59525985199997],[-69.97332333899993,-22.603544839999927]]]}"
    },
    {
      "id": 274,
      "name": "19KDR",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-69.96713347299993,-21.700596469999937],[-68.90564044699994,-21.70339043399997],[-68.90497525899997,-22.695360250999954],[-69.97394998799996,-22.69242498899996],[-69.96713347299993,-21.700596469999937]]]}"
    },
    {
      "id": 275,
      "name": "19KDS",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-69.96125429899996,-20.797006153999973],[-68.90621416299996,-20.799673028999962],[-68.90558271599997,-21.79175109199997],[-69.96772507799994,-21.78894461899995],[-69.96125429899996,-20.797006153999973]]]}"
    },
    {
      "id": 276,
      "name": "19KDT",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-69.95568107799994,-19.893319254999938],[-68.90675802099997,-19.89586049899998],[-68.90615939499997,-20.888043168999957],[-69.96181553799994,-20.88536393199996],[-69.95568107799994,-19.893319254999938]]]}"
    },
    {
      "id": 277,
      "name": "19KDU",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-69.95040889599994,-18.99008146099993],[-68.90727249999998,-18.992498517999934],[-68.90670581799998,-19.984781991999967],[-69.95621603299998,-19.98222845099997],[-69.95040889599994,-18.99008146099993]]]}"
    },
    {
      "id": 278,
      "name": "19KDV",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-69.94542396299994,-18.086212039999964],[-68.90775894699993,-18.088506123999935],[-68.90722342499998,-19.080886618999955],[-69.95091179999997,-19.078457468999943],[-69.94542396299994,-18.086212039999964]]]}"
    },
    {
      "id": 279,
      "name": "19KEP",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-69.00019590099998,-23.510014057999967],[-67.92477369499994,-23.506302637999966],[-67.91650150999993,-24.49787066199997],[-69.00019740799996,-24.501758377999977],[-69.00019590099998,-23.510014057999967]]]}"
    },
    {
      "id": 280,
      "name": "19KEQ",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-69.00019459799995,-22.606495447999976],[-67.93192496499995,-22.602942403999975],[-67.92405498599999,-23.594627918999947],[-69.00019603199996,-23.598354934999975],[-69.00019459799995,-22.606495447999976]]]}"
    },
    {
      "id": 281,
      "name": "19KER",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-69.00019335999997,-21.703417284999944],[-67.93871714099998,-21.70002053299993],[-67.93123733899995,-22.691819926999983],[-69.00019472299994,-22.695388460999936],[-69.00019335999997,-21.703417284999944]]]}"
    },
    {
      "id": 282,
      "name": "19KES",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-69.00019218399996,-20.799698659999933],[-67.94516839799996,-20.796456413999977],[-67.93806796899997,-21.788366104999966],[-69.00019347799997,-21.79177806399997],[-69.00019218399996,-20.799698659999933]]]}"
    },
    {
      "id": 283,
      "name": "19KET",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-69.00019106999997,-19.895884921999937],[-67.95128393499994,-19.89279541199994],[-67.94455254699994,-20.884811643999967],[-69.00019229599997,-20.88806891799993],[-69.00019106999997,-19.895884921999937]]]}"
    },
    {
      "id": 284,
      "name": "19KEU",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-69.00019001499999,-18.992521746999955],[-67.95706914399995,-18.98958321699996],[-67.95069692499999,-19.98170207299995],[-69.00019117699998,-19.984806532999983],[-69.00019001499999,-18.992521746999955]]]}"
    },
    {
      "id": 285,
      "name": "19KEV",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-69.00018901899995,-18.08852817199994],[-67.96253915599993,-18.085739144999934],[-67.95651730299994,-19.07795673299995],[-69.00019011599994,-19.080909963999943],[-69.00018901899995,-18.08852817199994]]]}"
    },
    {
      "id": 286,
      "name": "19KFP",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-68.02055127599994,-23.50693439099996],[-66.94551266499997,-23.49646383499993],[-66.92971654799999,-24.48756455499995],[-68.01301564499994,-24.498532422999972],[-68.02055127599994,-23.50693439099996]]]}"
    },
    {
      "id": 287,
      "name": "19KFQ",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-68.02706579199997,-22.603547198999934],[-66.95916852499994,-22.593523408999943],[-66.94414024699995,-23.584747770999968],[-68.01989655999995,-23.595262325999954],[-68.02706579199997,-22.603547198999934]]]}"
    },
    {
      "id": 288,
      "name": "19KFR",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-68.03325318299994,-21.700598724999963],[-66.97213882699998,-21.691015824999965],[-66.95785544799998,-22.68235986899998],[-68.02643939299998,-22.692427357999975],[-68.03325318299994,-21.700598724999963]]]}"
    },
    {
      "id": 289,
      "name": "19KGQ",
      "geom": "{\"type\":\"Polygon\",\"coordinates\":[[[-67.05484379299997,-22.594711160999964],[-65.98767270499997,-22.57823407199993],[-65.96551288199998,-23.56871001199994],[-67.04051892599995,-23.585993671999972],[-67.05484379299997,-22.594711160999964]]]}"
    }
]

export default chileTiles;
