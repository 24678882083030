import { Stack, TextField } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { AppDispatch, RootState } from "../../../app/state/store"
import { selectProcessStringById, updateProcessString } from "../../state/processSlice"

const StringProcessInput = ({ id }: { id: string }) => {
    const processString = useSelector((state: RootState) => selectProcessStringById(state, id))
    const dispatch = useDispatch<AppDispatch>()

    return <Stack direction='row' spacing={3} useFlexGap flexWrap='wrap'>
        <TextField
            fullWidth
            required={processString.required}
            value={processString.value ? processString.value : ''}
            onChange={(e) => {
                dispatch(updateProcessString({ id: id, changes: { value: e.target.value } }))
            }}
            label={processString.name}
            inputProps={{ maxLength: processString.maxLength }}
        />
    </Stack>
}

export default StringProcessInput