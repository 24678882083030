import { LatLng } from "leaflet";
import { svgIcon } from "../../../../rovLabel/utils/rovLabelHelpers";
import { Marker, Polyline, useMapEvents } from "react-leaflet";
import { useEffect, useState } from "react";

const markerIcon = svgIcon('red');

const Vertex = ({ id, position, setVertexs, draggable }: { id: number, position: LatLng, setVertexs: React.Dispatch<React.SetStateAction<LatLng[]>>, draggable: boolean }) => {
    const eventHandlers = {
        drag: (e: any) => {
            setVertexs(prev => prev.map((vertex, index) => index === id ? e.latlng : vertex))
        },
    }
    return <Marker position={position} icon={markerIcon} draggable={draggable} eventHandlers={eventHandlers} />
}

const MeasureTool = ({ setMeasure, setLine, initVertexs = [], complete = false }: { setMeasure?: React.Dispatch<React.SetStateAction<number>>, setLine?: any, initVertexs?: LatLng[], complete?: boolean }) => {
    const [completed, setCompleted] = useState(complete);
    const [vertexs, setVertexs] = useState<LatLng[]>(initVertexs)

    const [mousePosition, setMousePosition] = useState<LatLng>()
    useMapEvents({
        mousemove(e) {
            !completed && setMousePosition(e.latlng)
        },
        click(e) {
            !completed && setVertexs(prev => [...prev, e.latlng])
        },
        dblclick(e) {
            if (!completed) {
                setVertexs(prev => prev.slice(0, -1))
                setLine && setLine(vertexs.slice(0, -1))
                setCompleted(true)
            }
        }
    })

    useEffect(() => {
        if (setMeasure) {
            const sum = vertexs.reduce((accumulator, currentVertex, index, array) => {
                if (index + 1 >= array.length) return accumulator
                return accumulator + currentVertex.distanceTo(array[index + 1])
            }, 0);

            if (!completed && vertexs.length > 0 && mousePosition) {
                setMeasure(sum + mousePosition.distanceTo(vertexs[vertexs.length - 1]))
            }
            else setMeasure(sum)
        }

    }, [vertexs, completed, mousePosition, setMeasure])

    return (
        <>
            {!completed && mousePosition && <Marker position={mousePosition} icon={markerIcon} />}
            {vertexs.map((vertex, index) => <Vertex position={vertex} key={index} id={index} setVertexs={setVertexs} draggable={completed} />)}
            {<Polyline positions={vertexs} pathOptions={{ dashArray: '5 5', color: 'yellow' }} />}
            {vertexs.length > 0 && !completed && mousePosition &&

                <Polyline positions={[vertexs[vertexs.length - 1], mousePosition]} pathOptions={{ dashArray: '5 5', color: 'yellow' }} />
            }
        </>
    )
}

export default MeasureTool;
