import packageJson from '../../../package.json';
import { Box, Grid } from "@mui/material";
import PolygonsTab from "../components/UI/PolygonsTab";
import TaskTab from "../components/UI/TaskTab";
import RovLabelControls from "../components/UI/RovLabelControls";
import UIControls from "../components/drawing/UIControls";
import { AppDispatch, RootState } from "../../app/state/store";
import { useDispatch, useSelector } from "react-redux";
import {
    addPolygon,
    deletePolygon,
    selectState,
    setPolygonEditing,
    setPolygonVisibility,
    setSelectedType,
    setImageNumber,
    setProjectInfo,
    setSelectedProjectId,
    deletePolygonVertex,
    resetRovLabelState,
    setPolygonfinished,
    deselectPolygon,
    setPolygonType,
    setTaskValid,
} from "../state/rovLabelSlice";
import { useCallback, useEffect } from "react";
import MapLabels from "../components/mapData/MapLabels";
import ImageSwitcher from "../components/mapData/ImageSwitcher";
import { projectApi } from "../api/rovLabelApi";
import { MapContainer, TileLayer } from "react-leaflet";
import { CRS, LatLngExpression } from "leaflet";
import { CustomAlertDialog } from '../../common/components/UI/general/AlertDialog';
import {
    resetRovLabelLoadingState,
    selectRovLabelLoadingState,
    setAlertMessage,
    setAlertOpen,
    setAlertSeverity,
    setProjectLoading
} from '../state/rovLabelLoadingSlice';
import { keyBinds } from '../utils/rovLabelHelpers';
import Info from '../components/UI/Info';
import "../../common/styles/leaflet_elements.css";
import { useParams } from 'react-router-dom';



function RovLabel() {
    const params = useParams<{ projectId: string }>();

    const center : LatLngExpression = [-33.75, -71.05];
    const zoom: number = 6;
    const minZoom: number = 3;
    const maxZoom: number = 20;
    const crs: L.CRS = CRS.EPSG3857;

    const {
        projectInfo,
        taskInfo,
        polygons,
        selectedPolygonId,
        imageNumber,
        maxImageNumber,
        selectedItem,
    } = useSelector((state: RootState) => selectState(state));
    const selectedPolygon = polygons[selectedPolygonId];
    const { alertOpen, alertMessage, alertSeverity } = useSelector((state: RootState) => selectRovLabelLoadingState(state));
    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        const getProjectData = async (projectId: number) => {
            dispatch(setProjectLoading(true));
            await projectApi.get(Number(params.projectId)).then((response) => {
                if (!response) {
                    dispatch(setAlertOpen(true));
                    dispatch(setAlertMessage('failed to get project data'));
                    dispatch(setAlertSeverity('error'));
                }
                else {
                    dispatch(setProjectInfo({projectInfo: response}));
                    dispatch(setSelectedProjectId({projectId: projectId}));
                }
                dispatch(setProjectLoading(false));
            });
        }
        getProjectData(1);
    }, [dispatch, params.projectId]);


    const keyDownHandler = useCallback((e: KeyboardEvent) => {
        e.preventDefault();
        //deselect polygon
        e.key === keyBinds.deselectPolygon && dispatch(deselectPolygon());
        //delete item
        if (e.key === keyBinds.deleteItem && selectedPolygon) {
            selectedItem === 'polygon' && dispatch(deletePolygon({ polygonId: selectedPolygonId }));
            if (selectedItem === 'vertex' && selectedPolygon.canDeleteVertex && selectedPolygon.selectedVertexId !== -1){
                dispatch(deletePolygonVertex({polygonId: selectedPolygonId, vertexId: selectedPolygon.selectedVertexId}));
            }
        }
        //edit polygon
        e.key === keyBinds.editPolygon && selectedPolygon &&
            dispatch(setPolygonEditing({ polygonId: selectedPolygonId, editing: !selectedPolygon.editing }));
        //polygon visible
        e.key === keyBinds.polygonVisible && selectedPolygon &&
            dispatch(setPolygonVisibility({ polygonId: selectedPolygonId, visible: !selectedPolygon.visible }));
        //finish polygon
        e.key === keyBinds.finishPolygon &&
            dispatch(setPolygonfinished({ polygonId: selectedPolygonId }));
        //change polygon type
        if (projectInfo.types &&
            e.key in keyBinds.changePolygonType &&
            Number(e.key) <= projectInfo.types.length) {
                dispatch(setSelectedType({ typeId: projectInfo.types[Number(e.key)-1].id }));
                selectedPolygon ?
                    dispatch(setPolygonType({ polygonId: selectedPolygonId, typeId: projectInfo.types[Number(e.key)-1].id })) :
                    dispatch(addPolygon());
            }
        // image switcher
        e.key === keyBinds.imageSwitcher &&
            dispatch(setImageNumber({imageNumber: (imageNumber + 1) % maxImageNumber}));
        // change task valid
        if (e.key === keyBinds.taskValid && taskInfo.id) {
            dispatch(setTaskValid({valid: !taskInfo.is_valid}));
        }
    }, [
        projectInfo.types,
        imageNumber,
        maxImageNumber,
        selectedPolygonId,
        selectedPolygon,
        dispatch,
        selectedItem,
        taskInfo.id,
        taskInfo.is_valid
    ]);

    useEffect(() => {
        document.addEventListener('keydown', keyDownHandler);

        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        }
    }, [keyDownHandler]);

    useEffect(() => {
        return () => {
            dispatch(resetRovLabelState());
            dispatch(resetRovLabelLoadingState());
        }

    },[dispatch]);

    return (
        <Box flexGrow={1} sx={{ display: 'flex', flexDirection: 'column' }}>
            <Grid container flexGrow={1}>
                <Grid item xs='auto' sx={{ display: 'flex', flexDirection:'column' }}>
                    <TaskTab/>
                </Grid>
                <Grid item xs>
                    <Grid container direction='column' sx={{ height: '100%'}}>
                        <Grid item xs='auto'>
                            <Info />
                        </Grid>
                        <Grid item xs>
                            <MapContainer
                                center={center}
                                crs={crs}
                                zoom={zoom}
                                minZoom={minZoom}
                                maxZoom={maxZoom}
                                doubleClickZoom={false}
                                keyboard={false}
                            >
                                <TileLayer
                                    attribution={`v${packageJson.rov_label_version}`}
                                    url={'https://a.tile.openstreetmap.org/{z}/{x}/{y}.png'}
                                />

                                <UIControls />
                                <MapLabels />
                                <ImageSwitcher imageNumber={imageNumber}/>
                            </MapContainer>

                        </Grid>
                        <Grid item xs='auto'>
                            <RovLabelControls/>
                            <CustomAlertDialog
                                alertOpen={alertOpen}
                                setAlertOpen={(value: boolean) => dispatch(setAlertOpen(value))}
                                message={alertMessage}
                                severity={alertSeverity}/>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs='auto' sx={{ display: 'flex', flexDirection:'column' }}>
                    <PolygonsTab />
                </Grid>
            </Grid>
        </Box>
    );
}

export default RovLabel;
