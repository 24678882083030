import { gisApi } from "../../common/api/redux_api";

export const risksApi = gisApi.injectEndpoints({
    endpoints: (builder) => ({
        calculateRisk: builder.query<any, { coords: number[], radius: number, layers: string[], placeId: string }>({
            query: ({ coords, radius, layers }) => ({
                url: `/risks/calculate_risk`,
                method: 'POST',
                body: {
                    'lat': coords[0],
                    'lng': coords[1],
                    'radius': radius,
                    'layers': layers,
                }
            })
        }),
        getRecentPlaces: builder.query<any[], void>({
            query: () => '/risks/recent_places',
        }),
        saveRecentPlace: builder.mutation<any, { coords: number[], name: string, address: string, data: any,placeId: string }>({
            query: ({ coords, name, address, data }) => ({
                url: '/risks/save_recent_place',
                method: 'POST',
                body: {
                    lat: coords[0],
                    lng: coords[1],
                    name: name,
                    address: address,
                    data: data
                }
            })
        }),
        saveAnalysis: builder.mutation<any, { analysisId: number, saved: boolean}>({
            query: ({ analysisId, saved }) => ({
                url: '/risks/save_analysis',
                method: 'PUT',
                body: {
                    analysis_id: analysisId,
                    saved: saved,
                }
            })
        }),
    }),
})

export const {
    useCalculateRiskQuery,
    useLazyCalculateRiskQuery,
    useGetRecentPlacesQuery,
    useSaveRecentPlaceMutation,
    useSaveAnalysisMutation
} = risksApi
