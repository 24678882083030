import { Alert, Box, Breadcrumbs, Button, Collapse, Container, Divider, Snackbar, Stack, TextField, Typography } from "@mui/material"
import { ConfigInput, PipelineModel } from "../../types/DataTypes"
import dayjs from "dayjs"
import React, { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react"
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom"
import { useCreateProcessMutation, useGetPipelineInstanceQuery, useGetPipelineModelQuery } from "../../api/processApi"
import { useDispatch, useSelector } from "react-redux"
import { wktToVertexs } from "../../../common/utils/tools"
import DraggableLayout from "../../../common/components/layout/DraggableLayout"
import InfoPopover from "../common/InfoPopover"
import { LoadingButton } from "@mui/lab"
import BaseMap from "../../../common/components/map/BaseMap"
import GeometriesProcessDraw from "./GeometryProcessDraw"
import { selectInputValues, updateProcessDate, updateProcessDateRange, updateProcessGeometry, updateProcessNumber, updateProcessRaster, updateProcessString } from "../../state/processSlice"
import RastersProcessDraw from "./RasterProcessDraw"
import CenteredCircularProgress from "../../../common/components/UI/general/CenteredCircularProgress"
import { RootState } from "../../../app/state/store"
import { Polygon } from "react-leaflet"
import { AddRoad, ArrowDropDown, ArrowDropUp, RemoveRoad } from "@mui/icons-material"
import ConfigProcessInputComponent from "./ConfigProcessInputComponent"
import { calculateModelEquation } from "../../utils/priceCalculator"
import { useGetOrgCreditsQuery } from "../../../authentication/api/authApi"
import BaseMapControls, { ControlGroup } from "../../../common/components/map/layout/BaseMapControls"
import LocationUtilities from "../../../common/components/map/control/LocationUtilities"
import BaseMapSelector from "../../../mainMap/components/control/BaseMapSelector"
import { setStreets } from "../../../mainMap/state/baseMapSlice"
import ToggleButton from "../../../common/components/UI/general/ToggleButton"
import { MapLibreTileLayer } from "../../../common/utils/MapLibreTileLayer"
import NotFound from "../../../app/views/NotFound"
import TimeDisplay from "../common/TimeDisplay"

interface DrawPolygon {
    wkt: string,
    color: string
    opacity: number
}

interface ProcessCreationContextProps {
    polygons: { [key: string]: DrawPolygon }
    setPolygons: React.Dispatch<React.SetStateAction<{ [key: string]: DrawPolygon }>>
}

const ProcessCreationContext = createContext<ProcessCreationContextProps>({ polygons: {}, setPolygons: () => { } });

export const useProcessCreationContext = () => useContext(ProcessCreationContext);

const DynamicProcessCreation = ({ model, name: defaultName }: { model: PipelineModel, name?: string }) => {
    const [name, setName] = useState(() => (
        defaultName ? defaultName : `${model?.title.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().replaceAll(' ', '_')}-${dayjs().format()}`
    ))
    const navigate = useNavigate()
    const [polygons, setPolygons] = useState<{ [key: string]: DrawPolygon }>({})
    const [createProcessApi, result] = useCreateProcessMutation()
    const [optional, setOptional] = useState(false)
    const [openAlert, setOpenAlert] = useState(false)
    const [errorMessage, setErrorMessage] = useState<string>()

    const { data: credits } = useGetOrgCreditsQuery();

    const map = useRef<L.Map>(null)
    const updateMapSize = useCallback(() => {
        map.current && map.current.invalidateSize(true)
    }, [map])

    const inputValues = useSelector(selectInputValues)

    const price = useMemo(() => inputValues.valid ? calculateModelEquation(model.config.price, model, inputValues.values) : 0, [model, inputValues])

    const estimatedTime = useMemo(() => {
        if (model.config.time) {
            return inputValues.valid ? calculateModelEquation(model.config.time, model, inputValues.values) : -1
        }
        return undefined
    }, [model, inputValues])

    const createProcess = () => {
        const query = {
            name: name,
            modelId: model.id,
            cost: price,
            input: inputValues.values
        }
        createProcessApi(query)
    }

    useEffect(() => {
        if (result.isSuccess) {
            navigate("/dashboard")
            return
        }
        if (result.isError) {
            setOpenAlert(true)
            if ('status' in result.error) {
                // you can access all properties of `FetchBaseQueryError` here
                const errMsg = 'error' in result.error ? result.error.error : JSON.stringify(result.error.data)
                setErrorMessage(errMsg)
            }
            else {
                setErrorMessage(result.error.message)
            }
        }
    }, [navigate, result])

    const requiredInputs = useMemo(() => {
        return Object.keys(model.input_format).filter(key => model.input_format[key].required)
    }, [model])

    const optionalInputs = useMemo(() => {
        return Object.keys(model.input_format).filter(key => !model.input_format[key].required)
    }, [model])

    const baseMapConfig = useSelector((state: RootState) => state.baseMap)
    const dispatch = useDispatch()

    return (
        <ProcessCreationContext.Provider value={{ polygons: polygons, setPolygons: setPolygons }}>
            <DraggableLayout
                topLeft={
                    <Box sx={{ height: 1, width: 1, overflow: 'auto' }}>
                        <Stack direction={'column'} sx={{ paddingX: 5, paddingY: 2 }} spacing={3}>
                            <Breadcrumbs aria-label="breadcrumb" separator=">">
                                <Link to="/processes">Procesos</Link>
                                <Link to={`/processes/${model.id}`}>{model.subtitle ? `${model.title}:${model.subtitle}` : model.title}</Link>
                                <Typography color="textPrimary">Nueva ejecución</Typography>
                            </Breadcrumbs>
                            <Typography variant="h4">Configuración nueva ejecución</Typography>
                        </Stack>
                        <Divider sx={{ marginY: 2 }} />
                        <Container>
                            <Stack direction='column' spacing={2} divider={<Divider flexItem />}>
                                <Stack direction='row' spacing={3} useFlexGap flexWrap='wrap' alignItems='center'>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', width: 250, alignItems: 'center' }}>
                                        <Typography flexGrow={1} variant="h6">Nombre de la ejecución*</Typography>
                                        <InfoPopover>
                                            Nombre de la nueva ejecución, la cual se mostrará en el dashboard.
                                            <br />
                                            Cambiar el nombre para una identificación más sencilla.
                                        </InfoPopover>
                                    </Box>
                                    <Box sx={{ flexGrow: 1 }}>
                                        <TextField
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}
                                            fullWidth
                                        />
                                    </Box>
                                </Stack>
                                {requiredInputs.map(key => {
                                    const config = model.input_format[key] as ConfigInput
                                    return <ConfigProcessInputComponent config={config} id={key} key={key} />
                                })}
                                {optionalInputs.length > 0 &&
                                    <>
                                        <Button fullWidth onClick={() => setOptional(!optional)} endIcon={optional ? <ArrowDropUp /> : <ArrowDropDown />}>Opciones avanzadas</Button>
                                        <Collapse in={optional}>
                                            <Stack>
                                                {optionalInputs.map(key => {
                                                    const config = model.input_format[key] as ConfigInput
                                                    return <ConfigProcessInputComponent config={config} id={key} key={key} />
                                                })}
                                            </Stack>
                                        </Collapse>
                                    </>
                                }
                                {estimatedTime !== undefined &&
                                    <Stack direction='row' spacing={3} useFlexGap flexWrap='wrap' alignItems='center'>
                                        <Box sx={{ display: 'flex', flexDirection: 'row', width: 250, alignItems: 'center' }}>
                                            <Typography flexGrow={1} variant="h6">Tiempo estimado</Typography>
                                            <InfoPopover>
                                                Tiempo estimado que se tardará en ejecutar este proceso. El tiempo estimado es calculado en base a la cantidad de datos y la complejidad del proceso. Puede tomar más tiempo del estimado.
                                            </InfoPopover>
                                        </Box>
                                        <Box sx={{ flexGrow: 1 }}>
                                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline', marginY: 3 }}>
                                                {estimatedTime >= 0 ? <TimeDisplay time={estimatedTime} /> : "-"}
                                            </Box>
                                        </Box>
                                    </Stack>
                                }
                                <Stack direction='row' spacing={3} useFlexGap flexWrap='wrap' alignItems='center'>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', width: 250, alignItems: 'center' }}>
                                        <Typography flexGrow={1} variant="h6">Costo estimado</Typography>
                                        <InfoPopover>
                                            Costo estimado que se cobrará de este proceso. Si el proceso falla o no se ejecuta correctamente, no se descontarán los créditos.
                                        </InfoPopover>
                                    </Box>
                                    <Box sx={{ flexGrow: 1 }}>
                                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline', marginY: 3 }}>
                                            <Typography variant="h4">{price.toFixed(2)}</Typography>
                                            <Typography marginLeft={1}>CRÉDITOS</Typography>
                                        </Box>
                                    </Box>
                                </Stack>
                                <Box>
                                    {credits
                                        ? <Box>
                                            <Typography variant="subtitle2" sx={{ marginTop: 2, color: 'gray' }}>{`Créditos disponibles: ${credits.credits.toFixed(2)}`}</Typography>
                                        </Box>
                                        : null
                                    }
                                    <Typography variant="subtitle2" sx={{ color: 'gray' }}>Los campos marcados con * son obligatorios</Typography>
                                </Box>
                            </Stack>
                            <Box sx={{ display: 'flex', flexDirection: 'row-reverse', height: '10vh', width: 1, alignItems: 'center' }}>
                                <LoadingButton
                                    variant="contained"
                                    disabled={!inputValues.valid || name === ""}
                                    onClick={() => createProcess()}
                                    loading={result.isLoading}
                                >
                                    Iniciar ejecución
                                </LoadingButton>
                            </Box>
                        </Container>
                        <Snackbar
                            open={openAlert}
                            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                            autoHideDuration={5000}
                            onClose={() => setOpenAlert(false)}
                        >
                            <Alert
                                onClose={() => setOpenAlert(false)}
                                severity="error"
                                variant="filled"
                                sx={{ width: '100%' }}
                            >
                                {`No se pudo crear la ejecución: ${errorMessage}`}
                            </Alert>
                        </Snackbar>
                    </Box>
                }
                downRight={
                    <BaseMap baseMap={baseMapConfig.baseMap} maxZoom={22} doubleClickZoom={false} zoomControl={false} innerRef={map}>
                        <BaseMapControls>
                            <LocationUtilities />
                            <ControlGroup>
                                <BaseMapSelector />
                                <Divider orientation='vertical' variant='middle' flexItem />
                                <ToggleButton
                                    icon={<AddRoad fontSize='inherit' />}
                                    selectedIcon={<RemoveRoad fontSize='inherit' />}
                                    tooltip={'Show streets'}
                                    selectedTooltip={'HideStreets'}
                                    selected={baseMapConfig.streets}
                                    enabled={baseMapConfig.baseMap !== 'osm'}
                                    onClick={() => dispatch(setStreets(!baseMapConfig.streets))}
                                />
                            </ControlGroup>
                        </BaseMapControls>
                        <GeometriesProcessDraw />
                        <RastersProcessDraw />
                        {Object.keys(polygons).map((key) => <Polygon key={key} positions={wktToVertexs(polygons[key].wkt)} pathOptions={{ color: polygons[key].color, fillOpacity: polygons[key].opacity }} />)}
                        {baseMapConfig.streets && <MapLibreTileLayer url="https://api.maptiler.com/maps/29b43753-8fe0-4881-b56b-91b28b4f694c/style.json?key=dCFtNpk6lDWiGmtbFNYs" attribution="" />}
                    </BaseMap>
                }
                onDragEnd={updateMapSize}
                onChangeOrientation={updateMapSize}
                defaultSize={0.4}
            />
        </ProcessCreationContext.Provider>
    )
}

const CopyInstance = ({ model, pipelineExecutionId }: { model: PipelineModel, pipelineExecutionId: string }) => {
    const { data: pipelineExecution, isLoading, isSuccess, isError } = useGetPipelineInstanceQuery(pipelineExecutionId)
    const [rehidrate, setRehidrate] = useState(false)
    const dispatch = useDispatch()

    useEffect(() => {
        if (isSuccess && !rehidrate) {
            Object.keys(model.input_format).forEach(key => {
                const config = model.input_format[key] as ConfigInput
                switch (config.type) {
                    case "date":
                        dispatch(updateProcessDate({ id: key, changes: { date: pipelineExecution.input_data[key].payload.value } }))
                        break
                    case "geom":
                        dispatch(updateProcessGeometry({ id: key, changes: { geom: pipelineExecution.input_data[key].payload.value } }))
                        break
                    case "number":
                        dispatch(updateProcessNumber({ id: key, changes: { value: pipelineExecution.input_data[key] } }))
                        break
                    case "string":
                        dispatch(updateProcessString({ id: key, changes: { value: pipelineExecution.input_data[key] } }))
                        break
                    case "date_range":
                        dispatch(updateProcessDateRange({ id: key, changes: { init: pipelineExecution.input_data[key].payload.value.init, end: pipelineExecution.input_data[key].payload.value.end } }))
                        break
                    case "raster":
                        const raster = pipelineExecution.input_data[key]
                        dispatch(updateProcessRaster({ id: key, changes: { bbox: raster.bbox, dtype: raster.d_type, rasterId: raster.id, channels: raster.n_bands, resolution: raster.resolution, key: raster.payload.location.obj_key, bucket: raster.payload.location.bucket } }))
                        break
                    default:
                        break
                }
            })
            setRehidrate(true)
        }
    }, [pipelineExecution, model, isSuccess, dispatch, rehidrate, setRehidrate])

    if (isError) return <NotFound />

    return isLoading || !rehidrate ? <CenteredCircularProgress /> : isSuccess ? <DynamicProcessCreation model={model} name={`Copia de ${pipelineExecution.name}`} /> : <NotFound />
}

const ModelGetter = ({ id, copyId }: { id: string, copyId: string | null }) => {
    const { data: model, isFetching, isSuccess, isError } = useGetPipelineModelQuery(id, { refetchOnMountOrArgChange: true })
    const reduxModel = useSelector((state: RootState) => state.process.processStatus.currentModelId)

    if (isError) return <NotFound />

    return (isFetching || reduxModel !== id)
        ? <CenteredCircularProgress />
        : isSuccess
            ? copyId ? <CopyInstance model={model} pipelineExecutionId={copyId} /> : <DynamicProcessCreation model={model} />
            : <NotFound />
}

const ProcessCreation = () => {
    const { modelId } = useParams()
    const [searchParams] = useSearchParams()
    return modelId ? <ModelGetter id={modelId} copyId={searchParams.get("copyFrom")} /> : <NotFound />
}

export default ProcessCreation