import { Route, Routes } from "react-router-dom";
import NotFound from "../app/views/NotFound";
import Verified from "./views/Verified";
import ForgotPassword from "./views/ForgotPassword";
import PasswordReset from "./views/PasswordReset";


const AuthRoutes = () => {
    return (
        <Routes>
            <Route path="/password-recovery" element={<ForgotPassword />} />
            <Route path="/password-reset" element={<PasswordReset />} />
            <Route path="/verified" element={<Verified />} />
            <Route path="*" element={<NotFound />} />
        </Routes>
    )
}

export default AuthRoutes;
