const endpoint = `${process.env.REACT_APP_API_URL}`;


function getRequest(path: any, token?: string | null) {
    if (token) {
        return fetch(`${endpoint}${path}`, {
            method: 'GET',
            headers: {
                'accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }).then((response) => response.json())
        .then((data) => data)
        .catch((error) => {console.error(error)});
    }
    return fetch(`${endpoint}${path}`)
    .then((response) => response.json())
    .then((data) => data)
    .catch((error) => {console.error(error);
    });
}

function postRequest(path: any, body: any, token?: string | null) {
    if (token) {
        return fetch(`${endpoint}${path}`, {
            method: 'POST',
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(body)
        })
        .then((response) => response.json())
        .then((data) => data)
        .catch((error) => {console.error(error);
        });
    }
    return fetch(`${endpoint}${path}`, {
        method: 'POST',
        headers: {
            'accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
    })
    .then((response) => response.json())
    .then((data) => data)
    .catch((error) => {console.error(error);
    });
}

function getRequestBlob(path: any) {
    return fetch(`${endpoint}${path}`)
    .then((response) => response.blob())
    .then((data) => data)
    .catch((error) => {console.error(error);
    });
}

function postRequestBlob(path: any, body: any) {
    return fetch(`${endpoint}${path}`, {
        method: 'POST',
        headers: {
            'accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
    })
    .then((response) => response.blob())
    .then((data) => data)
    .catch((error) => {console.error(error);
    });
}

export { getRequest, postRequest, getRequestBlob, postRequestBlob };
