import FilterIcon from '@mui/icons-material/Filter';
import LocationSearchingIcon from '@mui/icons-material/LocationSearching';
import { Box, IconButton, Tooltip } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { selectState, setImageNumber } from '../../state/rovLabelSlice';
import { AppDispatch } from '../../../app/state/store';
import { getBounds, keyBinds } from '../../utils/rovLabelHelpers';
import { useMap } from 'react-leaflet';

const POSITION_CLASSES = {
    topright: 'leaflet-top leaflet-right',
    topleft: 'leaflet-top leaflet-left',
    bottomright: 'leaflet-bottom leaflet-right',
    bottomleft: 'leaflet-bottom leaflet-left'
};

function UIControls({position}: {position?: string}) {
    const { imageNumber, maxImageNumber, taskInfo } = useSelector((state: any) => selectState(state));
    const dispatch = useDispatch<AppDispatch>();
    const map = useMap();



    const positionClass = (position && POSITION_CLASSES[position as keyof typeof POSITION_CLASSES]) || POSITION_CLASSES.topright;

    return (
        <div className='leaflet-control-container'>
            <div className={positionClass}>
                <div className='leaflet-control leaflet-bar'>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                        <Tooltip title={`switch image (${keyBinds.imageSwitcher})`}>
                            <IconButton onClick={() => {
                                dispatch(setImageNumber({imageNumber: (imageNumber + 1) % maxImageNumber}));
                            }}
                            sx={{ backgroundColor: 'white', '&:hover':{ backgroundColor: '#D5D8DC'} }}>
                                <FilterIcon sx={{ color: 'black' }} />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={`re-center (${keyBinds.recenter})`}>
                            <IconButton onClick={() => {
                                const bounds = taskInfo.bbox && getBounds(taskInfo.bbox);
                                map.fitBounds(bounds);
                            }}
                            sx={{ backgroundColor: 'white', '&:hover':{ backgroundColor: '#D5D8DC'} }}>
                                <LocationSearchingIcon sx={{ color: 'black' }} />
                            </IconButton>
                        </Tooltip>
                    </Box>
                </div>
            </div>
        </div>
    );
}


export default UIControls;
