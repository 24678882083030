import { Add, Close, CropFree } from "@mui/icons-material"
import { Box, Collapse, Divider, Fab, FormControl, IconButton, MenuItem, Select, TextField, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import { Marker, Rectangle, useMapEvents } from "react-leaflet"
import { svgIcon } from "../../../rovLabel/utils/rovLabelHelpers"
import { LatLng, LeafletMouseEvent } from "leaflet"
import L from "leaflet"
import { useLazyCalculateStatisticQuery } from "../../../common/api/redux_api"
import { LoadingButton } from "@mui/lab"

const StatisticRow = ({ stat, index, setIndex, setStats, selectedIndex }: { stat: any, index: number, setIndex: any, setStats: any, selectedIndex: number | undefined }) => {
    const [layer1, setLayer1] = useState("")
    const [layer2, setLayer2] = useState("")

    const coordinates = stat.bbox.split(",").map((e: string) => Number(e))
    const [lng1, lat1, lng2, lat2] = coordinates

    const [trigger, result] = useLazyCalculateStatisticQuery()

    const [data, setData] = useState(result.data)

    useEffect(() => {
        setData(result.data)
    }, [result.data, setData])

    return <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Divider variant="middle" />
        <Typography variant="h6">{stat.name}</Typography>
        <Box sx={{ padding: 1 }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: 1 }}>
                <Typography sx={{ flexGrow: 1 }}>Capa 1:</Typography>
                <Box sx={{ flexGrow: 1 }}>
                    <FormControl fullWidth size="small">
                        <Select
                            value={layer1}
                            displayEmpty
                            onChange={(e) => setLayer1(e.target.value)}
                        >
                            <MenuItem value={"inter"}>Layer1</MenuItem>
                            <MenuItem value={"cover"}>Layer2</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: 1 }}>
                <Typography sx={{ flexGrow: 1 }}>Capa 2:</Typography>
                <Box sx={{ flexGrow: 1 }}>
                    <FormControl fullWidth size="small">
                        <Select
                            value={layer2}
                            displayEmpty
                            onChange={(e) => setLayer2(e.target.value)}
                        >
                            <MenuItem value={"inter"}>Layer1</MenuItem>
                            <MenuItem value={"cover"}>Layer2</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: 1 }}>
                <Typography>Bbox:</Typography>
                <Typography sx={{ flexGrow: 1, textAlign: "right" }}>{stat.bbox !== "" && lat1.toFixed(2) + ", " + lng1.toFixed(2) + ", " + lat2.toFixed(2) + ", " + lng2.toFixed(2)}</Typography>
                <IconButton size="medium" onClick={(e) => {
                    e.stopPropagation()
                    if (index === selectedIndex) {
                        setIndex(undefined)
                    }
                    else {
                        setIndex(index)
                        setStats((prev: any[]) => ([
                            ...prev.slice(0, index),
                            {
                                ...prev[index],
                                bbox: "",
                            },
                            ...prev.slice(index + 1)
                        ]))
                        setData(undefined)
                    }
                }}>
                    {selectedIndex !== index ? <CropFree fontSize="inherit" /> : <Close fontSize="inherit" />}
                </IconButton>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row-reverse', alignItems: 'center', padding: 1 }}>
                <LoadingButton
                    variant="contained"
                    disabled={stat.bbox === "" || layer1 === '' || layer2 === '' || data !== undefined}
                    onClick={() => trigger({ bbox: coordinates, layer_t1: "layer1", layer_t2: "layer2" })}
                    loading={result.isLoading}
                >
                    <span>Calcular</span>
                </LoadingButton>
            </Box>
            <Collapse in={data !== undefined}>
                <Typography variant="h6">Resultados</Typography>
                {data && Object.entries(data).map(([key, value]) => <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', padding: 1 }} key={key}>
                    <Typography>{key}: </Typography>
                    <TextField size="small" disabled defaultValue={value} />
                </Box>)}
            </Collapse>
        </Box >
    </Box >
}

const markerIcon = svgIcon('red', 100)

const BoundingBoxSelector = ({ index, setStats, setIndex }: { index: number, setStats: any, setIndex: any }) => {
    const [currentPosition, setCurrentPosition] = useState<LatLng>()
    const [firstVertex, setFirstVertex] = useState<LatLng>()

    useMapEvents({
        mousemove: (e: LeafletMouseEvent) => {
            setCurrentPosition(e.latlng)
        },
        click: (e: LeafletMouseEvent) => {
            if (firstVertex) {
                setIndex(undefined)
                setStats((prev: any[]) => ([
                    ...prev.slice(0, index),
                    {
                        ...prev[index],
                        bbox: L.latLngBounds([firstVertex, e.latlng]).toBBoxString()
                    },
                    ...prev.slice(index + 1)
                ]))
            }
            else {
                setFirstVertex(e.latlng)
            }
        }
    })

    return <>
        {!firstVertex && currentPosition && <Marker position={currentPosition} icon={markerIcon} />}
        {firstVertex && currentPosition && <Rectangle bounds={L.latLngBounds([firstVertex, currentPosition])} />}
    </>
}

const StatsTypes: any = {
    inter: "Intersección",
    cover: "Cobertura",
}

const StatisticTab = () => {
    const [stats, setStats] = useState<Array<any>>([])
    const [type, setType] = useState("")
    const [selectedIndex, setSelectedIndex] = useState<number>()

    return <Box sx={{ display: 'flex', flexDirection: 'column', padding: 2, overflow: 'hidden' }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', flexGrow: 1, padding: 1 }}>
                <Typography variant="h5">Agregar calculo</Typography>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <Typography>Tipo:</Typography>
                    <FormControl fullWidth size="small">
                        <Select
                            value={type}
                            displayEmpty
                            onChange={(e) => setType(e.target.value)}
                        >
                            <MenuItem value=""><em>Ninguno</em></MenuItem>
                            <MenuItem value={"inter"}>Intersección</MenuItem>
                            <MenuItem value={"cover"}>Cobertura</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
            </Box>
            <Fab
                size="medium"
                color="primary"
                onClick={() => setStats(prev => (
                    [
                        ...prev,
                        {
                            type: type,
                            name: StatsTypes[type],
                            layer1: undefined,
                            layer2: undefined,
                            bbox: "",
                        }
                    ]
                ))}
                disabled={type === ""}
            >
                <Add />
            </Fab>
            
        </Box>
        <Box sx={{ overflow: 'auto' }}>
            {stats.map((stat, index) => <StatisticRow stat={stat} index={index} key={index} setIndex={setSelectedIndex} selectedIndex={selectedIndex} setStats={setStats} />)}
        </Box>
        {selectedIndex !== undefined && <BoundingBoxSelector index={selectedIndex} setStats={setStats} setIndex={setSelectedIndex} />}
    </Box>
}

export default StatisticTab