import { AlertColor } from "@mui/material";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface RovLabelLoadingState {
    saveButtonLoading: boolean,
    saveButtonDisabled: boolean,
    projectLoading: boolean,
    taskLoading: boolean,
    alertOpen: boolean,
    alertMessage: string,
    alertSeverity: AlertColor;
}

const initialState: RovLabelLoadingState = {
    saveButtonLoading: false,
    saveButtonDisabled: false,
    projectLoading: false,
    taskLoading: false,
    alertOpen: false,
    alertMessage: '',
    alertSeverity: 'error'
}

const rovLabelLoadingSlice = createSlice({
    name: 'rovLabelLoading',
    initialState: initialState,
    reducers: {
        resetRovLabelLoadingState: (state) => {
            state.saveButtonLoading = false;
            state.saveButtonDisabled = false;
            state.projectLoading = false;
            state.taskLoading = false;
            state.alertOpen = false;
            state.alertMessage = '';
            state.alertSeverity = 'error';
        },
        setSaveButtonLoading: (state, action: PayloadAction<boolean>) => {
            state.saveButtonLoading = action.payload;
        },
        setSaveButtonDisabled: (state, action: PayloadAction<boolean>) => {
            state.saveButtonDisabled = action.payload;
        },
        setProjectLoading: (state, action: PayloadAction<boolean>) => {
            state.projectLoading = action.payload;
        },
        setTaskLoading: (state, action: PayloadAction<boolean>) => {
            state.taskLoading = action.payload;
        },
        setAlertOpen: (state, action: PayloadAction<boolean>) => {
            state.alertOpen = action.payload;
        },
        setAlertMessage: (state, action: PayloadAction<string>) => {
            state.alertMessage = action.payload;
        },
        setAlertSeverity: (state, action: PayloadAction<AlertColor>) => {
            state.alertSeverity = action.payload;
        }

    },
    selectors: {
        selectRovLabelLoadingState: (state) => state,
        selectSaveButtonLoading: (state) => state.saveButtonLoading,
        selectSaveButtonDisabled: (state) => state.saveButtonDisabled,
        selectProjectLoading: (state) => state.projectLoading,
        selectTaskLoading: (state) => state.taskLoading,
        selectAlertOpen: (state) => state.alertOpen,
        selectAlertMessage: (state) => state.alertMessage,
        selectAlertSeverity: (state) => state.alertSeverity
    }
});

export const {
    resetRovLabelLoadingState,
    setSaveButtonLoading,
    setSaveButtonDisabled,
    setProjectLoading,
    setTaskLoading,
    setAlertOpen,
    setAlertMessage,
    setAlertSeverity
} = rovLabelLoadingSlice.actions;

export const {
    selectRovLabelLoadingState,
    selectSaveButtonLoading,
    selectSaveButtonDisabled,
    selectProjectLoading,
    selectTaskLoading,
    selectAlertOpen,
    selectAlertMessage,
    selectAlertSeverity
} = rovLabelLoadingSlice.selectors;

export default rovLabelLoadingSlice.reducer;
