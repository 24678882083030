import { useDispatch, useSelector } from "react-redux"
import { AppDispatch, RootState } from "../../../app/state/store"
import { Marker } from "react-leaflet"
import { svgIcon } from '../../utils/rovLabelHelpers';
import { useMemo, useState } from "react";
import { setCenterofPolygon, setInitDragging } from "../../state/rovLabelSlice";

const CenterMarker = ({ polygonId }: { polygonId: number }) => {
    const center = useSelector((state: RootState) => state.rovLabel.polygons[polygonId].center)
    const [hover, setHover] = useState(false);
    const [dragging, setDragging] = useState(false);
    const dispatch = useDispatch<AppDispatch>();

    const markerIcon = useMemo(() => {
        const color = hover ? "red" : 'white'
        return svgIcon(color);
    }, [hover]);

    const eventHandlers = {
        mouseover: () => { !dragging && setHover(true) },
        mouseout: () => { !dragging && setHover(false) },
        drag: (e: any) => {
            const diff = [e.latlng.lat - e.oldLatLng.lat, e.latlng.lng - e.oldLatLng.lng]
            dispatch(setCenterofPolygon({ polygonId, diff }))
        },
        dragstart: (e: any) => {
            dispatch(setInitDragging({polygonId}))
            setDragging(true)
        },
        dragend: (e: any) => {
            setDragging(false);
        },
    }

    return <Marker position={center} eventHandlers={eventHandlers} icon={markerIcon} draggable />
}

export default CenterMarker
