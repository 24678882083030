import { Route, Routes } from "react-router-dom";
import NotFound from "../app/views/NotFound";
import Profile from "./views/Profile";
import History from "./views/History";
import ProfileHome from "./views/ProfileHome";


const ProfileRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<ProfileHome />} >
                <Route index element={<Profile />} />
                <Route path="history" element={<History />} />
            </Route>
            <Route path="*" element={<NotFound />} />
        </Routes>
    )
}

export default ProfileRoutes;
