import "leaflet/dist/leaflet.css";
import { AddRoad, Category, CloudUpload, Layers, QueryStats, RemoveRoad, Signpost } from "@mui/icons-material";
import BaseMapDrawer, { DrawerTab } from "../../common/components/map/layout/BaseMapDrawer";
import { SelectLocations } from "../components/control/SelectLocation";
import { ZoomControl } from "react-leaflet";
import LayersTab from "../components/control/LayersTab";
import BaseMap from "../../common/components/map/BaseMap";
import BaseMapControls, { ControlGroup } from "../../common/components/map/layout/BaseMapControls";
import LocationUtilities from "../../common/components/map/control/LocationUtilities";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../app/state/store";
import { MapLibreTileLayer } from "../../common/utils/MapLibreTileLayer";
import DrawingUtilities from "../../common/components/map/control/DrawingUtilities";
import BaseMapSelector from "../components/control/BaseMapSelector";
import { Divider } from "@mui/material";
import ToggleButton from "../../common/components/UI/general/ToggleButton";
import { setStreets } from "../state/baseMapSlice";
import StatisticTab from "../components/control/StatisticTab";
import UploadTab from "../components/control/UploadTab";
import WktGeoJsonTab from "../components/control/WktGeoJsonTab";
import GeoJSONTab from "../components/control/GeoJSONTab";

function MainMap() {
    const baseMap = useSelector((state: RootState) => state.baseMap.baseMap)
    const streets = useSelector((state: RootState) => state.baseMap.streets)

    const dispatch = useDispatch<AppDispatch>()

    return (
        <BaseMap maxZoom={20} doubleClickZoom={false} baseMap={baseMap} zoomControl={false} zoomSnap={0.25} zoomDelta={0.25}>
            <ZoomControl position="topright" />
            <BaseMapDrawer>
                <DrawerTab icon={<></>} title="Coordenadas">
                    <SelectLocations />
                </DrawerTab>
                <DrawerTab icon={<Layers fontSize="inherit" />} title="Capas">
                    <LayersTab />
                </DrawerTab>
                <DrawerTab icon={<QueryStats fontSize="inherit" />} title="Estadísticas">
                    <StatisticTab />
                </DrawerTab>
                <DrawerTab icon={<CloudUpload />} title="Cargar Imagen">
                    <UploadTab />
                </DrawerTab>
                <DrawerTab icon={<Signpost />} title="WKT to/from GeoJSON">
                    <WktGeoJsonTab />
                </DrawerTab>
                <DrawerTab icon={<Category />} title="GeoJSON Viewer">
                    <GeoJSONTab />
                </DrawerTab>
            </BaseMapDrawer>
            <BaseMapControls>
                <LocationUtilities />
                <DrawingUtilities />
                <ControlGroup>
                    <BaseMapSelector />
                    <Divider orientation='vertical' variant='middle' flexItem />
                    <ToggleButton
                        icon={<AddRoad fontSize='inherit' />}
                        selectedIcon={<RemoveRoad fontSize='inherit' />}
                        tooltip={'Show streets'}
                        selectedTooltip={'HideStreets'}
                        selected={streets}
                        enabled={baseMap !== 'osm'}
                        onClick={() => dispatch(setStreets(!streets))}
                    />
                </ControlGroup>
            </BaseMapControls>
            {streets && <MapLibreTileLayer url="https://api.maptiler.com/maps/29b43753-8fe0-4881-b56b-91b28b4f694c/style.json?key=dCFtNpk6lDWiGmtbFNYs" attribution="" />}
        </BaseMap>
    );
}

export default MainMap;
