import { Box, Button, Icon, InputAdornment, LinearProgress, Link, Stack, TextField, Typography } from "@mui/material"
import { PipelineInstance, statusEnum, StatusType } from "../../types/DataTypes"
import { DataGrid, GridActionsCellItem, GridColDef, GridRenderCellParams, GridRowSelectionModel, GridSlots, GridSortDirection, useGridApiRef } from "@mui/x-data-grid"
import { NavigateFunction, useNavigate } from "react-router-dom"
import { useCallback, useMemo, useState } from "react"
import { useGetPipelineModelsQuery } from "../../api/processApi"
import { useGetUsersQuery } from "../../../authentication/api/authApi"
import { ContentCopy, Search } from "@mui/icons-material"
import { dateFormat, SecondsToStopWatch, StatusDisplay } from "../../../common/components/UI/general/InfoDisplay"
import { MapIcon } from "../../../common/icons/CustomIcons"
import { useDebouncyEffect } from "use-debouncy"
import { pipelineInstancePriceDisplay } from "../../utils/utils"

const LinearProgressOverlay = () => {
    return <Box
        sx={{
            height: 1,
            width: 1,
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: '#ffffff55'
        }}
    >
        <LinearProgress sx={{
            width: 1,
        }} />
    </Box>
}

const SendToProcessCreation = () => {
    const navigate = useNavigate()
    return <Box sx={{ display: 'flex', flexDirection: 'column', height: 1, justifyContent: 'center', alignItems: 'center' }}>
        <Typography sx={{ padding: 1 }}>Aún no han realizado ejecuciones de ningún proceso.</Typography>
        <Button
            variant="contained"
            onClick={() => navigate("/processes")}
        >
            Nueva ejecución
        </Button>
    </Box>
}

const NameColumn = ({ params, navigate }: { params: GridRenderCellParams<PipelineInstance>, navigate: NavigateFunction }) => {
    return <Link
        onClick={() => navigate(`/processes/${params.row.pipeline_model_id}/instance/${params.row.id}`)}
        component="button"
    >
        {params.value}
    </Link>
}

const ModelColumn = ({ id, navigate, modelName }: { id: number, navigate: NavigateFunction, modelName: string }) => {
    return <Link
        onClick={() => navigate(`/processes/${id}`)}
        component="button"
    >
        {modelName}
    </Link>
}

const defaultSlots = {
    loadingOverlay: LinearProgressOverlay as GridSlots["loadingOverlay"],
    noRowsOverlay: SendToProcessCreation as GridSlots["noRowsOverlay"],
}

const sortmodel: { field: string, sort: GridSortDirection } = { field: 'created_at', sort: 'desc' }

const initialGridState = {
    sorting: {
        sortModel: [sortmodel]
    }
}

interface ExecutionsDataGridProps {
    instances: PipelineInstance[],
    isLoading?: boolean,
    onSelectionChange?: (selectedRows: PipelineInstance[]) => void
    slots?: Partial<GridSlots>
    excludeColumns?: string[]
    columnOrder?: string[]
    searchBar?: boolean
}

const ExecutionsDataGrid = ({ instances, isLoading, onSelectionChange, slots, excludeColumns = ['cost'], columnOrder = [], searchBar = false }: ExecutionsDataGridProps) => {
    const navigate = useNavigate()
    const { data: models } = useGetPipelineModelsQuery()
    const { data: users } = useGetUsersQuery()

    const columns: GridColDef<PipelineInstance>[] = useMemo(() => ([
        {
            field: 'name',
            headerName: 'Nombre de la ejecución',
            width: 550,
            renderCell: (params) => <NameColumn params={params} navigate={navigate} />
        },
        {
            field: 'created_at',
            headerName: 'Fecha de creación',
            type: "dateTime",
            width: 200,
            valueGetter: (value, row) => new Date(row.created_at),
            valueFormatter: (value, row) => dateFormat(row.created_at)
        },
        {
            field: 'status',
            headerName: 'Estado',
            width: 150,
            type: 'singleSelect',
            valueOptions: Object.keys(statusEnum).map((status) => ({ value: status, data: statusEnum[status as StatusType] })),
            getOptionLabel: (option: any) => option.data.name,
            getOptionValue: (option: any) => option.value,
            renderCell: (params) => <StatusDisplay status={params.value} />
        },
        {
            field: 'pipeline_model_id',
            headerName: 'Proceso',
            width: 350,
            type: 'singleSelect',
            valueOptions: models?.map((model) => ({ value: model.id, data: model })),
            getOptionLabel: (option: any) => option.data.subtitle ? `${option.data.title}: ${option.data.subtitle}` : option.data.title,
            getOptionValue: (option: any) => option.value,
            renderCell: (params) => <ModelColumn id={params.value} navigate={navigate} modelName={params.formattedValue} />
        },
        {
            field: 'user_id',
            headerName: 'Usuario',
            width: 250,
            valueGetter: (value, row) => users?.find((user) => user.id === row.user_id)?.display_name,
        },
        {
            field: 'finished_at',
            headerName: 'Fecha de término',
            type: "dateTime",
            width: 200,
            valueGetter: (value, row) => row.finished_at ? new Date(row.finished_at) : null,
            valueFormatter: (value, row) => row.finished_at ? dateFormat(row.finished_at) : null,
        },
        {
            field: 'runtime',
            headerName: 'Tiempo (hh:mm:ss)',
            width: 150,
            valueFormatter: (value, row) => SecondsToStopWatch(row.runtime)
        },
        {
            field: 'cost',
            headerName: 'Costo (CRED.)',
            width: 200,
            valueFormatter: (value, row) => { pipelineInstancePriceDisplay(row) },
            renderCell: (params) => <Box>{pipelineInstancePriceDisplay(params.row)}</Box>
        },
        {
            field: 'actions',
            type: 'actions',
            getActions: (params) => [
                <GridActionsCellItem
                    icon={<MapIcon />}
                    onClick={() => navigate(`/processes/${params.row.pipeline_model_id}/instance/${params.row.id}/results`)}
                    label="Ver resultados"
                    disabled={params.row.status !== 'finished'}
                    showInMenu
                />,
                <GridActionsCellItem
                    icon={<ContentCopy />}
                    onClick={() => navigate(`/processes/${params.row.pipeline_model_id}/new_execution?copyFrom=${params.row.id}`)}
                    label="Duplicar"
                    showInMenu
                />,
            ]
        },
    ]), [navigate, models, users])

    const currentColumns = useMemo(() => {
        const filteredColumns = columns.filter((column) => !excludeColumns.includes(column.field));
        const orderedColumns = columnOrder
            .map((field) => filteredColumns.find((col) => col.field === field))
            .filter((col): col is GridColDef<PipelineInstance> => col !== undefined);
        const remainingColumns = filteredColumns.filter((col) => !columnOrder.includes(col.field));
        return [...orderedColumns, ...remainingColumns];
    }, [columns, excludeColumns, columnOrder]);

    const currentSlots = slots ? { ...defaultSlots, ...slots } : defaultSlots
    const onRowSelectionChange = useCallback((e: GridRowSelectionModel) => {
        if (onSelectionChange === undefined) return
        const currentSelectedRows = instances.filter((row: PipelineInstance) => e.includes(row.id))
        onSelectionChange(currentSelectedRows)
    }, [instances, onSelectionChange])

    const [filter, setFilter] = useState("")
    const apiRef = useGridApiRef()

    useDebouncyEffect(() => {
        apiRef.current.setQuickFilterValues(filter.trim().toLowerCase().split(','))
    }, 20, [filter, apiRef])

    return <Stack spacing={3} height={1} width={1} overflow='hidden' paddingTop={searchBar ? 1 : 0} boxSizing="border-box">
        {searchBar &&
            <TextField
                fullWidth
                placeholder="Buscar por nombre de la ejecución, estado, nombre del proceso o usuario"
                size="small"
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
                InputProps={{
                    startAdornment: <InputAdornment position="start">
                        <Icon>
                            <Search fontSize="inherit" />
                        </Icon>
                    </InputAdornment>
                }}
                sx={{ backgroundColor: 'white' }}
            />
        }
        <DataGrid
            rows={instances}
            loading={isLoading}
            columns={currentColumns}
            checkboxSelection
            disableRowSelectionOnClick
            onRowSelectionModelChange={onRowSelectionChange}
            initialState={initialGridState}
            slots={currentSlots}
            apiRef={apiRef}
            sx={{ backgroundColor: 'white' }}
        />
    </Stack>


}

export default ExecutionsDataGrid
