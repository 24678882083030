import { TileLayer, TileLayerProps } from "react-leaflet"

interface SentinelLayerProps extends Omit<TileLayerProps, "url" | "tileSize"> {
    format?: string
    initDate?: Date
    endDate?: Date
}


const SentinelLayer = ({ format = 'image/jpeg', initDate, endDate, ...options }: SentinelLayerProps) => {
    const url = `https://sh.dataspace.copernicus.eu/ogc/wmts/e88b26dd-90ae-42e1-a6e0-645c9e7a4dca?REQUEST=GetTile&SERVICE=WMTS&TILEMATRIXSET=PopularWebMercator512&TILEMATRIX={z}&TILECOL={x}&TILEROW={y}&LAYER=TRUE-COLOR&FORMAT=${format}`
    if(initDate || endDate) {
        const init = initDate ? initDate.toISOString().split('T')[0] : endDate?.toISOString().split('T')[0]
        const end = endDate ? endDate.toISOString().split('T')[0] : initDate?.toISOString().split('T')[0]
        return <TileLayer url={`${url}&TIME=${init}/${end}`} {...options} tileSize={512}/>
    }
    return <TileLayer url={url} {...options} tileSize={512}/>
}

export default SentinelLayer;
