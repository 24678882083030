import useTimeoutRedirect from "../components/useTimeoutRedirect"

function Unauthorized() {
    useTimeoutRedirect()

    return (
        <div>
            <h1>Unauthorized</h1>
            <p>Sorry, you are not authorized to view this page.</p>
        </div>
    )
}

export default Unauthorized
