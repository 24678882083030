import { ImageOverlay, useMap } from "react-leaflet"
import WktLayer from "../../../common/utils/WktLayer"
import { useGetGeometryQuery, useGetRasterQuery } from "../../api/processApi"
import { ConfigGeometryInput, ConfigInput, ConfigRasterInput } from "../../types/DataTypes"
import { wktToBounds } from "../../../common/utils/tools"
import { useEffect, useMemo } from "react"

const GeomMapDB = ({ config, data, pane }: { config: ConfigGeometryInput, data: { [key: string]: any }, pane?: string }) => {
    const { data: geometry } = useGetGeometryQuery(data.id)
    const map = useMap()
    useEffect(() => {
        config.processBbox && geometry && geometry.preview_bbox && map.setMaxBounds(wktToBounds(geometry.preview_bbox)) && map.fitBounds(wktToBounds(geometry.preview_bbox))
    }, [geometry, map, config])
    return geometry && geometry.preview && geometry.preview_bbox ? <ImageOverlay url={geometry.preview} bounds={wktToBounds(geometry.preview_bbox)} pane={pane} /> : null
}

const GeomMapValue = ({ config, data, pane }: { config: ConfigGeometryInput, data?: { [key: string]: any }, pane?: string }) => {
    const map = useMap()
    const bounds = wktToBounds(data?.payload.value)
    useEffect(() => {
        config.processBbox && bounds && map.setMaxBounds(bounds) && map.fitBounds(bounds)
    }, [bounds, map, config])
    return data ? <WktLayer wktData={data.payload.value} pathOptions={{ opacity: 1, fillOpacity: 0 }} pane={pane} /> : null
}

const GeomMap = ({ config, data, pane }: { config: ConfigGeometryInput, data?: { [key: string]: any }, pane?: string }) => (
    data?.id ? <GeomMapDB config={config} data={data} pane={pane} /> : <GeomMapValue config={config} data={data} pane={pane} />
)

const RasterMap = ({ config, data, pane }: { config: ConfigRasterInput, data?: { [key: string]: any }, pane?: string }) => {
    const map = useMap()
    const { data: rasterData } = useGetRasterQuery(data?.id)

    const bounds = rasterData && wktToBounds(rasterData.preview_bbox)
    useEffect(() => {
        config.processBbox && bounds && map.setMaxBounds(bounds) && map.fitBounds(bounds)
    }, [bounds, map, config])

    return rasterData && rasterData.preview ? <ImageOverlay url={rasterData.preview} bounds={wktToBounds(rasterData.preview_bbox)} pane={pane} /> : null
}

const ListMap = ({ config, data, pane }: { config: ConfigInput, data: { [key: string]: any }, pane?: string }) => {
    const items = useMemo(() => {
        if(config.subType === undefined || data === undefined) return null
        switch (config.subType) {
            case "geom":
                return data.map((item: { [key: string]: any }) => <GeomMap key={item.id} config={config as ConfigGeometryInput} data={item} pane={pane} />)
            case "raster":
                return data.map((item: { [key: string]: any }) => <RasterMap key={item.id} config={config as ConfigRasterInput} data={item} pane={pane} />)
            default:
                return null
        }
    }, [data, config, pane])
    return <>
    {items}
    </>
}


const ExecutionDetailsMap = ({ format, data, pane }: { format: { [key: string]: any }, data: { [key: string]: any }, pane?: string }) => {
    return <>
        {Object.keys(format).map(key => {
            const config = format[key] as ConfigInput
            switch (config.type) {
                case "geom":
                    return <GeomMap key={key} config={config as ConfigGeometryInput} data={data[key]} pane={pane} />
                case "raster":
                    return <RasterMap key={key} config={config as ConfigRasterInput} data={data[key]} pane={pane} />
                case "list":
                    return <ListMap key={key} config={config} data={data[key]} pane={pane} />
                default:
                    return null
            }
        })}
    </>
}

export default ExecutionDetailsMap