import { NodeProps } from "reactflow";
import { DataNodeProps } from "../types/NodesTypes";
import { Box, CircularProgress, FormControl, InputLabel, MenuItem, Paper, Select, Stack, Typography } from "@mui/material";
import { memo, useState } from "react";
import { useGetImagesQuery } from "../../process/api/processApi";
import ModelHandle from "./ModelHandle";
import { useSelector } from "react-redux";
import { selectAllAnalysisRenderLayers } from "../../analysis/state/analysisSlice";

const ImageInput = ({ id }: { id: string }) => {
    const [imageId, setImageId] = useState('')
    const { data: images } = useGetImagesQuery({origin: 'library'})
    return (
        <Box sx={{ padding: 3 }}>
            <FormControl fullWidth className="nodrag">
                <InputLabel id={`${id}_label_id`}>Select image</InputLabel>
                {images
                    ? <Select
                        labelId={`${id}_label_id`}
                        id={`${id}_select_id`}
                        value={imageId}
                        label="Select Image"
                        onChange={(e) => setImageId(e.target.value)}
                    >
                        {images.map(image => <MenuItem value={image.id} key={image.id}>{image.name}</MenuItem>)}
                    </Select>
                    : <CircularProgress />
                }
            </FormControl>
        </Box>
    )
}

const LayerInput = ({ id }: { id: string }) => {
    const [layerId, setLayerId] = useState('')
    const layers = useSelector(selectAllAnalysisRenderLayers)
    return (
        <Box sx={{ padding: 3 }}>
            <FormControl fullWidth className="nodrag">
                <InputLabel id={`${id}_label_id`}>Select layer</InputLabel>
                {layers
                    ? <Select
                        labelId={`${id}_label_id`}
                        id={`${id}_select_id`}
                        value={layerId}
                        label="Select Image"
                        onChange={(e) => setLayerId(e.target.value)}
                    >
                        <MenuItem value="idasdsa">Predios</MenuItem>
                        <MenuItem value="vmeklemsd">Catastro 2024</MenuItem>
                        {layers.map(layer => <MenuItem value={layer.id} key={layer.id}>{layer.title}</MenuItem>)}
                    </Select>
                    : <CircularProgress />
                }
            </FormControl>
        </Box>
    )
}

const DataNode = memo(({ data, selected, id }: NodeProps<DataNodeProps>) => {
    if(data === undefined) return null
    return (
        <Paper
            sx={{
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: '#80ff72',
                overflow: 'hidden',
                width: 300,
                borderRadius: 5,
            }}
            elevation={selected ? 5 : 1}
        >
            <Box sx={{ padding: 1 }}>
                <Typography textAlign='center' variant="h6">{data.name}</Typography>
            </Box>
            {data.type === "image"
                ? <ImageInput id={id} />
                : <LayerInput id={id} />
            }
            <Stack spacing={2} direction="row" padding={2}>
                <Stack spacing={1} flexGrow={1}>
                    {data.inputs.map(input => <Stack spacing={1} direction={'row'} alignItems={'center'} key={input.id}>
                        <ModelHandle type="target" props={input} />
                        <Typography>{input.name}</Typography>
                    </Stack>)}
                </Stack>
                <Stack spacing={1} flexGrow={1}>
                    {data.outputs.map(output => <Stack spacing={1} direction='row-reverse' alignItems='center' key={output.id}>
                        <ModelHandle type="source" props={output} />
                        <Typography>{output.name}</Typography>
                    </Stack>)}
                </Stack>
            </Stack>
        </Paper>
    )
})

export default DataNode
