import { StatusType } from "../../process/types/DataTypes"

export interface SFExecutionEvent {
    timestamp: string
    type: SFExecutionEventType
    id: number
    previousEventId: number
    stateEnteredEventDetails?: {
        name: string
    }
    stateExitedEventDetails?: {
        name: string
    }
}

export interface DefinitionState {
    Type: string;
    [key: string]: any;
}

export interface SFStateMachineDefinition {
    Comment: string
    StartAt: string
    States: {[key: string]: DefinitionState}
}

export interface PipelineInstanceDetail{
    state_machine_definition: SFStateMachineDefinition
    execution_history: SFExecutionEvent[]
}

export interface SFStateDisplay {
    name: string
    status: string
    started_at: string
    finished_at: string
    duration: number
}

export interface SFExecutionStateDisplay extends SFStateDisplay {
    status: string
}

export interface SFEventDisplay {
    id: number
    type: string
    name: string | undefined
    started_at: string
    started_after: number
}

export interface SFExecutionGraphNode{
    id: string
    data: {
        label: string
        status: string
    }
}

export interface SFExecutionGraphEdge{
    id: string
    source: string
    target: string
    isParallelSuccess?: boolean
}

export interface SFExecutionGraph {
    nodes: SFExecutionGraphNode[]
    edges: SFExecutionGraphEdge[]
}

export type SFExecutionEventType = "ActivityFailed" | "ActivityScheduled" | "ActivityScheduleFailed" | "ActivityStarted" | "ActivitySucceeded" | "ActivityTimedOut" | "ChoiceStateEntered" | "ChoiceStateExited" | "ExecutionAborted" | "ExecutionFailed" | "ExecutionStarted" | "ExecutionSucceeded" | "ExecutionTimedOut" | "FailStateEntered" | "LambdaFunctionFailed" | "LambdaFunctionScheduled" | "LambdaFunctionScheduleFailed" | "LambdaFunctionStarted" | "LambdaFunctionStartFailed" | "LambdaFunctionSucceeded" | "LambdaFunctionTimedOut" | "MapIterationAborted" | "MapIterationFailed" | "MapIterationStarted" | "MapIterationSucceeded" | "MapStateAborted" | "MapStateEntered" | "MapStateExited" | "MapStateFailed" | "MapStateStarted" | "MapStateSucceeded" | "ParallelStateAborted" | "ParallelStateEntered" | "ParallelStateExited" | "ParallelStateFailed" | "ParallelStateStarted" | "ParallelStateSucceeded" | "PassStateEntered" | "PassStateExited" | "SucceedStateEntered" | "SucceedStateExited" | "TaskFailed" | "TaskScheduled" | "TaskStarted" | "TaskStartFailed" | "TaskStateAborted" | "TaskStateEntered" | "TaskStateExited" | "TaskSubmitFailed" | "TaskSubmitted" | "TaskSucceeded" | "TaskTimedOut" | "WaitStateAborted" | "WaitStateEntered" | "WaitStateExited" | "MapRunAborted" | "MapRunFailed" | "MapRunStarted" | "MapRunSucceeded" | "ExecutionRedriven" | "MapRunRedriven"

export const EVENT_TYPE_STATUS: {[key in SFExecutionEventType]: StatusType} = {
    "ActivityFailed": "failed",
    "ActivityScheduled": "queued",
    "ActivityScheduleFailed": "failed",
    "ActivityStarted": "running",
    "ActivitySucceeded": "finished",
    "ActivityTimedOut": "canceled",
    "ChoiceStateEntered": "running",
    "ChoiceStateExited": "finished",
    "ExecutionAborted": "canceled",
    "ExecutionFailed": "failed",
    "ExecutionStarted": "finished",
    "ExecutionSucceeded": "finished",
    "ExecutionTimedOut": "canceled",
    "FailStateEntered": "failed",
    "LambdaFunctionFailed": "failed",
    "LambdaFunctionScheduled": "queued",
    "LambdaFunctionScheduleFailed": "failed",
    "LambdaFunctionStarted": "running",
    "LambdaFunctionStartFailed": "failed",
    "LambdaFunctionSucceeded": "finished",
    "LambdaFunctionTimedOut": "canceled",
    "MapIterationAborted": "canceled",
    "MapIterationFailed": "failed",
    "MapIterationStarted": "running",
    "MapIterationSucceeded": "finished",
    "MapStateAborted": "canceled",
    "MapStateEntered": "running",
    "MapStateExited": "finished",
    "MapStateFailed": "failed",
    "MapStateStarted": "running",
    "MapStateSucceeded": "finished",
    "ParallelStateAborted": "canceled",
    "ParallelStateEntered": "running",
    "ParallelStateExited": "finished",
    "ParallelStateFailed": "failed",
    "ParallelStateStarted": "running",
    "ParallelStateSucceeded": "finished",
    "PassStateEntered": "running",
    "PassStateExited": "finished",
    "SucceedStateEntered": "running",
    "SucceedStateExited": "finished",
    "TaskFailed": "failed",
    "TaskScheduled": "queued",
    "TaskStarted": "running",
    "TaskStartFailed": "failed",
    "TaskStateAborted": "canceled",
    "TaskStateEntered": "running",
    "TaskStateExited": "finished",
    "TaskSubmitFailed": "failed",
    "TaskSubmitted": "running",
    "TaskSucceeded": "finished",
    "TaskTimedOut": "canceled",
    "WaitStateAborted": "canceled",
    "WaitStateEntered": "running",
    "WaitStateExited": "finished",
    "MapRunAborted": "canceled",
    "MapRunFailed": "failed",
    "MapRunStarted": "running",
    "MapRunSucceeded": "finished",
    "ExecutionRedriven": "running",
    "MapRunRedriven": "running",
}
