import { geodesicArea, latLngBoundsToWKT, wktToBounds, wktToVertexs } from "../../common/utils/tools"
import { PipelineModel } from "../types/DataTypes"

const getValue = (obj: any, key: string): string => {
    try {
        const keys = key.split(".")
        if (keys.length === 1) {
            return obj[key]
        }
        else {
            return getValue(obj[keys[0]], keys.slice(1).join("."))
        }
    }
    catch (e) {
        return ""
    }
}

const replaceModelData = (eq: string, model: PipelineModel): string => {
    const re = /Model.([\w.]*)/g
    return eq.replace(re, (match, p1) => {
        return getValue(model, p1)
    })
}

const replaceInputs = (eq: string, inputData: any): string => {
    const re = /Inputs.([\w.]*)/g
    return eq.replace(re, (match, p1) => {
        return getValue(inputData, p1)
    })
}

const evaluateFunctions = (eq: string): string => {
    const re = /Utils\.([\w]*)\(([-,\w.\s(\d)]*)\)/g
    return eq.replace(re, (match, p1, p2) => {
        if (re.test(p2)) {
            p2 = evaluateFunctions(p2)
        }
        switch (p1) {
            case "area":
                return geodesicArea(wktToVertexs(p2)).toString()
            case "bbox":
                return latLngBoundsToWKT(wktToBounds(p2)).toString()
            default:
                return ""
        }
    })
}


export const calculateModelEquation = (equation: string | undefined, model: PipelineModel, inputData: any): number => {
    if (equation === undefined) return 0

    try {
        const eq = replaceInputs(equation, inputData)
        const eq2 = evaluateFunctions(eq)
        const eq3 = replaceModelData(eq2, model)
        // eslint-disable-next-line no-new-func
        const result = Function(`return ${eq3}`)()
        return result === undefined || result === "" || (typeof result !== "number") ? 0 : result
    }
    catch (e) {
        return 0
    }
}