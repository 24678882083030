import { Box, CircularProgress, Container, Divider, Grid, TextField, Typography } from "@mui/material";
import { useGetCurrentUserInfoQuery, useGetOrgCreditsQuery } from "../api/authApi";
import { User } from "../types/UserTypes";
import { dateFormat } from "../../common/components/UI/general/InfoDisplay";


const ProfilePage = ({ userData, creditsData }: { userData: User, creditsData: {credits: number} }) => {
    return <Box sx={{ height: 1, width: 1, overflow: 'auto' }}>
        <Container>
            <Typography variant="h4" margin={5} textAlign='center'>Perfil</Typography>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} alignItems="center">
                <Grid item xs={4}>
                    <Typography>Nombre</Typography>
                </Grid>
                <Grid item xs={8}>
                    <TextField fullWidth value={userData.display_name} sx={{ marginY: 5 }} />
                </Grid>
                <Grid item xs={12}>
                    <Divider />
                </Grid>
                <Grid item xs={4}>
                    <Typography>Nombre de usuario</Typography>
                </Grid>
                <Grid item xs={8}>
                    <TextField fullWidth value={userData.username} sx={{ marginY: 5 }} />
                </Grid>
                <Grid item xs={12}>
                    <Divider />
                </Grid>
                <Grid item xs={4}>
                    <Typography>Email</Typography>
                </Grid>
                <Grid item xs={8}>
                    <TextField fullWidth value={userData.email} sx={{ marginY: 5 }} />
                </Grid>
                <Grid item xs={12}>
                    <Divider />
                </Grid>
                <Grid item xs={4}>
                    <Typography>Última vez ingresado</Typography>
                </Grid>
                <Grid item xs={8}>
                    <Typography marginY={5}>{dateFormat(userData.logged_at)}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Divider />
                </Grid>

                <Grid item xs={4}>
                    <Typography>Usuario creado</Typography>
                </Grid>
                <Grid item xs={8}>
                    <Typography marginY={5}>{dateFormat(userData.created_at)}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Divider />
                </Grid>

                <Grid item xs={4}>
                    <Typography>Créditos disponibles</Typography>
                </Grid>
                <Grid item xs={8}>
                    <Typography marginY={5}>{`${(creditsData.credits).toFixed(2)}`}</Typography>
                </Grid>
            </Grid>
        </Container>
    </Box>
}


const Profile = () => {
    const { data: userData, isSuccess: userIsSuccess } = useGetCurrentUserInfoQuery();
    const { data: creditsData, isSuccess: creditsIsSuccess } = useGetOrgCreditsQuery();

    return (
        <Box sx={{ height: 1, width: 1, overflow: 'auto' }}>
            {userIsSuccess && creditsIsSuccess
                ? <ProfilePage userData={userData} creditsData={creditsData} />
                : <Box sx={{ height: 1, width: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}><CircularProgress /></Box>
            }
        </Box>
    );
}

export default Profile;
