import { Stack } from "@mui/material"
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import dayjs, { Dayjs } from "dayjs"
import minMax from "dayjs/plugin/minMax"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { useDispatch, useSelector } from "react-redux"
import { AppDispatch, RootState } from "../../../app/state/store"
import { selectDateRangeById, updateProcessDateRange } from "../../state/processSlice"
import { safeDayjs } from "../../utils/DateTools"
import { createSelector } from "@reduxjs/toolkit"
import "dayjs/locale/es-mx"

dayjs.extend(minMax)

const selectInitMinMax = createSelector(
    [
        (state: RootState, id) => state.process.dateRange.entities[id].minRange,
        (state: RootState, id) => state.process.dateRange.entities[id].maxRange,
        (state: RootState, id) => state.process.dateRange.entities[id].minDate,
        (state: RootState, id) => state.process.dateRange.entities[id].maxDate,
        (state: RootState, id) => state.process.dateRange.entities[id].end
    ],
    (minRange, maxRange, minDate, maxDate, end) => {
        const endDate = safeDayjs(end)
        const mins = []
        minDate && mins.push(safeDayjs(minDate) as Dayjs)
        endDate && maxRange && mins.push(endDate.subtract(maxRange.value, maxRange.unit))

        const maxs = []
        maxDate && maxs.push(safeDayjs(maxDate) as Dayjs)
        endDate && minRange && maxs.push(endDate.subtract(minRange.value, minRange.unit))

        return {
            min: mins.length > 0 ? dayjs.max(mins) : undefined,
            max: maxs.length > 0 ? dayjs.min(maxs) : undefined
        } as { min: Dayjs | undefined, max: Dayjs | undefined }
    }
)

const selectEndMinMax = createSelector(
    [
        (state: RootState, id) => state.process.dateRange.entities[id].minRange,
        (state: RootState, id) => state.process.dateRange.entities[id].maxRange,
        (state: RootState, id) => state.process.dateRange.entities[id].minDate,
        (state: RootState, id) => state.process.dateRange.entities[id].maxDate,
        (state: RootState, id) => state.process.dateRange.entities[id].init
    ],
    (minRange, maxRange, minDate, maxDate, init) => {
        const initDate = safeDayjs(init)
        const mins = []
        minDate && mins.push(safeDayjs(minDate) as Dayjs)
        initDate && minRange && mins.push(initDate.add(minRange.value, minRange.unit))

        const maxs = []
        maxDate && maxs.push(safeDayjs(maxDate) as Dayjs)
        initDate && maxRange && maxs.push(initDate.add(maxRange.value, maxRange.unit))

        return {
            min: mins.length > 0 ? dayjs.max(mins) : undefined,
            max: maxs.length > 0 ? dayjs.min(maxs) : undefined
        } as { min: Dayjs | undefined, max: Dayjs | undefined }
    }
)

const RangeDateProcessInput = ({ id }: { id: string }) => {
    const processDateRange = useSelector((state: RootState) => selectDateRangeById(state, id))

    const dispatch = useDispatch<AppDispatch>()

    const minMaxInitDate = useSelector((state: RootState) => selectInitMinMax(state, id))
    const minMaxEndDate = useSelector((state: RootState) => selectEndMinMax(state, id))

    return <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es-mx">
        <Stack direction='row' spacing={3} useFlexGap flexWrap='wrap'>
            <DatePicker
                format='DD/MM/YYYY'
                slotProps={{
                    field: { clearable: true, onClear: () => dispatch(updateProcessDateRange({ id: id, changes: { init: undefined } })) },
                }}
                label={processDateRange.initDate.name}
                value={safeDayjs(processDateRange.init) ? safeDayjs(processDateRange.init) : null}
                onChange={newValue => {
                    newValue && newValue.isValid() && dispatch(updateProcessDateRange({ id: id, changes: { init: newValue.toISOString() } }))
                }}
                minDate={minMaxInitDate.min}
                maxDate={minMaxInitDate.max}
                sx={{ flexGrow: 1 }}
            />
            <DatePicker
                format='DD/MM/YYYY'
                slotProps={{
                    field: { clearable: true, onClear: () => dispatch(updateProcessDateRange({ id: id, changes: { end: undefined } })) },
                }}
                label={processDateRange.endDate.name}
                value={safeDayjs(processDateRange.end) ? safeDayjs(processDateRange.end) : null}
                onChange={newValue => {
                    newValue && newValue.isValid() && dispatch(updateProcessDateRange({ id: id, changes: { end: newValue.toISOString() } }))
                }}
                minDate={minMaxEndDate.min}
                maxDate={minMaxEndDate.max}
                sx={{ flexGrow: 1 }}
            />
        </Stack>
    </LocalizationProvider>
}

export default RangeDateProcessInput