import { useDispatch, useSelector } from "react-redux";
import { Box, Checkbox, Collapse, Divider, InputAdornment, Stack, TextField, Typography } from "@mui/material";
import { selectRiskLayerById, selectRiskLayerValue, selectRiskResultById, selectSelectedPlace, updateRiskLayer, updateRiskResult } from "../../state/riskSlice";
import { useCallback, useState } from "react";
import { AppDispatch, RootState } from "../../../app/state/store";
import { Settings } from "@mui/icons-material";

const RiskParameter = ({ id, defaultValue, parameter, changeFunc, symbol }: { id: string, defaultValue: number, parameter: string, changeFunc: any, symbol: string }) => {
    const [value, setValue] = useState(defaultValue.toString())
    const [error, setError] = useState(false)

    return <TextField
        type="text"
        error={error}
        helperText={error && "No es un número"}
        value={value}
        InputProps={{
            startAdornment: <InputAdornment position="start">{decodeURI(symbol) + ":"}</InputAdornment>,
        }}
        onChange={e => {
            if (!isNaN(Number(e.target.value))) {
                changeFunc(id, parameter, Number(e.target.value))
                setError(false)
            }
            else {
                setError(true)
            }

            setValue(e.target.value)
        }}
        sx={{width: '150px'}}
    />
}

const RiskRowConfiguration = ({ layerId }: { layerId: string }) => {
    const riskResult = useSelector((state: RootState) => selectRiskResultById(state, layerId))
    const riskLayer = useSelector((state: RootState) => selectRiskLayerById(state, riskResult.name))

    //const total = useSelector((state: RootState) => selectRiskLayerValue(state, layerId))

    const dispatch = useDispatch<AppDispatch>()

    const changeParameter = useCallback((id: string, parameter: string, value: number) => dispatch(updateRiskLayer({ id: id, changes: { [parameter]: value } })), [dispatch])

    const onChangeEq = useCallback((e: any) => {
        dispatch(updateRiskLayer({ id: riskLayer.id, changes: { equation: e.target.value } }))
    }, [dispatch, riskLayer])

    return <>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Typography flexGrow={1} textAlign="right" paddingRight={2}>Riesgo = </Typography>
            <TextField sx={{ flexGrow: 3 }} value={riskLayer.equation} onChange={onChangeEq} />
        </Box>
        <Typography variant="h6" marginTop={2}>Point data</Typography>
        <Stack spacing={{ xs: 1, sm: 2 }} direction="row" useFlexGap flexWrap="wrap">
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <Typography sx={{width: '100px'}}>{"Distance (m):"}</Typography>
                <TextField disabled defaultValue={riskResult.distance.toFixed(2)} InputProps={{
                    startAdornment: <InputAdornment position="start">dist:</InputAdornment>,
                }} sx={{width: '150px'}}/>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <Typography sx={{width: '100px'}}>Area (m<sup>2</sup>):</Typography>
                <TextField disabled defaultValue={riskResult.area.toFixed(2)} InputProps={{
                    startAdornment: <InputAdornment position="start">area:</InputAdornment>,
                }} sx={{width: '150px'}}/>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <Typography sx={{width: '100px'}}>{"Area (%):"}</Typography>
                <TextField disabled defaultValue={riskResult.percentage.toFixed(2)} InputProps={{
                    startAdornment: <InputAdornment position="start">p:</InputAdornment>,
                }} sx={{width: '150px'}}/>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <Typography sx={{width: '100px'}}>Risk weight:</Typography>
                <TextField disabled defaultValue={riskResult.riskWeight} InputProps={{
                    startAdornment: <InputAdornment position="start">rw:</InputAdornment>,
                }} sx={{width: '150px'}}/>
            </Box>
        </Stack>
        <Typography variant="h6" marginTop={2}>Layers constants</Typography>
        <Stack spacing={{ xs: 1, sm: 2 }} direction="row" useFlexGap flexWrap="wrap">
            <RiskParameter changeFunc={changeParameter} defaultValue={riskLayer.alpha} id={riskLayer.id} parameter="alpha" symbol="a" />
            <RiskParameter changeFunc={changeParameter} defaultValue={riskLayer.beta} id={riskLayer.id} parameter="beta" symbol="b" />
            <RiskParameter changeFunc={changeParameter} defaultValue={riskLayer.gamma} id={riskLayer.id} parameter="gamma" symbol="g" />
            <RiskParameter changeFunc={changeParameter} defaultValue={riskLayer.theta} id={riskLayer.id} parameter="theta" symbol="th" />
        </Stack>
    </>
}

const RiskRow = ({ id }: { id: string }) => {
    const riskResult = useSelector((state: RootState) => selectRiskResultById(state, id))
    const riskLayer = useSelector((state: RootState) => selectRiskLayerById(state, riskResult.name))

    const total = useSelector((state: RootState) => selectRiskLayerValue(state, id))

    const dispatch = useDispatch<AppDispatch>()
    const onHover = useCallback(() => dispatch(updateRiskResult({ id: id, changes: { showGeom: true } })), [id, dispatch])
    const onLeave = useCallback(() => dispatch(updateRiskResult({ id: id, changes: { showGeom: false } })), [id, dispatch])

    const [showSettings, setShowSettings] = useState(false)

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', width: 1, marginTop: 1 }} onMouseEnter={onHover} onMouseLeave={onLeave}>
            <Box sx={{ display: 'flex', flexDirection: 'row', padding: 2, alignItems: 'center' }}>
                <Typography sx={{ flexGrow: 1 }} variant="h5">{riskLayer.name}</Typography>
                <Typography>{total.toFixed(4)}</Typography>
                <Checkbox
                    icon={<Settings />}
                    checkedIcon={<Settings />}
                    onChange={(e, val) => setShowSettings(val)}
                />
            </Box>
            <Collapse in={showSettings} sx={{ marginBottom: 2 }}>
                <RiskRowConfiguration layerId={id} />
            </Collapse>
            <Divider flexItem />
        </Box >
    )
}

const RiskAnalysis = () => {
    const place = useSelector(selectSelectedPlace)

    return <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        {place.layers.map((id, index) => <RiskRow id={place.id + "###" + id} key={index} />)}
    </Box>
}

export default RiskAnalysis;
