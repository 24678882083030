import { Box, CircularProgress, CircularProgressProps } from "@mui/material"

const CenteredCircularProgress = (props: CircularProgressProps) => {
  return (
    <Box sx={{display: 'flex', width: 1, height: 1, alignItems: 'center', justifyContent: 'center'}}>
      <CircularProgress {...props}/>
    </Box>
  );
}

export default CenteredCircularProgress