const userRoles = {
    admin: "admin",
    risk: "risk",
    label: "label",
    inference: "inference"

}


export { userRoles }
