import PolygonTool from "../../../common/components/map/tools/PolygonTool"
import { LatLng } from "leaflet"
import { leafletPolygonToWKT, wktToVertexs } from "../../../common/utils/tools"
import WktLayer from "../../../common/utils/WktLayer"
import RectangleTool from "../../../common/components/map/tools/RectangleTool"
import { useDispatch, useSelector } from "react-redux"
import { editProcessGeometry, ProcessGeometry, selectProcessGeoemtriesIds, selectProcessGeometryById, updateProcessGeometry } from "../../state/processSlice"
import { RootState } from "../../../app/state/store"
import { Button, Divider, Stack } from "@mui/material"
import { Delete, Save } from "@mui/icons-material"

const PolygonProcessDraw = ({ polygon }: { polygon: ProcessGeometry }) => {
    const dispatch = useDispatch()
    return <>
        {polygon.editing && polygon.editingTool === "polygon" &&
            <PolygonTool
                setPolygon={
                    (e: LatLng[]) => {
                        const wkt = leafletPolygonToWKT(e)
                        dispatch(updateProcessGeometry({ id: polygon.id, changes: { geom: wkt } }))
                    }
                }
                initVertexs={polygon.geom !== "" ? wktToVertexs(polygon.geom) : undefined}
                barContent={
                    <Stack direction="row" divider={<Divider flexItem orientation="vertical" />} spacing={1}>
                        <Button startIcon={<Save />} onClick={() => dispatch(editProcessGeometry(""))}>
                            Guardar
                        </Button>
                        <Button startIcon={<Delete />} onClick={() => {
                            dispatch(updateProcessGeometry({ id: polygon.id, changes: { geom: "" } }))
                            dispatch(editProcessGeometry(""))
                        }}>
                            Borrar
                        </Button>
                    </Stack>
                }
            />
        }
        {polygon.editing && polygon.editingTool === "rectangle" &&
            <RectangleTool
                setPolygon={(e: LatLng[]) => {
                    const wkt = leafletPolygonToWKT(e)
                    dispatch(updateProcessGeometry({ id: polygon.id, changes: { geom: wkt } }))
                    dispatch(editProcessGeometry(""))
                }}
            />
        }
        {!polygon.editing && polygon.visible &&
            <WktLayer
                wktData={polygon.geom}
                pathOptions={{ color: polygon.color }}
                onClick={() => {
                    dispatch(updateProcessGeometry({ id: polygon.id, changes: { editingTool: "polygon" } }))
                    dispatch(editProcessGeometry(polygon.id))
                }}
            />
        }
    </>
}


const GeometryProcessDraw = ({ id }: { id: string }) => {
    const geometry = useSelector((state: RootState) => selectProcessGeometryById(state, id))
    switch (geometry.geomType) {
        case "Polygon":
            return <PolygonProcessDraw polygon={geometry} />
        default:
            return null
    }
}

const GeometriesProcessDraw = () => {

    const geometries = useSelector(selectProcessGeoemtriesIds)

    return <>
        {geometries.map(id => <GeometryProcessDraw id={id} key={id} />)}
    </>
}

export default GeometriesProcessDraw