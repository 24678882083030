import { useDispatch } from "react-redux";
import { AppDispatch } from "../../app/state/store";
import { logOut } from "../state/authSlice";
import { useNavigate } from "react-router-dom";
import { MenuItem, Typography } from "@mui/material";
import { gisApi } from "../../common/api/redux_api";

function LogOut() {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const handleLogout = () => {
        dispatch(logOut());
        dispatch(gisApi.util.resetApiState());
        navigate('/login');
    }
    return (
        <MenuItem onClick={() => {
            handleLogout();
        }}>
            <Typography textAlign="center">Cerrar sesión</Typography>
        </MenuItem>
    );
}

export default LogOut;
