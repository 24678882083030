import { Visibility, VisibilityOff } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";

export function ToggleVisibility({tooltip, show, setShow}: {tooltip: string, show: boolean, setShow: React.Dispatch<React.SetStateAction<boolean>>}) {
    return (
        <Tooltip title={`${show ? 'Ocultar' : 'Mostrar'} ${tooltip}`}>
            <IconButton onClick={() => setShow(prev => !prev)}>
                {show
                    ? <Visibility color="primary" />
                    : <VisibilityOff />
                }
            </IconButton>
        </Tooltip>
    )
}
