import { Autocomplete, Box, Slider, TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import chileTiles from "../../static/tiles";
import { FilterContext } from "../../views/DashBoard";
import { useContext } from "react";


function TileSelect() {
    const { selectedTiles, setSelectedTiles  } = useContext(FilterContext);

    return (
        <Autocomplete
            value={selectedTiles}
            onChange={(e, value) =>{
                setSelectedTiles(value);
            }}
            multiple
            id="tiles-filter"
            options={chileTiles}
            getOptionLabel={(option) => option.name}
            filterSelectedOptions
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="Tile(s)"
                    placeholder="Tiles"
                    name="tiles"
                />
            )}
        />
    );
}

interface CloudSliderProps {
    value: number;
    handleFormChange: (e: any) => void;
}

function CloudSlider({value, handleFormChange}: CloudSliderProps) {
    return (
        <Box>
            <Slider name="cloud" value={value} onChange={(e) => {
                handleFormChange(e);
            }} aria-label="Default" valueLabelDisplay="auto" />
        </Box>
    );
}

interface DateSelectProps {
    label: string;
    name: string;
    value: dayjs.Dayjs;
    handleFormChange: (e: any) => void;
}

function DateSelect({label, name, value, handleFormChange}: DateSelectProps){
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker format='DD/MM/YYYY' label={label} name={name} value={value} onChange={
                (e) => {
                    handleFormChange({target: {name: name, value: e}});
                }
            } />
        </LocalizationProvider>
    );
}


function getDate(lastMonth=false) {
    const today = new Date();
    lastMonth && today.setMonth(today.getMonth()-1);
    return dayjs(today);
}

export { TileSelect, CloudSlider, DateSelect, getDate };
