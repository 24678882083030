import { Box, Button, Stack, Typography, useTheme } from "@mui/material"
import { useGetPipelineInstancesQuery } from "../api/processApi";
import ExecutionsDataGrid from "../components/common/ExecutionsDataGrid";
import { useNavigate } from "react-router-dom";

const ProcessDashboard = () => {
    const { data, isFetching } = useGetPipelineInstancesQuery(undefined, { pollingInterval: 30000, skipPollingIfUnfocused: true })
    const navigate = useNavigate()
    const theme = useTheme()

    return <Box
        sx={{
            display: 'flex',
            flexDirection: 'column',
            height: 1,
            width: 1,
            boxSizing: 'border-box',
            padding: 3,
            backgroundColor: theme.palette.background.default
        }}
    >
        <Stack spacing={3} height={1} width={1} overflow='hidden'>
            <Stack direction="row" alignItems="center" spacing={2}>
                <Typography variant="h4" flexGrow={1}>Mis ejecuciones</Typography>
                <Button variant="outlined" onClick={() => navigate('/processes')}>Nueva ejecución</Button>
            </Stack>
            <Box sx={{ flexGrow: 1, overflow: "hidden", width: 1 }}>
                <ExecutionsDataGrid instances={data ? data : []} isLoading={isFetching} searchBar />
            </Box>
        </Stack>
    </Box>

}

export default ProcessDashboard
