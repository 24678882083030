import { getRequest, postRequest } from '../../app/api/apiBase';

const searchApi = {
    get(tile: string, init_date: string, end_date: string, cloud: number) {
        return getRequest(
            `/admin/search`
        );
    },
    post(tile: string, init_date: string, end_date: string, cloud: number) {
        return postRequest(
            `/admin/search`,
            {
                'tile': tile,
                'init_date': init_date,
                'end_date': end_date,
                'cloud': cloud,
            }
        );
    }
};

const downloadApi = {
    get(uuid: string, token: string | null) {
        return getRequest(
            `/admin/download/${uuid}`,
            token
            );
    },
};

const requestApi = {
    post(uuid: string, tile: string, date: string, cloud: number, footprint: string, token: string | null) {
        return postRequest(
            `/admin/request`,
            {
                'uuid': uuid,
                'tile': tile,
                'date': date,
                'cloud': cloud,
                'footprint': footprint
            },
            token
        );
    }
};

const tilesApi = {
    get() {
        return getRequest(
            `/admin/tiles`
        )
    }
}

export { searchApi, downloadApi, requestApi, tilesApi };
