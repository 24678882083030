import { useDispatch, useSelector } from "react-redux";
import DrawPolygon from "../drawing/DrawPolygon";
import { loadPolygon, selectPolygons, selectTaskInfo } from "../../state/rovLabelSlice";
import { useEffect } from "react";
import { AppDispatch } from "../../../app/state/store";

function MapLabels() {
    const polygons = useSelector((state: any) => selectPolygons(state));
    const taskInfo = useSelector((state: any) => selectTaskInfo(state));
    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        if (!taskInfo.labels) return;
        for (let label of taskInfo.labels) {
            dispatch(loadPolygon({label: label}));
        }
    }, [taskInfo.labels, dispatch]);

    return (
        <>
            {Object.entries(polygons).map(([key, value]) => {
                return <DrawPolygon key={key} id={Number(key)} />
            })}
        </>
    );
}

export default MapLabels;
