import { Box, Collapse, IconButton, Popover, Stack, Switch, ToggleButton, ToggleButtonGroup, Tooltip, Typography } from "@mui/material"
import { useCallback, useEffect, useMemo, useState } from "react"
import { Circle, Clear, Delete, Edit, PentagonOutlined, RectangleOutlined, Save } from "@mui/icons-material"
import { FormatedArea } from "../../../common/utils/Measurements"
import { geodesicArea, wktToVertexs } from "../../../common/utils/tools"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../../app/state/store"
import { editProcessGeometry, selectProcessGeometryById, updateProcessGeometry } from "../../state/processSlice"
import AceEditor from 'react-ace'
import { parse, stringify } from "wellknown"
import { EolStyle, Formatter, FracturedJsonOptions } from "fracturedjsonjs"
import { HexColorPicker } from "react-colorful"

const GeometryProcessInput = ({ id }: { id: string }) => {
    const processGeometry = useSelector((state: RootState) => selectProcessGeometryById(state, id))
    const measure = geodesicArea(wktToVertexs(processGeometry.geom))
    const dispatch = useDispatch()

    const [showSettings, setShowSettings] = useState(false)
    const [editorFormat, setEditorFormat] = useState<"wkt" | "geojson">("wkt")
    const [localGeoJSON, setLocalGeoJSON] = useState('')

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

    const handleChange = (
        event: React.MouseEvent<HTMLElement>,
        newAlignment: "wkt" | "geojson",
    ) => {
        newAlignment && setEditorFormat(newAlignment)
    }

    const options = useMemo(() => {
        var o = new FracturedJsonOptions()
        o.MaxTotalLineLength = 100
        o.AlwaysExpandDepth = 1
        o.MaxInlineComplexity = 1
        o.MaxInlineLength = 50
        o.JsonEolStyle = EolStyle.Crlf;
        return o
    }, [])

    const formatter = useMemo(() => {
        var f = new Formatter()
        f.Options = options
        return f
    }, [options])

    const convertToGeoJson = useCallback((polygonWkt: string) => {
        const parsed = parse(polygonWkt)
        if (parsed) {
            const geoJSONText = formatter.Serialize(parsed)
            geoJSONText && setLocalGeoJSON(geoJSONText)
        }
        else {
            editorFormat === "wkt" && setLocalGeoJSON("")
        }
    }, [setLocalGeoJSON, formatter, editorFormat])

    const convertToWkt = useCallback((geoJson: any) => {
        try {
            const parsed = stringify(geoJson)
            if (parsed !== '') {
                dispatch(updateProcessGeometry({ id: id, changes: { geom: parsed } }))
            }
        } catch {
        }
    }, [dispatch, id])

    useEffect(() => {
        convertToGeoJson(processGeometry.geom)
    }, [processGeometry.geom, convertToGeoJson])

    return <>
        <Stack direction={"row"} spacing={1} alignItems="center">
            <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
                <FormatedArea area={measure} message="Dibuje en el mapa la zona a analizar"/>
            </Box>
            <Tooltip
                title={processGeometry.editing && processGeometry.editingTool === "rectangle" ? "Guardar" : "Dibujar rectángulo en el mapa"}
            >
                <IconButton
                    onClick={() => {
                        dispatch(updateProcessGeometry({ id: id, changes: { editingTool: "rectangle" } }))
                        dispatch(editProcessGeometry(processGeometry.editing ? "" : id))
                    }}
                >
                    {processGeometry.editing && processGeometry.editingTool === "rectangle" ? <Save /> : <RectangleOutlined />}

                </IconButton>
            </Tooltip>
            <Tooltip
                title={processGeometry.editing && processGeometry.editingTool === "polygon" ? "Guardar" : "Dibujar polígono en el mapa"}
            >
                <IconButton
                    onClick={() => {
                        dispatch(updateProcessGeometry({ id: id, changes: { editingTool: "polygon" } }))
                        dispatch(editProcessGeometry(processGeometry.editing ? "" : id))
                    }}
                >
                    {processGeometry.editing && processGeometry.editingTool === "polygon" ? <Save /> : <PentagonOutlined />}
                </IconButton>
            </Tooltip>
            <Tooltip
                title={showSettings ? "Ocultar detalles" : "Mostrar detalles"}
            >
                <IconButton
                    onClick={() => setShowSettings(prev => !prev)}
                >
                    {showSettings ? <Clear /> : <Edit />}
                </IconButton>
            </Tooltip>
        </Stack>
        <Collapse in={showSettings}>
            <Stack direction="row" alignItems="center">
                <Typography sx={{ flexGrow: 1 }}>Color</Typography>
                <IconButton style={{ color: processGeometry.color }} onClick={(e) => setAnchorEl(e.currentTarget)}>
                    <Circle />
                </IconButton>
            </Stack>
            <Stack direction="row" alignItems="center">
                <Typography sx={{ flexGrow: 1 }}>Visible</Typography>
                <Switch checked={processGeometry.visible} onChange={(e, check) => dispatch(updateProcessGeometry({ id: id, changes: { visible: check } }))} />
            </Stack>
            <Stack direction="row" alignItems="center">
                <Typography sx={{ flexGrow: 1 }}>Borrar</Typography>
                <IconButton onClick={() => dispatch(updateProcessGeometry({ id: id, changes: { geom: "" } }))}>
                    <Delete />
                </IconButton>
            </Stack>
            <ToggleButtonGroup
                color="primary"
                value={editorFormat}
                exclusive
                onChange={handleChange}
                sx={{ padding: 1 }}
            >
                <ToggleButton value="wkt">WKT</ToggleButton>
                <ToggleButton value="geojson">GeoJSON</ToggleButton>
            </ToggleButtonGroup>
            <Box sx={{ height: 400 }}>
                {editorFormat === "wkt" &&
                    <AceEditor
                        mode="text"
                        theme='monokai'
                        fontSize="16px"
                        value={processGeometry.geom}
                        onChange={(e) => {
                            dispatch(updateProcessGeometry({ id: id, changes: { geom: e } }))
                            dispatch(editProcessGeometry(""))
                            convertToGeoJson(e)
                        }}
                        setOptions={{
                            showLineNumbers: true,
                            tabSize: 2,
                            useWorker: false,
                            wrap: true,
                        }}
                        width='100%'
                        height='100%'
                    />
                }
                {editorFormat === "geojson" &&
                    <AceEditor
                        mode="json"
                        theme='monokai'
                        fontSize="16px"
                        value={localGeoJSON}
                        onChange={(e) => {
                            setLocalGeoJSON(e)
                            dispatch(editProcessGeometry(""))
                            try {
                                const geojson = JSON.parse(e)
                                convertToWkt(geojson)
                            } catch {
                                dispatch(updateProcessGeometry({ id: id, changes: { geom: "" } }))
                            }
                        }}
                        setOptions={{
                            showLineNumbers: true,
                            tabSize: 2,
                            useWorker: false,
                            wrap: true,
                        }}
                        width='100%'
                        height='100%'
                    />
                }
            </Box>
        </Collapse>
        <Popover
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={() => setAnchorEl(null)}
        >
            <Box sx={{ padding: 1 }}>
                <HexColorPicker color={processGeometry.color} onChange={(color) => dispatch(updateProcessGeometry({ id: id, changes: { color: color } }))} />
            </Box>
        </Popover>
    </>
}

export default GeometryProcessInput