import { IconButton, Popper, Tooltip } from "@mui/material";
import { useState } from "react";

const InfoButton = ({
    title,
    icon,
    children,
    placement = 'top',
} : {
    title: string,
    icon: any,
    children: any
    placement?: 'top' | 'top-start' | 'top-end'
}) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;

    return (
        <div>
            <Tooltip title={title} placement='bottom'>
                <IconButton
                    aria-describedby={id}
                    onClick={handleClick}
                    sx={{ backgroundColor: 'white', '&:hover': { backgroundColor: '#D5D8DC' } }}
                    size='large'
                >
                    {icon}
                </IconButton>
            </Tooltip>
            <Popper
                id={id}
                open={open}
                anchorEl={anchorEl}
                placement={placement}
                sx={{ padding: 1, zIndex: 2000 }}
                onMouseLeave={() => setAnchorEl(null)}
            >
                {children}
            </Popper>
        </div>
    );
}

export default InfoButton;
