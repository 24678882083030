import packageJson from '../../../package.json';
import "leaflet/dist/leaflet.css";
import FilterTab from '../components/FilterTab';
import { Box, Grid } from "@mui/material";
import { /* useEffect, */ createContext, useState } from "react";
import Results from '../components/Results';
// import { tilesApi } from "../api/gisApi";
import chileTiles from "../static/tiles";
import SelectPolygon from '../components/SelectPolygon';
import { Circle, LayersControl, MapContainer, Polygon, TileLayer } from "react-leaflet";
import { flipCoordinates, footprintColor } from "../utils/polygonConfigs";
import { CRS, LatLngExpression } from "leaflet";

interface TileType {
    id: number;
    name: string;
    geom: string;
}

interface FootprintType {
    type: string;
    coordinates: number[][][];
}

interface ResultsRowType {
    title: string;
    date: string;
    tile: string;
    cloud: number;
    uuid: string;
    footprint: string;
    requested: boolean;
    on_db: boolean;
    online: boolean;
}

interface ResultsContextProps {
    results: ResultsRowType[][];
    setFootprint: (footPrint: FootprintType) => void;
}

interface FilterContextProps {
    selectedTiles: TileType[];
    setSelectedTiles: (tiles: TileType[]) => void;
    setResults: (results: ResultsRowType[][]) => void;
}

const ResultsContext = createContext<ResultsContextProps>({results: [], setFootprint: (footprint: FootprintType) => {}});
const FilterContext = createContext<FilterContextProps>({selectedTiles: [], setSelectedTiles: (tiles: TileType[]) => {}, setResults: (results: ResultsRowType[][]) => {}});

function DashBoard() {
    const [results, setResults] = useState<ResultsRowType[][]>([]);
    const [selectedTiles, setSelectedTiles] = useState<TileType[]>([]);
    const [footprint, setFootprint] = useState<FootprintType>({type: "Polygon", coordinates: []});
    const [colorTiles, setColorTiles] = useState(false);

    const center : LatLngExpression = [-33.75, -71.05];
    const zoom: number = 6;
    const minZoom: number = 3;
    const maxZoom: number = 15;
    const crs: L.CRS = CRS.EPSG3857;

    //uncomment to fetch tiles from api instead of using the static file
    /*const [chileTiles, setChileTiles] = useState([]);

    useEffect(() => {
        const fetchTiles = async () => {
            console.log("fetching tiles")
            const tilesData = await tilesApi.get();
            console.log(tilesData);
            setChileTile(tilesData);

        };
        fetchTiles();
    }, []); */


    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', height: 1 }}>
            <Grid container flexGrow={1}>
                <Grid item xs={2}>
                    <FilterContext.Provider value={{ selectedTiles, setSelectedTiles, setResults }}>
                        <FilterTab/>
                    </FilterContext.Provider>
                </Grid>
                <Grid item xs={10}>
                    <MapContainer
                        center={center}
                        crs={crs}
                        zoom={zoom}
                        minZoom={minZoom}
                        maxZoom={maxZoom}
                    >
                        <TileLayer
                            attribution={`v${packageJson.dashboard_version}`}
                            url={'https://a.tile.openstreetmap.org/{z}/{x}/{y}.png'}
                            // maxZoom={11}
                            // url={'http://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}'}
                        />
                        <LayersControl position="bottomright">
                            <LayersControl.Overlay name="show Tiles">
                                {/* this is a hack to make the layer control work */}
                                <Circle eventHandlers={
                                    {
                                        add: (e: any) => {
                                            setColorTiles(!colorTiles);
                                        },
                                        remove: (e: any) => {
                                            setColorTiles(!colorTiles);
                                        }
                                    }} center={[-33.466743, -70.669720]} radius={0} pathOptions={{color: ''}}
                                />
                            </LayersControl.Overlay>
                        </LayersControl>
                        {chileTiles.map((tile) => {
                            const selectPolygonProps = {
                                tile: tile,
                                selectedTiles: selectedTiles,
                                setSelectedTiles: setSelectedTiles,
                                colorTiles: colorTiles
                            }
                            return (
                                <SelectPolygon key={tile.id} {...selectPolygonProps}/>
                            )
                        })}
                        <Polygon pathOptions={footprintColor}
                            positions={flipCoordinates(footprint.coordinates)}
                        />
                    </MapContainer>
                </Grid>
            </Grid>
            <ResultsContext.Provider value={{ results, setFootprint }}>
                <Results/>
            </ResultsContext.Provider>
        </Box>
    );
}

export type { TileType, FootprintType, ResultsRowType };
export { ResultsContext, FilterContext };
export default DashBoard;
