import { useEffect, useRef, useState } from "react"
import { GeoJSON } from "react-leaflet"

export function InteractiveGeoJSON({geojson, setSelectedFeature = undefined}: {geojson: any, setSelectedFeature?: any}) {
    const [feature, setFeature] = useState({})
    const polygonRef = useRef<any>()

    useEffect(() => {
        setSelectedFeature && setSelectedFeature(feature)
    }, [feature, setSelectedFeature])

    return <GeoJSON
        data={geojson}
        onEachFeature={(feature, layer) => {
            layer.on({
                'click': (e) => {
                    setFeature(feature)
                    polygonRef.current && polygonRef.current.setStyle({ color: '#3388ff' })
                    polygonRef.current = e.target
                    e.target.setStyle({ color: 'red' })
                },
                'mouseover': (e) => {
                    e.target !== polygonRef.current &&
                    e.target.setStyle({ color: 'green' })
                },
                'mouseout': (e) => {
                    e.target !== polygonRef.current &&
                    e.target.setStyle({ color: '#3388ff' })
                }
            })
        }}
    />
}
