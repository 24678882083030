import AceEditor from 'react-ace'
import { Box } from "@mui/material"
import { useState } from 'react'
import { GeoJSON } from 'react-leaflet'
import gjv from 'geojson-validation'
import L from 'leaflet'

import "ace-builds/src-noconflict/mode-text"
import "ace-builds/src-noconflict/mode-json"
import "ace-builds/src-noconflict/theme-monokai"

import "../../css/geojsonTable.css"

const geojsonMarkerOptions = {
    radius: 8,
    fillColor: "#ff7800",
    color: "#000",
    weight: 1,
    opacity: 1,
    fillOpacity: 0.8
};

const popupOptions = {
    minWidth: 100,
    maxWidth: 250,
    maxHeight: 300,
    className: "popup-classname"
};

const GeoJSONTab = () => {
    const [geoJsonString, setGeoJsonString] = useState('')
    const [geoJSON, setGeoJson] = useState()

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', overflow: 'hidden', height: 1}}>
            <AceEditor
                mode="json"
                theme='monokai'
                fontSize="16px"
                value={geoJsonString}
                onChange={(e) => {
                    setGeoJsonString(e)
                    try {
                        const parsedGeoJSON = JSON.parse(e)
                        if (
                            gjv.isGeoJSONObject(parsedGeoJSON)
                        ) {
                            setGeoJson(parsedGeoJSON)
                        }
                    } catch {
                        setGeoJson(undefined)
                    }
                }}
                setOptions={{
                    showLineNumbers: true,
                    tabSize: 2,
                    useWorker: false,
                    wrap: true,
                }}
                width='100%'
                height='100%'
            />
            {geoJSON &&
                <GeoJSON
                    key={geoJsonString.length}
                    data={geoJSON}
                    onEachFeature={(feature, leafletLayer) => {
                        if (feature.properties) {
                            leafletLayer.bindPopup(() => {
                                return Object.entries(feature.properties).reduce((prev, entry) => {
                                    const [key, value] = entry
                                    return prev + `<tr><td>${key}</td><td>${value}</td></tr>`
                                }, "<table><tr><th>name</th><th>value</th></tr>") + "</table>"
                            }, popupOptions);
                        }
                    }}
                    pointToLayer={((point, latlng) => L.circleMarker(latlng, geojsonMarkerOptions))}
                />
            }
        </Box>
    )
}

export default GeoJSONTab