import { useNavigate } from "react-router-dom";
import { roleInitialPaths } from "../routes/routes";
import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../state/store";

const useTimeoutRedirect = () => {
    const navigate = useNavigate();
    const { roles }: {roles: string[]} = useSelector((state: RootState) => state.auth);
    const timeoutRef = useRef<any | null>(null);

    useEffect(() => {
        timeoutRef.current && clearTimeout(timeoutRef.current);

        timeoutRef.current = setTimeout(() => navigate(roleInitialPaths[roles[0] as keyof typeof roleInitialPaths]), 3000)

        return () => {
            timeoutRef.current && clearTimeout(timeoutRef.current);
        }
    }, [navigate, roles]);
}

export default useTimeoutRedirect;
