import { PayloadAction, createSlice } from "@reduxjs/toolkit"

interface UserConfig {
    mainTutorial: boolean
    processTutorial: boolean
    analysisTutorial: boolean
}

const initialState = {
    mainTutorial: false,
    processTutorial: false,
    analysisTutorial: false,
} as UserConfig

const userConfigSlice = createSlice({
    name: 'userConfig',
    initialState,
    reducers: {
        updateUserConfig(state, action: PayloadAction<Partial<UserConfig>>) {
            if(action.payload.mainTutorial !== undefined) state.mainTutorial = action.payload.mainTutorial
            if(action.payload.processTutorial !== undefined) state.processTutorial = action.payload.processTutorial
            if(action.payload.analysisTutorial !== undefined) state.analysisTutorial = action.payload.analysisTutorial
        },
    },
})

export default userConfigSlice.reducer;

export const {
    updateUserConfig,
} = userConfigSlice.actions
