import { Stack } from "@mui/material"
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { useDispatch, useSelector } from "react-redux"
import { AppDispatch, RootState } from "../../../app/state/store"
import { selectProcessDateById, updateProcessDate } from "../../state/processSlice"
import { safeDayjs } from "../../utils/DateTools"
import "dayjs/locale/es-mx"

const DateProcessInput = ({ id }: { id: string }) => {
    const processDate = useSelector((state: RootState) => selectProcessDateById(state, id))
    const dispatch = useDispatch<AppDispatch>()

    return <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es-mx">
        <Stack direction='row' spacing={3} useFlexGap flexWrap='wrap'>
            <DatePicker
                format='DD/MM/YYYY'
                slotProps={{
                    field: { clearable: true, onClear: () => dispatch(updateProcessDate({ id: id, changes: { date: undefined } })) },
                }}
                label={processDate.name}
                value={safeDayjs(processDate.date) ? safeDayjs(processDate.date) : null}
                onChange={newValue => {
                    dispatch(updateProcessDate({ id: id, changes: { date: newValue?.toISOString() } }))
                }}
                minDate={safeDayjs(processDate.minDate)}
                maxDate={safeDayjs(processDate.maxDate)}
                sx={{ flexGrow: 1 }}
            />
        </Stack>
    </LocalizationProvider>
}

export default DateProcessInput