import { Dispatch, SetStateAction, useMemo } from "react"
import { parse } from "wellknown"
import GeoJsonLayer from "./GeoJsonLayer"
import { LatLngBounds, PathOptions } from "leaflet"

interface WktLayerOptions {
    wktData: string
    setBbox?: Dispatch<SetStateAction<LatLngBounds | undefined>>
    pathOptions?: PathOptions
    pane?: string
    onClick?: (e: any) => void
    tooltip?: any
    onHover?: (e: any) => void
}

const WktLayer = ({ wktData, setBbox, pathOptions, pane, onClick, tooltip, onHover }: WktLayerOptions) => {
    const data = useMemo(() => parse(wktData), [wktData])

    return data && <GeoJsonLayer data={data} setBbox={setBbox} pathOptions={pathOptions} pane={pane} onClick={onClick} tooltip={tooltip} onHover={onHover} />
}

export default WktLayer