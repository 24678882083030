import { StatusType } from "../types/DataTypes";
import { SFStateMachineDefinition, SFExecutionEvent, SFEventDisplay, SFExecutionGraph, SFExecutionGraphEdge, SFExecutionGraphNode, SFStateDisplay, EVENT_TYPE_STATUS } from "../../pipeline/types/StepFunctionTypes";


interface EventMemo {
    step_name: string | undefined
    unresolved_stack: number[]
}

function getStepFunctionExecutionEventInfo(event: SFExecutionEvent, eventMemo: EventMemo[]): { event_status: StatusType, step_name: string | undefined } {
    const event_id = event.id;
    const event_type = event.type;
    const event_status = EVENT_TYPE_STATUS[event_type];

    if (event.stateEnteredEventDetails) {
        const prev_unresolved_stack = eventMemo[event.previousEventId].unresolved_stack;
        eventMemo[event_id] = {
            step_name: event.stateEnteredEventDetails.name,
            unresolved_stack: [...prev_unresolved_stack, event_id]
        };
    }
    else if (event.stateExitedEventDetails){
        const prev_unresolved_stack = eventMemo[event.previousEventId].unresolved_stack;
        eventMemo[event_id] = {
            step_name: event.stateExitedEventDetails.name,
            unresolved_stack: prev_unresolved_stack.slice(0, -1)
        };
    }
    else if (
        event_type === 'ExecutionStarted'
    ) {
        const prev_unresolved_stack = eventMemo[event.previousEventId].unresolved_stack;
        eventMemo[event_id] = {
            step_name: 'start',
            unresolved_stack: prev_unresolved_stack
        };
    }
    else if (
        event_type === 'ExecutionSucceeded'
    ) {
        const prev_unresolved_stack = eventMemo[event.previousEventId].unresolved_stack;
        eventMemo[event_id] = {
            step_name: 'end',
            unresolved_stack: prev_unresolved_stack
        };
    }
    else if (
        event_type === 'ExecutionAborted' ||
        event_type === 'ExecutionFailed' ||
        event_type === 'ExecutionTimedOut' ||
        event_type === 'ExecutionRedriven'
    ) {
        const prev_unresolved_stack = eventMemo[event.previousEventId].unresolved_stack;
        eventMemo[event_id] = {
            step_name: prev_unresolved_stack.length > 0 ? eventMemo[prev_unresolved_stack[prev_unresolved_stack.length - 1]].step_name : undefined,
            unresolved_stack: prev_unresolved_stack
        };
    }
    else {
        const prev_unresolved_stack = eventMemo[event.previousEventId].unresolved_stack;
        eventMemo[event_id] = {
            step_name: eventMemo[prev_unresolved_stack[prev_unresolved_stack.length - 1]].step_name,
            unresolved_stack: prev_unresolved_stack
        };
    }

    return {
        event_status,
        step_name: eventMemo[event_id].step_name
    };
}

export function getStepFunctionStateNames(definition: SFStateMachineDefinition): string[] {
    //traverse the definition using BFS
    const queue: SFStateMachineDefinition[] = [definition];
    const state_names: string[] = [];

    while (queue.length > 0) {
        const currentState = queue.shift()!;
        const states = currentState.States;

        for (const stateName in states) {
            state_names.push(stateName);

            const state = states[stateName];

            if (state.Type === 'Parallel' && state.Branches) {
                for (const branch of state.Branches) {
                    queue.push(branch);
                }
            }

            if (state.Type === 'Map' && state.ItemProcessor) {
                queue.push(state.ItemProcessor);
            }
        }
    }

    return state_names;
}

export function getStepFunctionExecutionEvents(history: SFExecutionEvent[]): SFEventDisplay[] {
    const first_timeStamp = history[0].timestamp;
    const events_length = history.length + 1;
    const eventMemo: EventMemo[] = new Array(events_length)
    eventMemo[0] = {step_name: undefined, unresolved_stack: []};

    const results: SFEventDisplay[] = [];
    for (const event of history) {
        const { step_name } = getStepFunctionExecutionEventInfo(event, eventMemo)

        results.push({
            id: event.id,
            type: event.type,
            name: step_name,
            started_at: event.timestamp,
            started_after: (new Date(event.timestamp).getTime() - new Date(first_timeStamp).getTime()) / 1000
        })
    }

    return results;
}

export function getStepFunctionStateInfo(state_names: string[], history?: SFExecutionEvent[]){
    let states_info: {[key: string]: SFStateDisplay} = {
        start: {
            name: 'start',
            status: '',
            started_at: '',
            finished_at: '',
            duration: 0
        }
    };
    state_names.forEach((state_name) => {
        states_info[state_name] = {
            name: state_name,
            status: '',
            started_at: '',
            finished_at: '',
            duration: 0
        }
    })
    states_info['end'] = {
        name: 'end',
        status: '',
        started_at: '',
        finished_at: '',
        duration: 0
    }

    if (history) {
        const events_length = history.length + 1;
        const eventMemo: EventMemo[] = new Array(events_length)
        eventMemo[0] = {step_name: undefined, unresolved_stack: []};

        for (const event of history) {
            const { event_status, step_name } = getStepFunctionExecutionEventInfo(event, eventMemo)

            if (!step_name) continue;

            const state = states_info[step_name];
            const status = event_status;
            state.status = status;

            if (status === 'running') {
                state.started_at = event.timestamp;
            }
            else if (status === 'finished') {
                state.finished_at = event.timestamp;
                state.duration = (new Date(state.finished_at).getTime() - new Date(state.started_at).getTime()) / 1000;
            }
        }
    }
    return states_info;
}


export function getStepFunctionExecutionStates(definition: SFStateMachineDefinition, history: SFExecutionEvent[]): SFStateDisplay[] {
    const state_names = getStepFunctionStateNames(definition);
    const states_info = getStepFunctionStateInfo(state_names, history);

    return Object.values(states_info);
}

function getStepFunctionNodes(state_names: string[], states_info: {[key: string]: SFStateDisplay}): SFExecutionGraphNode[] {
    return state_names.map(state_name => ({
        id: state_name,
        data: {
            label: state_name,
            status: states_info[state_name].status
        }
    }));
}

function getStepFunctionEdges(definition: SFStateMachineDefinition): SFExecutionGraphEdge[] {
    const edges: SFExecutionGraphEdge[] = [];

    const queue: SFStateMachineDefinition[] = [definition];

    while (queue.length > 0) {
        const currentState = queue.shift()!;
        const states = currentState.States;

        const entries = Object.entries(states);

        for (const [state_name, state] of entries) {
            if (state.Type === 'Parallel' && state.Branches) {
                for (const branch of state.Branches) {
                    queue.push(branch);
                    edges.push({
                        id: `${state_name} -> ${branch.StartAt}`,
                        source: state_name,
                        target: branch.StartAt
                    })
                }
                // parallel success next
                if (state.Next) {
                    const next_state = state.Next;
                    edges.push({
                        id: `${state_name} -> ${next_state}`,
                        source: state_name,
                        target: next_state,
                        isParallelSuccess: true
                    })
                }
                continue;
            }

            if (state.Type === 'Map' && state.ItemProcessor) {
                queue.push(state.ItemProcessor);
                edges.push({
                    id: `${state_name} -> ${state.ItemProcessor.StartAt}`,
                    source: state_name,
                    target: state.ItemProcessor.StartAt
                })
                // map success next
                if (state.Next) {
                    const next_state = state.Next;
                    edges.push({
                        id: `${state_name} -> ${next_state}`,
                        source: state_name,
                        target: next_state,
                        isParallelSuccess: true
                    })
                }
            }

            if (state.Next) {
                const next_state = state.Next;
                edges.push({
                    id: `${state_name} -> ${next_state}`,
                    source: state_name,
                    target: next_state
                })
            }

            if (state.Type === 'Choice') {
                state.Choices.forEach((choice: any) => {
                    const next_state = choice.Next;
                    edges.push({
                        id: `${state_name} -> ${next_state}`,
                        source: state_name,
                        target: next_state
                    })
                })

                const default_state = state.Default;
                if (default_state) {
                    edges.push({
                        id: `${state_name} -> ${default_state}`,
                        source: state_name,
                        target: default_state
                    })
                }
            }

            if (state.End) {
                continue;
            }
        }
    }

    return edges;
}


export function getStepFunctionGraph(definition: SFStateMachineDefinition, history?: SFExecutionEvent[]): SFExecutionGraph {
    const state_names = getStepFunctionStateNames(definition);
    const states_info = getStepFunctionStateInfo(state_names, history);

    const nodes: SFExecutionGraphNode[] = getStepFunctionNodes(state_names, states_info);

    const edges: SFExecutionGraphEdge[] = getStepFunctionEdges(definition);

    return { nodes, edges };
}
