import { Check, Clear } from "@mui/icons-material"
import { Box, List, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material"


export function passwordCheck(password: string) {
    const passwordRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[=#¿?¡!|@$%^&*-.,:;{}()'[\]]).{8,}$/
    return !passwordRegex.test(password)
}

function PasswordHelper({password}: {password: string}) {
    const test1 = /^.*?[A-Z].*?$/.test(password)
    const test2 = /^.*?[=#¿?¡!|@$%^&*-.,:;{}()'[\]].*?$/.test(password)
    const test3 = /^.*?[0-9].*?$/.test(password)
    const test4 = /^.{8,}$/.test(password)

    return <Box>
        <Typography sx={{ fontSize: 12 }}>La contraseña debe cumplir con las siguiente características:</Typography>
        <List dense>
            <ListItem>
                <ListItemIcon>
                    {test1 ? <Check color="success" /> : <Clear color="error" />}
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{ fontSize: 12 }} primary="Contener al menos un caráctere en mayúscula" />
            </ListItem>
            <ListItem>
                <ListItemIcon>
                    {test2 ? <Check color="success" /> : <Clear color="error" />}
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{ fontSize: 12 }} primary="Contener al menos un símbolo" />
            </ListItem>
            <ListItem>
                <ListItemIcon>
                    {test3 ? <Check color="success" /> : <Clear color="error" />}
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{ fontSize: 12 }} primary="Contener al menos un dígito" />
            </ListItem>
            <ListItem>
                <ListItemIcon>
                    {test4 ? <Check color="success" /> : <Clear color="error" />}
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{ fontSize: 12 }} primary="Tener al menos ocho caracteres de largo" />
            </ListItem>
        </List>
    </Box>
}

export default PasswordHelper
